var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getCookie, getOrganizationIdFromStorage, getTokenFromStorage } from '@eva-pacs/core/lib/utils/storage';
import { ENCRYPTED_CREDENTIALS_PARAM, LOGIN_PATH, I18N_LANGUAGE_KEY, VIEWER_PATH } from '@eva-pacs/core/lib/constants';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { GlobalError, useGlobalErrorStore } from '@eva-pacs/app-pacs/src/store/useGlobalErrorStore';
import { UploadLink } from './upload';
import { fromHttpToWebsocketURL, navigateTo, removeAuthCookies } from './utils';
var WS_IS_ACTIVE = process.env.REACT_APP_WS_IS_ACTIVE === 'true';
var API_URL = ((_a = process.env.REACT_APP_API) !== null && _a !== void 0 ? _a : 'https://develop.dev-land.space');
var webSocketURL = "".concat(fromHttpToWebsocketURL((_b = process.env.REACT_APP_GRAPHQL_WS_URL) !== null && _b !== void 0 ? _b : API_URL), "/graphql/");
var errorLink = onError(function (_a) {
    var operation = _a.operation, graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
    var setToastError = useGlobalErrorStore.getState().setToastError;
    if (graphQLErrors && operation)
        graphQLErrors.forEach(function (error) {
            var message = error.message, locations = error.locations, path = error.path;
            console.log("[GraphQL error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path));
            switch (message) {
                case 'Visitant does not have permission to perform this action.':
                    removeAuthCookies();
                    navigateTo(LOGIN_PATH);
                    break;
                case 'You do not have permission to perform this action':
                    removeAuthCookies();
                    navigateTo(LOGIN_PATH);
                    break;
                case 'Expired token.':
                    removeAuthCookies();
                    navigateTo(LOGIN_PATH);
                    break;
                case 'Access denied! You need to be authorized to perform this action!':
                    removeAuthCookies();
                    navigateTo(LOGIN_PATH);
                    break;
                default:
                    console.log('Unhandled error');
                    break;
            }
        });
    if (networkError) {
        var context = operation.getContext();
        if (context.retryCount === 2) {
            setToastError(GlobalError.GLOBAL_ERROR_TIMEOUT);
            console.log("[Timeout error]: ".concat(networkError));
        }
        console.log("[Network error]: ".concat(networkError));
    }
});
var retryLink = new RetryLink({
    delay: { initial: 1000 },
    attempts: function (count, operation, error) {
        var maxAttempts = 2;
        operation.setContext(function (_a) {
            var _b = _a.retryCount, retryCount = _b === void 0 ? 1 : _b;
            return ({
                retryCount: retryCount + 1,
            });
        });
        return !!error && count < maxAttempts;
    },
});
var uploadLink = new UploadLink({
    uri: "".concat(API_URL, "/graphql/"),
});
var wsLink = new WebSocketLink(new SubscriptionClient(webSocketURL, {
    reconnect: true,
    connectionParams: function () {
        var token = getTokenFromStorage();
        return __assign({}, (token && {
            Authorization: "JWT ".concat(token),
        }));
    },
}));
var authLink = setContext(function (_, _a) {
    var _b = _a.headers, headers = _b === void 0 ? {} : _b;
    var finalHeaders = getHeaders(headers);
    return { headers: finalHeaders };
});
/**
 * Return the headers to the context so httpLink can read them
 */
var getHeaders = function (headers) {
    var _a;
    if (headers === void 0) { headers = {}; }
    var token = getTokenFromStorage();
    var acceptLanguage = getCookie(I18N_LANGUAGE_KEY);
    var searchParams = new URLSearchParams(window.location.search);
    var mutableHeaders = new Headers();
    var finalHeaders = {};
    var isViewer = window.location.pathname.includes(VIEWER_PATH);
    var studyId = (_a = window.location.pathname.split(VIEWER_PATH)[1]) === null || _a === void 0 ? void 0 : _a.replace('/', '');
    var visitantToken = searchParams.get(ENCRYPTED_CREDENTIALS_PARAM);
    Object.entries(headers).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        return mutableHeaders.set(key, "".concat(value));
    });
    if (token) {
        mutableHeaders.set('Authorization', "JWT ".concat(token));
        mutableHeaders.set('Organization-Id', getOrganizationIdFromStorage());
    }
    if (visitantToken)
        mutableHeaders.set('Credentials', visitantToken);
    if (isViewer)
        mutableHeaders.set('Resource', studyId);
    if (acceptLanguage)
        mutableHeaders.set('Accept-Language', acceptLanguage);
    mutableHeaders.forEach(function (value, key) { return (finalHeaders[key] = value); });
    return finalHeaders;
};
var httpLink = from([authLink, retryLink, errorLink, uploadLink]);
var fullLink = split(function (_a) {
    var query = _a.query;
    var definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}, wsLink, httpLink);
export var apolloClient = new ApolloClient({
    connectToDevTools: true,
    link: WS_IS_ACTIVE ? fullLink : httpLink,
    cache: new InMemoryCache(),
});
