import { GetState, SetState } from 'zustand';
import type { KeyMap, ApplicationKeyMap, ExtendedKeyMapOptions } from 'react-hotkeys';

export interface HotkeysStore {
  hotkeys: KeyMap;
  activeToolbarTool: string;

  setHotkeys: (hotkeys: ApplicationKeyMap) => void;
  // No replace existing fixed hotkeys
  setCustomHotkeys: (hotkeys: ApplicationKeyMap) => void;
  getHotkeys: () => KeyMap;
}

const hotkeys: HotkeysStore['hotkeys'] = {
  DELETE_MEASUREMENT: {
    name: '',
    sequences: ['Delete', 'Backspace', 'Del', 'del', 'backspace', 'delete'],
    action: 'keydown',
  } as ExtendedKeyMapOptions,
  COPY_MEASUREMENT: {
    name: '',
    sequences: ['command+c', 'command+C', 'ctrl+c', 'ctrl+C'],
    action: 'keydown',
  } as ExtendedKeyMapOptions,
  PASTE_MEASUREMENT: {
    name: '',
    sequences: ['command+v', 'command+V', 'ctrl+v', 'ctrl+V'],
    action: 'keydown',
  } as ExtendedKeyMapOptions,
  NEXT_WINDOW: {
    name: 'viewer.shortcuts.nextWindow',
    sequence: 'right',
    action: 'keydown',
  },
  PREV_WINDOW: {
    name: 'viewer.shortcuts.previusWindow',
    sequence: 'left',
    action: 'keydown',
  },
  ROTATE_RIGHT: {
    name: 'viewer.shortcuts.rotateR',
    sequence: 'r',
    action: 'keydown',
  },
  ROTATE_LEFT: {
    name: 'viewer.shortcuts.rotateL',
    sequence: 'l',
    action: 'keydown',
  },
  INVERT_COLORS: {
    name: 'viewer.shortcuts.invert',
    sequence: 'i',
    action: 'keydown',
  },
  FLIP_HORIZONTAL: {
    name: 'viewer.shortcuts.flipH',
    sequence: 'h',
    action: 'keydown',
  },
  FLIP_VERTICAL: {
    name: 'viewer.shortcuts.flipP',
    sequence: 'v',
    action: 'keydown',
  },
  ZOOM_IN: {
    name: 'viewer.shortcuts.zoomI',
    sequence: '+',
    action: 'keydown',
  },
  ZOOM_OUT: {
    name: 'viewer.shortcuts.zoomO',
    sequence: '-',
    action: 'keydown',
  },
  ZOOM_TO_FIT: {
    name: 'viewer.shortcuts.zoomF',
    sequence: '=',
    action: 'keydown',
  },
  UNDO: {
    name: 'viewer.shortcuts.restore',
    sequence: 'space',
    action: 'keydown',
  },
  NEXT_IMAGE: {
    name: 'viewer.shortcuts.nextImage',
    sequence: 'down',
    action: 'keydown',
  },
  PREV_IMAGE: {
    name: 'viewer.shortcuts.prevImage',
    sequence: 'up',
    action: 'keydown',
  },
  NEXT_SERIE: {
    name: 'viewer.shortcuts.nextSerie',
    sequence: 'Shift+up',
    action: 'keydown',
  },
  PREV_SERIE: {
    name: 'viewer.shortcuts.prevSerie',
    sequence: 'Shift+down',
    action: 'keydown',
  },
  ZOOM: {
    name: 'viewer.toolbar.zoom',
    sequence: 'z',
    action: 'keydown',
  },
};

const hotKeysStore = (set: SetState<HotkeysStore>, get: GetState<HotkeysStore>) => ({
  hotkeys,
  setHotkeys: (hotkeys) => {
    return set((state) => {
      state.hotkeys = hotkeys;
    });
  },
  setCustomHotkeys: (hotkeys) => {
    const draftHotkeys = get().hotkeys;
    const fixedHotkeys = Object.keys(draftHotkeys)
      .filter((key) => !(draftHotkeys[key] as ExtendedKeyMapOptions)?.name)
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: draftHotkeys[key] });
      }, {});
    return set((state) => {
      state.hotkeys = { ...hotkeys, ...fixedHotkeys };
    });
  },
  getHotkeys: () => {
    return get().hotkeys;
  },
});

export { hotKeysStore };
