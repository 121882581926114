import cn from 'classnames';
import { PopoverPlacement } from './';
/* Class assignment */
export var popoverContainerClass = function (className) {
    return cn(className, 
    /* Popover container (base) */
    'popover-container shadow-lg rounded-md z-20');
};
export var popoverClass = function (className) {
    return cn(className, 
    /* Popover (base) */
    'bg-basic-black rounded-md', 'text-basic-white text-caption text-center', 'rounded', 'transition duration-100 ease-out');
};
export var arrowContainerClass = function (placement) {
    return cn(
    /* Popover arrow container (base) */
    'absolute w-2 h-2 bg-basic-black invisible', 
    /* arrow placement */
    placement.includes(PopoverPlacement.top) && '-bottom-1', placement.includes(PopoverPlacement.bottom) && '-top-1', placement.includes(PopoverPlacement.left) && '-right-0', placement.includes(PopoverPlacement.right) && '-left-2');
};
export var arrowClass = cn(
/* Popover arrow (base) */
'bg-basic-black absolute w-2 h-2 visible transform rotate-45');
