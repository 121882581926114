var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback } from 'react';
import { StepState } from './Step';
import cn from 'classnames';
/**
 * Steps helps to control the selection between multiple Step components
 * @author Alan Chávez <alan.chavez@evacenter.com>
 * Created at 2022-06-01
 */
export var Steps = function (_a) {
    var children = _a.children, currentStep = _a.currentStep, _b = _a.currentStepState, currentStepState = _b === void 0 ? StepState.Active : _b, _c = _a.shouldCompleteAllSteps, shouldCompleteAllSteps = _c === void 0 ? false : _c, className = _a.className, _d = _a.dataTestId, dataTestId = _d === void 0 ? 'steps' : _d;
    var getStepState = useCallback(function (step) {
        if (step === currentStep && !shouldCompleteAllSteps)
            return currentStepState !== null && currentStepState !== void 0 ? currentStepState : StepState.Active;
        if (currentStep > step || shouldCompleteAllSteps)
            return StepState.Success;
    }, [currentStep, shouldCompleteAllSteps, currentStepState]);
    var totalSteps = React.Children.count(children);
    var stepsChildren = React.Children.map(children, function (child, index) {
        var item = child;
        var step = item.props.step;
        var _step = step !== null && step !== void 0 ? step : index + 1;
        return React.cloneElement(child, __assign(__assign(__assign({}, item.props), { step: _step, isLastStep: _step === totalSteps }), (!item.props.state && {
            state: getStepState(_step),
        })));
    });
    var stepsClasses = {
        container: cn(className, 'flex flex-col'),
    };
    return (React.createElement("div", { className: stepsClasses.container, "data-testid": dataTestId }, stepsChildren));
};
