var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* --- DEPENDENCIES --- */
import { useContext, useEffect, useState, createContext } from 'react';
import cn from 'classnames';
export var TabsContext = createContext({});
export var TabsProvider = function (_a) {
    var defaultSelected = _a.defaultSelected, className = _a.className, children = _a.children, ariaLabel = _a.ariaLabel, restProps = __rest(_a, ["defaultSelected", "className", "children", "ariaLabel"]);
    var _b = useState(defaultSelected), activeTab = _b[0], setChangeTab = _b[1];
    var tabsContainerClass = cn(className);
    var changeTab = function (tab) { return setChangeTab(tab); };
    useEffect(function () {
        changeTab(defaultSelected);
    }, [defaultSelected]);
    var tabProviderValue = {
        activeTab: activeTab,
        changeTab: changeTab,
    };
    return (_jsx(TabsContext.Provider, { value: tabProviderValue, children: _jsx("div", __assign({ className: tabsContainerClass, "aria-label": ariaLabel }, restProps, { children: children })) }));
};
export var useTabs = function () {
    return useContext(TabsContext);
};
