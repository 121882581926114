import { USRegion } from '../USHelpers';

export const isPointInRegion = (point: [number, number], region: USRegion) => {
  const [x, y] = point;
  return (
    x >= (region.regionLocationMinX0 || 0) &&
    x <= (region.regionLocationMaxX1 || 0) &&
    y >= (region.regionLocationMinY0 || 0) &&
    y <= (region.regionLocationMaxY1 || 0)
  );
};
