import cn from 'classnames';
import { SwitchSize } from './';
/* Class assignment */
export var switchClass = function (className, size, isDisabled) {
    return cn(className, 
    /* Switch (base) */
    'relative', 
    /* Switch--xs */
    size === SwitchSize.sm && 'w-6 h-4', 
    /* Switch--isDisabled */
    isDisabled === true && 'opacity-5 pointer-events-none');
};
export var inputClass = function (size) {
    return cn(
    /* Switch (base) */
    'absolute z-10 opacity-0', 
    /* Switch--xs */
    size === SwitchSize.sm && 'w-6 h-4');
};
export var switchInnerClass = function (size, isOn) {
    return cn(
    /* Switch (base) */
    'absolute top-0', 
    /* Switch--xs */
    size === SwitchSize.sm && 'w-6 h-4 rounded-2xl', 
    /* isOn */
    !isOn && 'bg-gray-100', isOn && 'bg-primary-400');
};
export var switchMarkerClass = function (size, isOn) {
    return cn(
    /* Switch (base) */
    'rounded-full bg-basic-white absolute shadow-sm transition-all duration-100 ease-in', 
    /* Switch--xs */
    size === SwitchSize.sm && 'w-3 h-3 left-0.5 top-0.5', 
    /* isOn */
    isOn && 'transform translate-x-2');
};
