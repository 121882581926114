import type { Mat, MatVector, Point } from 'mirada';
import { LATERALITY_TYPE } from '~/src/constants';
import {
  DEFAULT_DILATION_MATRIX_SIZE,
  MAX_BACKGROUND_MAMMOGRAPHY_GRAY_TONE_RANGE,
  MAX_BACKGROUND_MAMMOGRAPHY_GRAY_TONE_TO_CONTRAST,
  BASE_CONTRAST_LEVEL,
  MAX_GRAY_TONE_VALUE,
  MIN_MAX_GRAY_TONE_VALUE,
} from '~/src/constants';
interface Box {
  x: number;
  y: number;
  width: number;
  height: number;
}
export const imageToGrayScale = (src: Mat) => {
  const dst = new cv.Mat();
  cv.cvtColor(src, dst, cv.COLOR_BGR2GRAY);
  return dst;
};

export const loadImage = (canvasSrc: HTMLCanvasElement | null) => {
  if (!canvasSrc) return null;
  return cv.imread(canvasSrc);
};

export const drawImageInCanvas = (src?: Mat, canvasTarget?: HTMLCanvasElement | null) => {
  if (!canvasTarget! || !src) return;
  cv.imshow(canvasTarget, src);
};

export const applyDilaton = (src: Mat, matrixSize = DEFAULT_DILATION_MATRIX_SIZE, iterations = 1) => {
  const borderValue = cv.Scalar.all(Number.MIN_VALUE);
  const dilate = new cv.Mat();
  const kernel = cv.Mat.ones(matrixSize, matrixSize, cv.CV_8U);
  const anchor = new cv.Point(-1, -1);
  cv.dilate(src, dilate, kernel, anchor, iterations, cv.BORDER_CONSTANT, borderValue);
  return dilate;
};

export const applyErosion = (src: Mat, matrixSize = 5, iterations = 1) => {
  const erodeImg = new cv.Mat();
  const kernel = cv.Mat.ones(matrixSize, matrixSize, cv.CV_8U);
  const anchor = new cv.Point(-1, -1);
  cv.erode(src, erodeImg, kernel, anchor, iterations, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
  return erodeImg;
};

export const applyHighContrast = (src: Mat, backgroundTone: number, alpha = BASE_CONTRAST_LEVEL, beta = 0) => {
  const highContrast = new cv.Mat();

  const alphaLevel =
    backgroundTone > MAX_BACKGROUND_MAMMOGRAPHY_GRAY_TONE_TO_CONTRAST
      ? alpha
      : (MAX_BACKGROUND_MAMMOGRAPHY_GRAY_TONE_TO_CONTRAST / 2 - Math.floor(backgroundTone / 2)) * alpha;
  cv.convertScaleAbs(src, highContrast, alphaLevel, beta);
  return highContrast;
};

export const removeMaxTonePixels = (src: Mat) => {
  const dst = src.clone();
  for (let y = 0; y < src.rows; y++) {
    for (let x = 0; x < src.cols; x++) {
      const value = src.ucharPtr(y, x)[0];
      if (value >= MIN_MAX_GRAY_TONE_VALUE && value <= MAX_GRAY_TONE_VALUE) {
        dst.ucharPtr(y, x)[0] = 0;
      }
    }
  }
  return dst;
};

export const applyThresholdOtsu = (src: Mat) => {
  const dst = new cv.Mat();
  const idealTreshValue = cv.threshold(src, dst, 0, MAX_GRAY_TONE_VALUE, cv.THRESH_OTSU);
  cv.threshold(dst, dst, idealTreshValue, MAX_GRAY_TONE_VALUE, cv.THRESH_BINARY);
  return dst;
};

export const applyGaussianBlur = (
  src: Mat,
  kernelSize: number,
  sigmaX: number,
  sigmaY?: number,
  borderType?: number,
  iterations = 1,
) => {
  const ksize = new cv.Size(kernelSize, kernelSize);
  const smoothedImage = src.clone();
  let round = 0;
  do {
    cv.GaussianBlur(smoothedImage, smoothedImage, ksize, sigmaX, sigmaY, borderType);
    round++;
  } while (round < iterations);
  return smoothedImage;
};

export const getHistogram = (src: Mat) => {
  const srcVec = new cv.MatVector();
  srcVec.push_back(src);
  const channels = [0]; // Only need one because src is in gray scale
  const histSize = [256]; // All gray tones
  const ranges = [0, 255]; // All black-white range
  const hist = new cv.Mat();
  const mask = new cv.Mat();
  cv.calcHist(srcVec, channels, mask, hist, histSize, ranges);
  mask.delete();
  srcVec.delete();
  return hist;
};

const getPredominantBackgroundTone = (src: Mat) => {
  const histogram = getHistogram(src);
  let mode = 0;
  //Review the number of occurrences of each tone to find the mode
  for (let tone = 0; tone < MAX_BACKGROUND_MAMMOGRAPHY_GRAY_TONE_RANGE; tone++) {
    if (histogram.data32F[mode] <= histogram.data32F[tone]) mode = tone;
  }
  return mode;
};

/**
 * Prepares the image to find edges.
 * @param {Mat} src - The source image in Mat format.
 * @returns {Mat} - Processed image with enhanced edge detection capability.
 */

export const prepareImageToFindEdges = (src: Mat) => {
  const grayIMG = imageToGrayScale(src);
  // Remove somo borders and text
  const cleanedIMG = removeMaxTonePixels(grayIMG);
  const backgroundTone = getPredominantBackgroundTone(cleanedIMG);
  const highContrastImg = applyHighContrast(cleanedIMG, backgroundTone);
  const thresholdImg = applyThresholdOtsu(highContrastImg);
  grayIMG.delete();
  highContrastImg.delete();
  return thresholdImg;
};

/**
 * Finds the biggest contour in the given image using OpenCV.
 * @param src The input image from which the biggest contour will be identified (OpenCV Mat object).
 * @returns A tuple containing the index of the biggest contour, the contours, and the hierarchy.
 */

export const getBiggestImageContour = (src: Mat): [number, MatVector, Mat] => {
  const contours = new cv.MatVector();
  const hierarchy = new cv.Mat();
  const img = prepareImageToFindEdges(src);
  cv.findContours(img, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
  img.delete();
  let biggestContourIndex = 0;
  for (let index = 0; index < Number(contours.size()); ++index) {
    const contour = contours.get(index);
    const biggestContour = contours.get(biggestContourIndex);
    const currentBox = cv.boundingRect(contour);
    const biggestBox = cv.boundingRect(biggestContour);
    if (!biggestBox || currentBox.width * currentBox.height > biggestBox.width * biggestBox.height)
      biggestContourIndex = index;
  }
  return [biggestContourIndex, contours, hierarchy];
};

/**
 * Finds the extreme point in a contour based on the direction specified.
 * @param {Mat} contour - The contour to search within.
 * @param {string} direction - The direction ('L' or 'R') to find the extreme point.
 * @returns {{x: number, y: number}} - Object representing the extreme point's coordinates.
 */
export function findExtremePointInContour(contour: Mat, direction: LATERALITY_TYPE = LATERALITY_TYPE.L) {
  const extremePoint = { x: direction !== LATERALITY_TYPE.L ? Infinity : -Infinity, y: 0 }; // Initialize x with large value for left, small value for right
  for (let i = 0; i < contour.data32S.length; i += 2) {
    const x = contour.data32S[i];
    const y = contour.data32S[i + 1];
    if (direction === LATERALITY_TYPE.R && x < extremePoint.x) {
      extremePoint.x = x;
      extremePoint.y = y;
    } else if (direction === LATERALITY_TYPE.L && x > extremePoint.x) {
      extremePoint.x = x;
      extremePoint.y = y;
    }
  }
  return extremePoint?.x === Infinity || extremePoint?.x === -Infinity ? null : extremePoint;
}

/**
 * Finds the extreme point in an array based on the direction specified.
 * @param {Mat} contour - The contour to search within.
 * @param {string} direction - The direction ('R' or 'L') to find the extreme point.
 * @returns {{x: number, y: number}} - Object representing the extreme point's coordinates.
 */
export function findExtremePointInArray(
  list: Array<{ x: number; y: number }>,
  direction: LATERALITY_TYPE = LATERALITY_TYPE.L,
) {
  const extremePoint = { x: direction !== LATERALITY_TYPE.L ? Infinity : -Infinity, y: 0 }; // Initialize x with large value for left, small value for right
  for (let i = 0; i < list.length; i += 1) {
    const { x, y } = list[i];
    if (direction === LATERALITY_TYPE.L && x > extremePoint.x) {
      extremePoint.x = x;
      extremePoint.y = y;
    } else if (direction === LATERALITY_TYPE.R && x < extremePoint.x) {
      extremePoint.x = x;
      extremePoint.y = y;
    }
  }
  return extremePoint?.x === Infinity || extremePoint?.x === -Infinity ? null : extremePoint;
}

export const imageToContours = (src: Mat, lineThickness = 10, maxLevelNestedContours = 1) => {
  const imageContours = cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC3);
  const [biggestContourIndex, contours, hierarchy] = getBiggestImageContour(src);
  // Color white to draw contour line;
  const color = new cv.Scalar(255, 255, 255);

  cv.drawContours(
    imageContours,
    contours,
    biggestContourIndex,
    color,
    lineThickness,
    cv.LINE_8,
    hierarchy,
    maxLevelNestedContours,
  );
  return { imageContours, contours, biggestContourIndex };
};

/**
 * Retrieves the bounding box and the biggest contour from an image using OpenCV.
 * @param src The input image from which the bounding box and contour will be determined (OpenCV Mat object).
 * @returns An object containing the bounding box coordinates and the biggest contour.
 */

export const getBoundingBox = (src: Mat) => {
  const [biggestContourIndex, contours, hierarchy] = getBiggestImageContour(src);
  const biggestContour = contours.get(biggestContourIndex);
  const box = cv.boundingRect(biggestContour);
  contours.delete();
  hierarchy.delete();
  return { box, biggestContour };
};

/**
 * Get possible positions of nipple marks in the image based on contours' circularity.
 * @param {MatVector} contours - Collection of contours.
 * @returns {Array.<{ point: Point, index: number }>} - Array of objects with potential nipple mark positions and their indices.
 */
export const getPosibleNippleMarkPositionInImage = (contours?: Mat) => {
  const markPoints: Array<{ point: Point; index: number }> = [];
  if (!contours) return markPoints;
  for (let i = 0; i < Number(contours.size()); i++) {
    const perimeter = cv.arcLength(contours.get(i), true);
    const area = cv.contourArea(contours.get(i));

    const circularity = (4 * Math.PI * area) / (perimeter * perimeter);
    const circularityThreshold = 0.85;

    if (circularity > circularityThreshold) {
      const moments = cv.moments(contours.get(i), false);
      const x = moments.m10 / moments.m00;
      const y = moments.m01 / moments.m00;
      markPoints.push({ point: { x, y }, index: i });
    }
  }
  return markPoints;
};

/**
 * Finds stationary points in a contour.
 * @doc https://www.ncl.ac.uk/webtemplate/ask-assets/external/maths-resources/core-mathematics/calculus/stationary-points.html#:~:text=A%20stationary%20point%20of%20a,is%20neither%20increasing%20nor%20decreasing
 * @param {Mat} contour - The contour of the image.
 * @param {number} [epsilon=0.7] - Threshold to determine stationary points.
 * @returns {Array.<{x: number, y: number}>} - Array of objects with coordinates of stationary points.
 */

export const getStationaryPoints = (contour: Mat, epsilon = 0.7) => {
  const pointsDerivativeZero: Array<Point> = [];
  const contourData = contour.data32S;
  for (let i = 1; i < contourData.length - 3; i += 2) {
    const prevX = contourData[i - 2];
    const prevY = contourData[i - 1];
    const currentY = contourData[i];
    const currentX = contourData[i + 1];
    const nextX = contourData[i + 2];
    const nextY = contourData[i + 3];

    const derivateX = (nextX - prevX) / 2;
    const derivateY = (nextY - prevY) / 2;

    if (Math.abs(derivateX) < epsilon && Math.abs(derivateY) < epsilon) {
      pointsDerivativeZero.push({ x: currentX, y: currentY });
    }
  }
  return pointsDerivativeZero;
};

/**
 * Draws a rectangle on an image using OpenCV.
 * @param src The input image on which the rectangle will be drawn (OpenCV Mat object).
 * @param box The coordinates and dimensions of the rectangle (Box object).
 * @param lineThickness The thickness of the lines used to draw the rectangle (default: 1).
 * @param shift The number of fractional bits in the point coordinates (default: 0).
 * @returns The image with the rectangle drawn on it.
 */

export const drawRectangle = (src: Mat, box: Box, lineThickness = 1, shift = 0) => {
  const srcCopy = src.clone();
  const rectangleColor = new cv.Scalar(96, 176, 255); // bg-color-300
  const point1 = new cv.Point(box.x, box.y);
  const point2 = new cv.Point(box.x + box.width, box.y + box.height);
  cv.rectangle(srcCopy, point1, point2, rectangleColor, lineThickness, cv.LINE_AA, shift);
  return srcCopy;
};

/**
 * Scales an image using OpenCV.
 * @param src The input image to be scaled (OpenCV Mat object).
 * @param scale The scaling factor for the image.
 * @returns The scaled image.
 */

export const scaleImage = (src: Mat, scale: number) => {
  const size = new cv.Size(src.cols * scale, src.rows * scale);
  const dst = new cv.Mat();
  cv.resize(src, dst, size, 0, 0, cv.INTER_AREA);
  return dst;
};
