import create, { SetState } from 'zustand';
import produce from 'immer';
import { Markers } from '~/utils/cornestone/SpineLabel/Markers';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

/**
 * Spine Label Store
 */
export interface SpineLabelStore {
  panelIsOpen: boolean;
  setPanelIsOpen: (isOpen: boolean) => void;
  propagation: number;
  setPropagation: (propagation?: number) => void;
  interval: number;
  setInterval: (interval?: number) => void;
  ascending: boolean;
  setAscending: (ascending: boolean) => void;
  crossVisibility: boolean;
  setCrossVisibility: (crossVisibility: boolean) => void;
  current: string;
  setCurrent: (current: string) => void;
}

const spineLabelStore = (set: SetState<SpineLabelStore>): SpineLabelStore => ({
  panelIsOpen: false,
  setPanelIsOpen: (isOpen) => {
    return set((state) => {
      state.panelIsOpen = isOpen;
    });
  },
  propagation: 10,
  setPropagation: (propagation) => {
    return set((state) => {
      state.propagation = propagation ?? 1;
    });
  },
  interval: 1,
  setInterval: (interval) => {
    return set((state) => {
      state.interval = interval ?? 1;
    });
  },
  ascending: true,
  setAscending: (ascending: boolean) => {
    return set((state) => {
      state.ascending = ascending;
    });
  },
  crossVisibility: true,
  setCrossVisibility: (crossVisibility: boolean) => {
    return set((state) => {
      state.crossVisibility = crossVisibility;
    });
  },
  current: Markers.C1,
  setCurrent: (current: string) => {
    return set((state) => {
      state.current = current;
    });
  },
});

export const useSpineLabelStore = create<SpineLabelStore>(immer(spineLabelStore));
