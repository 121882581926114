import React from 'react';
import Icon, { IconCatalog } from '../../atoms/Icon';
import { alertClass, iconClass } from './styles';
export var AlertVariant;
(function (AlertVariant) {
    AlertVariant["info"] = "info";
    AlertVariant["success"] = "success";
    AlertVariant["warning"] = "warning";
    AlertVariant["error"] = "error";
})(AlertVariant || (AlertVariant = {}));
/**
 * Generally, an alert displays a prominent message at the top of the screen.
 * It could be used to promote a new feature or provide action-based feedback messages.
 * They’re persistent and nonmodal, allowing the user to either ignore them or interact with them at any time.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-08-04
 */
export var Alert = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? AlertVariant.info : _b, title = _a.title, description = _a.description, _c = _a.hasIcon, hasIcon = _c === void 0 ? true : _c, _d = _a.hasCloseButton, hasCloseButton = _d === void 0 ? true : _d, className = _a.className;
    return (React.createElement("div", { className: alertClass(className, variant), role: "alert", "aria-label": "polite", "aria-labelledby": "title", "aria-describedby": "description" },
        hasIcon && (React.createElement("span", { className: iconClass(variant) },
            React.createElement(Icon, { icon: IconCatalog.warning, width: "24", height: "24" }))),
        React.createElement("div", { className: "flex flex-col" },
            title && (React.createElement("div", { id: "title", className: "mb-1 font-semi-bold" }, title)),
            React.createElement("div", { id: "description", className: "text-label-md" }, description)),
        hasCloseButton && (React.createElement("button", { type: "button", className: "ml-auto text-light-gray-600", "aria-label": "Close Alert" },
            React.createElement(Icon, { icon: IconCatalog.close, width: "24", height: "24" })))));
};
