import cornerstone from 'cornerstone-core';

export const getEnabledElementControlled = (element: HTMLElement | undefined | null) => {
  if (!element) return null;
  try {
    return cornerstone.getEnabledElement(element);
  } catch (error) {
    return null;
  }
};
