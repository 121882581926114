import { MODALITY_TYPES } from '@eva-pacs/core';
import { LATERALITY_TYPE, MG_VARIANTS, VIEW_POSITION } from '~/constants';
import type { SeriesThumbnailsType } from '@eva-pacs/client';
import { createMetaSerieId } from './metaSerieId';

interface MetaSerieItem {
  id: string;
  instanceCount: number;
  meta: {
    originSerieId: string;
    metaSerieVariant: MG_VARIANTS; //In the future, this should be for another type of studies
    instanceIds: Array<string>;
    originalDescription: string;
  };
}

export const isASplittableSerie = (serie: SeriesThumbnailsType) => {
  // For now, only MG series are splittable
  if (serie.modality !== MODALITY_TYPES.MG) return false;
  return serie.series.dicomTags && serie.series.dicomTags?.length > 0;
};

export const splitMGSerie = (serie: SeriesThumbnailsType) => {
  const { series } = serie;

  if (!series.dicomTags || !series.dicomTags?.length) {
    return [];
  }

  const CCLImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      originalDescription: '',
      metaSerieVariant: MG_VARIANTS.CCL,
      instanceIds: [],
    },
  };
  const MLOLImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      originalDescription: '',
      metaSerieVariant: MG_VARIANTS.MLOL,
      instanceIds: [],
    },
  };
  const CCRImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      originalDescription: '',
      metaSerieVariant: MG_VARIANTS.CCR,
      instanceIds: [],
    },
  };
  const MLORImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      originalDescription: '',
      metaSerieVariant: MG_VARIANTS.MLOR,
      instanceIds: [],
    },
  };
  const OtherImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      originalDescription: '',
      metaSerieVariant: MG_VARIANTS.CCL,
      instanceIds: [],
    },
  };

  series.dicomTags.forEach((serieTagsInfo) => {
    if (!serieTagsInfo?.tagValues) return;
    const { Laterality, ViewPosition, ImageLaterality } = JSON.parse(serieTagsInfo.tagValues) || {};
    const instanceLateralities = [ImageLaterality, Laterality];
    if (instanceLateralities.includes(LATERALITY_TYPE.R) && ViewPosition === VIEW_POSITION.CC) {
      CCRImages.meta.instanceIds.push(serieTagsInfo.instance);
    } else if (instanceLateralities.includes(LATERALITY_TYPE.L) && ViewPosition === VIEW_POSITION.MLO) {
      MLOLImages.meta.instanceIds.push(serieTagsInfo.instance);
    } else if (instanceLateralities.includes(LATERALITY_TYPE.L) && ViewPosition === VIEW_POSITION.CC) {
      CCLImages.meta.instanceIds.push(serieTagsInfo.instance);
    } else if (instanceLateralities.includes(LATERALITY_TYPE.R) && ViewPosition === VIEW_POSITION.MLO) {
      MLORImages.meta.instanceIds.push(serieTagsInfo.instance);
    } else {
      OtherImages.meta.instanceIds.push(serieTagsInfo.instance);
    }
  });

  const metaSeries = [CCRImages, CCLImages, MLORImages, MLOLImages, OtherImages].filter(
    (series) => series.meta.instanceIds.length > 0,
  );

  metaSeries.forEach((metaSerie, index) => {
    metaSerie.instanceCount = metaSerie.meta.instanceIds.length;
    metaSerie.meta.originalDescription = serie.dicomDescription || '';
    metaSerie.id = createMetaSerieId(serie.id, index);
  });
  return metaSeries;
};

export const createMetaSeries = (serie) => {
  if (serie.modality === MODALITY_TYPES.MG) {
    return splitMGSerie(serie);
  }
  return [];
};
