import { useMemo } from 'react';
import shallow from 'zustand/shallow';
import { formatStringNoTimeDate, formatStringDateTime } from '@eva-pacs/core';

import { useStudyListStore } from '~/src/store';
import { StudyRow } from '~/components/StudyList/studyList';
import { useScreenPreferences, useStudyPatientAge } from '~/hooks';

/**
 * This hook is in charge of updating the rows in the study table whenever studies
 * has changed in the studyListStore. This is performing automatic updates for the rows
 * and the only base of truth is studies prop from the studyListStore.
 * @author Salvador Gonzalez<salvador.gonzalez@edenmed.com>
 * Created at 2024-02-29
 */
export const useStudyTableUtils = () => {
  const { getYearslabel } = useStudyPatientAge();
  const { activeScreenPreference } = useScreenPreferences();
  const studies = useStudyListStore((state) => state.studies, shallow);

  const rows = useMemo(() => {
    if (!studies) return [];
    const parsedRows = <Array<StudyRow>>[];
    for (const study of Object.values(studies)) {
      const item: StudyRow = ({
        patient: {
          screenPreferences: activeScreenPreference,
          id: study?.id,
          identifier: study.patient?.identifier,
          identifierEditable: study?.patient?.identifierEditable,
          name: study?.patient?.fullName,
          age: getYearslabel({
            currentAge: study?.patientAge,
            currentMonthAge: study?.patientCurrentMonthAge,
            currentDayAge: study?.patientCurrentDayAge,
          }),
          gender: study?.patient?.gender,
        },
        criticalValues: study?.criticalValues,
        modalities: {
          name: study?.modalities,
          folio: study?.folio,
        },
        enableEntranceAnimation: study.enableEntranceAnimation,
        isSwapStudy: study.isSwapStudy,
        dicomDescription: study?.dicomDescription,
        dicomReason: study?.dicomReason,
        dicomReferringPhysician: study?.dicomReferringPhysician,
        dicomRequestingPhysician: study?.dicomRequestingPhysician,
        facilityId: study?.facilityId,
        referringPractitioner: {
          id: study?.referringPractitioner?.id,
          fullName: study?.referringPractitioner?.fullName,
          email: study?.referringPractitioner?.email,
          hasAccess: study?.referringPractitioner?.hasAccess,
          phone: study?.referringPractitioner?.phone,
          status: study?.referringPractitioner?.status,
        },
        facility: study?.facilityName,
        facilityTimezone: study?.facilityTimezone,
        studyType: study?.studyType,
        reviewRequired: study?.reviewRequired,
        reviewerPractitioner: {
          value: study.reviewerPractitionerId || '',
          studyId: study?.id,
          default: {
            value: study.reviewerPractitionerId,
            label: study.reviewerPractitionerFullName,
          },
        },
        practitionerAssigned: {
          value: study.practitionerAssignedId || '',
          studyId: study?.id,
          default: {
            value: study.practitionerAssignedId,
            label: study.practitionerAssignedFullName,
          },
        },
        status: study.studyStatus,
        urgencyLevel: {
          level: study?.urgencyLevel,
          studyId: study?.id,
        },
        dicomDateTime: {
          time: study?.dicomDateTime || study?.createdAt,
          captureDate: study?.dicomDateTime ? formatStringNoTimeDate(study?.dicomDateTime) : '',
          dateAndTime: study?.dicomDateTime ? formatStringDateTime(study?.dicomDateTime) : '',
        },
        dicomDateTimeEditable: study?.dicomDateTimeEditable,
        createdAt: {
          time: study?.createdAt,
          createdDate: study?.createdAt ? formatStringNoTimeDate(study?.createdAt) : '',
        },
        actionColumn: study?.id,
      } as unknown) as StudyRow;

      parsedRows.push(item);
    }
    return parsedRows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies, activeScreenPreference]);

  return {
    rows,
  };
};
