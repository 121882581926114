var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable i18next/no-literal-string */
import React, { Children, cloneElement, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { hasProp, useOnClickOutside } from '@eva-pacs/core';
import Portal from '../Portal';
import { arrowClass, arrowContainerClass, popoverContainerClass, popoverClass } from './styles';
export var PopoverPlacement;
(function (PopoverPlacement) {
    PopoverPlacement["topLeft"] = "top-start";
    PopoverPlacement["top"] = "top";
    PopoverPlacement["topRight"] = "top-end";
    PopoverPlacement["leftTop"] = "left-start";
    PopoverPlacement["left"] = "left";
    PopoverPlacement["leftBottom"] = "left-end";
    PopoverPlacement["rightTop"] = "right-start";
    PopoverPlacement["right"] = "right";
    PopoverPlacement["rightBottom"] = "right-end";
    PopoverPlacement["bottomLeft"] = "bottom-start";
    PopoverPlacement["bottom"] = "bottom";
    PopoverPlacement["bottomRight"] = "bottom-end";
})(PopoverPlacement || (PopoverPlacement = {}));
/**
 * The Popover component displays floating informative and actionable content in relation to a target. Popovers appear either at the top, bottom, left, or right of their target.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-03
 */
var Popover = function (_a) {
    var content = _a.content, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, _c = _a.hasArrow, hasArrow = _c === void 0 ? true : _c, _d = _a.placement, placement = _d === void 0 ? PopoverPlacement.top : _d, onClickOutside = _a.onClickOutside, _e = _a.role, role = _e === void 0 ? 'menu' : _e, className = _a.className, menuClassName = _a.menuClassName, children = _a.children;
    var _f = useState(null), popoverElement = _f[0], setPopoverElement = _f[1];
    var _g = useState(null), arrowElement = _g[0], setArrowElement = _g[1];
    var refTriggerNode = useRef(null);
    var _h = useState(isOpen), open = _h[0], setOpen = _h[1];
    useOnClickOutside(popoverElement, function () {
        if (onClickOutside)
            return onClickOutside();
        setOpen(false);
    });
    /* Popper config */
    var _j = usePopper(refTriggerNode.current, popoverElement, {
        placement: placement,
        modifiers: [
            { name: 'offset', options: { offset: [0, 8] } },
            { name: 'arrow', options: { element: arrowElement } },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['top', 'right'],
                },
            },
        ],
    }), styles = _j.styles, attributes = _j.attributes, forceUpdate = _j.forceUpdate;
    useEffect(function () {
        setOpen(isOpen);
        if (forceUpdate)
            setTimeout(function () { return forceUpdate(); });
    }, [isOpen]);
    var handleTriggerClick = function () { return setOpen(!open); };
    // TODO: find any
    var elements = Children.toArray(children);
    /* Append handle to the trigger component */
    elements = hasProp(elements[0].props, 'onClick')
        ? cloneElement(elements[0], { ref: refTriggerNode })
        : cloneElement(elements[0], { ref: refTriggerNode, onClick: handleTriggerClick });
    return (React.createElement(React.Fragment, null,
        elements,
        React.createElement(Portal, null,
            React.createElement("div", __assign({ role: role, className: popoverContainerClass(menuClassName), ref: setPopoverElement, style: __assign({}, styles.popper) }, attributes.popper), open && (React.createElement("div", { className: popoverClass(className) },
                content,
                hasArrow && (React.createElement("div", { id: "arrow", ref: setArrowElement, style: styles.arrow, "data-popper-arrow": true, className: arrowContainerClass(placement) },
                    React.createElement("span", { className: arrowClass })))))))));
};
export default Popover;
