import {
  ORGANIZATION_ID_KEY,
  removeCookie,
  cookieIsDuplicate,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from '@eva-pacs/core';

export const useRecoverSession = () => {
  const validateSession = () => {
    const isOldVersion = cookieIsDuplicate(ACCESS_TOKEN_KEY) || cookieIsDuplicate(REFRESH_TOKEN_KEY);
    if (!isOldVersion) return;
    removeCookie(ACCESS_TOKEN_KEY);
    removeCookie(REFRESH_TOKEN_KEY);
    removeCookie(ORGANIZATION_ID_KEY);
  };

  return { validateSession };
};
