export * from './canvas';
export * from './chat';
export * from './mammographyContourDetection';
export * from './boundingBox';
export * from './cornestone';
export * from './convolveFilters';
export * from './errorCodes';
export * from './panels';
export * from './paths';
export * from './policies';
export * from './printing';
export * from './presets3D';
export * from './products';
export * from './roleGroups';
export * from './routes';
export * from './toolbarOption';
export * from './reportTextEditor';
export * from './synchronizableTools';
export * from './studies';
export * from './viewPositions';
export * from './eventMessage';
export * from './annotationStyles';
export * from './featureFlags';
export * from './usRegionTypes';

export const INITIAL_ROUTE = '/';

export const RESIZE_THROTTLE = 200;

export const SAVING_DELAY = 2000;

export const MAXIMUM_TIME_TO_UPDATE = 300;

export const PAGE_SIZE = 10;
export const PAGE_SIZE_INFINITE_SCROLL = 20;

export const REMOVE_STUDY_ANIMATION_DURATION = 800;

export const IDLE_TIME_MS = 3600000;

export const MX_AREA_CODE = '52';
export const DEFAULT_ISO_CODE = 'MX';

export const RETRY_DOWNLOAD_TIME = 15000;
export const RETRY_CSV_DOWNLOAD_TIME = 2000;
export const RETRY_ISO_DOWNLOAD_TIME = 5000;

export const LOGO_SYMBOL_WIDTH = '26px';
export const LOGO_SYMBOL_HEIGHT = '24px';

export const LOGO_COMPLETE_WIDTH = '100px';
export const LOGO_COMPLETE_HEIGHT = '40px';

export const MAX_REPORT_UPLOAD_FILE_SIZE = 100;

export const DELAY_TO_AUTOALIGN = 400;
export const BROADCAST_CLOSE_TEXT = 'CLOSE';
export const BROADCAST_PING_TEXT = 'PING';
export const BROADCAST_PING_SUCCESS_TEXT = 'PING_OK';
export const BROADCAST_ADD_ADDITIONAL_STUDY_TEXT = 'ADD_ADDITIONAL_STUDY';
export const BROADCAST_REMOVE_ADDITIONAL_STUDY_TEXT = 'REMOVE_ADDITIONAL_STUDY';
export const BROADCAST_REQUEST_ADDITIONAL_STUDIES_TEXT = 'GET_ADDITIONAL_STUDIES';
export const BROADCAST_REQUEST_ADDITIONAL_STUDIES_SUCCESS_TEXT = 'GET_ADDITIONAL_STUDIES_OK';
export const OVERLAY_INFO_ID = 'OVERLAY_INFO_ID';
export const EDEN_TOOLBAR_SUBMENU_ID = 'EDEN_TOOLBAR_SUBMENU_ID';
export const EDEN_REPORT_ID = 'EDEN_REPORT_ID';
export const EDEN_SIDEBAR_ID = 'EDEN_SIDEBAR_ID';
export const BROADCAST_SYNC_EVENT_TEXT = 'BROADCAST_SYNC_EVENT';
export const BROADCAST_AVAILABILITY_WINDOW_PING_TEXT = 'BROADCAST_AVAILABILITY_WINDOW_PING';

export const PIXELS_MEASUREMENT_UNIT = 'px';
export const MILIMETER_MEASUREMENT_UNIT = 'mm';
