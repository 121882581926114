import cornerstone from 'cornerstone-core';
import { getEnabledElementControlled } from './getEnabledElementControlled';

export const forceUpdateImage = (targetElement: HTMLElement, modifiedImage) => {
  if (!targetElement || !modifiedImage) return;
  const enabledElement = getEnabledElementControlled(targetElement);
  if (!enabledElement) return;
  enabledElement.image = modifiedImage;

  cornerstone.updateImage(targetElement, true);
};
