import React, { useState } from 'react';
import cn from 'classnames';
import { useGetStudyPreviewQuery, SeriesThumbnailsType } from '@eva-pacs/client';
import { Carousel, ImageOption } from '@eva-pacs/ui';
import { MODALITY_TYPES } from '@eva-pacs/core';

export interface PreviewFileModalProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Study id
   */
  studyId: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
}

/**
 * Use this component in order to show the preview the Study's DICOM files
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-08-31
 */
export const PreviewFileModal: React.FC<PreviewFileModalProps> = ({ ariaLabel, studyId, className }) => {
  const modalClass = cn(className);
  const [imagesList, setImageList] = useState<Array<ImageOption>>([]);

  const { loading } = useGetStudyPreviewQuery({
    variables: {
      studyId,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ seriesThumbnails }) => {
      setImageList(thumbnailDTO((seriesThumbnails ?? []) as Array<SeriesThumbnailsType>));
    },
  });

  return (
    <div aria-label={ariaLabel} className={modalClass}>
      <Carousel images={imagesList} loading={loading} />
    </div>
  );
};

const thumbnailDTO = (thumbnails: Array<SeriesThumbnailsType>): Array<ImageOption> =>
  thumbnails
    .filter((thumbnail) => {
      const modality = thumbnail.series?.modality?.identifier as MODALITY_TYPES;
      return ![MODALITY_TYPES.DOC, MODALITY_TYPES.SR].includes(modality);
    })
    .map((thumbnailData) => ({
      id: thumbnailData.id,
      imageUrl: thumbnailData.thumbnail ?? '',
      title: thumbnailData.dicomDescription ?? '',
    }));
