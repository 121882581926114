import cn from 'classnames';
import { NativeSelectSize } from '.';
/* Class assignment */
export var containerClass = function (className) { return cn(className, 'inline-flex relative'); };
export var nativeSelectClass = function (size) {
    return cn(
    /* NativeSelect (base) */
    'w-full py-2 px-4', 'bg-gray-600 rounded-lg appearance-none outline-none truncate', 'text-basic-white leading-tight', size === NativeSelectSize.xs && 'text-caption', size === NativeSelectSize.sm && 'h-10 text-label-md');
};
export var iconClass = function (size) {
    return cn(
    /* Icon (base) */
    'absolute right-1', 'pointer-events-none', 'text-gray-50', size === NativeSelectSize.xs && 'top-1.5', size === NativeSelectSize.sm && 'top-2');
};
