import csTools from 'cornerstone-tools';
import drawDoubleLinkedTextBox from './drawDoubleLinkedTextBox';

const draw = csTools.importInternal('drawing/draw');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawLine = csTools.importInternal('drawing/drawLine');
const setShadow = csTools.importInternal('drawing/setShadow');
const lineSegDistance = csTools.importInternal('util/lineSegDistance');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const roundToDecimal = csTools.importInternal('util/roundToDecimal');

/**
 * @public
 * @class CobbAngle
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring the angle between two straight lines.
 * @extends Tools.Annotation.CobbAngle
 * @author Ricardo Aguirre <ricardo.a.nava@gmail.com>
 * Created at 2022-03-14
 */
export default class CustomCobbAngle extends csTools.CobbAngleTool {
  name;
  constructor(props) {
    super(props);
  }

  createNewMeasurement(eventData) {
    // Create the measurement data for this tool with the end handle activated
    super.hasIncomplete = true;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: '',
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
          drawnIndependently: true,
        },
        end2: {
          x: eventData.currentPoints.image.x + 1,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
          drawnIndependently: true,
          moving: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    if (data.visible === false) return false;
    if (super.hasIncomplete) return false;

    return (
      lineSegDistance(element, data.handles.start, data.handles.end, coords) < 25 ||
      lineSegDistance(element, data.handles.start2, data.handles.end2, coords) < 25
    );
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    const dx1 = Math.ceil(data.handles.start.x - data.handles.end.x) * (colPixelSpacing || 1);
    const dy1 = Math.ceil(data.handles.start.y - data.handles.end.y) * (rowPixelSpacing || 1);
    const dx2 = Math.ceil(data.handles.start2.x - data.handles.end2.x) * (colPixelSpacing || 1);
    const dy2 = Math.ceil(data.handles.start2.y - data.handles.end2.y) * (rowPixelSpacing || 1);

    let angle = Math.acos(
      Math.abs((dx1 * dx2 + dy1 * dy2) / (Math.sqrt(dx1 * dx1 + dy1 * dy1) * Math.sqrt(dx2 * dx2 + dy2 * dy2))),
    );

    angle *= 180 / Math.PI;

    data.rAngle = roundToDecimal(angle, 2);
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving, renderDashed } = super.configuration;

    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = csTools.getToolState(evt.currentTarget, this.name);
    if (!toolData) return;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    const font = csTools.textStyle.getFont();
    const textBoxAnchorPoints1 = (handles) => [handles.start, handles.end];
    const textBoxAnchorPoints2 = (handles) => [handles.start2, handles.end2];
    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      if (data.visible === false) continue;

      draw(context, (context) => {
        setShadow(context, super.configuration);

        // Differentiate the color of activation tool
        const color = csTools.toolColors.getColorIfActive(data);
        const lineOptions = { color } as { color: string; lineDash: number[] };
        if (renderDashed) lineOptions.lineDash = lineDash;

        drawLine(context, eventData.element, data.handles.start, data.handles.end, lineOptions);
        if (data.complete) drawLine(context, eventData.element, data.handles.start2, data.handles.end2, lineOptions);

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };
        if (super.configuration.drawHandles) drawHandles(context, eventData, data.handles, handleOptions);

        // Draw the textbox
        context.fillStyle = color;
        const text = data.value;
        const validateXPosition1 = data.handles.start.x > data.handles.end.x;
        const validateXPosition2 = data.handles.start2.x > data.handles.end2.x;
        const xAnchorHandle1 = validateXPosition1 ? data.handles.start.x : data.handles.end.x;
        const yAnchorHandle1 = validateXPosition1 ? data.handles.start.y : data.handles.end.y;
        const xAnchorHandle2 = validateXPosition2 ? data.handles.start2.x : data.handles.end2.x;
        const yAnchorHandle2 = validateXPosition2 ? data.handles.start2.y : data.handles.end2.y;
        const textboxCoordsX = (xAnchorHandle1 > xAnchorHandle2 ? xAnchorHandle1 : xAnchorHandle2) + 20;
        const textboxCoordsY = (yAnchorHandle1 + yAnchorHandle2) / 2;

        if (!data.handles.textBox.hasMoved) {
          const textCoords = {
            x: textboxCoordsX,
            y: textboxCoordsY,
          };
          context.font = font;
          data.handles.textBox.x = textCoords.x;
          data.handles.textBox.y = textCoords.y;
        }

        if (data.complete) {
          drawDoubleLinkedTextBox(
            context,
            eventData.element,
            data.handles.textBox,
            text,
            data.handles,
            textBoxAnchorPoints1,
            textBoxAnchorPoints2,
            color,
            lineWidth,
            0,
            true,
          );
        }
      });
    }
  }
}
