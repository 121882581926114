import loadable from '@loadable/component';
import { AuthLayout } from '~/src/layouts/Auth';
import { GlobalLayout } from '~/src/layouts/Global';
import { WithoutLayout } from '~/src/layouts/Without';
import { RedirectLogin } from '~/src/components/Auth/RedirectLogin';

import {
  ACTIVATE_PATH,
  ADMIN_INVITE_PATH,
  ADMIN_PATH,
  EMAIL_VALIDATION_PATH,
  FOUND_SERIES_PATH,
  HOME_PATH,
  LOGIN_PATH,
  PASSWORD_RESET_PATH,
  RECOVERY_PASSWORD_PATH,
  REPORT_FULL_SCREEN_PATH,
  REPORT_SUBMISSION_PATH,
  REQUEST_RECOVERY_PASSWORD_PATH,
  REVIEW_SIGNUP_PATH,
  SIGNUP_PATH,
  STUDY_LIST_PATH,
  UPLOAD_STUDY_PATH,
  LEGACY_LOGIN_PATH,
  VIEWER_PATH,
} from '@eva-pacs/core';
/* PLOP_INJECT_ROUTE_IMPORT */
const ReportFullScreenPage = loadable(() => {
  return import('~/pages/ReportFullScreen');
});

const FoundSeriesPage = loadable(() => {
  return import('~/pages/FoundSeries');
});

const AdminInvitePage = loadable(() => {
  return import('~/modules/admininvite/AdminInvitePage');
});

const AdminPage = loadable(() => {
  return import('~/pages/Admin');
});

const StudyListPage = loadable(() => {
  return import('~/pages/StudyList');
});

const UploadStudyPage = loadable(() => {
  return import('~/pages/UploadStudy');
});

const ReportSubmissionPage = loadable(() => {
  return import('~/pages/ReportSubmission');
});

const RequestRecoveryPasswordPage = loadable(() => {
  return import('~/pages/RequestRecoveryPassword');
});

const PasswordRecoveryPage = loadable(() => {
  return import('~/pages/PasswordRecovery');
});

const EmailValidationPage = loadable(() => {
  return import('~/pages/EmailValidation');
});

const LoginPage = loadable(() => {
  return import('~/pages/Login');
});

const ReviewSignUpPage = loadable(() => {
  return import('~/pages/ReviewSignUp');
});

const SignUpPage = loadable(() => {
  return import('~/pages/SignUp');
});

const ViewerPage = loadable(() => {
  return import('~/pages/Viewer');
});

interface RouteOptions {
  key: string;
  path: string | Array<string>;
  isPrivate: boolean;
  component: any;
  layout: React.FC;
  policy?: string;
}
const ROUTES: Array<RouteOptions> = [
  {
    key: 'review-sign-up',
    path: REVIEW_SIGNUP_PATH,
    isPrivate: false,
    component: ReviewSignUpPage,
    layout: AuthLayout,
  },
  {
    key: 'email-validation',
    path: [EMAIL_VALIDATION_PATH, ACTIVATE_PATH],
    isPrivate: false,
    component: EmailValidationPage,
    layout: AuthLayout,
  },
  { key: 'signup', path: SIGNUP_PATH, isPrivate: false, component: SignUpPage, layout: WithoutLayout },
  { key: 'viewer', path: VIEWER_PATH + ':id', isPrivate: true, component: ViewerPage, layout: WithoutLayout },
  {
    key: 'recovery-password',
    path: [RECOVERY_PASSWORD_PATH, PASSWORD_RESET_PATH],
    isPrivate: false,
    component: PasswordRecoveryPage,
    layout: AuthLayout,
  },
  {
    key: 'requestrecoverypassword',
    path: REQUEST_RECOVERY_PASSWORD_PATH,
    isPrivate: false,
    component: RequestRecoveryPasswordPage,
    layout: AuthLayout,
  },
  {
    key: 'reportsubmission',
    path: REPORT_SUBMISSION_PATH,
    isPrivate: true,
    component: ReportSubmissionPage,
    layout: WithoutLayout,
  },
  { key: 'uploadstudy', path: UPLOAD_STUDY_PATH, isPrivate: true, component: UploadStudyPage, layout: GlobalLayout },
  {
    key: 'studylist',
    path: [HOME_PATH, STUDY_LIST_PATH],
    isPrivate: true,
    component: StudyListPage,
    layout: GlobalLayout,
  },
  { key: 'foundseries', path: FOUND_SERIES_PATH, isPrivate: true, component: FoundSeriesPage, layout: GlobalLayout },
  {
    key: 'admin',
    path: ADMIN_PATH,
    isPrivate: true,
    component: AdminPage,
    layout: GlobalLayout,
  },
  {
    key: 'admininvite',
    path: `${ADMIN_INVITE_PATH}/:eventType`,
    isPrivate: true,
    component: AdminInvitePage,
    layout: GlobalLayout,
  },
  {
    key: 'report-full-screen',
    path: REPORT_FULL_SCREEN_PATH + '/:id',
    isPrivate: true,
    component: ReportFullScreenPage,
    layout: WithoutLayout,
  },
  {
    key: 'legacy-login',
    path: LEGACY_LOGIN_PATH,
    isPrivate: false,
    layout: AuthLayout,
    component: LoginPage,
  },
  /* PLOP_INJECT_ROUTE */
];

if (process.env.REACT_APP_ENV) {
  ROUTES.unshift({
    key: 'login',
    path: LOGIN_PATH,
    isPrivate: false,
    layout: WithoutLayout,
    component: RedirectLogin,
  });
} else {
  ROUTES.unshift({
    key: 'login',
    path: LOGIN_PATH,
    isPrivate: false,
    layout: AuthLayout,
    component: LoginPage,
  });
}

export { ROUTES };
