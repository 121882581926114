import cn from 'classnames';
import { BadgeColor, BadgeSize } from './';
/* Class assignment */
export var badgeClass = function (className, color, size) {
    return cn(className, 
    /* Badge (base) */
    'flex items-center justify-center rounded-full', 
    /* Badge Color */
    color === BadgeColor.primary && 'e-bg-primary-500', color === BadgeColor.positive && 'bg-feedback-positive-500', color === BadgeColor.warning && 'bg-feedback-warning-500', color === BadgeColor.negative && 'bg-feedback-negative-500', color === BadgeColor.neutral && 'bg-light-gray-900', 
    /* Badge Sizes */
    size === BadgeSize.xs && 'h-1 w-1', size === BadgeSize.sm && 'h-2 w-2', size === BadgeSize.md && 'h-3 w-3', size === BadgeSize.lg && 'h-4 w-4', size === BadgeSize.xl && 'h-5 w-5', size === BadgeSize.xxl && 'h-6 w-6');
};
export var counterClass = function (color, size) {
    return cn(
    /* Counter Color */
    color === BadgeColor.primary && 'text-basic-white', color === BadgeColor.positive && 'text-basic-white', color === BadgeColor.warning && 'text-basic-black', color === BadgeColor.negative && 'text-basic-white', 
    /* Counter Sizes */
    size === BadgeSize.xs && 'text-tiny', size === BadgeSize.sm && 'text-tiny', size === BadgeSize.md && 'text-tiny', size === BadgeSize.lg && 'text-caption-sm', size === BadgeSize.xl && 'e-text-xs');
};
