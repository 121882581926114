import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import { UserPreferenceFamily } from '@eva-pacs/client';
import { useLocalStorage } from '@eva-pacs/core';

import { useScreenPreferencesStore } from '~/src/store/screenPreferencesStore';
import { useSessionStore } from '~/src/store';

import {
  SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT,
  SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_KEY,
} from '~/utils/screenPreferences';

import { useScreenPreferencesQueryHandlers } from './useScreenPreferencesQueryHandlers';

const DISABLE_STATE_SCREEN_PREFERENCE_ID = '0000';

/**
 * Hook that retrieves the user screen preferences. It returns the screen preferences as a state.
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2024-26-02
 */
export const useScreenPreferences = () => {
  const [screenPreferencesMachineId, setScreenPreferencesMachineId] = useLocalStorage<string | undefined>(
    SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_KEY,
    undefined,
  );

  const [user, selectedScreenPreferenceId, setSelectedScreenPreferenceId] = useSessionStore(
    (store) => [store.user, store.selectedScreenPreferenceId, store.setSelectedScreenPreferenceId],
    shallow,
  );

  const screenPreferenceList = useScreenPreferencesStore((store) => store.screenPreferenceList, shallow);

  const {
    getPreferences,
    createPreferences,
    updatePreferences,
    deletePreferences,
    isLoading,
  } = useScreenPreferencesQueryHandlers({
    onPreferenceCreation: (screenId) => activatePreference(screenId),
  });

  const activeScreenPreference = useMemo(() => {
    const matchSelectedPreference = screenPreferenceList?.find(
      (preference) => preference?.id === selectedScreenPreferenceId,
    );
    const matchMachinePreference = screenPreferenceList?.find((preference) =>
      preference.key.includes(screenPreferencesMachineId || ''),
    );

    if (selectedScreenPreferenceId === DISABLE_STATE_SCREEN_PREFERENCE_ID) return undefined;
    if (matchSelectedPreference !== undefined) return matchSelectedPreference;
    if (matchMachinePreference !== undefined) return matchMachinePreference;

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenPreferenceList, screenPreferencesMachineId, selectedScreenPreferenceId]);

  const activatePreference = (id: string) => {
    if (selectedScreenPreferenceId !== id && selectedScreenPreferenceId?.length)
      return setSelectedScreenPreferenceId(id);
    setSelectedScreenPreferenceId(DISABLE_STATE_SCREEN_PREFERENCE_ID);
  };

  const saveScreenPreferences = async (
    nextPreferences: Array<{ name: string; type: string; args: string }>,
    name: string,
    id?: string,
  ) => {
    const existingPreference = screenPreferenceList?.find((preferences) => preferences?.id === id);

    const value = JSON.stringify({ windows: nextPreferences, name });
    const key = `${screenPreferencesMachineId}-${name}`;
    const family = UserPreferenceFamily.SCREENS;
    const userId = user?.id;
    const backendId = existingPreference?.id;

    const input = {
      user: userId,
      family,
      key,
      value,
    };

    if (existingPreference?.name?.length)
      return await updatePreferences({
        variables: {
          input,
          id: backendId,
        },
      });

    return await createPreferences({
      variables: {
        input,
      },
    });
  };

  const deleteScreenPreferences = async (id: string) => await deletePreferences({ variables: { id } });

  useEffect(() => {
    if (!screenPreferencesMachineId) setScreenPreferencesMachineId(SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenPreferencesMachineId]);

  useEffect(() => {
    if (!screenPreferenceList) getPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenPreferenceList]);

  return {
    isLoading,
    activeScreenPreference,
    activatePreference,
    saveScreenPreferences,
    deleteScreenPreferences,
  };
};
