var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { IconCatalog } from '../../Icon';
import { IconSize, BasicInput, BasicInputSize } from '../BasicInput';
/**
 * The inputs are used so that the user can enter text or other characters that he requires.
 * @author Alejandro Forero<alejoforero.af@gmail.com>
 * Created at 2021-05-26
 */
export var PasswordInput = function (_a) {
    var _b = _a.inputSize, inputSize = _b === void 0 ? BasicInputSize.md : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.iconSize, iconSize = _d === void 0 ? IconSize.md : _d, _e = _a.isFullWidth, isFullWidth = _e === void 0 ? false : _e, _f = _a.iconPosition, iconPosition = _f === void 0 ? 'right' : _f, hasError = _a.hasError, _g = _a.value, value = _g === void 0 ? '' : _g, name = _a.name, onChange = _a.onChange, restOfProps = __rest(_a, ["inputSize", "isDisabled", "iconSize", "isFullWidth", "iconPosition", "hasError", "value", "name", "onChange"]);
    var _h = useState(true), passwordIsHidden = _h[0], setPasswordIsHidden = _h[1];
    var handleInput = function (event) {
        if (onChange)
            onChange(event);
    };
    var tooglePassword = function () { return setPasswordIsHidden(function (prevState) { return !prevState; }); };
    /* Render JSX */
    return (React.createElement(BasicInput, __assign({ name: name, icon: passwordIsHidden ? IconCatalog.view : IconCatalog.hide, value: value, inputSize: inputSize, iconSize: iconSize, hasError: hasError, iconPosition: iconPosition, isFullWidth: isFullWidth, isDisabled: isDisabled, type: passwordIsHidden ? 'password' : 'text' }, restOfProps, { onChange: handleInput, onIconClick: tooglePassword })));
};
