/**
 * Checks if two timezones are related.
 * @param tz1 The first timezone.
 * @param tz2 The second timezone.
 * @returns True if the timezones are related, false otherwise.
 * @example
 * areTimeZonesRelated('Europe/Paris', 'Europe/London');
 * // returns false
 */
export var areTimeZonesRelated = function (tz1, tz2) {
    var segments1 = tz1.split('/').slice(1);
    var segments2 = tz2.split('/').slice(1);
    return (segments1.some(function (segment) { return segments2.includes(segment); }) || segments2.some(function (segment) { return segments1.includes(segment); }));
};
