import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Spinner, SpinnerSize, SpinnerColor } from '../../../atoms/Spinner';
/**
 * This component is used for presenting a loading states
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-09-07
 */
export var LoadingState = function (_a) {
    var title = _a.title, description = _a.description, footerSection = _a.footerSection, className = _a.className;
    var loadingStateClass = cn(className, 'flex');
    var t = useTranslation().t;
    return (React.createElement("div", { className: loadingStateClass },
        React.createElement("div", { className: "gap-y-6 m-auto" },
            React.createElement("div", { className: "mb-6 mx-auto w-fit-content" },
                React.createElement(Spinner, { size: SpinnerSize.md, color: SpinnerColor.primary })),
            React.createElement("div", { className: "mx-auto" },
                React.createElement("p", { className: "mb-6 text-center text-basic-white text-subtitle font-semi-bold tracking-tight" }, title || t('general.genericLoadingCaption')),
                description && React.createElement("p", { className: "mb-6 text-center text-light-gray-600 text-paragraph-sm" }, description),
                footerSection))));
};
