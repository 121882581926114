import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Image from '../../../atoms/Image';
/**
 * When sync is down or content has failed to load, the user should be able to interact with as much of the rest of the app as possible.
 * @author Ricardo Aguirre<ricardo.a.nava@gmail.com>
 * Created at 2021-09-02
 */
export var ErrorState = function (_a) {
    var className = _a.className, title = _a.title, description = _a.description, _b = _a.imageSrc, imageSrc = _b === void 0 ? '/assets/illustrations/connection-error.svg' : _b, _c = _a.hasIllustration, hasIllustration = _c === void 0 ? true : _c, footerSection = _a.footerSection;
    var errorStateClass = cn(className, 'flex h-full');
    var t = useTranslation().t;
    return (React.createElement("div", { className: errorStateClass },
        React.createElement("div", { className: "gap-y-6 m-auto" },
            hasIllustration && (React.createElement("div", { className: "mb-6 mx-auto w-fit-content" },
                React.createElement(Image, { src: imageSrc, hasMaxWidth: true, alt: title || t('general.genericErrorCaption'), noImg: React.createElement(React.Fragment, null) }))),
            React.createElement("div", { className: "mx-auto" },
                React.createElement("p", { className: "mb-6 text-center text-basic-white text-subtitle font-semi-bold tracking-tight" }, title || t('general.genericErrorCaption')),
                React.createElement("p", { className: "mb-6 text-center text-light-gray-600 text-paragraph-sm" }, description || t('general.genericErrorDescription')),
                footerSection))));
};
