import { useState } from 'react';

import { MutationFunctionOptions } from '@apollo/client';

import {
  useCreateUserPreferenceMutation,
  useUpdateUserPreferenceMutation,
  useDeleteUserPreferenceMutation,
  CreateUserPreferenceMutation,
  useUserPreferencesLazyQuery,
  UserPreferenceFamily,
  UserPreferencesQuery,
  Exact,
  UserPreferenceInputType,
  UpdateUserPreferenceMutation,
  DeleteUserPreferenceMutation,
} from '@eva-pacs/client';

import { useScreenPreferencesStore } from '~/src/store/screenPreferencesStore';
import { useErrorHandler } from '~/utils/appHelpers';
import { useSessionStore } from '~/src/store';

import {
  handleScreenDuplicationKey,
  getPreferenceValues,
  ScreenPreferences,
  getCachedQueryInput,
  savedCachedQueryInput,
} from '~/utils/screenPreferences';

const DUPLICATION_ERROR_MESSAGE = 'duplicate key value violates unique constraint';

interface useScreenPreferencesQueryHandlersProps {
  onPreferenceCreation: (screenId: string) => void;
}

export const useScreenPreferencesQueryHandlers = ({ onPreferenceCreation }: useScreenPreferencesQueryHandlersProps) => {
  const { handleError } = useErrorHandler();
  const user = useSessionStore((store) => store.user);
  const [isLoading, setPreferencesLoading] = useState(false);

  const setScreenPreferenceList = useScreenPreferencesStore((store) => store.setScreenPreferenceList);

  const handleQueryErrors = (error) => {
    setPreferencesLoading(false);
    handleError({ logMessage: error });
  };

  const [getScreenPreferences] = useUserPreferencesLazyQuery({
    variables: { userId: user?.id, family: UserPreferenceFamily.SCREENS },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => buildScreenPreferences(data),
    onError: handleQueryErrors,
  });

  const [createScreenPreferences] = useCreateUserPreferenceMutation({ onError: handleQueryErrors });

  const [updateScreenPreferences] = useUpdateUserPreferenceMutation({ onError: handleQueryErrors });

  const [deleteScreenPreferences] = useDeleteUserPreferenceMutation();

  const buildScreenPreferences = (data: UserPreferencesQuery) => {
    const userScreenPreferences = data?.userPreferences?.results;

    if (!userScreenPreferences?.length) {
      setPreferencesLoading(false);
      return setScreenPreferenceList([]);
    }

    const screenPreferences: Array<ScreenPreferences> = [];
    userScreenPreferences.forEach((preference) => {
      const transformedPreferences = getPreferenceValues(preference);
      if (!transformedPreferences) return;
      screenPreferences.push(transformedPreferences);
    });

    setPreferencesLoading(false);
    setScreenPreferenceList(screenPreferences);
  };

  const handleCreationError = async (data: CreateUserPreferenceMutation) => {
    const errorMessages = data?.createUserPreference?.errors?.system?.map((error) => error?.message);
    const isDuplicationError = errorMessages.findIndex((message) => message.includes(DUPLICATION_ERROR_MESSAGE)) !== -1;
    const lastInput = getCachedQueryInput();

    if (!isDuplicationError || lastInput === undefined) return false;

    const { key, value } = handleScreenDuplicationKey(lastInput);
    const { user, family } = lastInput;
    const input = { key, value, user, family };

    savedCachedQueryInput(input);
    return await createPreferences({ variables: { input } });
  };

  const handleCompletion = async () => {
    getScreenPreferences();
    return await getIfGetProcessIsDone();
  };

  const getIfGetProcessIsDone = (): Promise<true> =>
    new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isLoading) return;

        clearInterval(intervalId);
        resolve(true);
      }, 1000);
    });

  const getPreferences = () => {
    setPreferencesLoading(true);
    getScreenPreferences();
  };

  const createPreferences = async (
    options: MutationFunctionOptions<CreateUserPreferenceMutation, Exact<{ input: UserPreferenceInputType }>>,
  ): Promise<boolean> => {
    setPreferencesLoading(true);

    try {
      savedCachedQueryInput(options.variables?.input);
      const result = await createScreenPreferences(options);

      const createdScreenId = result.data?.createUserPreference?.userPreference?.id;
      const success = result.data?.createUserPreference?.success;
      const queryWasOk = createdScreenId && success;

      if (!queryWasOk) return handleCreationError(result.data as CreateUserPreferenceMutation);

      savedCachedQueryInput();
      onPreferenceCreation(createdScreenId);

      const complete = await handleCompletion();
      return complete;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updatePreferences = async (
    options: MutationFunctionOptions<
      UpdateUserPreferenceMutation,
      Exact<{
        id: any;
        input: UserPreferenceInputType;
      }>
    >,
  ) => {
    setPreferencesLoading(true);

    try {
      const result = await updateScreenPreferences(options);

      const success = result.data?.updateUserPreference?.success;
      const message = result.data?.updateUserPreference?.errors;

      if (success) {
        const complete = await handleCompletion();
        return complete;
      }

      console.error(message);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deletePreferences = async (
    options?: MutationFunctionOptions<
      DeleteUserPreferenceMutation,
      Exact<{
        id: any;
      }>
    >,
  ) => {
    setPreferencesLoading(true);

    try {
      const result = await deleteScreenPreferences(options);

      const success = result.data?.deleteUserPreference?.success;
      const message = result.data?.deleteUserPreference?.errors;

      if (success) {
        const complete = await handleCompletion();
        return complete;
      }

      console.error(message);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return { getPreferences, createPreferences, updatePreferences, deletePreferences, isLoading };
};
