import { useTranslation } from 'react-i18next';
import { I18N_LANGUAGE_KEY, LanguageKeys, setCookie } from '@eva-pacs/core';

/**
 * Use `useLanguage` hook to get access to the current language or update it.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-06-21
 */
export const useLanguage = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as LanguageKeys;

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    // Store in the cookie the selected language
    setCookie(I18N_LANGUAGE_KEY, language, { path: '/', domain: process.env['REACT_APP_ROOT_DOMAIN'] });
  };

  return {
    currentLanguage,
    changeLanguage,
  };
};
