import React from 'react';
import { BurnerType } from '@eva-pacs/client';
import { setMaxHeightByOptions } from '@eva-pacs/core';
import { Button, ButtonColor, ButtonType, Icon, IconCatalog } from '@eva-pacs/ui';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { SimpleTooltip, TooltipPosition } from '@evacenter/eden';
import { optionClass } from './styles';

export interface BurnerListProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set the subtitle modal info
   */
  subtitle: string;

  /**
   * The available burners
   */
  burnersList: Array<BurnerType>;

  /**
   * Set the selected default burner option
   */
  defaultOption: string;

  /**
   * Whether the download button is in the loading state
   */
  isLoadingDownloadBtn?: boolean;

  /**
   * Callback to handle burner option click
   */
  onBurnerOptionClick?: (burnerId: string) => void;

  /**
   * Callback to handle download button click
   */
  onDownloadBtnClick: () => void;

  /**
   * Callback to handle burn button click
   */
  onBurnBtnClick: () => void;
}

/**
 * Use this component in order to show the burners list (BurnIso)
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-03-02
 */
export const BurnerList: React.FC<BurnerListProps> = ({
  className,
  subtitle,
  burnersList = [],
  defaultOption,
  isLoadingDownloadBtn = false,
  onBurnerOptionClick,
  onDownloadBtnClick,
  onBurnBtnClick,
}) => {
  const classes = {
    container: cn(className),
    tooltipClass: cn('e-z-20 w-60 pointer-events-none'),
  };
  const MAX_CONTAINER_LIST_HEIGHT = '250px';
  const { t } = useTranslation();

  const handleSelectOption = (itemId: string) => () => {
    if (onBurnerOptionClick) onBurnerOptionClick(itemId);
  };

  const buildOptionList = burnersList.map((item) => (
    <div
      role="button"
      tabIndex={-1}
      key={item?.id}
      className={optionClass(item?.id, defaultOption)}
      onClick={handleSelectOption(item?.id)}
      onKeyPress={handleSelectOption(item?.id)}>
      <div className="flex items-center w-full">
        <div className="flex items-center">
          <div className="flex items-center">
            <Icon className="text-basic-white" icon={IconCatalog.burn} />
            <span className="ml-2 text-basic-white text-paragraph font-medium overflow-hidden">{item?.name}</span>
          </div>
        </div>
        <div className="ml-auto mr-4 text-basic-white text-paragraph font-medium">{item?.facility.name}</div>
      </div>
    </div>
  ));

  return (
    <div className={classes.container}>
      {subtitle && (
        <div className="mb-6 pb-4 text-left text-basic-white text-paragraph font-medium leading-6 border-b-2 border-gray-600">
          {subtitle}
        </div>
      )}

      {/* DISK LIST */}
      <div
        style={setMaxHeightByOptions({
          listLength: burnersList.length,
          maxOptions: 4,
          maxHeight: MAX_CONTAINER_LIST_HEIGHT,
        })}
        className="scrollbar-w-2 scrollbar-thumb-gray-200 scrollbar-track-gray-700 scrollbar-thumb-rounded-lg flex flex-col items-center pr-2 h-56 overflow-y-auto">
        {buildOptionList}
      </div>

      {/* ACTIONS */}
      <div className="flex gap-x-4 justify-end pt-9">
        <Button
          type={ButtonType.button}
          color={ButtonColor.secondary}
          onClick={onDownloadBtnClick}
          leftIcon={IconCatalog.download}
          loadingText={t('general.actions.preparingIso')}
          isLoading={isLoadingDownloadBtn}>
          {t('general.actions.downloadIso')}
        </Button>
        {isLoadingDownloadBtn ? (
          <SimpleTooltip
            delayDuration={1}
            contentClassName={classes.tooltipClass}
            position={TooltipPosition.RIGHT}
            description={t('study.downloadIso.waiting')}>
            <span>
              <Button type={ButtonType.button} leftIcon={IconCatalog.burn} isDisabled={isLoadingDownloadBtn}>
                {t('study.dropdownMenu.burn')} CD
              </Button>
            </span>
          </SimpleTooltip>
        ) : (
          <Button
            type={ButtonType.button}
            onClick={onBurnBtnClick}
            leftIcon={IconCatalog.burn}
            isDisabled={isLoadingDownloadBtn}>
            {t('study.dropdownMenu.burn')} CD
          </Button>
        )}
      </div>
    </div>
  );
};
