import csTools from 'cornerstone-tools';

import { conrnerstoneUnits, transformUnits } from '@eva-pacs/core';

const drawLinkedTextBox = csTools.importInternal('drawing/drawLinkedTextBox');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const setShadow = csTools.importInternal('drawing/setShadow');
const drawLine = csTools.importInternal('drawing/drawLine');
const draw = csTools.importInternal('drawing/draw');

export default class CustomBidirectional extends csTools.BidirectionalTool {
  name;
  constructor(props) {
    super(props);

    // @ts-ignore
    this.renderToolData = this.handleToolData.bind(this);
  }

  updateCachedStats(image, _, data) {
    // Prevent updating other tools' data
    if (data.toolName !== this.name) {
      return;
    }

    const pixelSpacing = getPixelSpacing(image);
    const { longestDiameter, shortestDiameter } = this.calculateLongestAndShortestDiameters(data, pixelSpacing);

    // Set measurement text to show lesion table
    data.longestDiameter = longestDiameter;
    data.shortestDiameter = shortestDiameter;
    data.invalidated = false;
  }

  handleToolData(evt) {
    const eventData = evt.detail;
    const { element, canvasContext, image } = eventData;
    // @ts-ignore
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving, renderDashed } = this.configuration;

    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;

    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = csTools.getToolState(element, this.name);

    if (!toolData) return;

    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // LT-29 Disable Target Measurements when pixel spacing is not available
    if (!rowPixelSpacing || !colPixelSpacing) return;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(canvasContext.canvas);

    let color;
    const activeColor = csTools.toolColors.getActiveColor();
    const lineWidth = csTools.toolStyle.getToolWidth();

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) continue;

      color = data.active ? activeColor : csTools.toolColors.getToolColor();

      // Calculate the data measurements
      if (data.invalidated === true) {
        if (data.longestDiameter && data.shortestDiameter) {
          // @ts-ignore
          this.throttledUpdateCachedStats(image, element, data);
        } else {
          // @ts-ignore
          this.updateCachedStats(image, element, data);
        }
      }

      draw(context, (context) => {
        // @ts-ignore
        setShadow(context, this.configuration);

        const { start, end, perpendicularStart, perpendicularEnd, textBox } = data.handles;

        const lineOptions = { color, lineDash: undefined };
        const perpendicularLineOptions = { color, strokeWidth: undefined, lineDash: undefined };

        if (renderDashed) {
          lineOptions.lineDash = lineDash;
          perpendicularLineOptions.lineDash = lineDash;
        }

        // Draw the measurement line
        drawLine(context, element, start, end, lineOptions);

        this.updatePerpendicularLineHandles(eventData, data);

        drawLine(context, element, perpendicularStart, perpendicularEnd, perpendicularLineOptions);

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        // @ts-ignore
        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Draw the textbox
        // Move the textbox slightly to the right and upwards
        // So that it sits beside the length tool handle
        const xOffset = 10;
        const textBoxAnchorPoints = (handles) => [
          handles.start,
          handles.end,
          handles.perpendicularStart,
          handles.perpendicularEnd,
        ];
        const textLines = this.getTextBoxText(data, rowPixelSpacing, colPixelSpacing);
        const labels = textLines.map((text) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, tag, value, unit] = text.split(' ');
          const transformedValue = transformUnits([value, unit].join(' '));
          return [tag, transformedValue].join(' ');
        });

        drawLinkedTextBox(
          context,
          element,
          textBox,
          labels,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          xOffset,
          true,
        );
      });
    }
  }

  calculateLongestAndShortestDiameters(measurementData, pixelSpacing) {
    const { rowPixelSpacing, colPixelSpacing } = pixelSpacing;
    const { start, end, perpendicularStart, perpendicularEnd } = measurementData.handles;

    // Calculate the long axis length
    const dx = (start.x - end.x) * (colPixelSpacing || 1);
    const dy = (start.y - end.y) * (rowPixelSpacing || 1);
    let length = Math.sqrt(dx * dx + dy * dy);

    // Calculate the short axis length
    const wx = (perpendicularStart.x - perpendicularEnd.x) * (colPixelSpacing || 1);
    const wy = (perpendicularStart.y - perpendicularEnd.y) * (rowPixelSpacing || 1);
    let width = Math.sqrt(wx * wx + wy * wy);

    if (!width) width = 0;

    // Length is always longer than width
    if (width > length) {
      const tempW = width;
      const tempL = length;

      length = tempW;
      width = tempL;
    }

    return {
      longestDiameter: length.toFixed(1),
      shortestDiameter: width.toFixed(1),
    };
  }

  getTextBoxText = (data, rowPixelSpacing, colPixelSpacing) => {
    let suffix = ` ${conrnerstoneUnits.MM}`;

    if (!rowPixelSpacing || !colPixelSpacing) suffix = ` ${conrnerstoneUnits.pixelsComplete}`;

    const lengthText = ` L ${data.longestDiameter}${suffix}`;
    const widthText = ` W ${data.shortestDiameter}${suffix}`;

    const { labels } = data;

    if (labels && Array.isArray(labels)) {
      return [...labels, lengthText, widthText];
    }

    return [lengthText, widthText];
  };

  updatePerpendicularLineHandles(eventData, measurementData) {
    if (!measurementData.handles.perpendicularStart?.locked) return false;

    let startX, startY, endX, endY;

    const { start, end } = measurementData.handles;
    const { columnPixelSpacing = 1, rowPixelSpacing = 1 } = eventData.image;

    if (start.x === end.x && start.y === end.y) {
      startX = start.x;
      startY = start.y;
      endX = end.x;
      endY = end.y;
    } else {
      // Mid point of long-axis line
      const mid = {
        x: (start.x + end.x) / 2,
        y: (start.y + end.y) / 2,
      };

      // Inclination of the perpendicular line
      const vector = this.getLineVector(columnPixelSpacing, rowPixelSpacing, start, end);

      const perpendicularLineLength = vector.length / 2;
      const rowMultiplier = perpendicularLineLength / (2 * rowPixelSpacing);
      const columnMultiplier = perpendicularLineLength / (2 * columnPixelSpacing);

      startX = mid.x + columnMultiplier * vector.y;
      startY = mid.y - rowMultiplier * vector.x;
      endX = mid.x - columnMultiplier * vector.y;
      endY = mid.y + rowMultiplier * vector.x;
    }

    measurementData.handles.perpendicularStart.x = startX;
    measurementData.handles.perpendicularStart.y = startY;
    measurementData.handles.perpendicularEnd.x = endX;
    measurementData.handles.perpendicularEnd.y = endY;

    return true;
  }

  getLineVector(columnPixelSpacing, rowPixelSpacing, startPoint, endPoint) {
    const dx = (startPoint.x - endPoint.x) * columnPixelSpacing;
    const dy = (startPoint.y - endPoint.y) * rowPixelSpacing;
    const length = Math.sqrt(dx * dx + dy * dy);
    const vectorX = dx / length;
    const vectorY = dy / length;

    return {
      x: vectorX,
      y: vectorY,
      length,
    };
  }
}
