import cn from 'classnames';
import { AlertVariant } from './';
/* Class assignment */
export var alertClass = function (className, variant) {
    return cn(className, 
    /* Alert (base) */
    'flex items-center p-3 rounded border', 
    /* Alert variants */
    variant === AlertVariant.warning && 'bg-gray-600 text-feedback-warning-500 border-feedback-warning-500', variant === AlertVariant.success && 'bg-gray-600 text-feedback-positive-500 border-feedback-positive-500', variant === AlertVariant.error && 'bg-gray-600 text-feedback-negative-500 border-feedback-negative-500', variant === AlertVariant.info && 'bg-gray-600 text-secondary-500 border-secondary-500');
};
export var iconClass = function (variant) {
    return cn(
    /* Icon (base) */
    'mr-3', 
    /* Icon variants */
    variant === AlertVariant.warning && 'text-feedback-warning-500', variant === AlertVariant.success && 'text-feedback-positive-500', variant === AlertVariant.error && 'text-feedback-negative-500', variant === AlertVariant.info && 'text-secondary-500');
};
