var _a;
/* eslint-disable i18next/no-literal-string */
/* _TODO: Migrate component to @eva-pacs/eva-eden  */
import React from 'react';
import cn from 'classnames';
var DEFAULT_STEP_VALUE = 1;
export var StepState;
(function (StepState) {
    StepState["Active"] = "active";
    StepState["Enabled"] = "enabled";
    StepState["Disabled"] = "disabled";
    StepState["Success"] = "success";
    StepState["Error"] = "error";
})(StepState || (StepState = {}));
export var StepStateIconVariant = (_a = {},
    _a[StepState.Active] = 'border-none bg-primary-300',
    _a[StepState.Enabled] = 'border-gray-50 bg-gray-100',
    _a[StepState.Success] = 'border-feedback-positive-400 bg-feedback-positive-800',
    _a[StepState.Error] = 'bg-feedback-negative-800 border-feedback-negative-500',
    _a[StepState.Disabled] = 'border-gray-50 bg-gray-100',
    _a);
/**
 * A Step component is used to indicate users their current progress within a flow.
 * @author Alan Chávez <alan.chavez@evacenter.com>
 * Created at 2022-06-01
 */
export var Step = function (_a) {
    var _b, _c, _d, _e, _f;
    var step = _a.step, title = _a.title, content = _a.content, assistiveText = _a.assistiveText, state = _a.state, _g = _a.isLastStep, isLastStep = _g === void 0 ? false : _g, className = _a.className, _h = _a.dataTestId, dataTestId = _h === void 0 ? 'step' : _h;
    var _step = step !== null && step !== void 0 ? step : DEFAULT_STEP_VALUE;
    var stepState = state !== null && state !== void 0 ? state : StepState.Enabled;
    var isDisabled = stepState === StepState.Disabled;
    var isError = stepState === StepState.Error;
    var isEnabled = stepState === StepState.Enabled;
    var stepClasses = {
        text: cn('text-caption font-light', (_b = {},
            _b['text-gray-50'] = !isDisabled,
            _b)),
        step: cn(className, 'flex items-center space-x-2', (_c = {},
            _c['pb-4 mb-4 border-b border-gray-100'] = !isLastStep,
            _c)),
        icon: cn('flex-none border w-6 h-6 rounded-full flex items-center justify-center font-bold text-caption text-basic-white', StepStateIconVariant[stepState]),
        contentContainer: cn((_d = {},
            _d['text-gray-300'] = isDisabled,
            _d)),
        content: cn((_e = {},
            _e['text-gray-50 font-light'] = isEnabled,
            _e['text-basic-white font-bold'] = !isEnabled && !isDisabled,
            _e)),
        assistiveText: cn((_f = {},
            _f['text-feedback-negative-600'] = isError,
            _f)),
    };
    return (React.createElement("div", { className: stepClasses.step, "data-testid": dataTestId },
        React.createElement("div", { className: stepClasses.icon, "data-testid": "".concat(dataTestId, "-icon") }, _step),
        React.createElement("div", { className: stepClasses.contentContainer },
            title && React.createElement("div", { className: stepClasses.text }, title),
            content && React.createElement("div", { className: stepClasses.content }, content),
            assistiveText && (React.createElement("div", { className: cn(stepClasses.text, stepClasses.assistiveText), "data-testid": "".concat(dataTestId, "-assistiveText") }, assistiveText)))));
};
