import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
export var LogoColor;
(function (LogoColor) {
    LogoColor["default"] = "#FF5C61";
    LogoColor["blue"] = "#3983F7";
    LogoColor["light"] = "#F2F2F2";
    LogoColor["dark"] = "#060606";
    LogoColor["primary"] = "#4E55FF";
})(LogoColor || (LogoColor = {}));
export var LogoType;
(function (LogoType) {
    LogoType["symbol"] = "symbol";
    LogoType["wordmark"] = "wordmark";
    LogoType["complete"] = "complete";
})(LogoType || (LogoType = {}));
/**
 * The logo is a visual representation of a brand or product. It can be a word or an image, or a combination of both.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-04-02
 */
var Logo = function (_a) {
    var _b = _a.hasLink, hasLink = _b === void 0 ? true : _b, _c = _a.width, width = _c === void 0 ? '123.17px' : _c, _d = _a.height, height = _d === void 0 ? '32px' : _d, arialLabel = _a.arialLabel, _e = _a.color, color = _e === void 0 ? LogoColor.default : _e, _f = _a.type, type = _f === void 0 ? LogoType.complete : _f, className = _a.className;
    var logoClass = cn(className, 'inline-flex');
    var logo = {
        // SYMBOL
        symbol: function () {
            return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 35 32", fill: color, xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "M10.4659 26.0431C12.8194 27.5207 15.5555 28.2718 18.3328 28.2026C19.3088 28.1784 20.2799 28.0547 21.2308 27.8334L20.6076 25.148C17.6355 25.8388 14.5109 25.3196 11.9212 23.7045C9.33151 22.0894 7.48887 19.5107 6.79864 16.5357C6.10842 13.5607 6.62714 10.4331 8.2407 7.84089C9.85426 5.24871 12.4305 3.4043 15.4026 2.71341C15.7344 2.63356 16.0246 2.4331 16.2169 2.15098C16.4092 1.86886 16.4899 1.52525 16.4432 1.18692C16.3965 0.8486 16.2258 0.53974 15.9643 0.320379C15.7028 0.101017 15.3691 -0.013174 15.0281 9.81148e-07C14.945 0.00300931 14.8622 0.0133055 14.7808 0.0307716H14.7599L14.7319 0.0391635C11.9896 0.68399 9.49962 2.12779 7.57653 4.18822C5.65345 6.24865 4.38348 8.8333 3.92701 11.6157C3.47055 14.3982 3.84806 17.2536 5.01186 19.8214C6.17567 22.3893 8.07358 24.5543 10.4659 26.0431Z" }),
                React.createElement("path", { d: "M32.3898 18.8707C31.4685 21.6047 29.791 24.0209 27.5522 25.8384C25.3135 27.6559 22.6054 28.8002 19.7427 29.1383C15.7729 29.6097 11.5055 28.5215 7.8166 25.4417C3.13838 21.0107 1.66841 14.795 3.55758 9.16263C3.67376 8.81635 3.64775 8.43806 3.48528 8.11097C3.32282 7.78388 3.03719 7.53479 2.69124 7.4185C2.3453 7.30221 1.96736 7.32824 1.64059 7.49086C1.31382 7.65349 1.06497 7.93939 0.948788 8.28567C-0.570637 12.8057 -0.233992 17.7447 1.88466 22.0163C4.00332 26.2878 7.73044 29.542 12.2461 31.0629C16.7618 32.5838 21.6961 32.2468 25.9635 30.1261C30.2309 28.0054 33.482 24.2747 35.0014 19.7547L32.3898 18.8707Z" }),
                React.createElement("path", { d: "M11.9568 18.882L9.78122 20.5673C8.07372 18.3706 7.30797 15.5849 7.65243 12.823C7.9969 10.0611 9.42335 7.54927 11.618 5.84012C13.8126 4.13098 16.5957 3.36449 19.3549 3.70929C22.1142 4.05408 24.6235 5.48191 26.331 7.67867C26.556 7.96689 26.6573 8.33276 26.6128 8.69579C26.5682 9.05881 26.3815 9.38925 26.0935 9.61442C25.8056 9.83958 25.44 9.94103 25.0774 9.89644C24.7147 9.85185 24.3846 9.66487 24.1596 9.37664C22.3054 6.99192 19.2564 5.87159 16.065 6.61568C10.396 8.32065 8.74301 14.7391 11.9568 18.882Z" }),
                React.createElement("path", { d: "M22.7343 17.8567C22.9747 17.8502 23.2091 17.7808 23.4143 17.6554C23.6195 17.53 23.7883 17.353 23.9039 17.142C23.9039 17.1349 23.9091 17.127 23.9141 17.1194C23.916 17.1166 23.9178 17.1139 23.9193 17.1112L23.9319 17.0833C25.1294 14.7363 24.91 11.9082 23.1535 9.64098C19.7175 5.72473 13.976 6.73596 11.8353 10.8312L14.275 12.1082C15.5479 9.67035 18.9672 9.06473 21.0143 11.3991C22.0636 12.7614 22.1936 14.4608 21.46 15.8664C21.3609 16.0675 21.3129 16.2899 21.3203 16.514C21.3248 16.6952 21.365 16.8737 21.4385 17.0393C21.5121 17.2049 21.6176 17.3543 21.749 17.4791C21.8803 17.6038 22.035 17.7014 22.2041 17.7662C22.3732 17.831 22.5534 17.8617 22.7343 17.8567Z" }),
                React.createElement("path", { d: "M16.5344 16.991C16.4953 16.9756 16.4576 16.9588 16.4199 16.9406L16.3807 16.9238C16.0568 16.7545 15.8134 16.4633 15.704 16.1143C15.5946 15.7653 15.6282 15.3871 15.7974 15.0629C15.9665 14.7387 16.2575 14.495 16.6061 14.3855C16.9548 14.276 17.3326 14.3096 17.6565 14.479C17.7157 14.5128 17.7812 14.5343 17.8489 14.5423C17.9166 14.5503 17.9853 14.5445 18.0507 14.5252C18.1162 14.506 18.1771 14.4738 18.2298 14.4305C18.2825 14.3872 18.3259 14.3337 18.3575 14.2732C18.3891 14.2127 18.4082 14.1464 18.4136 14.0784C18.419 14.0103 18.4107 13.9419 18.3891 13.8771C18.3674 13.8124 18.333 13.7527 18.2878 13.7015C18.2426 13.6504 18.1876 13.609 18.126 13.5796L19.4017 11.1362C20.151 11.5321 20.7167 12.2047 20.9789 13.0112C21.2411 13.8176 21.1791 14.6946 20.8062 15.4561C20.4332 16.2177 19.7786 16.8038 18.9812 17.0904C18.1837 17.3769 17.3061 17.3412 16.5344 16.991Z" })));
        },
        // WORDMARK
        wordmark: function () {
            return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 77 24", fill: color, xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "M2.502 19.337A11.994 11.994 0 010 12.001 11.968 11.968 0 0111.953 0C18.529 0 24 5.377 24 12c0 .481-.048 1.009-.096 1.488H4.944a7.18 7.18 0 0012.912 2.64l4.609 1.728a11.996 11.996 0 01-19.963 1.481zm9.45-14.536c-3.126 0-5.76 2.063-6.769 4.847h13.585C17.76 6.864 15.12 4.8 11.953 4.8z" }),
                React.createElement("path", { d: "M28.204 24c13.152 0 22.37-10.704 22.37-24h-4.797c0 8.928-4.944 16.417-12.768 18.577V0h-4.805v24z" }),
                React.createElement("path", { d: "M71.337 0v2.394a11.989 11.989 0 10-7.2 21.6 11.745 11.745 0 007.2-2.447v2.447h4.808V0h-4.808zm-7.2 19.2a7.2 7.2 0 117.248-7.2 7.18 7.18 0 01-7.248 7.2z" })));
        },
        // COMPLETE
        complete: function () {
            return (React.createElement("svg", { width: width, height: height, fill: color, viewBox: "0 0 124 33", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "M10.466 26.043a14.133 14.133 0 007.867 2.16 14.355 14.355 0 002.898-.37l-.623-2.685c-2.973.69-6.097.172-8.687-1.444A11.525 11.525 0 018.241 7.84a11.505 11.505 0 017.162-5.127A1.375 1.375 0 0015.964.32a1.374 1.374 0 00-1.183-.29h-.021l-.028.01a14.25 14.25 0 00-7.155 4.148 14.272 14.272 0 00-2.565 15.633 14.262 14.262 0 005.454 6.222z" }),
                React.createElement("path", { d: "M32.39 18.87a15.245 15.245 0 01-4.838 6.968 15.223 15.223 0 01-7.81 3.3c-3.97.472-8.237-.617-11.925-3.696-4.679-4.431-6.149-10.647-4.26-16.28a1.379 1.379 0 00-.866-1.744 1.375 1.375 0 00-1.742.868 17.997 17.997 0 00.936 13.73 17.97 17.97 0 0010.361 9.047 17.948 17.948 0 0013.717-.937 17.976 17.976 0 009.038-10.371l-2.611-.884z" }),
                React.createElement("path", { d: "M11.957 18.882L9.78 20.567A10.502 10.502 0 0111.618 5.84 10.477 10.477 0 0126.331 7.68a1.38 1.38 0 01-1.254 2.217 1.378 1.378 0 01-.917-.52c-1.855-2.384-4.904-3.504-8.095-2.76-5.669 1.705-7.322 8.123-4.108 12.266z" }),
                React.createElement("path", { d: "M22.734 17.857a1.375 1.375 0 001.17-.715c0-.007.005-.015.01-.023a.11.11 0 00.005-.008l.013-.028c1.197-2.347.978-5.175-.778-7.442-3.436-3.916-9.178-2.905-11.319 1.19l2.44 1.277c1.273-2.438 4.692-3.043 6.74-.709 1.049 1.362 1.179 3.062.445 4.467-.1.201-.147.424-.14.648a1.378 1.378 0 001.414 1.343z" }),
                React.createElement("path", { d: "M16.535 16.991a1.65 1.65 0 01-.115-.05l-.04-.017a1.379 1.379 0 011.277-2.445.509.509 0 10.469-.9l1.276-2.443a3.26 3.26 0 011.404 4.32 3.26 3.26 0 01-4.271 1.535z" }),
                React.createElement("path", { d: "M49.536 23.337a11.995 11.995 0 01-2.503-7.336A11.97 11.97 0 0158.986 4c6.577 0 12.047 5.377 12.047 12 0 .481-.047 1.009-.095 1.488h-18.96a7.18 7.18 0 0012.911 2.64l4.61 1.728a11.995 11.995 0 01-19.963 1.481zm9.45-14.536c-3.126 0-5.76 2.063-6.769 4.847h13.585C64.794 10.864 62.153 8.8 58.986 8.8z" }),
                React.createElement("path", { d: "M75.237 28c13.153 0 22.37-10.704 22.37-24H92.81c0 8.928-4.945 16.417-12.769 18.577V4h-4.805v24z" }),
                React.createElement("path", { d: "M118.371 4v2.394a11.992 11.992 0 00-15.568 1.06 11.993 11.993 0 00-3.45 10.35 11.996 11.996 0 0011.819 10.19 11.744 11.744 0 007.199-2.447v2.447h4.808V4h-4.808zm-7.2 19.2a7.202 7.202 0 01-5.027-12.307A7.2 7.2 0 01118.419 16a7.191 7.191 0 01-2.12 5.114 7.181 7.181 0 01-5.128 2.086z" })));
        },
    };
    if (!hasLink)
        return (React.createElement("div", { className: logoClass, "aria-label": arialLabel }, logo[type]()));
    return (
    // eslint-disable-next-line i18next/no-literal-string
    React.createElement(Link, { className: logoClass, "aria-label": arialLabel, rel: "noopener noreferrer", to: "/" }, logo[type]()));
};
export default Logo;
