var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable i18next/no-literal-string */
import React from 'react';
import cn from 'classnames';
/**
 * Checkbox are used when there are multiple items to select in a list. Users can select zero, one, or any number of items.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-06-22
 */
export var Checkbox = React.forwardRef(function (_a, ref) {
    var name = _a.name, value = _a.value, className = _a.className, checked = _a.checked, onChange = _a.onChange, restOfProps = __rest(_a, ["name", "value", "className", "checked", "onChange"]);
    var checkboxClass = cn(className, 'checked:border-transparent min-w-4 h-4 checked:bg-primary-300 border-2 border-light-gray-600 rounded focus:outline-none appearance-none');
    var handleChange = function (event) {
        if (onChange)
            onChange(event);
    };
    var checkedStyles = {
        backgroundImage: "url(\"data:image/svg+xml,<svg width='8' height='6' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.6L3.28571 7L9 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>\")",
        borderColor: 'transparent',
        backgroundSize: '70% 100%',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
    };
    return (React.createElement("input", __assign({ ref: ref, type: "checkbox", name: name, style: checked ? checkedStyles : {}, className: checkboxClass, checked: checked, value: value, onChange: handleChange }, restOfProps)));
});
