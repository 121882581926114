import React from 'react';
import cn from 'classnames';
import { Popover, PopoverPlacement, Icon, IconCatalog } from '@evacenter/eden';

import { ADMIN_PATH, setMaxHeightByOptions, useRouter } from '@eva-pacs/core';

import { EVA_PRODUCT } from '~/constants';
import { useProductRolesStore } from '~/src/store/useProductRolesStore';
import { Item } from './Item/Item';

export interface ProductPopupMenuProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set the default selected option
   */
  selectedProductId?: EVA_PRODUCT;

  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;

  iconClassName?: string;
}

/**
 * This components display a popup menu to list all the Eva products.
 * @author Alejandro Forero<alejoforero.af@gmail.com>
 * Created at 2022-09-09
 */
export const ProductPopupMenu = ({
  className,
  dataTestId,
  selectedProductId,
  iconClassName,
}: ProductPopupMenuProps) => {
  const classes = {
    container: cn(className, 'flex-grow overflow-hidden w-80 e-bg-neutral-700'),
    icon: cn('e-cursor-pointer', iconClassName),
    option: (itemId: EVA_PRODUCT) =>
      cn({
        'e-bg-neutral-700': selectedProductId === itemId,
      }),
  };
  const { push: historyPush } = useRouter();

  const { getProductList } = useProductRolesStore();

  const handleOptionClick = (productId: EVA_PRODUCT) => () => {
    const evacenterUrl = process.env['REACT_APP_EVACENTER_URL'];
    switch (productId) {
      case EVA_PRODUCT.PACS:
        return historyPush('/');
      case EVA_PRODUCT.MANAGEMENT:
        return (window.location.href = `${evacenterUrl}/management`);
      case EVA_PRODUCT.INTELLIGENCE:
        return (window.location.href = `${process.env['REACT_APP_INTELLIGENCE']}`);
      case EVA_PRODUCT.PANEL:
        return historyPush(ADMIN_PATH);
    }
  };

  const popoverContent = getProductList().map((product) => {
    const productSlug = product.slug as EVA_PRODUCT;
    return (
      <Item
        key={productSlug}
        slug={productSlug}
        description={product.description}
        className={classes.option(productSlug)}
        onClick={handleOptionClick(productSlug)}
        selectedProduct={selectedProductId === productSlug}
      />
    );
  });
  return (
    <Popover
      content={
        <div
          data-testid={dataTestId}
          role="listbox"
          style={setMaxHeightByOptions({
            listLength: getProductList().length,
            maxOptions: 6,
            maxHeight: '210px',
          })}
          className={classes.container}>
          {popoverContent}
        </div>
      }
      placement={PopoverPlacement.bottomEnd}
      hasArrow={false}
      menuClassName="e-overflow-hidden">
      <span>
        <Icon className={classes.icon} icon={IconCatalog.apps} />
      </span>
    </Popover>
  );
};
