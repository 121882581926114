import axios from 'axios';
import { navigateTo, removeAuthCookies } from './utils';
import { LOGIN_PATH } from '@eva-pacs/core';
var url = "".concat(process.env.REACT_APP_API, "/api/v1/");
export var restClient = axios.create({
    baseURL: url,
    headers: {
        'Content-type': 'application/json',
    },
});
restClient.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
        removeAuthCookies();
        navigateTo(LOGIN_PATH);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
