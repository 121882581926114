//@ts-ignore
import { ProductPermissionGroupType, ProductType, useGetProductsLazyQuery } from '@eva-pacs/client';

import { EVA_PRODUCT } from '../constants/products';
import { useSessionStore } from '../store';

import {
  PermissionGroup,
  ProductPermissionGroup,
  useProductPermissionGroupsStore,
} from '../store/productPermissionGroupsStore';

export const useLoadProductPermissionGroups = () => {
  const { user } = useSessionStore();
  const { setProductPermissionGroups, setProductsAreReady, productsAreReady } = useProductPermissionGroupsStore();

  const saveProductPermissionGroups = (products: Array<ProductType>) => {
    const productPermissionGroups = formatProductPermissionGroups(products);
    setProductPermissionGroups(productPermissionGroups);
  };

  const [fetchProductPermissionGroups, { loading: loadingProductPermissionGroups }] = useGetProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ products }) => {
      setProductsAreReady(true);
      if (!products?.results.length) return;
      saveProductPermissionGroups(products.results as Array<ProductType>);
    },
  });

  const loadProductPermissionGroups = () => {
    if (productsAreReady || user?.visitant?.id) return;
    fetchProductPermissionGroups();
  };

  return { loadProductPermissionGroups, loadingProductPermissionGroups };
};

const formatPermissionGroups = (permissionGroup: Array<ProductPermissionGroupType>): Array<PermissionGroup> =>
  permissionGroup.map((permissionGroup) => {
    return {
      id: permissionGroup.group?.id ?? '',
      name: permissionGroup.group?.name ?? '',
      isVisible: permissionGroup.isVisible,
    };
  });

const formatProductPermissionGroups = (productPermissionGroups: Array<ProductType>): Array<ProductPermissionGroup> =>
  productPermissionGroups.map<ProductPermissionGroup>((productPermissionGroup) => {
    return {
      groups: formatPermissionGroups(productPermissionGroup.productPermissionGroups),
      product: productPermissionGroup.name,
      id: productPermissionGroup.id,
      slug: productPermissionGroup.slug as EVA_PRODUCT,
      hasAccess: productPermissionGroup.productOrganizations[0]?.hasAccess,
    };
  });
