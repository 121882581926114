/*
 * US Region Types
 * https://dicom.innolitics.com/ciods/ultrasound-image/us-region-calibration/00186011/00186014
 */

export enum US_MODALITY_REGION_TYPES {
  NONE = 0, // 0000H None or not applicable
  TISSUE = 1, // 0001H Tissue
  COLOR_FLOW = 2, // 0002H Color Flow
  PW_SPECTRAL_DOPPLER = 3, // 0003H PW Spectral Doppler
  CW_SPECTRAL_DOPPLER = 4, // 0004H CW Spectral Doppler
  DOPPLER_MEAN_TRACE = 5, // 0005H Doppler Mean Trace
  DOPPLER_MODE_TRACE = 6, // 0006H Doppler Mode Trace
  DOPPLER_MAX_TRACE = 7, // 0007H Doppler Max Trace
  VOLUME_TRACE = 8, // 0008H Volume Trace
  ECG_TRACE = 10, // 000AH ECG Trace
  PULSE_TRACE = 11, // 000BH Pulse Trace
  PHONOCARDIOGRAM_TRACE = 12, // 000CH Phonocardiogram Trace
  GRAY_BAR = 13, // 000DH Gray bar
  COLOR_BAR = 14, // 000EH Color bar
  INTEGRATED_BACKSCATTER = 15, // 000FH Integrated Backscatter
}
