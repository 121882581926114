export var FileExtension;
(function (FileExtension) {
    FileExtension["jpg"] = "jpg";
    FileExtension["jpeg"] = "jpeg";
    FileExtension["png"] = "png";
    FileExtension["JPG"] = "JPG";
    FileExtension["JPEG"] = "JPEG";
    FileExtension["PNG"] = "PNG";
    FileExtension["pdf"] = "pdf";
    FileExtension["PDF"] = "PDF";
    FileExtension["stl"] = "stl";
    FileExtension["STL"] = "STL";
    FileExtension["ZIP"] = "ZIP";
    FileExtension["PUB"] = "PUB";
    FileExtension["pub"] = "pub";
})(FileExtension || (FileExtension = {}));
export var FileType;
(function (FileType) {
    FileType["PDF"] = "PDF";
    FileType["CONVENTIONAL_IMAGE"] = "CONVENTIONAL_IMAGE";
    FileType["DICOM"] = "DICOM";
    FileType["ECG"] = "ECG";
    FileType["STL"] = "STL";
    FileType["NO_DICOM"] = "NO_DICOM";
    FileType["DICOM_PDF"] = "DICOM_PDF";
})(FileType || (FileType = {}));
export var FileCategory;
(function (FileCategory) {
    FileCategory["REPORT"] = "/media/report";
})(FileCategory || (FileCategory = {}));
export var NO_DICOM_EXTENSIONS = [
    FileExtension.jpg,
    FileExtension.JPG,
    FileExtension.jpeg,
    FileExtension.JPEG,
    FileExtension.png,
    FileExtension.PNG,
    FileExtension.pdf,
    FileExtension.PDF,
    FileExtension.stl,
    FileExtension.STL,
    FileExtension.PUB,
    FileExtension.pub,
];
export var IMAGES_EXTENSIONS = [
    FileExtension.jpg,
    FileExtension.jpeg,
    FileExtension.png,
    FileExtension.JPG,
    FileExtension.JPEG,
    FileExtension.PNG,
    FileExtension.pdf,
    FileExtension.PDF,
];
export var EXTERNAL_KEY = '-EXTERNAL';
export var NO_DICOM_FILES_TYPES = NO_DICOM_EXTENSIONS.map(function (ext) { return ".".concat(ext); }).join(', ');
export var IMAGES_FILES_TYPES = IMAGES_EXTENSIONS.map(function (ext) { return ".".concat(ext); }).join(', ');
