import produce from 'immer';
import create, { GetState, SetState } from 'zustand';
import { BoundingBox } from '~/src/interfaces/BoundingBox';
import { getBiggestCombinedBox, validateBoundingBox } from '~/src/utils/boundingBox';
import { calcRegionsMatrix } from '~/utils/cornestone/canvasViewportRelation';
export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

export interface Region {
  x: number;
  y: number;
}

interface MatrixSize {
  rows: number;
  columns: number;
}

interface RegionComparisonStore {
  /**
   * Mammography study id
   */
  studyId: number;
  /**
   * Size of the array into which a viewport is divided
   */
  matrixSize: MatrixSize;
  /**
   * Current visited region in the matrix,
   * it can be null when initialized or after all regions of
   * the array have been traversed
   */
  currentRegion: Region | null;
  /**
   * Registered Image Bounding Box List
   */
  imagesBoundingBoxes: BoundingBox[];
  /**
   * The largest bounding box of enabled elements.
   * This can be the combination of the maximum values
   * of the bounding boxes list
   */
  biggestBox: BoundingBox;
  /**
   * Add a bounding box to the list and performs the
   * calculation and update of the largest bounding box if necessary
   */
  addBoundingBox: (study: BoundingBox) => void;
  /**
   * Get bounding box by image id
   */
  getBoundingBoxByImageId: (imageId: string) => BoundingBox;
  /**
   * Get bounding box by viewport index
   */
  getBoundingBoxByViewportIndex: (viewportIndex: number) => BoundingBox;
  /**
   * Set matrix size
   */
  setMatrixSize: (matrix: { columns: number; rows: number }) => void;
  /**
   * Recalculates the size of the matrix
   * and obtains the next region to traverse in it
   */
  nextRegion: (currentElement: HTMLElement) => [Region, MatrixSize];
  /**
   * Set current region in the matrix
   */
  setRegion: (region: Region | null) => void;
  /**
   * Get current region in the matrix
   */
  getCurrentRegion: () => Region | null;
  /**
   * Reset region in the matrix
   */
  resetRegion: () => void;
}

const regionComparisonStore = (set: SetState<RegionComparisonStore>, get: GetState<RegionComparisonStore>) => ({
  currentRegion: null,
  imagesBoundingBoxes: [],
  biggestBox: null,
  addBoundingBox: (boundingBox: BoundingBox) => {
    return set((state) => {
      const filteredBoxes = state.imagesBoundingBoxes.filter((b) => b.viewportIndex !== boundingBox.viewportIndex);
      const validatedBox = validateBoundingBox(boundingBox);
      filteredBoxes.push(validatedBox);
      const biggestBox = getBiggestCombinedBox(filteredBoxes);
      state.imagesBoundingBoxes = filteredBoxes;
      if (
        !state.biggestBox ||
        biggestBox.y !== state.biggestBox.y ||
        biggestBox.height !== state.biggestBox.height ||
        biggestBox.width !== state.biggestBox.width ||
        biggestBox.x !== state.biggestBox.x
      ) {
        state.biggestBox = biggestBox;
      }
    });
  },
  nextRegion: (currentElement: HTMLElement) => {
    const { currentRegion, biggestBox } = get();
    const matrixSize = calcRegionsMatrix(currentElement, biggestBox);
    if (!matrixSize) return;

    if (!currentRegion) {
      const region = { x: 0, y: 0 };
      set((state) => {
        state.matrixSize = matrixSize;
      });
      return [region, matrixSize];
    }

    let y = currentRegion.y + 1;
    let x = currentRegion.x;

    if (y >= matrixSize.rows) {
      y = 0;
      x++;
    }
    if (x >= matrixSize.columns) x = 0;
    if (x === 0 && y === 0) {
      set((state) => {
        state.matrixSize = matrixSize;
      });
      return [null, matrixSize];
    }

    const nextRegion = { x, y };

    set((state) => {
      state.matrixSize = matrixSize;
    });
    return [nextRegion, matrixSize];
  },
  setRegion: (region: Region) => {
    return set((state) => {
      state.currentRegion = region;
    });
  },
  getCurrentRegion: () => {
    return get().currentRegion;
  },
  resetRegion: () => {
    return set((state) => {
      state.currentRegion = null;
    });
  },
  setMatrixSize: (matrix: { columns: number; rows: number }) => {
    set((state) => {
      state.matrixSize = {
        rows: matrix.rows,
        columns: matrix.columns,
      };
    });
  },
  clearBoundingBoxes: () => {
    return set((state) => {
      state.imagesBoundingBoxes = [];
    });
  },
  getBoundingBoxByImageId: (imageId: string) => {
    return get().imagesBoundingBoxes.find((boundingBox) => boundingBox.imageId === imageId);
  },
  getBoundingBoxByViewportIndex: (viewportIndex: number) => {
    return get().imagesBoundingBoxes.find((boundingBox) => boundingBox.viewportIndex === viewportIndex);
  },
});

export const useRegionComparisonStore = create<RegionComparisonStore>(immer(regionComparisonStore));
