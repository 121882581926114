import { BoundingBox } from '~/src/interfaces/BoundingBox';
import { getEnabledElementControlled } from './getEnabledElementControlled';

/**
 * This function returns the coordinate in the viewport of the upper right corner of the canvas
 */
export const getCanvasOriginInViewport = (element: HTMLElement) => {
  const { image, canvas, viewport } = getEnabledElementControlled(element) ?? {};
  if (!image || !canvas || !viewport?.scale) return;
  const canvasHeight = canvas.clientHeight;
  const canvasWidth = canvas.clientWidth;
  const imageWidthInViewport = image.width * viewport.scale;
  const imageHeightInViewport = image.height * viewport.scale;
  const x = Math.round((imageWidthInViewport - canvasWidth) / 2);
  const y = Math.round((imageHeightInViewport - canvasHeight) / 2);
  return { x, y };
};

/**
 * This function calculate and return the minimum size of a matrix in which a bounding box will fit
 */
export const calcRegionsMatrix = (element: HTMLElement, boundingBox: BoundingBox) => {
  if (!boundingBox) return { rows: 1, columns: 1 };
  const { canvas } = getEnabledElementControlled(element) ?? {};
  if (!canvas) return;
  const { width, height } = boundingBox;
  const columns = Math.ceil(width / canvas.clientWidth);
  const rows = Math.ceil(height / canvas.clientHeight);
  return { rows, columns };
};
