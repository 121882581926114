import { useViewportStore } from '../store';

export const useLayoutHooks = () => {
  const { layout } = useViewportStore();

  /**
   * This will compare is a grid has that value as the largest number
   */
  const largeGridValueIs = (value: number) => {
    const list = [layout.numColumns, layout.numRows];
    const max = Math.max(...list);
    return value === max;
  };

  /**
   * This function will return true if the grid has
   * the number of rows or the number columns specified
   * @param {number} layoutCount - Number of images on viewer
   */
  const isGridOf = (layoutCount: number) => {
    const cols = layout.numColumns;
    const rows = layout.numRows;
    return cols === layoutCount || rows === layoutCount;
  };

  return { isGridOf, largeGridValueIs };
};
