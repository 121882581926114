var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
/**
 * Hook used for a simpler navigation.
 * See more: https://usehooks.com/useRouter/
 * @author Mario Lugo<mariolugo23@gmail.com>
 * Created at 2021-04-05
 */
export var useRouter = function () {
    var params = useParams();
    var location = useLocation();
    var history = useHistory();
    var match = useRouteMatch();
    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(function () {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: history.push,
            replace: history.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: __assign(__assign({}, queryString.parse(location.search)), params),
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            match: match,
            location: location,
            history: history,
        };
    }, [params, match, location, history]);
};
