import { useEffect, useRef, useCallback } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import shallow from 'zustand/shallow';
import { LEFT_MOUSE_BUTTON } from '~/constants';
import { useErrorHandler } from '~/utils/appHelpers';
import { usePrinterStore, useSeriesStore, useViewportStore } from '~/src/store';
import { getViewportEnabledElements } from '~/utils/getViewportEnabledElements';
import { allImagesHasLoaded } from '~/utils/allImagesHasLoaded';

interface UseCornerstoneParams {
  eventToListen: string;
  synchronizerFunction: (synchronizer, sourceElement: HTMLElement, targetElement: HTMLElement) => void;
  initialEnabled?: number | null;
  toolName: string;
}

type UseCornerstoneSynchHook = [
  (activeViewportIndex: number, forceToolActivation?: boolean) => void,
  () => void,
  () => void,
];

export const useCornerstoneSynchToolWithContext = ({
  eventToListen,
  synchronizerFunction,
  toolName,
}: UseCornerstoneParams): UseCornerstoneSynchHook => {
  const showPrintView = usePrinterStore((state) => state.showPrintView);
  const getSerieById = useSeriesStore((state) => state.getSerieById);
  const [viewportData, activeIndex] = useViewportStore((state) => [state.viewportData, state.activeIndex], shallow);
  const synchronizerRef = useRef(new cornerstoneTools.Synchronizer(eventToListen, synchronizerFunction));
  const { handleError } = useErrorHandler();

  const removeSynchronizer = useCallback(() => {
    synchronizerRef.current.destroy();
  }, []);

  const clearElementSynchronizer = useCallback(
    (element) => {
      synchronizerRef.current.remove(element);
      cornerstoneTools.setToolDisabledForElement(element, toolName, {
        synchronizationContext: synchronizerRef.current,
      });
    },
    [toolName],
  );

  const clearAllSynchronizers = useCallback(() => {
    const enabledElements = cornerstone.getEnabledElements() ?? [];
    enabledElements.forEach((viewport) => {
      clearElementSynchronizer(viewport.element);
    });
  }, [clearElementSynchronizer]);

  const setElementSynchronizer = useCallback((element) => {
    synchronizerRef.current.addSource(element);
  }, []);

  const setElementTarget = useCallback((element) => {
    synchronizerRef.current.addTarget(element);
  }, []);

  const lastImageIdLoaded = viewportData[activeIndex]?.lastImageIdLoaded;

  const setSincronizersElements = (currentIndex: number, forceToolActivation = false) => {
    if (showPrintView || currentIndex === null || !lastImageIdLoaded) return;
    try {
      const viewportDataArray = Object.values(viewportData);
      const enabledElements = getViewportEnabledElements(viewportDataArray);
      const vieportsAreReady = enabledElements?.length > 0 && allImagesHasLoaded(viewportDataArray);

      if (!vieportsAreReady) return;

      const currentViewport = viewportDataArray[currentIndex];
      const currentSerieIsLoaded = getSerieById(currentViewport.currentSerieId)?.isLoaded;

      if (!currentSerieIsLoaded) return;
      enabledElements.forEach((element, indexElement) => {
        clearElementSynchronizer(element);

        if (indexElement === currentIndex) {
          setElementSynchronizer(element);
        } else {
          setElementTarget(element);
        }
        cornerstoneTools.setToolEnabledForElement(element, toolName, {
          synchronizationContext: synchronizerRef.current,
        });
      });
      if (forceToolActivation) cornerstoneTools.setToolActive(toolName, { mouseButtonMask: LEFT_MOUSE_BUTTON });
    } catch (error) {
      handleError({ logMessage: error });
    }
  };

  useEffect(() => {
    return removeSynchronizer;
  }, [removeSynchronizer]);

  return [setSincronizersElements, removeSynchronizer, clearAllSynchronizers];
};
