var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { dropFiles } from '@eva-pacs/core';
import { FileInput } from '../Inputs/FileInput';
import Icon, { IconCatalog } from '../Icon';
import Image from '../Image';
var FileUploaderStatus;
(function (FileUploaderStatus) {
    FileUploaderStatus["default"] = "default";
    FileUploaderStatus["error"] = "error";
})(FileUploaderStatus || (FileUploaderStatus = {}));
/**
 * Allow to upload a file to the server
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-19
 */
export var FileUploader = function (_a) {
    var ariaLabel = _a.ariaLabel, className = _a.className, accept = _a.accept, acceptOnlyFolders = _a.acceptOnlyFolders, extensions = _a.extensions, hasError = _a.hasError, multiple = _a.multiple, onFilesDrop = _a.onFilesDrop;
    var studyUploaderClass = cn(className, 
    /* FileUploader (base) */
    'inline-flex items-center rounded-lg p-12 relative w-full', 'cursor-pointer', 'border border-dashed border-gray-100 hover:bg-gray-700');
    var inputClass = cn(
    /* Input (base) */
    'absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer z-2');
    var t = useTranslation().t;
    var _b = useState(FileUploaderStatus.default), status = _b[0], setStatus = _b[1];
    var filesRef = useRef(null);
    var renderFileUploader = function () {
        switch (status) {
            case FileUploaderStatus.default:
                return (React.createElement("div", { className: "flex flex-col items-center w-full space-y-4" },
                    React.createElement(Image, { src: "/assets/illustrations/folder-empty.svg", hasMaxWidth: true, alt: "upload study", noImg: React.createElement(React.Fragment, null) }),
                    React.createElement("div", { className: "text-center text-light-gray-600 text-caption font-medium" },
                        React.createElement("span", { className: "mr-1" }, t('study.uploadStudy.studyUploader.description')),
                        React.createElement("span", { className: "text-primary-400 underline" }, t('study.uploadStudy.studyUploader.link')))));
            case FileUploaderStatus.error:
                return (React.createElement("div", { className: "flex flex-col items-center w-full space-y-4" },
                    React.createElement("div", { className: "flex items-center justify-items-center" },
                        React.createElement(Icon, { className: "text-feedback-negative-500", icon: IconCatalog.warning, width: "24", height: "24" }),
                        React.createElement("span", { className: "ml-3 text-basic-white text-subtitle font-semi-bold" }, t('errors.uploader.genericError.caption'))),
                    React.createElement("div", { className: "text-center text-light-gray-600 text-paragraph-sm font-medium" }, t('errors.uploader.genericError.description', { extension: accept })),
                    React.createElement("div", { className: "text-center text-primary-400 underline text-label-sm" }, t('study.uploadStudy.studyUploader.linkError'))));
        }
    };
    var handleOnDrop = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var files;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dropFiles(event)];
                case 1:
                    files = _a.sent();
                    if (onFilesDrop)
                        onFilesDrop(files);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleInputChange = function (validationResponse) { return __awaiter(void 0, void 0, void 0, function () {
        var errorMsg, files;
        return __generator(this, function (_a) {
            errorMsg = validationResponse.find(function (response) { return response.error !== ''; });
            if (errorMsg) {
                setStatus(FileUploaderStatus.error);
                return [2 /*return*/];
            }
            // Check React reference is valid and that there are files
            if (filesRef.current !== null && filesRef.current.files) {
                files = Array.from(filesRef.current.files);
                if (onFilesDrop)
                    onFilesDrop(files);
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (acceptOnlyFolders && filesRef.current !== null) {
            // Add non-standart attributes to element to be able to drop directories
            // eslint-disable-next-line i18next/no-literal-string
            filesRef.current.setAttribute('directory', '');
            // eslint-disable-next-line i18next/no-literal-string
            filesRef.current.setAttribute('webkitdirectory', '');
        }
    }, [filesRef]);
    useEffect(function () {
        if (hasError)
            setStatus(FileUploaderStatus.error);
    }, [hasError]);
    return (React.createElement("div", { "aria-label": ariaLabel, className: studyUploaderClass, onDrop: handleOnDrop, onDragOver: function (event) {
            event.preventDefault();
        } },
        React.createElement(FileInput, { className: inputClass, limitSize: 100, onInputChange: handleInputChange, multiple: multiple, ref: filesRef, accept: accept, extensions: extensions }),
        renderFileUploader()));
};
/**
 * References:
 * [1] https://stackoverflow.com/questions/25333488
 * File input uses a FileList which is not an Array or Iterable
 * For better compability with ES5 we transform it the elements to a Array
 */
