var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ApolloLink, checkFetcher, fallbackHttpConfig, fromError, Observable, selectURI, } from '@apollo/client';
import { createSignalIfSupported } from '../http/createSignalIfSupported';
import { parseAndCheckHttpResponse } from '../http/parseAndCheckHttpResponse';
import { rewriteURIForGET } from '../http/rewriteURIForGET';
import { selectHttpOptionsAndBody } from '../http/selectHttpOptionsAndBody';
import { serializeFetchParameter } from '../http/serializeFetchParameter';
import { extractFiles, isExtractableFile } from 'extract-files';
function formDataAppendFile(formData, fieldName, file) {
    formData.append(fieldName, file, file.name);
}
export var createUploadLink = function (linkOptions) {
    if (linkOptions === void 0) { linkOptions = {}; }
    var _a = linkOptions.uri, uri = _a === void 0 ? '/graphql' : _a, 
    // use default global fetch if nothing passed in
    includeExtensions = linkOptions.includeExtensions, useGETForQueries = linkOptions.useGETForQueries, _b = linkOptions.isExtractableFile, customIsExtractableFile = _b === void 0 ? isExtractableFile : _b, _c = linkOptions.formDataAppendFile, customFormDataAppendFile = _c === void 0 ? formDataAppendFile : _c, requestOptions = __rest(linkOptions, ["uri", "includeExtensions", "useGETForQueries", "isExtractableFile", "formDataAppendFile"]);
    var fetcher = linkOptions.fetch;
    // dev warnings to ensure fetch is present
    checkFetcher(fetcher);
    //fetcher is set here rather than the destructuring to ensure fetch is
    //declared before referencing it. Reference in the destructuring would cause
    //a ReferenceError
    if (!fetcher) {
        fetcher = fetch;
    }
    var linkConfig = {
        http: { includeExtensions: includeExtensions },
        options: requestOptions.fetchOptions,
        credentials: requestOptions.credentials,
        headers: requestOptions.headers,
    };
    return new ApolloLink(function (operation) {
        var chosenURI = selectURI(operation, uri);
        var context = operation.getContext();
        // `apollographql-client-*` headers are automatically set if a
        // `clientAwareness` object is found in the context. These headers are
        // set first, followed by the rest of the headers pulled from
        // `context.headers`. If desired, `apollographql-client-*` headers set by
        // the `clientAwareness` object can be overridden by
        // `apollographql-client-*` headers set in `context.headers`.
        var clientAwarenessHeaders = {};
        if (context.clientAwareness) {
            var _a = context.clientAwareness, name_1 = _a.name, version = _a.version;
            if (name_1) {
                clientAwarenessHeaders['apollographql-client-name'] = name_1;
            }
            if (version) {
                clientAwarenessHeaders['apollographql-client-version'] = version;
            }
        }
        var contextHeaders = __assign(__assign({}, clientAwarenessHeaders), context.headers);
        var contextConfig = {
            http: context.http,
            options: context.fetchOptions,
            credentials: context.credentials,
            headers: contextHeaders,
        };
        //uses fallback, link, and then context to build options
        var _b = selectHttpOptionsAndBody(operation, fallbackHttpConfig, linkConfig, contextConfig), options = _b.options, body = _b.body;
        var _c = extractFiles(body, '', customIsExtractableFile), clone = _c.clone, files = _c.files;
        var payload = serializeFetchParameter(clone, 'Payload');
        var controller;
        if (!options.signal) {
            var _d = createSignalIfSupported(), _controller = _d.controller, signal = _d.signal;
            controller = _controller;
            if (controller)
                options.signal = signal;
        }
        // If requested, set method to GET if there are no mutations.
        var definitionIsMutation = function (definition) {
            return definition.kind === 'OperationDefinition' && definition.operation === 'mutation';
        };
        if (useGETForQueries && !operation.query.definitions.some(definitionIsMutation)) {
            options.method = 'GET';
        }
        if (options.method === 'GET') {
            var _e = rewriteURIForGET(chosenURI, body), newURI = _e.newURI, parseError = _e.parseError;
            if (parseError) {
                return fromError(parseError);
            }
            chosenURI = newURI;
        }
        else if (files.size) {
            // Automatically set by fetch when the body is a FormData instance.
            delete options.headers['content-type'];
            // GraphQL multipart request spec:
            // https://github.com/jaydenseric/graphql-multipart-request-spec
            var form_1 = new FormData();
            form_1.append('operations', payload);
            var map_1 = {};
            var i_1 = 0;
            files.forEach(function (paths) {
                map_1[++i_1] = paths;
            });
            form_1.append('map', JSON.stringify(map_1));
            i_1 = 0;
            files.forEach(function (_, file) {
                customFormDataAppendFile(form_1, ++i_1, file);
            });
            options.body = form_1;
        }
        else {
            try {
                options.body = serializeFetchParameter(body, 'Payload');
            }
            catch (parseError) {
                return fromError(parseError);
            }
        }
        return new Observable(function (observer) {
            fetcher(chosenURI, options)
                .then(function (response) {
                operation.setContext({ response: response });
                return response;
            })
                .then(parseAndCheckHttpResponse(operation))
                .then(function (result) {
                // we have data and can send it to back up the link chain
                observer.next(result);
                observer.complete();
                return result;
            })
                .catch(function (err) {
                // fetch was cancelled so it's already been cleaned up in the unsubscribe
                if (err.name === 'AbortError')
                    return;
                // if it is a network error, BUT there is graphql result info
                // fire the next observer before calling error
                // this gives apollo-client (and react-apollo) the `graphqlErrors` and `networErrors`
                // to pass to UI
                // this should only happen if we *also* have data as part of the response key per
                // the spec
                if (err.result && err.result.errors && err.result.data) {
                    // if we don't call next, the UI can only show networkError because AC didn't
                    // get any graphqlErrors
                    // this is graphql execution result info (i.e errors and possibly data)
                    // this is because there is no formal spec how errors should translate to
                    // http status codes. So an auth error (401) could have both data
                    // from a public field, errors from a private field, and a status of 401
                    // {
                    //  user { // this will have errors
                    //    firstName
                    //  }
                    //  products { // this is public so will have data
                    //    cost
                    //  }
                    // }
                    //
                    // the result of above *could* look like this:
                    // {
                    //   data: { products: [{ cost: "$10" }] },
                    //   errors: [{
                    //      message: 'your session has timed out',
                    //      path: []
                    //   }]
                    // }
                    // status code of above would be a 401
                    // in the UI you want to show data where you can, errors as data where you can
                    // and use correct http status codes
                    observer.next(err.result);
                }
                observer.error(err);
            });
            return function () {
                // support canceling this request
                // https://developers.google.com/web/updates/2017/09/abortable-fetch
                if (controller)
                    controller.abort();
            };
        });
    });
};
