import { Maybe } from '@eva-pacs/client';
import { useTranslation } from 'react-i18next';

interface getYearslabelProps {
  currentAge?: Maybe<number>;
  currentMonthAge?: Maybe<number>;
  currentDayAge?: Maybe<number>;
}

export const useStudyPatientAge = () => {
  const { t } = useTranslation();

  const getYearslabel = ({ currentAge = 0, currentMonthAge = 0, currentDayAge = 0 }: getYearslabelProps) => {
    if (currentAge) return `${currentAge} ${t('general.years', { count: currentAge })}`;
    if (currentMonthAge) return `${currentMonthAge} ${t('general.months', { count: currentMonthAge })}`;
    if (currentDayAge) return `${currentDayAge} ${t('general.days', { count: currentDayAge })}`;
    return '';
  };

  return { getYearslabel };
};
