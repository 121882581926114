import { useSessionStore } from '../store';
import { EVA_PRODUCT } from '../constants/products';

type UseUserProductPermissionHook = {
  hasPACSAccess: boolean;
  hasAdminPanelAccess: boolean;
  validateUserProductAccess: (product: string | EVA_PRODUCT) => boolean;
};

export const useUserProductPermission = (): UseUserProductPermissionHook => {
  const { isAuthenticated, user } = useSessionStore((state) => ({
    isAuthenticated: state.isAuthenticated(),
    user: state.user,
  }));

  const validateUserProductAccess = (product: string | EVA_PRODUCT) => {
    return (
      (isAuthenticated &&
        user?.productGroups?.find((productGroup) => productGroup?.product?.slug === product)?.isVisible) ??
      false
    );
  };

  const hasAdminPanelAccess = validateUserProductAccess(EVA_PRODUCT.PANEL);
  const hasPACSAccess = validateUserProductAccess(EVA_PRODUCT.PACS);

  return {
    hasPACSAccess,
    hasAdminPanelAccess,
    validateUserProductAccess,
  };
};
