import React from 'react';
import { Controller, } from 'react-hook-form';
import { formFieldClass } from './styles';
/**
 * The Form Field are used so that the user can enter text or other characters that he requires.
 * @author Alejandro Forero<alejoforero.af@gmail.com>
 * Created at 2021-06-02
 */
export var FormField = function (_a) {
    var className = _a.className, control = _a.control, error = _a.error, successMessage = _a.successMessage, defaultValue = _a.defaultValue, _b = _a.isFullWidth, isFullWidth = _b === void 0 ? false : _b, name = _a.name, _c = _a.labelIsSimpleText, labelIsSimpleText = _c === void 0 ? false : _c, label = _a.label, render = _a.render;
    /* Render JSX */
    return (React.createElement("div", { className: formFieldClass(className, isFullWidth) },
        label && labelIsSimpleText && React.createElement("span", { className: "mb-2 text-label-md" }, label),
        label && !labelIsSimpleText && (React.createElement("label", { htmlFor: name, className: "mb-2 text-label-md" }, label)),
        React.createElement(Controller, { name: name, control: control, defaultValue: defaultValue, render: render }),
        error && (React.createElement("span", { className: "mt-2 text-feedback-negative-500 text-caption", role: "alert" }, error.message)),
        successMessage && (React.createElement("span", { className: "mt-2 text-feedback-positive-500 text-caption", role: "alert" }, successMessage))));
};
