import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

const drawLink = csTools.importInternal('drawing/drawLink');
const drawTextBox = csTools.importInternal('drawing/drawTextBox');

/**
 * Draw two linked lines between an annotation to a textBox.
 * @public
 * @method drawLinkedTextBox
 * @memberof Drawing
 *
 * @param {Object} context - The canvas context.
 * @param {HTMLElement} element - The element on which to draw the link.
 * @param {Object} textBox - The textBox to link.
 * @param {Object} text - The text to display in the textbox.
 * @param {Object[]} handles - The handles of the annotation.
 * @param {Object[]} textBoxAnchorPoints - An array of possible firsts anchor points on the textBox.
 * @param {Object[]} textBoxAnchorPoints2 - An array of possible seconds anchor points on the textBox.
 * @param {string} color - The link color.
 * @param {number} lineWidth - The line width of the link.
 * @param {number} xOffset - The x offset of the textbox.
 * @param {boolean} yCenter - Vertically centers the text if true.
 * @returns {undefined}
 */
export default function (
  context,
  element,
  textBox,
  text,
  handles,
  textBoxAnchorPoints,
  textBoxAnchorPoints2,
  color,
  lineWidth,
  xOffset,
  yCenter,
) {
  // Convert the textbox Image coordinates into Canvas coordinates
  const textCoords = cornerstone.pixelToCanvas(element, textBox);

  if (xOffset) {
    textCoords.x += xOffset;
  }

  const options = {
    centering: {
      x: false,
      y: yCenter,
    },
  };

  // Draw the text box
  textBox.boundingBox = drawTextBox(context, text, textCoords.x, textCoords.y, color, options);

  // Identify the possible anchor points for the tool -> text line
  const linkAnchorPoints = textBoxAnchorPoints(handles).map((handle) => cornerstone.pixelToCanvas(element, handle));
  const linkAnchorPoints2 = textBoxAnchorPoints2(handles).map((handle) => cornerstone.pixelToCanvas(element, handle));

  // Draw dashed link line between tool and text
  drawLink(linkAnchorPoints, textCoords, textBox.boundingBox, context, color, lineWidth);
  drawLink(linkAnchorPoints2, textCoords, textBox.boundingBox, context, color, lineWidth);
}
