import cornerstone from 'cornerstone-core';

import { getLaterality } from '~/components/Viewer/CornerstoneViewer/getOverlayData';
import { BoundingBox } from '~/src/interfaces/BoundingBox';
import { LATERALITY_TYPE } from '~/constants';

import { getEnabledElementControlled } from './getEnabledElementControlled';

export const alignImageByLaterality = async (
  enabledElement,
  boundingBox: BoundingBox | null = null,
  skipInitialAlignment = false,
) => {
  const { canvas, image, viewport } = getEnabledElementControlled(enabledElement) ?? {};

  if (!image || !canvas || !viewport?.scale) return;

  const viewportScale = viewport.scale;

  const imageLaterality = getLaterality(image.imageId);
  if (!imageLaterality || !boundingBox?.isValid) return;

  const yValue = getYCenterValue({
    imageHeight: image.height,
    boundingBox,
  });

  const initialPoint = {
    y: yValue, // to move to edge
    x: 0, //Center boundingbox in x
  };

  const xValue = getXPositionWithLaterality({
    imageWidth: image.width,
    canvasWidth: canvas.clientWidth,
    boundingBox,
    viewportScale,
    imageLaterality,
  });

  const imageWidthInViewport = image.width * viewportScale;

  if (!skipInitialAlignment || imageWidthInViewport > canvas.clientWidth) {
    initialPoint.x = xValue;
  }

  viewport.translation = initialPoint;
  cornerstone.setViewport(enabledElement, viewport);
};

const getYCenterValue = ({ imageHeight, boundingBox }: { imageHeight: number; boundingBox: BoundingBox | null }) => {
  if (!boundingBox) return 0;
  const imageHeightInViewport = imageHeight;
  const initCoordBoundingBox = boundingBox.y;
  const boundingBoxHeight = boundingBox.height;
  const middleCoordBoundingBox = boundingBoxHeight / 2;
  const middleBoundingBoxYInImage = middleCoordBoundingBox + initCoordBoundingBox;

  const middleImg = imageHeightInViewport / 2;
  const middleDifference = middleImg - middleBoundingBoxYInImage;

  const yCoordinateInViewport = middleDifference;

  return yCoordinateInViewport;
};

const getXPositionWithLaterality = ({
  imageWidth,
  canvasWidth,
  boundingBox = null,
  viewportScale,
  disableBoundingBoxAlign,
  imageLaterality,
}: {
  imageWidth: number;
  canvasWidth: number;
  boundingBox: BoundingBox | null;
  viewportScale: number;
  disableBoundingBoxAlign?: boolean;
  imageLaterality: string;
}) => {
  const imageWidthInViewport = imageWidth * viewportScale;
  let lateralOffset = 0;

  if (!disableBoundingBoxAlign && boundingBox) {
    const rightDelta = imageWidth - boundingBox.x - boundingBox.width; // If the bounding box is all the way to the right
    const leftDelta = boundingBox.x; // If the bounding box is all the way to the left
    lateralOffset = (imageLaterality === LATERALITY_TYPE.R ? rightDelta : leftDelta) * viewportScale;
  }
  // calculate the initial coordinates of the first region
  // from the difference between the size of the image and the size of the canvas
  const xCoordinate = (imageWidthInViewport - canvasWidth) / 2;
  const transformation = imageLaterality === LATERALITY_TYPE.R ? -1 : 1;

  return (transformation * (xCoordinate - lateralOffset)) / viewportScale;
};
