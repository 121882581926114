import { apolloClient, useRevokeTokenMutation } from '@eva-pacs/client';
import { useSessionStore } from '~/src/store/sessionStore';
import { removeCookie } from '@eva-pacs/core/lib/utils/storage';
import { REFRESH_TOKEN_KEY } from '@eva-pacs/core/lib/constants/session';

export const useLogout = () => {
  const { setToken, setRefreshToken, setCurrentOrganizationId, refreshToken } = useSessionStore();

  const [revokeToken] = useRevokeTokenMutation();

  const logout = async () => {
    try {
      await revokeToken({
        variables: {
          input: { refreshToken },
        },
      });
    } finally {
      setToken('');
      setRefreshToken('');
      removeCookie(REFRESH_TOKEN_KEY);
      setCurrentOrganizationId('');
      apolloClient.clearStore();
    }
  };
  return { logout };
};
