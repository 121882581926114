/**
 * Init Webspellchecker on the given element.
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-08-08
 */
export const initializeWebspellchecker = (lang, dictionaryIds) => {
  if (!(window as any).WEBSPELLCHECKER) {
    console.error('Webspellchecker is not available in the global scope.');
    return null;
  }

  (window as any).WEBSPELLCHECKER_CONFIG = {
    lang: lang,
    autoSearch: true,
    servicePort: '80',
    autoDestroy: true,
    autocorrect: false,
    serviceProtocol: 'https',
    disableOptionsStorage: ['lang'],
    customDictionaryIds: dictionaryIds,
    serviceHost: 'svc.webspellchecker.net',
    servicePath: 'spellcheck31/script/ssrv.cgi',
    serviceId: process.env.REACT_APP_WEBSPELLCHECKER_SERVICE_ID,
  };
};
