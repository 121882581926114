/**
 * Current available deployment environments
 */
export var Environments;
(function (Environments) {
    Environments["PRODUCTION"] = "production";
    Environments["TESTING"] = "testing";
    Environments["DEVELOPMENT"] = "development";
    Environments["STAGING"] = "staging";
})(Environments || (Environments = {}));
/**
 * Validate if we are on a specific deployment environment
 */
export var IS_PRODUCTION = process.env.REACT_APP_ENV === Environments.PRODUCTION;
export var IS_TESTING = process.env.REACT_APP_ENV === Environments.TESTING;
export var IS_DEVELOPMENT = process.env.REACT_APP_ENV === Environments.DEVELOPMENT;
export var IS_STAGING = process.env.REACT_APP_ENV === Environments.STAGING;
