import { isOlderThan30Days } from '@eva-pacs/core';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { useRemoveStudy } from '~/src/hooks/study/useRemoveStudy';
import { useStudyListTabs } from '~/src/hooks/study/useStudyListTabs';
import { useAddNewStudy } from '~/src/hooks/worklist/useAddNewStudy';
import { useStudyCriticalValues } from '~/src/hooks/worklist/useStudyCriticalValues';
import { useUpdateStudy } from '~/src/hooks/worklist/useUpdateStudy';
import { ProcessUpdateWatcherProps, useWorklistCounterWatcher } from '~/src/hooks/worklist/useWorklistCounterWatcher';
import { UpdateEvent, useWorklistUpdates } from '~/src/hooks/worklist/useWorklistUpdates';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { WorklistTabNames } from '~/src/types/StudyList';

export interface ProcessStudyUpdateProps extends ProcessUpdateWatcherProps {
  studyId: string;
}

export interface ProcessCriticalValueUpdateProps {
  id: string;
  studyId: string;
  deleted?: boolean;
  observation?: string;
  criticalValueId?: string;
  criticalValueName: string;
}

export const useStudyUpdatesOrchestrator = () => {
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const isWorkListEnabled = hasFlag(FeatureFlags.EnableNewWorklistSection);
  const isAutomaticUpdatesEnabled = hasFlag(FeatureFlags.EnableAutomaticUpdatesWorklist);
  const { handleUpdateWorklistStudy } = useWorklistUpdates();
  const { processUpdateStudyListStudy } = useUpdateStudy();
  const { processInsertNewStudyListStudy } = useAddNewStudy();
  const { processUpdateCounter } = useWorklistCounterWatcher();
  const { processRemoveStudy } = useRemoveStudy();
  const { activeTab } = useStudyListTabs();
  const { updateStudyCriticalValues } = useStudyCriticalValues();

  const processStudy = ({
    studyOldPractitionerId,
    studyId,
    eventType,
    studyPractitionerId,
    userId,
    status,
    reviewerPractitionerId,
    oldReviewerPractitionerId,
    oldStatus,
    createdAt,
  }: ProcessStudyUpdateProps) => {
    // TODO: Remove this flag when we integrate automatic update in study list AND we support worklist on all clients.
    if (!isWorkListEnabled && !isAutomaticUpdatesEnabled) return;

    processUpdateCounter({
      createdAt,
      eventType,
      studyOldPractitionerId,
      studyPractitionerId,
      userId,
      status,
      reviewerPractitionerId,
      oldReviewerPractitionerId,
      oldStatus,
    });
    // If there is no createdAt date, omit this study.
    const isOldWorklistStudy = createdAt ? isOlderThan30Days(new Date(createdAt)) : true;

    switch (eventType) {
      case UpdateEvent.DELETED: {
        if (activeTab === WorklistTabNames.WORKLIST && !isOldWorklistStudy) {
          // If study is assigned to the practitioner user, it means it is under worklist.
          if (studyPractitionerId === studyPractitionerId) {
            // Only proceeed with delete if we have it under our store.
            processRemoveStudy(studyId);
          }
        }

        // Remove from study list.
        // TODO: Uncomment this lines when we work on integrate automatic updates in study list.
        // processRemoveStudy(studyId)

        return;
      }
      case UpdateEvent.UPDATED: {
        if (activeTab === WorklistTabNames.WORKLIST && !isOldWorklistStudy) {
          return handleUpdateWorklistStudy(
            studyId,
            studyPractitionerId,
            studyOldPractitionerId,
            oldStatus,
            status,
            reviewerPractitionerId,
            oldReviewerPractitionerId,
          );
        }

        return processUpdateStudyListStudy(studyId);
      }

      case UpdateEvent.CREATED: {
        return processInsertNewStudyListStudy(studyId);
      }
      default:
        return '';
    }
  };

  const processCriticalValue = ({
    id,
    studyId,
    deleted,
    observation,
    criticalValueId,
    criticalValueName,
  }: ProcessCriticalValueUpdateProps) => {
    updateStudyCriticalValues(
      studyId,
      {
        id,
        observation,
        criticalValueId,
        name: criticalValueName,
      },
      deleted,
    );
  };

  return { processStudy, processCriticalValue };
};
