import { useState, useEffect } from 'react';
import { throttle } from 'lodash';
import { getDeviceSize } from '../utils/helpers';
export var useBreakpoint = function () {
    var _a = useState(function () { return getDeviceSize(window.innerWidth); }), breakpoint = _a[0], setBreakpoint = _a[1];
    useEffect(function () {
        var WAITING_TIME = 200;
        var calcInnerWidth = throttle(function () {
            setBreakpoint(getDeviceSize(window.innerWidth));
        }, WAITING_TIME);
        window.addEventListener('resize', calcInnerWidth);
        return function () { return window.removeEventListener('resize', calcInnerWidth); };
    }, []);
    return breakpoint;
};
