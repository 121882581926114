import React from 'react';
import { inlinealertClass } from './styles';
/**
 * Generally, an inline alert displays a prominent message on the action component.
 * They provide a feedback messages.
 * They’re persistent and nonmodal.
 * @author Ricardo Aguirre<ricardo.a.nava@gmail.com>
 * Created at 2021-11-10
 */
export var InlineAlert = function (_a) {
    var className = _a.className, icon = _a.icon, title = _a.title, description = _a.description;
    return (React.createElement("div", { className: inlinealertClass(className) },
        icon && React.createElement("div", null, icon),
        React.createElement("div", { className: "text-basic-white text-paragraph-sm space-y-4" },
            React.createElement("p", { className: "font-semi-bold" }, title),
            React.createElement("p", { className: "font-medium" }, description))));
};
