var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import crypto from 'crypto';
import querystring from 'query-string';
var createCanonicalRequest = function (method, pathname, query, headers, payload) {
    return [
        method.toUpperCase(),
        pathname,
        createCanonicalQueryString(query),
        createCanonicalHeaders(headers),
        createSignedHeaders(headers),
        payload,
    ].join('\n');
};
var createCanonicalQueryString = function (params) {
    return Object.keys(params)
        .sort()
        .map(function (key) { return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]); })
        .join('&');
};
var createCanonicalHeaders = function (headers) {
    return Object.keys(headers)
        .sort()
        .map(function (name) { return name.toLowerCase().trim() + ':' + headers[name].toString().trim() + '\n'; })
        .join('');
};
var createSignedHeaders = function (headers) {
    return Object.keys(headers)
        .sort()
        .map(function (name) { return name.toLowerCase().trim(); })
        .join(';');
};
var createCredentialScope = function (time, region, service) {
    return [toDate(time), region, service, 'aws4_request'].join('/');
};
var createStringToSign = function (time, region, service, request) {
    return ['AWS4-HMAC-SHA256', toTime(time), createCredentialScope(time, region, service), hash(request, 'hex')].join('\n');
};
var createSignature = function (secret, time, region, service, stringToSign) {
    var h1 = hmac('AWS4' + secret, toDate(time)); // date-key
    var h2 = hmac(h1, region); // region-key
    var h3 = hmac(h2, service); // service-key
    var h4 = hmac(h3, 'aws4_request'); // signing-key
    return hmac(h4, stringToSign, 'hex');
};
export var createPresignedURL = function (method, host, path, service, payload, options) {
    var optionsExtend = __assign({}, options);
    optionsExtend.protocol = options.protocol || 'https';
    optionsExtend.headers = options.headers || {};
    optionsExtend.timestamp = options.timestamp || Date.now();
    optionsExtend.region = options.region;
    optionsExtend.expires = options.expires || 86400; // 24 hours
    optionsExtend.headers = options.headers || {};
    // host is required
    optionsExtend.headers.Host = host;
    var query = optionsExtend.query || {};
    query['X-Amz-Algorithm'] = 'AWS4-HMAC-SHA256';
    query['X-Amz-Credential'] =
        optionsExtend.key + '/' + createCredentialScope(optionsExtend.timestamp, optionsExtend.region, service);
    query['X-Amz-Date'] = toTime(optionsExtend.timestamp);
    query['X-Amz-Expires'] = optionsExtend.expires;
    query['X-Amz-SignedHeaders'] = createSignedHeaders(optionsExtend.headers);
    if (optionsExtend.sessionToken) {
        query['X-Amz-Security-Token'] = optionsExtend.sessionToken;
    }
    var canonicalRequest = createCanonicalRequest(method, path, query, optionsExtend.headers, payload);
    var stringToSign = createStringToSign(optionsExtend.timestamp, optionsExtend.region, service, canonicalRequest);
    query['X-Amz-Signature'] = createSignature(optionsExtend.secret, optionsExtend.timestamp, optionsExtend.region, service, stringToSign);
    return optionsExtend.protocol + '://' + host + path + '?' + querystring.stringify(query);
};
var toTime = function (time) {
    return new Date(time).toISOString().replace(/[:-]|\.\d{3}/g, '');
};
var toDate = function (time) {
    return toTime(time).substring(0, 8);
};
var hmac = function (key, string, encoding) {
    return crypto.createHmac('sha256', key).update(string, 'utf8').digest(encoding);
};
var hash = function (string, encoding) {
    return crypto.createHash('sha256').update(string, 'utf8').digest(encoding);
};
