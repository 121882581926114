import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@eva-pacs/ui';
import { CurrentModal } from '@eva-pacs/core';

export const ConfirmCropModalContent = ({
  handleDissmissModal,
  setCurrentModal,
  handleSendData,
}: {
  setCurrentModal: React.Dispatch<React.SetStateAction<CurrentModal<any> | null>>;
  handleDissmissModal: () => void;
  handleSendData: () => void;
}) => {
  const { t } = useTranslation();

  const handleAcceptClick = () => {
    handleSendData();
    setCurrentModal(null);
  };

  return (
    <article>
      <div className="mb-6">
        <span className="flex justify-start text-left">{t('viewer.report.askForCrop')}</span>
      </div>
      <div className="flex justify-end">
        <Button variant={ButtonVariant.outlined} onClick={handleDissmissModal}>
          {t('general.actions.cancel')}
        </Button>
        <Button className="ml-6" onClick={handleAcceptClick}>
          {t('general.actions.accept')}
        </Button>
      </div>
    </article>
  );
};
