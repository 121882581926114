import React from 'react';

/**
 * Default Layout
 * @author Alejandro Forero<alejoforero.af@gmail.com>
 * Created at 2021-06-07
 */

export const WithoutLayout: React.FC = ({ children }) => {
  return <>{children}</>;
};
