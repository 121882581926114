import { getLaterality, getViewPosition } from '~/components/Viewer/CornerstoneViewer/getOverlayData';
import { getSequenceStringMetadataTag } from '~/utils/cornestone/getMetadataTag';
import { SHADOW_IMAGE_MAP_SIZE_SCALED } from '~/src/hooks/viewer/useBoundingBoxDrawer';
import {
  findExtremePointInArray,
  findExtremePointInContour,
  getPosibleNippleMarkPositionInImage,
  getStationaryPoints,
} from '~/utils/cv';
import { LATERALITY_TYPE, TAG_VIEW_CODE_SEQUENCE, VIEW_POSITION, VIEW_CODE_SEQUENCE } from '~/constants';
import { getElementStateConfig } from './setConfig';
import { getEnabledElementControlled } from '../getEnabledElementControlled';

export const getNipplePositionApproach = (element: HTMLElement) => {
  const enabledElement = getEnabledElementControlled(element);
  if (!enabledElement) return { x: 0, y: 0 };
  const { image } = enabledElement;
  if (!image) return { x: 0, y: 0 };
  const imageId = image?.imageId || '';
  const laterality = getLaterality(imageId);
  const viewPosition = getViewPosition(imageId);
  const [viewCodeSequence] = getSequenceStringMetadataTag(imageId, TAG_VIEW_CODE_SEQUENCE);
  const isMLO = viewCodeSequence?.find((codeSequence: string) =>
    codeSequence.toUpperCase().includes(VIEW_CODE_SEQUENCE.MLO),
  );
  const isCC = !viewPosition?.includes(VIEW_POSITION.MLO) && !isMLO;
  const toolElementConfig = getElementStateConfig(element);
  const { boundingBox, contoursImageData } = toolElementConfig?._configuration || {};

  const marks = getPosibleNippleMarkPositionInImage(contoursImageData?.contoursData?.contours);
  const scaleToFitInMap = !image.height ? 1 : SHADOW_IMAGE_MAP_SIZE_SCALED.height / image.height;

  //If there is a mark on the image of the breast for the nipple, we put the marker on it as long as it is not inside the breast
  if (marks[0]) {
    return {
      x: marks[0].point.x / scaleToFitInMap,
      y: marks[0].point.y / scaleToFitInMap,
    };
  }

  const biggestContour = contoursImageData?.contoursData?.contours?.get(
    contoursImageData?.contoursData?.biggestContourIndex,
  );

  //if there is not laterality or the bounding box is not valid set marker in the middle of image
  if (!laterality || !biggestContour || !boundingBox.isValid) return { x: image.width / 2, y: image.height / 2 };

  // If craniocaudal we put the mark at the most extreme point of the bounding box
  if (isCC) {
    const extremePoint = findExtremePointInContour(biggestContour, laterality as LATERALITY_TYPE);
    if (extremePoint) {
      return {
        x: extremePoint.x / scaleToFitInMap,
        y: extremePoint.y / scaleToFitInMap,
      };
    }
  }
  //Look for the most extreme stationary point in the lower half of the bounding box
  const posibleNipplePoints = getStationaryPoints(biggestContour);
  const extremePoint = findExtremePointInArray(posibleNipplePoints, laterality as LATERALITY_TYPE);
  if (extremePoint) {
    const pointInCords = {
      x: extremePoint.x / scaleToFitInMap,
      y: extremePoint.y / scaleToFitInMap,
    };
    const middleOfBoundingBox = boundingBox.height / 2;
    if (pointInCords.y > middleOfBoundingBox) return pointInCords;
  }

  const widthFactor = laterality === LATERALITY_TYPE.R ? 0.4 : 0.6;
  const heightFactor = isCC ? 0.5 : 0.8;
  if (!boundingBox) return { x: image.width * widthFactor, y: image.height * heightFactor };
  const x = laterality === LATERALITY_TYPE.R ? boundingBox.x : boundingBox.width - boundingBox.x;
  const y = boundingBox.y + boundingBox.height * heightFactor;
  return { x, y };
};
