import { useBrowserContextCommunication } from '@eva-pacs/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT } from '~/utils/screenPreferences';
import { BROADCAST_SYNC_EVENT_TEXT } from '~/constants';
export interface UseSyncActionBrowserEvent {
  scope: string;
  data: string;
}

/**
 * This hook allows you to send and receive messages between browser windows, in the same
 * ignoring the messages received by the window that sent them.
 *
 * ```ts
 *   const { event, notifyEvent } = useSyncActionBrowserWindowsCommunication();
 *   notifyEvent({scope: 'scope', data: 'EventString'});
 * ```
 */

export const useSyncActionBrowserWindowsCommunication = () => {
  const { id: studyId } = useParams() as { id: string };
  const [event, setLastEvent] = useState<UseSyncActionBrowserEvent>();
  const { communicationState, postMessage } = useBrowserContextCommunication(studyId);

  const notifyEvent = (event: UseSyncActionBrowserEvent) => {
    const eventAsText = JSON.stringify(event);
    postMessage(`${SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT}::${BROADCAST_SYNC_EVENT_TEXT}::${eventAsText}`);
  };

  useEffect(() => {
    const lastMessage = communicationState.lastMessage || '';
    if (!lastMessage) return;
    // eslint-disable-next-line i18next/no-literal-string
    const [browserWindowId, message, eventAsText] = lastMessage.split('::');
    if (
      browserWindowId !== SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT &&
      message === BROADCAST_SYNC_EVENT_TEXT
    )
      setLastEvent(JSON.parse(eventAsText));
  }, [communicationState.lastMessage]);

  return {
    event,
    notifyEvent,
  };
};
