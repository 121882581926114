import create, { SetState, GetState } from 'zustand';
import { immer } from '~/src/store';

export enum NotificationType {
  StudyListUpdates = 'STUDY_LIST_UPDATES',
}

export type NotificationTypeValues = `${NotificationType}`;

export type SetNotificationOptions<T> = { type: NotificationType; payload: T };

interface NotificationsStore {
  /**
   * All notifications data in store
   */
  notifications: {
    [key in NotificationTypeValues]?: unknown;
  };

  /**
   * Set selectedStudies on state
   */
  setNotifications: <T>(options: SetNotificationOptions<T>) => void;

  /**
   * Get notifications from a specific type
   */
  getNotifications: (type: NotificationType) => unknown;

  /**
   * Clear notifications for a given type
   */
  clearNotifications: (type: NotificationType) => void;

  /**
   * Clear all notifications from state
   */
  clearAllNotifications: () => void;
}

const notificationsStore = (
  set: SetState<NotificationsStore>,
  get: GetState<NotificationsStore>,
): NotificationsStore => ({
  notifications: {},
  setNotifications: <T>({ type, payload }: SetNotificationOptions<T>) => {
    return set((state) => {
      state.notifications[type] = Object.assign(state.notifications?.[type] ?? {}, payload);
    });
  },
  getNotifications: (type: NotificationType) => get().notifications[type] ?? {},
  clearNotifications: (notificationType: NotificationType) => {
    return set((state) => {
      delete state.notifications[notificationType];
    });
  },
  clearAllNotifications: () => {
    return set((state) => {
      state.notifications = {};
    });
  },
});

export const useNotificationsStore = create<NotificationsStore>(immer(notificationsStore));
