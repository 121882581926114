import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePagination } from '@eva-pacs/core';
import Icon, { IconCatalog } from '../Icon';
/**
 * Pagination is used for splitting up content or data into several pages, with a control for navigating to the next or previous page.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-27
 */
export var Pagination = function (_a) {
    var ariaLabel = _a.ariaLabel, className = _a.className, _b = _a.totalCount, totalCount = _b === void 0 ? 0 : _b, _c = _a.siblingCount, siblingCount = _c === void 0 ? 1 : _c, currentPage = _a.currentPage, pageSize = _a.pageSize, onPageChange = _a.onPageChange;
    var paginationRange = usePagination({
        currentPage: currentPage,
        totalCount: totalCount,
        siblingCount: siblingCount,
        pageSize: pageSize,
    });
    var t = useTranslation().t;
    var lastPage = paginationRange[paginationRange.length - 1];
    var classes = {
        pagination: cn(className, 'flex items-center space-x-6'),
        pill: function (pageNumber) {
            return cn('px-3 py-2 rounded', {
                'hover:text-basic-white text-light-gray-600 hover:bg-gray-500 cursor-pointer': pageNumber !== currentPage,
                'text-basic-white bg-primary-500': pageNumber === currentPage,
            });
        },
        prevBtn: cn('flex items-center space-x-3 select-none', 'hover:text-basic-white text-light-gray-600', {
            'opacity-40': currentPage === 1,
        }),
        nextBtn: cn('flex items-center space-x-3 select-none', 'hover:text-basic-white text-light-gray-600', {
            'opacity-40': currentPage === lastPage,
        }),
    };
    var handlePrevBtnClick = function () {
        if (currentPage === 1)
            return;
        onPageChange(currentPage - 1);
    };
    var handleNextBtnClick = function () {
        if (currentPage === lastPage)
            return;
        onPageChange(currentPage + 1);
    };
    var handlePillClick = function (pageNumber) { return function () { return onPageChange(pageNumber); }; };
    var renderPillList = paginationRange.map(function (pageNumber, index) {
        if (pageNumber === '...')
            return (
            // eslint-disable-next-line i18next/no-literal-string
            React.createElement("div", { key: index, className: "px-3 py-2 text-light-gray-600" }, "\u2026"));
        return (React.createElement("div", { key: index, role: "button", tabIndex: -1, className: classes.pill(pageNumber), onClick: handlePillClick(pageNumber), onKeyPress: handlePillClick(pageNumber) }, pageNumber));
    });
    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2)
        return null;
    return (React.createElement("div", { className: classes.pagination, "aria-label": ariaLabel },
        React.createElement("div", { role: "button", tabIndex: -1, className: classes.prevBtn, onClick: handlePrevBtnClick, onKeyPress: handlePrevBtnClick, "data-testid": "table-pagination-prev" },
            React.createElement(Icon, { icon: IconCatalog.chevronLeft, width: "16", height: "16" }),
            React.createElement("span", { className: "text-label-sm font-semi-bold" }, t('general.previous'))),
        React.createElement("div", { className: "flex items-center text-label-sm" }, renderPillList),
        React.createElement("div", { role: "button", tabIndex: -1, className: classes.nextBtn, onClick: handleNextBtnClick, onKeyPress: handleNextBtnClick, "data-testid": "table-pagination-next" },
            React.createElement("span", { className: "text-label-sm font-semi-bold" }, t('general.next')),
            React.createElement(Icon, { icon: IconCatalog.chevronRight, width: "16", height: "16" }))));
};
