import cornerstone from 'cornerstone-core';
import { SerieItem, useSeriesStore, useThumbnailSeriesStore } from '~/src/store';
import { createThumbnailImageSrcFromImage } from './createThumbnailImageSrcFromImage';

export const useMetaSeriesCreator = () => {
  const getSeries = useSeriesStore((store) => store.getSeries);
  const setThumbnailData = useThumbnailSeriesStore((store) => store.setThumbnailData);

  const createMetaSeriesData = (serieItem: SerieItem) => {
    const seriesList = getSeries();
    const metaSeries = seriesList.filter((serieC) => serieC.meta?.originSerieId.includes(serieItem.id));
    const seriesWithMetaData = metaSeries.map((metaSerie) => {
      const imagesIds: Array<string> = [];
      metaSerie.meta?.instanceIds.forEach((instanceId) => {
        const originalInstance = serieItem.dicomImageList.find((dicomImage) => dicomImage.externalId === instanceId);
        if (originalInstance) {
          imagesIds.push(originalInstance.id);
        }
      });
      const newSerie = {
        ...serieItem,
        id: metaSerie.id,
        meta: metaSerie.meta,
        imagesIds,
        dicomImageList: serieItem.dicomImageList.filter((dicomImage) => imagesIds.includes(dicomImage.id)),
      };
      if (newSerie.imagesIds.length > 0) {
        const [firstImageId] = newSerie.imagesIds;
        cornerstone.loadAndCacheImage(firstImageId).then(async (image) => {
          const thumbnailImg = createThumbnailImageSrcFromImage(image);
          setThumbnailData(newSerie.id, { thumbnailImgUrl: thumbnailImg as string, isImageReady: true });
        });
      }
      return newSerie;
    });
    return seriesWithMetaData;
  };

  return createMetaSeriesData;
};
