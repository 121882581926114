import { VISITANT_KEY, ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ENCRYPTED_CREDENTIALS_PARAM, ORGANIZATION_ID_KEY, } from '../constants';
/**
 * @param {String} key Name or key from cookie to search
 * @return {String} Cookie value
 */
export var getCookie = function (key) {
    var cookieValue = '';
    if (document.cookie !== '') {
        var cookies = document.cookie.split(';');
        cookies.forEach(function (cookie) {
            var cookieTmp = cookie.trim();
            if (cookieTmp.substring(0, key.length + 1) === key + '=') {
                cookieValue = decodeURIComponent(cookieTmp.substring(key.length + 1));
            }
        });
    }
    return cookieValue;
};
/**
 * @param {String} key Cookie name/key
 * @return {void}
 */
export var removeCookie = function (key) {
    setCookie(key, '', { expires: 'Thu, 01 Jan 1970 00:00:00 GMT' });
    setCookie(key, '', { expires: 'Thu, 01 Jan 1970 00:00:00 GMT', domain: process.env['REACT_APP_ROOT_DOMAIN'] });
};
/**
 * @param {String} key Cookie name/key
 * @param {String} value Cookie value
 * @param {String} options Cookie additional options
 * @return {void}
 */
export var setCookie = function (key, value, options) {
    var cookie = "".concat(key, "=").concat(value, ";secure");
    if (options === null || options === void 0 ? void 0 : options.expires)
        cookie = "".concat(cookie, ";expires=").concat(options.expires);
    if (options === null || options === void 0 ? void 0 : options.path)
        cookie = "".concat(cookie, ";path=").concat(options.path);
    if (options === null || options === void 0 ? void 0 : options.domain)
        cookie = "".concat(cookie, ";domain=").concat(options.domain);
    document.cookie = cookie;
};
/**
 * @param {String} key Cookie name/key
 * @return {boolean} if cookie is duplicate
 */
export var cookieIsDuplicate = function (key) {
    var _a;
    var cookies = ((_a = document.cookie) !== null && _a !== void 0 ? _a : '').split(';').map(function (cookie) { return cookie.trim(); });
    var cookiesWithKey = cookies.filter(function (cookie) { return cookie.substring(0, key.length + 1) === "".concat(key, "="); });
    return cookiesWithKey.length > 1;
};
export var getRefreshInfo = function () {
    var searchParams = new URLSearchParams(window.location.search);
    var visitantToken = searchParams.get(ENCRYPTED_CREDENTIALS_PARAM) || '';
    var isVisitant = Boolean(visitantToken === null || visitantToken === void 0 ? void 0 : visitantToken.length);
    var loginRefreshCookie = getCookie(REFRESH_TOKEN_KEY);
    var refreshToken = isVisitant ? visitantToken : loginRefreshCookie;
    return {
        refreshToken: refreshToken,
        isVisitant: isVisitant,
    };
};
var getAccessKey = function (visitant) {
    if (visitant === void 0) { visitant = false; }
    return (visitant ? "".concat(VISITANT_KEY, "_").concat(ACCESS_TOKEN_KEY) : ACCESS_TOKEN_KEY);
};
var getRefreshKey = function (visitant) {
    if (visitant === void 0) { visitant = false; }
    return (visitant ? "".concat(VISITANT_KEY, "_").concat(REFRESH_TOKEN_KEY) : REFRESH_TOKEN_KEY);
};
export var getOrganizationKey = function (visitant) {
    if (visitant === void 0) { visitant = false; }
    return visitant ? "".concat(VISITANT_KEY, "_").concat(ORGANIZATION_ID_KEY) : ORGANIZATION_ID_KEY;
};
export var getTokenFromStorage = function () {
    var isVisitant = getRefreshInfo().isVisitant;
    if (isVisitant)
        return getCookie(getAccessKey(isVisitant));
    return getCookie(ACCESS_TOKEN_KEY);
};
export var getRefreshFromStorage = function () {
    var isVisitant = getRefreshInfo().isVisitant;
    if (isVisitant)
        return getCookie(getRefreshKey(isVisitant));
    return getCookie(REFRESH_TOKEN_KEY);
};
export var getOrganizationIdFromStorage = function () {
    var isVisitant = getRefreshInfo().isVisitant;
    var currentCookie = getCookie(ORGANIZATION_ID_KEY);
    if (isVisitant)
        return getCookie(getOrganizationKey(isVisitant)) || currentCookie;
    return currentCookie;
};
