import cornerstone from 'cornerstone-core';

import { VOI_LEVEL, LevelCatalog } from '@eva-pacs/core';
import { Voi } from '~/src/interfaces/vtk';

/**
 * This is used to set the window levels for every option of the window options select
 * @param windowWidth
 * @param windowCenter
 * @param activeElement
 */
const setLevel = (windowWidth, windowCenter, activeElement) => {
  const enabledElement = activeElement;
  if (enabledElement) {
    const viewport = cornerstone.getViewport(enabledElement);
    if (!viewport) return;
    viewport.voi.windowWidth = windowWidth;
    viewport.voi.windowCenter = windowCenter;
    cornerstone.setViewport(enabledElement, viewport);
  }
};

/**
 * This will handle all the current cornerstone levels on the window option select
 * If you need to ADD A NEW WINDOW OPTION, you should add the window width and window center in hee
 *
 * @param level
 * @param activeElement
 * @returns
 */
export const cornerstoneLevels = (level: LevelCatalog, activeElement?: HTMLElement, dynamicVOI?: Voi) => {
  if (!activeElement) return;
  if (level === LevelCatalog.NONE) cornerstone.reset(activeElement);
  else if (level === LevelCatalog.FULL_DYNAMIC) {
    if (dynamicVOI) {
      setLevel(dynamicVOI.windowWidth, dynamicVOI.windowCenter, activeElement);
    }
  } else if (level !== LevelCatalog.OTHER) {
    setLevel(VOI_LEVEL[level].windowWidth, VOI_LEVEL[level].windowCenter, activeElement);
  }
};
