import React from 'react';
import { progressClass, progressBarClass } from './styles';
export var ProgressColor;
(function (ProgressColor) {
    ProgressColor["primary"] = "primary";
    ProgressColor["positive"] = "positive";
    ProgressColor["negative"] = "negative";
})(ProgressColor || (ProgressColor = {}));
export var ProgressSize;
(function (ProgressSize) {
    ProgressSize["xs"] = "xs";
    ProgressSize["sm"] = "sm";
    ProgressSize["md"] = "md";
    ProgressSize["lg"] = "lg";
})(ProgressSize || (ProgressSize = {}));
/**
 * A Progress is a linear indicator for providing feedback about an ongoing, user-initiated process.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-14
 */
export var Progress = function (_a) {
    var _b = _a.maxValue, maxValue = _b === void 0 ? 100 : _b, _c = _a.value, value = _c === void 0 ? 0 : _c, _d = _a.size, size = _d === void 0 ? ProgressSize.md : _d, _e = _a.color, color = _e === void 0 ? ProgressColor.primary : _e, _f = _a.isRounded, isRounded = _f === void 0 ? false : _f, className = _a.className;
    var getPercentage = function () {
        var percentage = (100 / maxValue) * value;
        return "".concat(percentage, "%");
    };
    return (React.createElement("div", { className: progressClass(className, size, isRounded) },
        React.createElement("div", { className: progressBarClass(size, color, isRounded), style: { width: getPercentage(), zIndex: 1 }, role: "progressbar", "aria-valuenow": value, "aria-valuemin": 0, "aria-valuemax": maxValue })));
};
