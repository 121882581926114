/* eslint-disable i18next/no-literal-string */
import React, { useCallback, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import cn from 'classnames';

import { exportFile, FileExtension, svgToImage } from '@eva-pacs/core';
import {
  ButtonColor,
  ButtonVariant,
  Button,
  BasicInput,
  ClipboardCopyButton,
  IconCatalog,
  ButtonSize,
} from '@eva-pacs/ui';
import { useGetViewerQrService } from '~/hooks';

export interface PreviewQRModalProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Use to generate the QR code
   */
  url?: string;

  /**
   * Reference of Study Id
   */
  studyId: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
}

/**
 * Use this component in order to show a QR code
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-09-03
 */
export const PreviewQRModal: React.FC<PreviewQRModalProps> = ({ ariaLabel, studyId = '', url = '', className }) => {
  const modalClass = cn(className);
  const { getViewerQrPdf } = useGetViewerQrService({ studyId });
  const nodeRef = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);

  const handleCopyBtnClick = () => setCopied(true);

  const handleCopied = (copied: boolean) => setCopied(copied);

  const handleDownloadPdf = () => getViewerQrPdf();

  const handleDownloadBtnClick = useCallback(() => {
    if (!nodeRef.current) return;
    const svg = nodeRef.current.innerHTML;
    svgToImage({
      svg,
      mimetype: 'image/png',
      quality: 1,
      outputFormat: 'blob',
    })
      .then((outputData) => {
        exportFile(outputData as any, 'qr-code.png', 'image/png');
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!url)
    return (
      <div aria-label={ariaLabel} className="text-basic-white">
        NO QR
      </div>
    );

  return (
    <div aria-label={ariaLabel} className={modalClass}>
      <div className="flex flex-col items-center justify-center justify-items-center">
        <div ref={nodeRef} className="mb-2">
          <QRCode
            className="rounded-2xl"
            value={url}
            size={150}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'M'}
            includeMargin={true}
            renderAs={'svg'}
          />
        </div>
        <div className="flex mb-6 mt-2">
          <Button
            onClick={handleDownloadBtnClick}
            leftIcon={IconCatalog.download}
            size={ButtonSize.sm}
            variant={ButtonVariant.contained}
            color={ButtonColor.dark}>
            {FileExtension.PNG}
          </Button>
          <Button
            className="ml-3"
            size={ButtonSize.sm}
            onClick={handleDownloadPdf}
            leftIcon={IconCatalog.download}
            variant={ButtonVariant.contained}
            color={ButtonColor.dark}>
            {FileExtension.PDF}
          </Button>
        </div>
        <div className="flex items-center space-x-3">
          <BasicInput value={url} isReadOnly />
          <ClipboardCopyButton
            textToCopy={url}
            color={copied ? ButtonColor.secondaryAlternative : ButtonColor.primaryAlternative}
            isRounded={false}
            onCopyBtnClick={handleCopyBtnClick}
            onCopied={handleCopied}
          />
        </div>
      </div>
    </div>
  );
};
