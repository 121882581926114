import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export enum PARAMS_KEYS {
  SEARCH = 'q',
  TABS = 'tab',
}

/**
 * This hook was taken from this website: https://brianm.me/posts/react-router-search-params/
 * @author Salvador Gonzalez <salvador.gonzalez@evacenter.com>
 * @returns Instance of URLSearchParams with search parameters.
 */
export function useSearchParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search) as ReadOnlyURLSearchParams, [search]);
}
