import { PRINTING_TEXT_OPTIONS } from '~/constants';
import { usePrinterStore, useViewportStore } from '~/src/store';

/**
 * Hook to show or hide the information overlay
 * @author Jesus Bossa<x@jesusbossa.dev>
 * Created at 2023-02-02
 */
export const useShowInformation = () => {
  const { showText, showPrintView } = usePrinterStore();
  const { hideViewportInformationText } = useViewportStore();

  const showInformation = shouldShowInformation(
    showPrintView,
    showText !== PRINTING_TEXT_OPTIONS.NO_TEXT,
    hideViewportInformationText,
  );

  return { showInformation };
};

/**
 * Returns a boolean factor about if the overlay should be hidden or not
 */
const shouldShowInformation = (
  printerViewIsActive: boolean,
  printerVisibilityOptionIsActive: boolean,
  toolbarVisibilityOptionIsActive: boolean,
) => {
  if (printerViewIsActive) return printerVisibilityOptionIsActive;
  return !toolbarVisibilityOptionIsActive;
};
