import { useCallback, useEffect } from 'react';
/**
 * Hook used for getting the click outside event of a container.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-05-03
 */
export var useOnClickOutside = function (element, handler) {
    var listener = useCallback(function (event) {
        if (!element || element.contains(event.target))
            return;
        handler(event);
    }, [element, handler]);
    useEffect(function () {
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
        // eslint-disable-next-line
    }, [element]);
};
