import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { HOME_PATH, STUDY_LIST_PATH } from '@eva-pacs/core';
import { Icon, IconCatalog } from '@evacenter/eden';

import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { useSessionStore } from '~/src/store';

export const MembershipExpiredAlert = () => {
  const { getCurrentAccount } = useSessionStore();
  const { hasFlag } = useFeatureFlagsStore();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isVisible = useMemo(() => {
    const account = getCurrentAccount();

    const isUserWithDebt = account?.hasPendingPayment || false;
    const isUserInStudyList = [HOME_PATH, STUDY_LIST_PATH].some((path) => pathname === path);
    const isDebtFlagActive = hasFlag(FeatureFlags.DebtWarning);

    return isUserWithDebt && isUserInStudyList && isDebtFlagActive;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!isVisible) return null;

  return (
    <div className="e-text-sm flex items-center justify-center py-1 text-basic-white bg-feedback-negative-600">
      <span className="inline-flex px-2 min-w-max">
        <Icon icon={IconCatalog.warning} />
      </span>
      <p>{t('errors.expired')}</p>
    </div>
  );
};
