export enum CrossPacsActionType {
  REFRESH_STUDY_LIST = 'refreshStudyList',
  UPDATE_LANGUAGE = 'updateLanguage',
}

export interface EventMessage {
  actionType: CrossPacsActionType;
  payload: Record<string, unknown>;
  time: number;
}
