const excludeStyle = (styleString: string, stylesAttrList: Array<string>) => {
  for (let i = 0; i < stylesAttrList.length; i++) {
    if (styleString.includes(stylesAttrList[i])) return true;
  }
  return false;
};
export const removeStylesFromHTML = (htmlContent: string, stylesToRemove: Array<string>) => {
  const styleAttributeRegexp = /style='[^']*'/g;

  function replaceStyle(match: string) {
    // Remove `style='` prefix and final `'`
    const styles = match?.slice(7, match.length - 1)?.split(';') || [];
    // Remove specifc style attributes
    const filteredStyles = styles.filter((styleAttr) => !excludeStyle(styleAttr, stylesToRemove));
    // Create style prop again
    return filteredStyles.length > 0 ? `style='${filteredStyles.join(';')};'` : '';
  }
  return htmlContent?.replace(styleAttributeRegexp, replaceStyle) || '';
};
