var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { Spinner, SpinnerColor, SpinnerSize } from '../Spinner';
import { buttonClass } from './styles';
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["xs"] = "xs";
    ButtonSize["sm"] = "sm";
    ButtonSize["md"] = "md";
    ButtonSize["lg"] = "lg";
    ButtonSize["xl"] = "xl";
})(ButtonSize || (ButtonSize = {}));
export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["contained"] = "contained";
    ButtonVariant["outlined"] = "outlined";
    ButtonVariant["text"] = "text";
})(ButtonVariant || (ButtonVariant = {}));
export var ButtonColor;
(function (ButtonColor) {
    ButtonColor["primary"] = "primary";
    ButtonColor["primaryAlternative"] = "primaryAlternative";
    ButtonColor["secondary"] = "secondary";
    ButtonColor["secondaryAlternative"] = "secondaryAlternative";
    ButtonColor["dark"] = "dark";
    ButtonColor["danger"] = "danger";
})(ButtonColor || (ButtonColor = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["button"] = "button";
    ButtonType["reset"] = "reset";
    ButtonType["submit"] = "submit";
})(ButtonType || (ButtonType = {}));
export var ButtonIconSize;
(function (ButtonIconSize) {
    ButtonIconSize["sm"] = "16";
    ButtonIconSize["md"] = "20";
    ButtonIconSize["lg"] = "24";
    ButtonIconSize["xl"] = "30";
})(ButtonIconSize || (ButtonIconSize = {}));
/**
 * Buttons are used to initialize an action. Button labels express what action will occur when the user interacts with it.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-04-09
 */
var Button = function (_a) {
    var children = _a.children, _b = _a.size, size = _b === void 0 ? ButtonSize.md : _b, _c = _a.color, color = _c === void 0 ? ButtonColor.primary : _c, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, _d = _a.variant, variant = _d === void 0 ? ButtonVariant.contained : _d, _e = _a.isDisabled, isDisabled = _e === void 0 ? false : _e, _f = _a.isLoading, isLoading = _f === void 0 ? false : _f, loadingText = _a.loadingText, _g = _a.isFullWidth, isFullWidth = _g === void 0 ? false : _g, _h = _a.isRounded, isRounded = _h === void 0 ? true : _h, _j = _a.type, type = _j === void 0 ? ButtonType.button : _j, className = _a.className, onClick = _a.onClick, restOfProps = __rest(_a, ["children", "size", "color", "leftIcon", "rightIcon", "variant", "isDisabled", "isLoading", "loadingText", "isFullWidth", "isRounded", "type", "className", "onClick"]);
    var t = useTranslation().t;
    var leftIconClass = cn({
        'mr-2': children,
    });
    var rightIconClass = cn({
        'ml-2': children,
    });
    var createLoadingText = loadingText ? loadingText : t('general.wait');
    /* Render JSX */
    return (React.createElement("button", __assign({ className: buttonClass(className, size, color, isDisabled, isLoading, isFullWidth, isRounded, variant, leftIcon, rightIcon, children), type: type, disabled: isDisabled || isLoading, onClick: onClick }, restOfProps),
        !isLoading && leftIcon && (React.createElement(Icon, { className: leftIconClass, icon: leftIcon, width: ButtonIconSize[size], height: ButtonIconSize[size] })),
        isLoading ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "mr-2" }, createLoadingText),
            React.createElement(Spinner, { size: SpinnerSize.xs, color: SpinnerColor.white, fullScreen: false }))) : (children),
        !isLoading && rightIcon && (React.createElement(Icon, { className: rightIconClass, icon: rightIcon, width: ButtonIconSize[size], height: ButtonIconSize[size] }))));
};
export default Button;
