import cn from 'classnames';
import { SpinnerColor, SpinnerSize } from './';
/* Class assignment */
export var spinnerClass = function (className, fullScreen) {
    if (fullScreen === void 0) { fullScreen = true; }
    return cn(className, 'flex items-center justify-center', fullScreen === true && 'h-full w-full');
};
export var strokeClass = function (color, size) {
    return cn(
    /* Spinner (base) */
    'rounded-full', 'border-gray-500', 'ease-linear animate-spin', 
    /* Spinner Size */
    size === SpinnerSize.xs && 'w-4 h-4 border-2', size === SpinnerSize.sm && 'w-8 h-8 border-4', size === SpinnerSize.md && 'w-16 h-16 border-8', 
    /* Spinner Color */
    color === SpinnerColor.primary && 'border-t-primary-500', color === SpinnerColor.white && 'border-basic-white');
};
