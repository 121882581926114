import shallow from 'zustand/shallow';
import { useStudyListStore } from '~/src/store';
import { CriticalValue } from '~/src/types/CriticalValue';

export const useStudyCriticalValues = () => {
  const [getStudy, updateStudy] = useStudyListStore((state) => [state.getStudy, state.updateStudy], shallow);

  const updateStudyCriticalValues = (studyId: string, criticalValue: CriticalValue | null, deleted?: boolean) => {
    if (!studyId || !criticalValue) return;

    const study = getStudy(studyId);
    if (!study) return;

    const { criticalValues = [] } = study;
    const updatedCriticalValues = criticalValues.reduce<Array<CriticalValue | null>>((acc, cv) => {
      if (deleted && cv?.id === criticalValue.id) return acc;
      if (cv?.id === criticalValue.id) return [...acc, criticalValue];
      return [...acc, cv];
    }, []);

    if (!deleted && !criticalValues.some((cv) => cv?.id === criticalValue.id)) {
      updatedCriticalValues.push(criticalValue);
    }

    updateStudy(studyId, { ...study, criticalValues: updatedCriticalValues });
  };

  return { updateStudyCriticalValues };
};
