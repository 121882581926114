import { useFeatureFlagsStore } from '~/src/store';
import { FeatureFlags } from '~/constants';
import cornerstone from 'cornerstone-core';
import { loadImage } from './loadImage';

export const registerImageLoaders = () => {
  useFeatureFlagsStore.subscribe((store) => {
    const inferTs = store.hasFlag(FeatureFlags.InferTSForEncapsulatedPixelSpacing);
    if (inferTs) {
      // Register loader with TS inferetion
      cornerstone.registerImageLoader('dicomweb', loadImage);
      cornerstone.registerImageLoader('wadouri', loadImage);
    }
  });
};
