import React from 'react';
import { menuClass } from './styles';
export var MenuPlacement;
(function (MenuPlacement) {
    MenuPlacement["bottomLeft"] = "bottom-left";
    MenuPlacement["bottomRight"] = "bottom-right";
    MenuPlacement["topLeft"] = "top-left";
    MenuPlacement["topRight"] = "top-right";
})(MenuPlacement || (MenuPlacement = {}));
// const getAbsPosition = (el) => {
//   let el2 = el;
//   let curtop = 0;
//   let curleft = 0;
//   if (document.getElementById || document.all) {
//     do {
//       curleft += el.offsetLeft - el.scrollLeft;
//       curtop += el.offsetTop - el.scrollTop;
//       el = el.offsetParent;
//       el2 = el2.parentNode;
//       while (el2 != el) {
//         curleft -= el2.scrollLeft;
//         curtop -= el2.scrollTop;
//         el2 = el2.parentNode;
//       }
//     } while (el.offsetParent);
//   } else if (document.layers) {
//     curtop += el.y;
//     curleft += el.x;
//   }
//   return [curtop, curleft];
// };
/**
 * Menu (Dropdown Menu)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-01
 */
export var Menu = function (_a) {
    var _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, _c = _a.placement, placement = _c === void 0 ? MenuPlacement.bottomLeft : _c, className = _a.className, children = _a.children;
    return isOpen ? (React.createElement("div", { className: menuClass(className, placement), role: "menu", "aria-orientation": "vertical", "aria-labelledby": "options-menu" }, children)) : null;
};
