import { PractitionerStatus } from '@eva-pacs/client';
import { UserRole } from '../constants/roleGroups';

interface PractitionersVariables {
  roleIn: Array<UserRole>;
  statusIn: Array<PractitionerStatus>;
}
export const getPractitionersVariables = ({ roleIn, statusIn }: PractitionersVariables) => ({
  roleIn,
  statusIn,
});

export const getAssignablePractitionersVariables = () =>
  getPractitionersVariables({
    roleIn: [UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN],
    statusIn: [PractitionerStatus.ACTIVE],
  });

// It is specified by the requirement of both cases, with and without Referring Physician
export const getAssignablePractitionersWithReferingPhysicianVariables = () =>
  getPractitionersVariables({
    roleIn: [UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.REFERRING_PHYSICIAN],
    statusIn: [PractitionerStatus.ACTIVE],
  });
