export enum ConvolveFilter {
  Blur3X3 = 'Blur3X3',
  Blur5X5 = 'Blur5X5',
  Sharpen = 'Sharpen',
  GaussianBlur3X3 = 'GaussianBlur3X3',
  GaussianBlur5X5 = 'GaussianBlur5X5',
}

export type FilterMatrix = Array<Array<number>>;

interface ConvolveFilterItem {
  keyI18n: string;
  matrix: FilterMatrix;
  multiplier: number;
}

export const convolveFilters: Record<ConvolveFilter, ConvolveFilterItem> = {
  Blur3X3: {
    keyI18n: 'blur3x3',
    multiplier: 1 / 9,
    matrix: [
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
    ],
  },
  Blur5X5: {
    keyI18n: 'blur5x5',
    multiplier: 1 / 25,
    matrix: [
      [1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1],
    ],
  },
  Sharpen: {
    keyI18n: 'sharpen',
    multiplier: 1,
    matrix: [
      [0, -1, 0],
      [-1, 5, -1],
      [0, -1, 0],
    ],
  },
  GaussianBlur3X3: {
    keyI18n: 'gaussianBlur3x3',
    multiplier: 1 / 16,
    matrix: [
      [1, 2, 1],
      [2, 4, 2],
      [1, 2, 1],
    ],
  },
  GaussianBlur5X5: {
    keyI18n: 'gaussianBlur5x5',
    multiplier: 1 / 273,
    matrix: [
      [1, 4, 7, 4, 1],
      [4, 16, 26, 16, 4],
      [7, 26, 41, 26, 7],
      [4, 16, 26, 16, 4],
      [1, 4, 7, 4, 1],
    ],
  },
};
