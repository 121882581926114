var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { AccordionPanel } from './AccordionPanel';
import { Children } from 'react';
/**
 * An accordion displays summary information that can be expanded to reveal content or collapsed to hide it. Can be used to group or hide complex regions to keep the page clean.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-20
 */
export var Accordion = function (_a) {
    var className = _a.className, _b = _a.allowMultipleOpen, allowMultipleOpen = _b === void 0 ? false : _b, children = _a.children;
    var accordionClass = cn(className);
    var _c = useState({}), openPanels = _c[0], setOpenPanels = _c[1];
    /* Open a default accordion panel */
    useEffect(function () {
        var obj = {};
        Children.toArray(children).forEach(function (child) {
            if (child.props.isOpen)
                obj[child.props.panelId] = true;
        });
        setOpenPanels(obj);
    }, []);
    var handleClick = function (panelId) {
        var _a, _b;
        var isOpen = Boolean(openPanels[panelId]);
        if (allowMultipleOpen)
            setOpenPanels(__assign(__assign({}, openPanels), (_a = {}, _a[panelId] = !isOpen, _a)));
        else
            setOpenPanels((_b = {}, _b[panelId] = !isOpen, _b));
    };
    return (React.createElement("div", { className: accordionClass }, Children.toArray(children).map(function (child) { return (React.createElement(AccordionPanel, __assign({ ariaLabel: child.props.ariaLabel, key: child.props.panelId, trigger: child.props.trigger, isOpen: Boolean(openPanels[child.props.panelId]), panelId: child.props.panelId, onClick: handleClick }, child.props), child.props.children)); })));
};
