import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { GenericModal } from '../GenericModal';
import Button, { ButtonColor, ButtonType } from '../../../atoms/Button';
import { useBodyClass } from '@eva-pacs/core';
/**
 * Use this component in order to show a confirmation Dialog
 * @author Ricardo Alfonso Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-01-14
 */
var ConfirmationModal = function (_a) {
    var description = _a.description, className = _a.className, acceptLabel = _a.acceptLabel, cancelLabel = _a.cancelLabel, hasCloseButton = _a.hasCloseButton, _b = _a.hasCancelButton, hasCancelButton = _b === void 0 ? true : _b, onClose = _a.onClose, onAccept = _a.onAccept, title = _a.title;
    var confirmationModalClass = cn(className);
    var t = useTranslation().t;
    // eslint-disable-next-line i18next/no-literal-string
    useBodyClass('opened-modal');
    var handleCancelButtonClick = function () {
        if (onClose)
            onClose();
    };
    var handleAcceptButtonClick = function () {
        if (onAccept)
            onAccept();
    };
    return (React.createElement(GenericModal, { className: confirmationModalClass, onClose: handleCancelButtonClick, isFullWidth: false, hasCloseButton: hasCloseButton, title: title || t('general.modal.confirmation.title') },
        React.createElement("div", { className: "flex flex-col gap-y-6" },
            React.createElement("p", { className: "text-light-gray-600 text-paragraph-sm" }, description || t('general.modal.confirmation.description')),
            React.createElement("div", { className: "flex gap-x-4 justify-end" },
                hasCancelButton && (React.createElement(Button, { type: ButtonType.button, color: ButtonColor.secondary, onClick: handleCancelButtonClick }, cancelLabel || t('general.actions.cancel'))),
                React.createElement(Button, { type: ButtonType.button, onClick: handleAcceptButtonClick }, acceptLabel || t('general.actions.accept'))))));
};
export default ConfirmationModal;
