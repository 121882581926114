import React from 'react';
import cn from 'classnames';
import { GenericModal } from '../GenericModal';
import { Spinner } from '../../../atoms';
import Caption from '../shared/ModalCaption';
import { useTranslation } from 'react-i18next';
/**
 * Use this component in order to show a loading Dialog
 * @author Ricardo Alfonso Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-08-11
 */
var LoadingModal = function (_a) {
    var caption = _a.caption, className = _a.className, onClose = _a.onClose;
    var t = useTranslation().t;
    var loadingModalClass = cn(className);
    return (React.createElement(GenericModal, { className: loadingModalClass, onClose: onClose, isFullWidth: false },
        React.createElement("div", { "data-testid": "container", className: "flex flex-col gap-y-6 items-center px-12" },
            React.createElement(Spinner, null),
            React.createElement(Caption, { caption: caption || t('general.genericLoadingCaption') }))));
};
export default LoadingModal;
