import React from 'react';
import { itemClass } from './styles';
/**
 * Item (Dropdown Menu)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-01
 */
export var Item = function (_a) {
    var href = _a.href, target = _a.target, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, onClick = _a.onClick, className = _a.className, children = _a.children, dataTestId = _a.dataTestId;
    var handleClick = function (event) {
        if (event)
            event.persist();
        if (onClick && !isDisabled)
            onClick(event);
    };
    return href ? (React.createElement("a", { className: itemClass(className, isDisabled), href: href, target: target, onClick: handleClick, role: "menuitem", rel: "noreferrer noopener" }, children)) : (React.createElement("div", { className: itemClass(className, isDisabled), onClick: handleClick, role: "menuitem", tabIndex: 0, "data-testid": dataTestId, onKeyPress: handleClick }, children));
};
