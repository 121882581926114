import { useEffect, useState } from 'react';
import { PARAMS_KEYS, useSearchParams } from '../useSearchParams';
import { WorklistTabNames } from '~/src/types/StudyList';

export const useStudyListTabs = () => {
  const [activeTab, setActiveTab] = useState<WorklistTabNames>();
  const searchParams = useSearchParams();

  useEffect(() => {
    const currentSearchParam = searchParams.get(PARAMS_KEYS.TABS) ?? '';
    setActiveTab(currentSearchParam as WorklistTabNames);
  }, [searchParams]);

  return {
    activeTab,
  };
};
