/**
 * Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
 * Method taken from this page: https://github.com/orgs/react-hook-form/discussions/1991
 * @author Salvador Gonzalez <salvador.gonzalez@evacenter.com>
 * Created at 2023-04-10
 * returns: An object without falsy values.
 */
export var getDirtyValues = function (dirtyFields, allValues) {
    // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
    // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields))
        return allValues;
    // Here, we have an object
    return Object.fromEntries(Object.keys(dirtyFields).map(function (key) { return [key, getDirtyValues(dirtyFields[key], allValues[key])]; }));
};
