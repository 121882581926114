var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * This will listen for changes in the image and resize the viewport based on the dimensions of each image
 *
 * this is a dom cornestone element
 * @param enabledElement
 */
export var handleResizeImage = function (cornerstone, eventData) {
    var enabledElement = eventData.detail.element;
    var imageWidth = eventData.detail.image.width;
    var imageHeight = eventData.detail.image.height;
    var viewportWidth = eventData.detail.viewport.displayedArea.brhc.x;
    var viewportHeight = eventData.detail.viewport.displayedArea.brhc.y;
    if (imageWidth === viewportWidth && imageHeight === viewportHeight)
        return;
    eventData.detail.viewport.displayedArea.brhc.x = imageWidth;
    eventData.detail.viewport.displayedArea.brhc.y = imageHeight;
    cornerstone.fitToWindow(enabledElement);
};
/**
 * This will listen for changes in the image and resize the viewport based on the dimensions of each image
 *
 * this is a dom cornestone element
 * @param enabledElement
 */
export var handleWwwcImage = function (cornerstone, event) {
    var element = event.detail.element;
    var viewportVoi = __assign({}, event.detail.viewport.voi);
    var imageVoi = __assign({}, cornerstone.getDefaultViewportForImage(element, event.detail.image).voi);
    if (viewportVoi.windowWidth === imageVoi.windowWidth && viewportVoi.windowCenter === imageVoi.windowCenter)
        return;
    event.detail.viewport.voi = __assign({}, imageVoi);
    cornerstone.updateImage(element);
};
