import { AxiosResponse, CancelTokenSource } from 'axios';
import { ENDPOINT_PATH, UpdateStudyMutation, restClient, useUpdateStudyMutation } from '@eva-pacs/client';
import { useErrorHandler } from '~/utils/appHelpers';
import { getAuthHeaders } from '~/utils/getHeadersAuth';

export interface StudyService {
  updateStudy: (
    studyId: string,
    dataSet: {
      [key: string]: string;
    },
  ) => Promise<UpdateStudyMutation | null | undefined>;
  uploadFile: (
    file: File,
    tempId: string,
    source: CancelTokenSource,
    studyId: string,
    isVisible: string,
    onUploadProgress,
  ) => Promise<AxiosResponse<any>>;
}

export const useStudyService = (): StudyService => {
  const { handleError } = useErrorHandler();

  // Apollo hooks
  const [updateStudyMutation] = useUpdateStudyMutation({
    onError: (error) => handleError({ logMessage: error }),
  });

  // Service actions
  const updateStudy: (
    studyId: string,
    dataSet: {
      [key: string]: string;
    },
  ) => Promise<UpdateStudyMutation | null | undefined> = async (
    studyId: string,
    dataSet: { [key: string]: string },
  ) => {
    const { data } = await updateStudyMutation({
      variables: {
        id: studyId,
        input: {
          ...dataSet,
        },
      },
    });
    return data;
  };

  const uploadFile = (
    file: File,
    tempId: string,
    source: CancelTokenSource,
    studyId: string,
    isVisible: string,
    onUploadProgress,
  ): Promise<AxiosResponse<any>> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('study', studyId);
    formData.append('name', file.name);
    formData.append('is_visible', isVisible);

    return restClient
      .post(`${ENDPOINT_PATH.STUDY_FILES}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...getAuthHeaders(),
        },
        onUploadProgress: onUploadProgress(tempId),
        cancelToken: source.token,
      })
      .then((response) => response);
  };

  // Export useStudyService values
  return { updateStudy, uploadFile };
};
