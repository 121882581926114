import { useEffect } from 'react';
import { useSessionStore } from '../store';
import type { UserType } from '@eva-pacs/client';

export const useLoggerSession = () => {
  const user = useSessionStore((state) => state.user);
  // Listen changes un user to update logger session
  useEffect(() => {
    if (!user) {
      clearUser();
      return;
    }
    setUser(user);
  }, [user]);
};

const setUser = (user: UserType): void => {
  if ((window as any)?.clarity) {
    // eslint-disable-next-line prettier/prettier, i18next/no-literal-string
    (window as any)?.clarity('identify', `${user.id}`);
    // eslint-disable-next-line prettier/prettier, i18next/no-literal-string
    (window as any).clarity('set', 'user', `${user.id}`);
  }
};

const clearUser = (): void => {};
