export enum UserRole {
  /** Admin */
  ADMIN = 'admin',
  /** Radiologist Admin */
  RADIOLOGIST_ADMIN = 'radiologist_admin',
  /** Radiologist */
  RADIOLOGIST = 'radiologist',
  /** Radiologist Technician */
  RADIOLOGIST_TECHNICIAN = 'radiologist_technician',
  /** Read Only */
  READ_ONLY = 'read_only',
  /** Visitant */
  VISITANT = 'visitant',
  /** Referring Physician */
  REFERRING_PHYSICIAN = 'referring_physician',
}

/**
 * ROLES GROUPS
 */
export const ALL_ROLES = Object.values(UserRole);
export const ALL_EXCEPT_ADMIN_ROLE = ALL_ROLES.filter((role) => role !== UserRole.ADMIN);
export const ALL_EXCEPT_RADIOLOGIST_ROLE = ALL_ROLES.filter((role) => role !== UserRole.RADIOLOGIST);
export const ALL_EXCEPT_RADIOLOGIST_ADMIN_ROLE = ALL_ROLES.filter((role) => role !== UserRole.RADIOLOGIST_ADMIN);
export const ALL_EXCEPT_RADIOLOGIST_TECHNICIAN_ROLE = ALL_ROLES.filter(
  (role) => role !== UserRole.RADIOLOGIST_TECHNICIAN,
);
export const ALL_EXCEPT_READ_ONLY_ROLE = ALL_ROLES.filter((role) => role !== UserRole.READ_ONLY);
export const ALL_EXCEPT_VISITANT_ROLE = ALL_ROLES.filter((role) => role !== UserRole.VISITANT);
