import { CrossPacsActionType, EventMessage } from '../constants/eventMessage';
import { EVACENTER_URL } from '../constants/products';

const isPostMessageAvailable = typeof window !== 'undefined' && 'opener' in window;

/**
 * use `usePostMessage` hook to send messages to the `opener`
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-06-01
 */
export const usePostMessage = ({ targetOrigin = EVACENTER_URL } = {}) => {
  const postMessage = ({
    actionType,
    payload = {},
  }: {
    actionType: CrossPacsActionType;
    payload?: Record<string, unknown>;
  }) => {
    if (!isPostMessageAvailable) return;

    const eventMessage: EventMessage = {
      actionType,
      payload,
      time: Date.now(),
    };

    window?.opener?.postMessage?.(eventMessage, targetOrigin);
    window?.syncedApp?.postMessage?.(eventMessage, targetOrigin as string);
  };

  return {
    postMessage,
  };
};
