import React from 'react';
import cn from 'classnames';

import {
  PatientType,
  PatientGender,
  PatientInputType,
  useUpdatePatientMutation,
  useGetFacilityByStudyQuery,
} from '@eva-pacs/client';
import { DEFAULT_ISO_CODE, MX_AREA_CODE } from '~/constants';
import { PatientEditForm, PatientFormFields } from '~/components/StudyList/PatientEditForm';
import { useReportService } from '~/hooks';

export interface PatientEditTabProps {
  /**
   * Set classes to wrapper element
   */
  className?: string;
  /**
   * Set patient object data
   */
  patient: PatientType;
  /**
   * Pass current study ID
   */
  currentStudyID: string;
  /**
   * Set if form is disabled
   */
  isDisabled?: boolean;
  /**
   * Callback to manage on close event
   */
  onCloseModal: () => void;
  /**
   * Callback to manage on default close event
   */
  onDefaultCancel: () => void;
}

/**
 * Tab to contain patient form and manage data.
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-07-26
 */
export const PatientEditTab: React.FC<PatientEditTabProps> = ({
  className,
  patient,
  currentStudyID,
  isDisabled = false,
  onCloseModal,
  onDefaultCancel,
}) => {
  const patientTabClasses = cn(className, 'px-2');

  const { rebuildActiveReportPDF } = useReportService();

  const [updatePatientMutation] = useUpdatePatientMutation({
    onCompleted: ({ updatePatient }) => {
      const patientSelectedStudy = updatePatient.patient?.studies.find((study) => study.id === currentStudyID);
      const reports = patientSelectedStudy?.reports;
      if (reports?.length) rebuildActiveReportPDF(reports);
      if (updatePatient.success) onCloseModal();
    },
  });

  const { data: facility } = useGetFacilityByStudyQuery({
    variables: {
      studyId: currentStudyID,
    },
    fetchPolicy: 'no-cache',
  });

  const facilityCountryPhoneCode = facility?.study?.facility?.country?.phoneCode ?? '';
  const facilityIsoCode = facility?.study?.facility.country?.isoCode;

  const patientEditFormFields: PatientFormFields = {
    identifierEditable: patient?.identifierEditable || '',
    fullName: patient?.fullName || '',
    gender: patient?.gender || PatientGender.M,
    birthDate: patient?.birthDate || '',
    email: patient?.email || '',
    phoneCountry: {
      id: '',
      isoCode: '',
    },
    phoneObj: {
      phone: patient?.phoneNumber || '',
      phoneCode:
        (patient?.phoneNumber ? patient?.phoneCode : facilityCountryPhoneCode || MX_AREA_CODE) ||
        facilityCountryPhoneCode ||
        MX_AREA_CODE,
      isoCode: patient?.phoneNumber ? patient?.phoneCountry?.isoCode : facilityIsoCode || DEFAULT_ISO_CODE,
    },
    identifier: patient?.identifier || '',
  };

  const parseData = (patientFields: PatientFormFields): PatientInputType => ({
    identifierEditable: patientFields.identifierEditable,
    identifier: patientFields.identifier,
    fullName: patientFields.fullName,
    gender: patientFields.gender,
    email: patientFields.email,
    birthDate: patientFields.birthDate ? patientFields.birthDate : undefined,
    phoneNumber: patientFields.phoneObj.phone,
    phoneCode: patientFields.phoneObj.phoneCode,
    phoneCountry: patientFields.phoneCountry?.id,
  });

  const handleOnSubmit = (patientFields: PatientFormFields) => {
    updatePatientMutation({
      variables: {
        id: patient.id,
        input: {
          ...parseData(patientFields),
        },
      },
    });
  };

  return (
    <div className={patientTabClasses}>
      <PatientEditForm
        isDisabled={isDisabled}
        defaultFieldsValue={patientEditFormFields}
        onSubmitEmit={handleOnSubmit}
        onCancel={onDefaultCancel}
      />
    </div>
  );
};
