import { UserType } from '@eva-pacs/client';
import { getUserRole } from '../utils/appHelpers';
import { ALL_EXCEPT_VISITANT_ROLE, UserRole } from './roleGroups';

/**
 * ACTIONS KEYS
 */
export const CAN_DELETE_MEASUREMENT = 'measurement:delete';
export const CAN_DELETE_STUDY = 'study:delete';
export const CAN_EDIT_MEASUREMENT = 'measurement:edit';
export const CAN_EDIT_PRIORITY = 'priority:edit';
export const CAN_EDIT_PATIENT = 'patient:edit';
export const CAN_EDIT_REPORT = 'report:edit';
export const CAN_TOGGLE_DOUBLE_SIGNATURE = 'toggle:doublesignature';
export const CAN_EDIT_STUDY = 'study:edit';
export const CAN_DOWNLOAD_CSV = 'csv:download';
export const CAN_READ_ADMIN = 'admin:read';
export const CAN_READ_MEASUREMENT = 'measurement:read';
export const CAN_READ_STUDIES = 'studies:read';
export const CAN_SEND_VIEWER = 'viewer:send';
export const CAN_UPLOAD_FILE = 'addFile:read';
export const CAN_DELETE_FILE = 'file:delete';
export const CAN_UPLOAD_STUDY = 'study:upload';
export const CAN_JOIN_STUDIES = 'studies:join';
export const CAN_SELECT_STUDIES = 'studies:select';
export const CAN_DICOMPRINT_STUDY = 'study:dicomprint';
export const CAN_HANDLE_KEY_IMAGES = 'study:keyImages';
export const CAN_USE_NOTIFICATIONS = 'user:notifications';
export const CAN_USE_WORKLIST = 'study:worklist';
export const CAN_BURN_DISCS = 'study:burnDiscs';
export const CAN_CURRENT_USER_BE_ASSIGNED_TO_STUDY = 'current:user:assigned:to:study';
export const CAN_SEND_REPORT = 'report:send';
export const CAN_PROA_SEND_REPORT = 'proa:report:send';

/**
 * POLICIES
 */
export const POLICIES = {
  [CAN_DELETE_MEASUREMENT]: (user: UserType | null) =>
    [UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_DELETE_STUDY]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_EDIT_MEASUREMENT]: (user: UserType | null) =>
    [UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST].includes(getUserRole(user)),
  [CAN_EDIT_PATIENT]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(
      getUserRole(user),
    ),
  [CAN_EDIT_PRIORITY]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(
      getUserRole(user),
    ),
  [CAN_EDIT_REPORT]: (user: UserType | null) =>
    [UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_EDIT_STUDY]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(
      getUserRole(user),
    ),
  [CAN_DOWNLOAD_CSV]: (user: UserType | null) => ALL_EXCEPT_VISITANT_ROLE.includes(getUserRole(user)),
  [CAN_READ_ADMIN]: (user: UserType | null) => [UserRole.ADMIN, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_READ_MEASUREMENT]: (user: UserType | null) =>
    [
      UserRole.ADMIN,
      UserRole.RADIOLOGIST,
      UserRole.RADIOLOGIST_ADMIN,
      UserRole.RADIOLOGIST_TECHNICIAN,
      UserRole.REFERRING_PHYSICIAN,
    ].includes(getUserRole(user)),
  [CAN_READ_STUDIES]: (user: UserType | null) => ALL_EXCEPT_VISITANT_ROLE.includes(getUserRole(user)),

  [CAN_SEND_VIEWER]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(
      getUserRole(user),
    ),
  [CAN_UPLOAD_FILE]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(
      getUserRole(user),
    ),
  [CAN_DELETE_FILE]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(
      getUserRole(user),
    ),
  [CAN_UPLOAD_STUDY]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(
      getUserRole(user),
    ),
  [CAN_JOIN_STUDIES]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_SELECT_STUDIES]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_PROA_SEND_REPORT]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_DICOMPRINT_STUDY]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(
      getUserRole(user),
    ),
  [CAN_HANDLE_KEY_IMAGES]: (user: UserType | null) =>
    [UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.RADIOLOGIST_TECHNICIAN].includes(getUserRole(user)),
  [CAN_USE_NOTIFICATIONS]: (user: UserType | null) =>
    [UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN, UserRole.REFERRING_PHYSICIAN].includes(getUserRole(user)),
  [CAN_USE_WORKLIST]: (user: UserType | null) =>
    [UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_BURN_DISCS]: (user: UserType | null) =>
    [
      UserRole.RADIOLOGIST_TECHNICIAN,
      UserRole.RADIOLOGIST,
      UserRole.RADIOLOGIST_ADMIN,
      UserRole.ADMIN,
      UserRole.READ_ONLY,
    ].includes(getUserRole(user)),
  [CAN_TOGGLE_DOUBLE_SIGNATURE]: (user: UserType | null) =>
    [UserRole.ADMIN, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_CURRENT_USER_BE_ASSIGNED_TO_STUDY]: (user: UserType | null) =>
    [UserRole.RADIOLOGIST, UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
  [CAN_SEND_REPORT]: (user: UserType | null) => [UserRole.RADIOLOGIST_ADMIN].includes(getUserRole(user)),
};
