import cn from 'classnames';
/* Class assignment */
export var radioCardClass = function (className, isChecked, isDisabled) {
    if (isChecked === void 0) { isChecked = false; }
    if (isDisabled === void 0) { isDisabled = false; }
    return cn(
    /* RadioCard (base) */
    'flex space-x-2 items-start', 'rounded-lg', 'transition duration-100 ease-out', 'border', 'p-4', isChecked ? 'border-primary-300' : 'border-light-gray-600', 
    /* RadioCard--isDisabled */
    isDisabled && 'border-primary-300 opacity-75 pointer-events-none', className);
};
