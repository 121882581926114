import cn from 'classnames';
import { MultiSelectSize } from '.';
/* Class assignment */
export var containerClass = function (className) { return cn(className, 'inline-flex relative w-full'); };
export var multiSelectClass = function (size) {
    return cn(
    /* MultiSelect (base) */
    'flex w-full p-2 pl-4 cursor-pointer', 'bg-gray-600 rounded-lg appearance-none outline-none', 'text-basic-white', size === MultiSelectSize.sm && 'h-10');
};
export var iconContainerClass = function (size) {
    return cn(
    /* MultiSelect icon container (base) */
    'flex items-center self-stretch flex-shrink-0 ml-auto', size === MultiSelectSize.sm && 'p-3');
};
