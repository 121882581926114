import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { resetMammographyReferenceLinesTool } from '~/utils/cornestone/resetMammographyReferenceLinesTool';
import { LEFT_MOUSE_BUTTON, MIDDLE_MOUSE_BUTTON, ToolbarOption } from '~/constants';
import { getEnabledElementControlled } from '~/utils/cornestone/getEnabledElementControlled';
import { useMeasurementStore } from '~/src/store';
import { disableAllMeasurements } from '~/utils/measurements/disableAllMeasurements';
import { manageResetToolState } from '~/components/Viewer/MeasurementsBrowser/handleMeasurements';
import { ToolName } from '~/src/constants/toolName';
import type { CursorTools } from '~/src/interfaces/CursorTools';
import { useSpineLabelStore } from '../../../store/spineLabelStore';

/**
 * This handles all the tools of cornerstone
 * If you need to ADD A NEW TOOL you should add it here.
 *
 * Refer to:
 * https://github.com/cornerstonejs/cornerstoneTools
 * https://tools.cornerstonejs.org/examples/
 *
 */
export const useSetterTools = () => {
  const { setSelectedMeasurement } = useMeasurementStore();
  const openSpineLabelPanel = useSpineLabelStore((state) => state.setPanelIsOpen);
  const setActiveTool = (
    tool: string,
    options?: {
      unselectMeasurement?: boolean;
      activeElement?: HTMLElement;
      onRemoveAllMeasurements?: () => void;
    },
  ): void => {
    const { activeElement, unselectMeasurement = true, onRemoveAllMeasurements } = options ?? {};
    openSpineLabelPanel(false);
    const tools: CursorTools = {
      [ToolbarOption.ANGLE]: () => {
        cornerstoneTools.setToolActive(ToolName.Angle, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.COBBANGLE]: () => {
        cornerstoneTools.setToolActive(ToolName.CobbAngle, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.MOVE]: () => {
        cornerstoneTools.setToolActive(ToolName.Pan, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.SCROLL]: () => {
        cornerstoneTools.setToolActive(ToolName.Scroll, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.UNDO]: () => {
        if (!activeElement) return;
        cornerstone.reset(activeElement);
      },
      [ToolbarOption.ANNOTATION]: () => {
        cornerstoneTools.setToolActive(ToolName.ArrowAnnotate, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.ZOOM]: () => {
        cornerstoneTools.setToolActive(ToolName.Zoom, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.MAGNIFY]: () => {
        cornerstoneTools.setToolActive(ToolName.Magnify, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.ROTATE]: () => {
        cornerstoneTools.setToolActive(ToolName.Rotate, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.SPINE_LABEL]: () => {
        cornerstoneTools.setToolActive(ToolName.SpineLabel, { mouseButtonMask: LEFT_MOUSE_BUTTON });
        openSpineLabelPanel(true);
      },
      [ToolbarOption.ROTATE_LEFT]: () => {
        const rotation = -90;
        const enabledElement = activeElement;

        if (enabledElement) {
          const viewport = cornerstone.getViewport(enabledElement);
          if (!viewport) return;
          viewport.rotation += rotation;
          cornerstone.setViewport(enabledElement, viewport);
        }
      },
      [ToolbarOption.ROTATE_RIGHT]: () => {
        const rotation = 90;
        const enabledElement = activeElement;

        if (enabledElement) {
          const viewport = cornerstone.getViewport(enabledElement);
          if (!viewport) return;
          viewport.rotation += rotation;
          cornerstone.setViewport(enabledElement, viewport);
        }
      },
      [ToolbarOption.LENGTH]: () => {
        cornerstoneTools.setToolActive(ToolName.Length, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.BIDIRECTIONAL]: () => {
        //   cornerstoneTools.setToolActiveForElement(activeElement, 'Bidirectional');
        cornerstoneTools.setToolActive(ToolName.Bidirectional, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.CTR]: () => {
        cornerstoneTools.setToolActive(ToolName.CTR, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.COXOMETRY]: () => {
        cornerstoneTools.setToolActive(ToolName.Coxometry, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.GONIOMETRY]: () => {
        cornerstoneTools.setToolActive(ToolName.Goniometry, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.NIPPLE_INJURY]: () => {
        cornerstoneTools.setToolActive(ToolName.NippleInjury, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.CROP]: () => {
        cornerstoneTools.setToolActive(ToolName.RectangleCrop, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.DEVIATION]: () => {
        cornerstoneTools.setToolActive(ToolName.Deviation, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.ERASE]: () => {
        cornerstoneTools.setToolActive(ToolName.Eraser, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.CLEAN]: () => {
        if (activeElement) {
          const enabledElement = getEnabledElementControlled(activeElement);
          if (!enabledElement?.image) return;

          const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();

          Object.keys(toolState).forEach((imageId) => {
            toolState[imageId] = {};
          });

          if (onRemoveAllMeasurements) onRemoveAllMeasurements();
          cornerstone.updateImage(activeElement);
        }
      },
      [ToolbarOption.FLIP_VERTICAL]: () => {
        const enabledElement = activeElement;

        if (enabledElement) {
          const viewport = cornerstone.getViewport(enabledElement);
          if (!viewport) return;
          viewport.hflip = !viewport.hflip;
          cornerstone.setViewport(enabledElement, viewport);
        }
      },
      [ToolbarOption.FLIP_HORIZONTAL]: () => {
        const enabledElement = activeElement;

        if (enabledElement) {
          const viewport = cornerstone.getViewport(enabledElement);
          if (!viewport) return;
          viewport.vflip = !viewport.vflip;
          cornerstone.setViewport(enabledElement, viewport);
        }
      },
      [ToolbarOption.WWWC]: () => {
        cornerstoneTools.setToolActive(ToolName.Wwwc, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.INVERT]: () => {
        const enabledElement = activeElement;

        if (enabledElement) {
          const viewport = cornerstone.getViewport(enabledElement);
          if (!viewport) return;
          viewport.invert = !viewport.invert;
          cornerstone.setViewport(enabledElement, viewport);
        }
      },
      [ToolbarOption.RECTANGLE]: () => {
        cornerstoneTools.setToolActive(ToolName.RectangleRoi, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.ELLIPSIS]: () => {
        cornerstoneTools.setToolActive(ToolName.EllipticalRoi, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.FREEHAND]: () => {
        cornerstoneTools.setToolActive(ToolName.FreehandRoi, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.PROBE]: () => {
        cornerstoneTools.setToolActive(ToolName.Probe, { mouseButtonMask: LEFT_MOUSE_BUTTON });
      },
      [ToolbarOption.REFERENCE_LINES]: () => {
        cornerstoneTools.setToolActive(ToolName.MammographyReferenceLines, {
          mouseButtonMask: LEFT_MOUSE_BUTTON,
        });
        return;
      },
    };
    resetMammographyReferenceLinesTool();
    if (unselectMeasurement) {
      disableAllMeasurements();
      setSelectedMeasurement(null);
    }

    if (tool !== ToolbarOption.INVERT) {
      cornerstoneTools.setToolDisabled(ToolName.Magnify);
      cornerstoneTools.setToolDisabled(ToolName.Zoom);
      cornerstoneTools.setToolDisabled(ToolName.Wwwc);
    }
    cornerstoneTools.setToolActive(ToolName.Zoom, { mouseButtonMask: MIDDLE_MOUSE_BUTTON });
    if (typeof tools[tool] !== 'undefined') return tools[tool]();
  };

  const resetToolState = (tool: string, element: HTMLElement) => {
    const tools = {
      [ToolbarOption.ANGLE]: () => manageResetToolState(element, ToolName.Angle),
      [ToolbarOption.COBBANGLE]: () => manageResetToolState(element, ToolName.CobbAngle),
      [ToolbarOption.ANNOTATION]: () => manageResetToolState(element, ToolName.ArrowAnnotate),
      [ToolbarOption.SPINE_LABEL]: () => manageResetToolState(element, ToolName.SpineLabel),
      [ToolbarOption.LENGTH]: () => manageResetToolState(element, ToolName.Length),
      [ToolbarOption.BIDIRECTIONAL]: () => manageResetToolState(element, ToolName.Bidirectional),
      [ToolbarOption.CTR]: () => manageResetToolState(element, ToolName.CTR),
      [ToolbarOption.COXOMETRY]: () => manageResetToolState(element, ToolName.Coxometry),
      [ToolbarOption.GONIOMETRY]: () => manageResetToolState(element, ToolName.Goniometry),
      [ToolbarOption.NIPPLE_INJURY]: () => manageResetToolState(element, ToolName.NippleInjury),
      [ToolbarOption.CROP]: () => manageResetToolState(element, ToolName.RectangleCrop),
      [ToolbarOption.DEVIATION]: () => manageResetToolState(element, ToolName.Deviation),
      [ToolbarOption.ERASE]: () => manageResetToolState(element, ToolName.Eraser),
      [ToolbarOption.WWWC]: () => manageResetToolState(element, ToolName.Wwwc),
      [ToolbarOption.RECTANGLE]: () => manageResetToolState(element, ToolName.RectangleRoi),
      [ToolbarOption.ELLIPSIS]: () => manageResetToolState(element, ToolName.EllipticalRoi),
      [ToolbarOption.FREEHAND]: () => manageResetToolState(element, ToolName.FreehandRoi),
      [ToolbarOption.PROBE]: () => manageResetToolState(element, ToolName.Probe),
      [ToolbarOption.REFERENCE_LINES]: () => manageResetToolState(element, ToolName.MammographyReferenceLines),
    };
    if (typeof tools[tool] !== 'undefined') tools[tool]();
    restorePassiveToolState();
  };

  return { setActiveTool, resetToolState };
};

export const restorePassiveToolState = () => {
  const anotationTools = [
    ToolName.Angle,
    ToolName.CobbAngle,
    ToolName.ArrowAnnotate,
    ToolName.SpineLabel,
    ToolName.Length,
    ToolName.Bidirectional,
    ToolName.CTR,
    ToolName.Coxometry,
    ToolName.Goniometry,
    ToolName.NippleInjury,
    ToolName.RectangleCrop,
    ToolName.Deviation,
    ToolName.RectangleRoi,
    ToolName.EllipticalRoi,
    ToolName.FreehandRoi,
    ToolName.Probe,
    ToolName.MammographyReferenceLines,
  ];
  anotationTools.forEach((tool) => {
    const toolInstances = cornerstoneTools.store.state.tools.filter((toolObject) => toolObject.name === tool);
    toolInstances.forEach((toolInstance) => {
      const isActive = toolInstance.mode === 'active';
      if (isActive) return;

      toolInstance.mode = 'passive';
    });
  });
};
