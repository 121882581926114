const JPEG_LOSSLESS_TS = '1.2.840.10008.1.2.4.70'; //'JPEG Lossless, Non-Hierarchical, First-Order Prediction (Process 14 [Selection Value 1]): Default Transfer Syntax for Lossless JPEG Image Compression',

const uncompressedTransferSyntaxes: Array<string> = [
  '1.2.840.10008.1.2', // Implicit VR Little Endian
  '1.2.840.10008.1.2.1', // Explicit VR Little Endian
  '1.2.840.10008.1.2.2', // Explicit VR Big Endian
];

function checkForEncapsulation(dataset, transferSyntaxUID): boolean {
  if (!dataset) return false;

  const isUncompressed = uncompressedTransferSyntaxes.includes(transferSyntaxUID);

  const pixelDataElement = dataset.elements.x7fe00010; // PixelData tag to validate encapsulation
  const isPixelDataEncapsulated = pixelDataElement && pixelDataElement.encapsulatedPixelData === true;

  // https://dicom.innolitics.com/ciods/rt-dose/general-image/00282110
  const lossyImageCompression = dataset.string('x00282110');
  const isLosslessCompresion = lossyImageCompression === '00';

  if (isUncompressed && isPixelDataEncapsulated && isLosslessCompresion) return true;

  return false;
}
export const getInferedTransferSyntax = (originalTransferSyntax, dataset) => {
  const isEncapsulated = checkForEncapsulation(dataset, originalTransferSyntax);
  if (isEncapsulated) return JPEG_LOSSLESS_TS;
  return originalTransferSyntax;
};
