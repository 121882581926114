import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, DeepMap, FieldError, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatStringDateTime, getDynamicSchema, getTextField, getDirtyValues } from '@eva-pacs/core';
import { SeriesType, StudyType } from '@eva-pacs/client';
import {
  Accordion,
  AccordionPanel,
  Alert,
  AlertVariant,
  BasicInput,
  Button,
  ButtonColor,
  ButtonType,
  FormField,
  Icon,
  IconCatalog,
  NativeSelect,
  NativeSelectOption,
  Textarea,
} from '@eva-pacs/ui';
import { fullstudyformClass } from './styles';

export interface StudyAndSeriesFields {
  practitionerAssigned: string;
  dicomRequestingPhysician: string;
  dicomReferringPhysician: string;
  dicomReason: string;
  dicomDescription: string;
  studyTypeEditable: string;
  folio: string;
  facility: string;
  series: Array<{
    id: string;
    bodyPart: string;
    dicomOperatorsName: string;
    dicomDescription: string;
    dicomPerformingPhysicianInstitution: string;
  }>;
}
export interface StudyAndSeriesFormProps {
  /**
   * Set html className to wrapper element
   */
  className?: string;
  /**
   * Study Data
   */
  study: StudyType;
  /**
   * Set list of practitioners
   */
  practitionerList: Array<NativeSelectOption>;
  /**
   * Set list of facilities
   */
  facilityList: Array<NativeSelectOption>;
  /**
   * Set if form is disabled
   */
  isDisabled?: boolean;
  /**
   * Listen for practitioner change
   */
  onPractitionerChange?: (id: string) => void;
  /**
   * Emit Event when form is submited
   */
  onSubmitEmit: (data: StudyAndSeriesFields) => void;
  /**
   * Emit Event when user clicked cancel button
   */
  onCancel: () => void;
}

/**
 * Form to edit study and series
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-07-22
 */
export const StudyAndSeriesForm: React.FC<StudyAndSeriesFormProps> = ({
  className,
  study,
  practitionerList,
  facilityList,
  isDisabled = false,
  onSubmitEmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    folio: getTextField(t),
    practitionerAssigned: getTextField(t),
    dicomRequestingPhysician: getTextField(t),
    dicomReferringPhysician: getTextField(t),
    dicomReason: getTextField(t),
    dicomDescription: getTextField(t),
    studyTypeEditable: getTextField(t, true),
    series: yup.array().of(
      getDynamicSchema(t, [
        { name: 'id', type: '', isRequired: true },
        { name: 'bodyPart', type: '', isRequired: false },
        { name: 'dicomOperatorsName', type: '', isRequired: false },
        { name: 'dicomDescription', type: '', isRequired: false },
        { name: 'dicomPerformingPhysicianInstitution', type: '', isRequired: false },
      ]),
    ),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<StudyAndSeriesFields>({
    resolver: yupResolver(schema),
  });

  const [errorIndex, setErrorIndex] = useState<number>();
  useEffect(() => {
    if (errors?.series?.length) setErrorIndex(errors.series.findIndex((item) => Boolean(item)));
    else setErrorIndex(-1);
  }, [errors?.series]);

  const onSubmit = (data: StudyAndSeriesFields) => {
    if (!dirtyFields) return;

    // Only send changed values
    const changedData = getDirtyValues<StudyAndSeriesFields>(dirtyFields, data);

    onSubmitEmit(changedData);
  };

  const seriesForms = study.series.map((serie, index) => {
    return (
      <AccordionPanel
        key={index}
        panelId={serie.id}
        className={`px-2 rounded ${index === errorIndex ? 'border border-feedback-negative-500' : ''}`}
        trigger={
          <div className="flex py-4 text-light-gray-600 text-caption font-semi-bold border-t border-gray-600">
            <p>
              {t('general.modality')}: <span className="text-basic-white">{serie.modality.name}</span>
            </p>
            <p className="ml-6">
              {t('general.numberOfImages')}: <span className="text-basic-white">{serie.instances.length}</span>
            </p>
            <p className="ml-6">
              {t('general.serieDateTime')}:{' '}
              <span className="text-basic-white">{formatStringDateTime(serie.dicomDateTime)}</span>
            </p>
            <button type="button" className="flex items-center ml-auto text-primary-300 text-label-sm font-semi-bold">
              {t('general.more')}
              <Icon className="ml-3" icon={IconCatalog.chevronDown} />
            </button>
          </div>
        }>
        <SerieForm
          className="py-10"
          index={index}
          serie={serie}
          control={control}
          errors={errors}
          isDisabled={isDisabled}
        />
      </AccordionPanel>
    );
  });
  return (
    <form data-testid="studyAndSeriesForm" className={fullstudyformClass(className)} onSubmit={handleSubmit(onSubmit)}>
      {isDirty && (
        <Alert
          className="mb-10 max-w-xs"
          description={t('general.genericPendingChangesText')}
          variant={AlertVariant.warning}
          hasCloseButton={false}
        />
      )}
      <fieldset
        className="grid gap-x-8 gap-y-10 grid-cols-3 mb-8 px-2 text-left text-basic-white"
        disabled={isDisabled}>
        <FormField
          name="folio"
          defaultValue={study.folio || ''}
          label={t('study.studyFields.folio')}
          control={control}
          error={errors.folio}
          render={({ field }) => {
            return (
              <BasicInput
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                maxLength={20}
                placeholder={t('study.studyFields.folio')}
              />
            );
          }}
        />
        <FormField
          name="studyTypeEditable"
          defaultValue={study.studyTypeEditable || ''}
          label={t('study.studyFields.studyTypeEditable')}
          control={control}
          error={errors.studyTypeEditable}
          render={({ field }) => {
            return (
              <BasicInput
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('study.studyFields.studyTypeEditable')}
              />
            );
          }}
        />
        <FormField
          name="facility"
          defaultValue={study?.facility.id || ''}
          label={t('study.studyFields.shortFacility')}
          control={control}
          error={errors.facility}
          render={({ field }) => {
            return (
              <NativeSelect
                options={facilityList}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('study.studyFields.shortFacility')}
              />
            );
          }}
        />
        <FormField
          name="practitionerAssigned"
          defaultValue={study?.practitionerAssigned?.id || ''}
          label={t('study.studyFields.practitionerAssigned')}
          control={control}
          error={errors.practitionerAssigned}
          render={({ field }) => {
            return (
              <NativeSelect
                options={practitionerList}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('study.studyFields.practitionerAssigned')}
              />
            );
          }}
        />
        <FormField
          name="dicomRequestingPhysician"
          defaultValue={study.dicomRequestingPhysician || ''}
          label={t('study.studyFields.dicomRequestingPhysician')}
          control={control}
          error={errors.dicomRequestingPhysician}
          render={({ field }) => {
            return (
              <BasicInput
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('study.studyFields.dicomRequestingPhysician')}
              />
            );
          }}
        />
        <FormField
          name="dicomReferringPhysician"
          defaultValue={study.dicomReferringPhysician || ''}
          label={t('study.studyFields.dicomReferringPhysician')}
          control={control}
          error={errors.dicomReferringPhysician}
          render={({ field }) => {
            return (
              <BasicInput
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('study.studyFields.dicomReferringPhysician')}
              />
            );
          }}
        />
      </fieldset>
      <fieldset
        className="grid gap-x-8 gap-y-10 grid-cols-2 mb-8 px-2 text-left text-basic-white"
        disabled={isDisabled}>
        <FormField
          name="dicomReason"
          defaultValue={study.dicomReason || ''}
          label={t('study.studyFields.dicomReason')}
          control={control}
          error={errors.dicomReason}
          render={({ field }) => {
            return (
              <Textarea
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('study.studyFields.dicomReason')}
              />
            );
          }}
        />
        <FormField
          name="dicomDescription"
          defaultValue={study.dicomDescription || ''}
          label={t('study.studyFields.dicomDescription')}
          control={control}
          error={errors.dicomDescription}
          render={({ field }) => {
            return (
              <Textarea
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('study.studyFields.dicomDescription')}
              />
            );
          }}
        />
      </fieldset>
      <Accordion>{seriesForms}</Accordion>
      <div className="flex justify-end mt-10">
        <Button className="mr-6" color={ButtonColor.primaryAlternative} onClick={onCancel} isRounded>
          {t('viewer.report.cancelBtn')}
        </Button>
        <Button type={ButtonType.submit} disabled={isDisabled} isDisabled={isDisabled} isRounded>
          {t('general.actions.saveChanges')}
        </Button>
      </div>
    </form>
  );
};

interface SerieFormProps {
  /**
   * Set serie data to edit
   */
  serie: SeriesType;
  /**
   * Set class to serie form
   */
  className?: string;
  /**
   * Set position into array
   */
  index: number;
  /**
   * Set if form is disabled
   */
  isDisabled?: boolean;
  /**
   * Form controller from use Form.
   */
  control: Control<any>;
  /**
   * Pass list of error field from controller.
   */
  errors: DeepMap<FieldValues, FieldError>;
}

const SerieForm: React.FC<SerieFormProps> = ({ className, control, errors, index, serie, isDisabled = false }) => {
  const { t } = useTranslation();
  return (
    <fieldset className={`${className} grid gap-8 grid-cols-2 text-left text-basic-white`} disabled={isDisabled}>
      <FormField
        name={`series.${index}.bodyPart`}
        defaultValue={(serie.bodyPart.name as string) || ''}
        label={t('study.serieFields.bodyPart')}
        control={control}
        error={errors.series?.length && errors.series[index]?.bodyPart}
        render={({ field }) => {
          return (
            <BasicInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder={t('study.serieFields.bodyPart')}
            />
          );
        }}
      />
      <FormField
        name={`series.${index}.dicomOperatorsName`}
        defaultValue={serie.dicomOperatorsName || ''}
        label={t('study.serieFields.dicomOperatorsName')}
        control={control}
        error={errors.series?.length && errors.series[index]?.dicomOperatorsName}
        render={({ field }) => {
          return (
            <BasicInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder={t('study.serieFields.dicomOperatorsName')}
            />
          );
        }}
      />
      <FormField
        name={`series.${index}.dicomDescription`}
        defaultValue={serie.dicomDescription || ''}
        label={t('study.serieFields.dicomDescription')}
        control={control}
        error={errors.series?.length && errors.series[index]?.dicomDescription}
        render={({ field }) => {
          return (
            <BasicInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder={t('study.serieFields.dicomDescription')}
            />
          );
        }}
      />
      <FormField
        name={`series.${index}.dicomPerformingPhysicianInstitution`}
        defaultValue={serie.dicomPerformingPhysicianInstitution || ''}
        label={t('study.studyFields.facility')}
        control={control}
        error={errors.dicomPerformingPhysicianInstitution}
        render={({ field }) => {
          return (
            <BasicInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder={t('study.studyFields.facility')}
            />
          );
        }}
      />
      <FormField
        name={`series.${index}.id`}
        defaultValue={serie.id}
        control={control}
        error={errors.series?.length && errors.series[index]?.id}
        render={({ field }) => <input className="w-0 h-0" name={field.name} value={field.value} type="hidden"></input>}
      />
    </fieldset>
  );
};
