import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import { MILIMETER_MEASUREMENT_UNIT, PIXELS_MEASUREMENT_UNIT, PIXELS_NEAR_TOOL } from '~/constants';
import { InteractionTypes } from './cornestone.helpers';
import { getLineMidpoint, findPerpendicularPoint, getIntersection, distanceBetweenPoints, calcAngle } from '../plane';
import { getEnabledElementControlled } from './getEnabledElementControlled';
import { transformUnits } from '@eva-pacs/core';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const drawLine = csTools.importInternal('drawing/drawLine');
const drawCircle = csTools.importInternal('drawing/drawCircle');
const setShadow = csTools.importInternal('drawing/setShadow');
const draw = csTools.importInternal('drawing/draw');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const drawTextBox = csTools.importInternal('drawing/drawTextBox');
const moveNewHandle = csTools.importInternal('manipulators/moveNewHandle');
const { angleCursor } = csTools.importInternal('tools/cursors');
const lineSegDistance = csTools.importInternal('util/lineSegDistance');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const roundToDecimal = csTools.importInternal('util/roundToDecimal');
const throttle = csTools.importInternal('util/throttle');
const triggerEvent = csTools.importInternal('util/triggerEvent');
const drawLinkedTextBox = csTools.importInternal('drawing/drawLinkedTextBox');

enum MEASUREMENT_STEPS {
  START = 'START',
  CIRCLE = 'CIRCLE',
  LINE_N = 'LINE_N',
  LINE_M = 'LINE_M',
  LINE_E = 'LINE_E',
}

/**
 * @public
 * @class GoniometryTool
 * @author Mauricio Campos <mauricio.campos@evacenter.com>
 * @memberof Tools.Goniometry
 * @extends BaseAnnotationTool
 * Created at 2023-10-31
 */
export default class Goniometry extends BaseAnnotationTool {
  throttledUpdateCachedStats: any;
  name: string;

  constructor(props = {}) {
    super(props, {
      supportedInteractionTypes: [InteractionTypes.MOUSE, InteractionTypes.TOUCH],
      svgCursor: angleCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
    });

    this.name = 'Goniometry';
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: '',
      step: MEASUREMENT_STEPS.START,
      handles: {
        circleCenter: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        circleEnd: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  /**
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    if (data.visible === false || !data.lines) return false;
    const lineColision = Object.keys(data.lines).find((lineName) => {
      if (!data.lines[lineName]) return false;
      return lineSegDistance(element, data.lines[lineName].start, data.lines[lineName].end, coords) < PIXELS_NEAR_TOOL;
    });
    const pointColision = Object.keys(data.handles).find((handleName) => {
      const probeCoords = cornerstone.pixelToCanvas(element, data.handles[handleName]);
      return cornerstoneMath.point.distance(probeCoords, coords) < PIXELS_NEAR_TOOL;
    });
    return lineColision || pointColision;
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
    const { circleCenter, lineNStart, lineNEnd, lineMStart, lineMEnd, lineEStart, lineEEnd } = data.handles;
    const pointsList = [circleCenter, lineNStart, lineNEnd, lineMStart, lineMEnd, lineEStart, lineEEnd];
    if (pointsList.some((point) => !point)) return;

    const lineNMidPoint = getLineMidpoint(lineNStart, lineNEnd);
    const lineMMidPoint = getLineMidpoint(lineMStart, lineMEnd);

    const perpendicularPoint = findPerpendicularPoint(lineEStart, lineEEnd, lineMMidPoint);
    const lineEintersectionPoint = getIntersection(lineMMidPoint, perpendicularPoint, lineEStart, lineEEnd);

    const length = distanceBetweenPoints(lineNMidPoint, circleCenter, rowPixelSpacing, colPixelSpacing);

    if (!lineEintersectionPoint) return;
    const angle = calcAngle(lineNMidPoint, lineEintersectionPoint, lineEintersectionPoint, circleCenter);
    const sufix = !rowPixelSpacing || !colPixelSpacing ? PIXELS_MEASUREMENT_UNIT : MILIMETER_MEASUREMENT_UNIT;
    data.length = `${roundToDecimal(length, 2)} ${sufix}`;
    data.angle = `${roundToDecimal(angle, 2)}°`;
    data.invalidated = false;
  }

  getIncomplete(element) {
    const toolState = csTools.getToolState(element, this.name);

    if (toolState && Array.isArray(toolState.data)) return toolState.data.find(({ complete }) => complete === false);
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let toMoveHandle;
    const doneMovingCallback = (step, measurement, data = { handleName: '' }) => (success) => {
      if (!success) {
        csTools.removeToolState(element, this.name, measurement);
        return;
      }
      if (data.handleName === 'circleEnd') {
        // By placing the second handler of the circle we keep the distance with respect to the center
        const { circleCenter, circleEnd } = measurement.handles;
        circleEnd.distanceFromCenterX = circleCenter.x - circleEnd.x;
        circleEnd.distanceFromCenterY = circleCenter.y - circleEnd.y;
        circleEnd.placed = true;
      }
      measurement.step = step;
      if (step === MEASUREMENT_STEPS.LINE_N) {
        const eventType = csTools.EVENTS.MEASUREMENT_MODIFIED;
        measurement.complete = true;
        const newEventData = {
          toolName: this.name,
          element,
          measurementData: measurement,
        };
        triggerEvent(element, eventType, newEventData);
      }
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    if (!pendingMeasurement) {
      const measurementData = this.createNewMeasurement(eventData);
      csTools.addToolState(element, this.name, measurementData);
      toMoveHandle = measurementData.handles.circleEnd;
      cornerstone.updateImage(element);

      moveNewHandle(
        eventData,
        this.name,
        measurementData,
        toMoveHandle,
        super.options,
        interactionType,
        doneMovingCallback(MEASUREMENT_STEPS.CIRCLE, measurementData, { handleName: 'circleEnd' }),
      );
      return;
    }
    if (pendingMeasurement.step === MEASUREMENT_STEPS.CIRCLE) {
      pendingMeasurement.handles.lineEStart = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      pendingMeasurement.handles.lineEEnd = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      cornerstone.updateImage(element);
      toMoveHandle = pendingMeasurement.handles.lineEEnd;

      moveNewHandle(
        eventData,
        this.name,
        pendingMeasurement,
        toMoveHandle,
        super.options,
        interactionType,
        doneMovingCallback(MEASUREMENT_STEPS.LINE_E, pendingMeasurement),
      );
      return;
    }
    if (pendingMeasurement.step === MEASUREMENT_STEPS.LINE_E) {
      pendingMeasurement.handles.lineMStart = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      pendingMeasurement.handles.lineMEnd = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      cornerstone.updateImage(element);
      toMoveHandle = pendingMeasurement.handles.lineMEnd;

      moveNewHandle(
        eventData,
        this.name,
        pendingMeasurement,
        toMoveHandle,
        super.options,
        interactionType,
        doneMovingCallback(MEASUREMENT_STEPS.LINE_M, pendingMeasurement),
      );
      return;
    }
    if (pendingMeasurement.step === MEASUREMENT_STEPS.LINE_M) {
      pendingMeasurement.handles.lineNStart = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      pendingMeasurement.handles.lineNEnd = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      cornerstone.updateImage(element);
      toMoveHandle = pendingMeasurement.handles.lineNEnd;

      moveNewHandle(
        eventData,
        this.name,
        pendingMeasurement,
        toMoveHandle,
        super.options,
        interactionType,
        doneMovingCallback(MEASUREMENT_STEPS.LINE_N, pendingMeasurement),
      );
      return;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);
  }

  onMeasureModified(evt) {
    const { element } = evt.detail;
    const image = getEnabledElementControlled(element)?.image;

    if (!image) return;

    if (evt.detail.toolName !== this.name) return;

    const data = evt.detail.measurementData;
    // Update textbox stats
    if (data.invalidated === true) {
      if (data.angle && data.length) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    const { angle, length } = data;

    data.value = length && angle ? `${angle}, ${length}` : '';
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving, renderDashed } = super.configuration;
    const toolData = csTools.getToolState(evt.currentTarget, this.name);
    // If we have no toolData for this element, return immediately as there is nothing to do
    if (!toolData) return;

    const context = getNewContext(eventData.canvasContext.canvas);
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    const font = csTools.textStyle.getFont();

    // We have tool data for this element - iterate over each one and draw it
    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) continue;

      const {
        circleCenter,
        circleEnd,
        lineEStart,
        lineEEnd,
        lineMStart,
        lineMEnd,
        lineNStart,
        lineNEnd,
      } = data.handles;

      draw(context, (context) => {
        setShadow(context, super.configuration);
        // Differentiate the color of activation tool
        const color = csTools.toolColors.getColorIfActive(data);

        const Clabel = 'C';
        const Elabel = 'E';
        const Nlabel = 'N';
        const Mlabel = 'M';

        const lineOptions = { color, lineDash: null };
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (circleCenter?.moving && circleEnd) {
          // If circlecenter move, also then move circleEnd handler
          circleEnd.x = circleCenter.x - circleEnd.distanceFromCenterX;
          circleEnd.y = circleCenter.y - circleEnd.distanceFromCenterY;
        }

        if (circleEnd?.moving) {
          // If we move the second handler of the circle we update its distance from the center
          circleEnd.distanceFromCenterX = circleCenter.x - circleEnd.x;
          circleEnd.distanceFromCenterY = circleCenter.y - circleEnd.y;
        }

        const pointsList = [circleCenter, circleEnd, lineEStart, lineEEnd, lineMStart, lineMEnd, lineNStart, lineNEnd];
        pointsList.forEach((handler, handlerIndex) => {
          if (!handler) return;
          const localHandleOptions = { ...handleOptions };
          if (
            (data.step === MEASUREMENT_STEPS.CIRCLE && handlerIndex === 0) || //Starting first line: set color to circle center handler
            (data.step === MEASUREMENT_STEPS.LINE_E && handlerIndex === 3) || //Starting second line: set color to end of first line handler
            (data.step === MEASUREMENT_STEPS.LINE_M && handlerIndex === 5) // Starting third line: set color to end of second line handler
          ) {
            localHandleOptions.color = '#00E0FF';
          }
          if (super.configuration.drawHandles) drawHandles(context, eventData, { end: handler }, localHandleOptions);
        });

        if (renderDashed) lineOptions.lineDash = lineDash;
        const circleOptions = { color };

        if (!circleCenter || !circleEnd) return;
        const centerCircleCanvasCords = cornerstone.pixelToCanvas(eventData.element, circleCenter);

        const endCanvas = cornerstone.pixelToCanvas(eventData.element, circleEnd);
        const circleRadious = Math.sqrt(
          Math.pow(endCanvas.x - centerCircleCanvasCords.x, 2) + Math.pow(endCanvas.y - centerCircleCanvasCords.y, 2),
        );
        drawCircle(context, eventData.element, circleCenter, circleRadious, circleOptions, 'pixel');

        const CLabelCanvasCords = cornerstone.pixelToCanvas(eventData.element, circleCenter);
        if (!circleEnd.moving || circleEnd.placed)
          drawTextBox(context, Clabel, CLabelCanvasCords.x + 2, CLabelCanvasCords.y - 20, color);
        data.lines = {};
        if (!lineEStart || !lineEEnd) return;
        drawLine(context, eventData.element, lineEStart, lineEEnd, lineOptions);
        data.lines.lineE = { start: lineEStart, end: lineEEnd };

        const ELabelCanvasCords = cornerstone.pixelToCanvas(eventData.element, lineEEnd);
        if (!lineEEnd.moving) drawTextBox(context, Elabel, ELabelCanvasCords.x + 2, ELabelCanvasCords.y - 20, color);

        if (!lineMStart || !lineMEnd) return;
        drawLine(context, eventData.element, lineMStart, lineMEnd, lineOptions);
        data.lines.lineM = { start: lineMStart, end: lineMEnd };

        const MLabelCanvasCords = cornerstone.pixelToCanvas(eventData.element, lineMEnd);
        if (!lineMEnd.moving) drawTextBox(context, Mlabel, MLabelCanvasCords.x + 2, MLabelCanvasCords.y - 20, color);

        if (!lineNStart || !lineNEnd) return;
        drawLine(context, eventData.element, lineNStart, lineNEnd, lineOptions);
        data.lines.lineN = { start: lineNStart, end: lineNEnd };

        const NLabelCanvasCords = cornerstone.pixelToCanvas(eventData.element, lineNEnd);
        if (!lineNEnd.moving) drawTextBox(context, Nlabel, NLabelCanvasCords.x + 2, NLabelCanvasCords.y - 20, color);

        if (!data.complete) return;

        const lineMMidPoint = getLineMidpoint(lineMStart, lineMEnd);

        const lineNMidPoint = getLineMidpoint(lineNStart, lineNEnd);
        drawLine(context, eventData.element, circleCenter, lineNMidPoint, { lineDash });
        data.lines.circleLineN = { start: circleCenter, end: lineNMidPoint };

        const perpendicularPoint = findPerpendicularPoint(lineEStart, lineEEnd, lineMMidPoint);
        const lineEintersectionPoint = getIntersection(lineMMidPoint, perpendicularPoint, lineEStart, lineEEnd);
        if (!lineEintersectionPoint) return;

        drawLine(context, eventData.element, circleCenter, lineEintersectionPoint);
        drawLine(context, eventData.element, lineMMidPoint, lineEintersectionPoint);
        drawLine(context, eventData.element, lineNMidPoint, lineEintersectionPoint);
        data.lines.circleLineE = { start: circleCenter, end: lineEintersectionPoint };
        data.lines.lineMLineE = { start: lineMMidPoint, end: lineEintersectionPoint };
        data.lines.lineNLineE = { start: lineNMidPoint, end: lineEintersectionPoint };

        if (!data.handles.textBox.hasMoved) {
          const textCoords = lineEEnd;
          context.font = font;
          data.handles.textBox.x = textCoords.x + 60;
          data.handles.textBox.y = textCoords.y;
        }
        const lineWidth = csTools.toolStyle.getToolWidth();
        const textBoxSpacingValue = 2;
        const textBoxAnchorPoints = () => [lineEEnd, lineEEnd];
        const stats = [`Goniometría`, `Ángulo = ${data.angle}`, `Longitud = ${transformUnits(data.length)}`];

        drawLinkedTextBox(
          context,
          eventData.element,
          data.handles.textBox,
          stats,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          textBoxSpacingValue,
          true,
        );
      });
    }
  }

  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }

  passiveCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }

  enabledCallback(element) {
    element.removeEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }

  disabledCallback(element) {
    element.removeEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }
}
