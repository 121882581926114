import React from 'react';
/**
 * Use recursiveChildrenMap to iterate recursively over all the children nodes.
 * Reference: https://stackoverflow.com/questions/32916786/react-children-map-recursively
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-04-28
 */
export var recursiveChildrenMap = function (children, callback) {
    return React.Children.map(children, function (child) {
        if (!React.isValidElement(child))
            return child;
        if (child.props.children) {
            var props = {
                children: recursiveChildrenMap(child.props.children, callback),
            };
            child = React.cloneElement(child, props);
        }
        return callback(child);
    });
};
