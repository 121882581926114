import { useEffect } from 'react';
import * as yup from 'yup';
import { EVACENTER_URL } from '../constants/products';
import { CrossPacsActionType, EventMessage } from '../constants/eventMessage';
const { ValidationError } = yup;

export const eventMessageSchema = yup.object<Record<keyof EventMessage, yup.AnySchema>>({
  actionType: yup.string().required(),
  payload: yup.object(),
  time: yup.number().required(),
});

interface UsePostMessageListenerOpts {
  /**
   * Specify an action type to filter from incoming messages
   */
  actionType?: CrossPacsActionType;
  /**
   * Specify the origin from where events should be allowed to receive messages
   */
  allowedOrigin?: string;
  /**
   * Callback to manage when a message is received
   */
  onMessageReceived: (eventMessage: EventMessage) => void;
  /**
   * Callback to manage when an error ocurred
   */
  onError?: (errorMessage?: string) => void;
}

/**
 * Use `usePostMessageListener` hook to setup a listener when a `message` is received using `postMessage` API
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-06-01
 */
export const usePostMessageListener = ({
  actionType,
  allowedOrigin: _allowedOrigin = EVACENTER_URL,
  onError,
  onMessageReceived,
}: UsePostMessageListenerOpts) => {
  const allowedOrigin = _allowedOrigin ?? window.location.origin;

  const handleOnMessageReceived = async (messageEvent: MessageEvent<EventMessage>) => {
    const messageEventOrigin = messageEvent?.origin;

    if (messageEventOrigin !== allowedOrigin) return;

    try {
      const data = await eventMessageSchema.validate(messageEvent.data);

      if (actionType && actionType !== data.actionType) return;

      onMessageReceived(data);
    } catch (error) {
      const handleOnError = (errorMessage?: string) => {
        if (onError) onError(errorMessage);
      };

      if (error instanceof ValidationError) {
        handleOnError('The messageEvent data is invalid');
        return;
      }

      handleOnError();
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('message', handleOnMessageReceived);

    return () => window.removeEventListener('message', handleOnMessageReceived);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
