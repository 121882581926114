import create, { SetState, GetState } from 'zustand';
import { immer } from '~/src/store';
import { EVA_PRODUCT } from '../constants/products';

export interface PermissionGroup {
  id: string;
  name: string;
  isVisible: boolean;
}

export interface ProductPermissionGroup {
  id: string;
  product: string;
  slug: EVA_PRODUCT;
  groups: Array<PermissionGroup>;
  hasAccess: boolean;
}

export interface ProductPermissionGroupsStore {
  productsAreReady: boolean;
  productPermissionGroups: Array<ProductPermissionGroup>;
  setProductsAreReady: (productsAreReady: boolean) => void;
  setProductPermissionGroups: (productPermissionGroups: Array<ProductPermissionGroup>) => void;
  getProductPermissionGroupByProductSlug: (product: EVA_PRODUCT | string) => ProductPermissionGroup | undefined;
  getProductPermissionGroupByGroupId: (groupId: string) => ProductPermissionGroup | undefined;
}

const productPermissionGroupsStore = (
  set: SetState<ProductPermissionGroupsStore>,
  get: GetState<ProductPermissionGroupsStore>,
): ProductPermissionGroupsStore => ({
  productsAreReady: false,
  productPermissionGroups: [],
  setProductsAreReady: (productsAreReady) => {
    return set((state) => {
      state.productsAreReady = productsAreReady;
    });
  },
  setProductPermissionGroups: (productPermissionGroups) => {
    return set((state) => {
      state.productPermissionGroups = productPermissionGroups;
    });
  },
  getProductPermissionGroupByProductSlug: (product) =>
    get().productPermissionGroups.find((productPermissionGroup) => productPermissionGroup.slug === product),
  getProductPermissionGroupByGroupId: (groupId: string) => {
    let result: ProductPermissionGroup | undefined = undefined;
    get().productPermissionGroups.forEach((productPermissionGroup) => {
      const targetGroup = productPermissionGroup.groups.find((group) => group.id === groupId);
      if (targetGroup) result = { ...productPermissionGroup };
    });
    return result;
  },
});

export const useProductPermissionGroupsStore = create<ProductPermissionGroupsStore>(
  immer(productPermissionGroupsStore),
);
