var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useState, useImperativeHandle, forwardRef, useMemo } from 'react';
import { TranscriberV2, getCookie, ACCESS_TOKEN_KEY } from '@eva-pacs/core';
import { Button, IconCatalog, ButtonVariant, ButtonSize } from '@evacenter/eden';
/**
 * Button to record audio and convert it to text. This uses our new transcriptions API (Transcriberv2).
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2024-01-24
 */
export var RecordAudioButtonV2 = forwardRef(function (_a, ref) {
    var className = _a.className, onRecord = _a.onRecord, onTerminate = _a.onTerminate, studyId = _a.studyId, reportField = _a.reportField, organizationId = _a.organizationId, onClick = _a.onClick, onSuccessfullConnection = _a.onSuccessfullConnection;
    var _b = useState(false), isRecording = _b[0], setIsRecording = _b[1];
    var _c = useState(false), isDisabled = _c[0], setIsDisabled = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var currentSessionToken = getCookie(ACCESS_TOKEN_KEY);
    var t = useTranslation().t;
    var classes = {
        startDictateButtonClass: cn('right-0 absolute w-8', className, {
            'hide-record-button': isRecording,
            'show-record-button': !isRecording,
        }),
        stopDictateButtonClass: cn('right-0 absolute w-8', className, {
            'show-record-button': isRecording,
            'hide-record-button': !isRecording,
        }),
    };
    var handleButtonLoadingState = function () {
        setIsLoading(true);
        setIsDisabled(true);
    };
    var handleButtonFinishLoadingState = function () {
        setIsLoading(false);
        setIsDisabled(false);
    };
    var transcriberSink = useMemo(function () {
        return {
            onTranscript: function (content) {
                onRecord(content);
            },
            onError: function (message) {
                setIsRecording(false);
                console.warn(message);
            },
            onStopping: function () {
                // Since we stop the connection lazy, we need to disable the button
                handleButtonLoadingState();
            },
            onSuccessfullConnection: function () {
                setIsRecording(true);
                handleButtonFinishLoadingState();
                onSuccessfullConnection();
            },
            onTerminate: function (message) {
                if (onTerminate)
                    onTerminate(message);
                handleButtonFinishLoadingState();
                setIsRecording(false);
            },
            studyId: studyId,
            reportField: reportField,
            organizationId: organizationId,
            sessionToken: currentSessionToken,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSessionToken, reportField, studyId]);
    var transcriber = useMemo(function () {
        return new TranscriberV2(transcriberSink);
    }, [transcriberSink]);
    var handleOnClick = function () {
        handleButtonLoadingState();
        if (onClick)
            onClick();
        if (isRecording) {
            return transcriber.stop();
        }
        try {
            transcriber.start();
        }
        catch (error) {
            handleButtonFinishLoadingState();
        }
    };
    useImperativeHandle(ref, function () { return ({
        onClickButton: function () { return handleOnClick(); },
    }); });
    var commonButtonProps = {
        isDisabled: isDisabled,
        disabled: isDisabled,
        isLoading: isLoading,
        size: ButtonSize.xs,
        variant: ButtonVariant.ghostNeutral,
        onClick: handleOnClick,
    };
    return (
    // This 2 buttons are intended to support the transition ease-in ease-out;
    // from changing into a different state.
    React.createElement(React.Fragment, null,
        React.createElement(Button, __assign({ "data-testid": "start-button" }, commonButtonProps, { className: classes.startDictateButtonClass, endIcon: IconCatalog.voice }), t('report.actions.startDictate')),
        React.createElement(Button, __assign({ "data-testid": "stop-button" }, commonButtonProps, { className: classes.stopDictateButtonClass, endIcon: IconCatalog.stopFilled }), t('report.actions.stopDictate'))));
});
