import { throwServerError } from '@apollo/client';
var hasOwnProperty = Object.prototype.hasOwnProperty;
export var parseAndCheckHttpResponse = function (operations) {
    return function (response) {
        return response
            .text()
            .then(function (bodyText) {
            try {
                return JSON.parse(bodyText);
            }
            catch (err) {
                var parseError = err;
                parseError.name = 'ServerParseError';
                parseError.response = response;
                parseError.statusCode = response.status;
                parseError.bodyText = bodyText;
                throw parseError;
            }
        })
            .then(function (result) {
            if (response.status >= 300) {
                // Network error
                throwServerError(response, result, "Response not successful: Received status code ".concat(response.status));
            }
            if (!Array.isArray(result) && !hasOwnProperty.call(result, 'data') && !hasOwnProperty.call(result, 'errors')) {
                // Data error
                throwServerError(response, result, "Server response was missing for query '".concat(Array.isArray(operations) ? operations.map(function (op) { return op.operationName; }) : operations.operationName, "'."));
            }
            return result;
        });
    };
};
