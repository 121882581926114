export function loadExternalScript(id: string, url: string, callback?: (this: GlobalEventHandlers, ev: Event) => void) {
  const script = createScriptElement(id, url, { async: true });
  if (!script) return;
  if (callback) script.onload = callback;
  document.head.appendChild(script);
}

interface ScriptElementOptions {
  type?: string;
  async?: boolean;
  defer?: boolean;
}

export const createScriptElement = (id: string, source: string, options?: ScriptElementOptions) => {
  const { type, async, defer } = options ?? {};
  const isElementCreated = document.getElementById(id);
  if (isElementCreated) return null;
  const scriptElement = document.createElement('script');
  scriptElement.id = id;
  scriptElement.src = source;
  // eslint-disable-next-line i18next/no-literal-string
  scriptElement.type = type ?? 'text/javascript';
  scriptElement.async = Boolean(async);
  scriptElement.defer = Boolean(defer);
  return scriptElement;
};

const createVoidElement = (element: string, id: string, content: string) => {
  const isElementCreated = document.getElementById(id);
  if (isElementCreated) return null;

  const voidElement = document.createElement(element);
  voidElement.id = id;
  voidElement.innerHTML = content;
  return voidElement;
};

export const HeadService = () => {
  const addGoogleTagManager = () => {
    if (!process.env?.REACT_APP_GTM_API_KEY?.length) return;
    // eslint-disable-next-line i18next/no-literal-string
    const googleTagId = 'GTM-script-loader';
    const googleTagScript = createVoidElement(
      // eslint-disable-next-line i18next/no-literal-string
      'script',
      googleTagId,
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_API_KEY}');`,
    );
    if (googleTagScript) document.head.appendChild(googleTagScript);

    // eslint-disable-next-line i18next/no-literal-string
    const googleTagBodyId = 'GTMN-script-loader';
    // eslint-disable-next-line i18next/no-literal-string
    const googleTagBodyScript = createVoidElement('noscript', googleTagBodyId, '');
    if (!googleTagBodyScript) return;
    const googleTagIframe = document.createElement('iframe');
    googleTagIframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_API_KEY}`;
    googleTagIframe.height = '0';
    googleTagIframe.width = '0';
    // eslint-disable-next-line i18next/no-literal-string
    googleTagIframe.style.cssText = 'display:none;visibility:hidden';
    googleTagBodyScript.appendChild(googleTagIframe);
    document.body.appendChild(googleTagBodyScript);
  };

  return { addGoogleTagManager };
};
