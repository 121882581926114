import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonType, BasicInput, FormField, PhoneObjType } from '@eva-pacs/ui';
import { getEmailField, getPhoneField, getTextField } from '@eva-pacs/core';
import { PhoneInputWrapper } from '~/components/shared/PhoneInputWrapper';

export interface SendDICOMViewerFormFields {
  email: string;
  phoneObj: PhoneObjType;
}

export interface SendDICOMViewerFormProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * If set to true, the button will be disabled while the form is loading.
   */
  isLoading?: boolean;

  /**
   * Pass default fields value
   */
  defaultFieldsValue?: SendDICOMViewerFormFields;

  /**
   * The callback to get notified when the form's fields are valid
   */
  onSubmit: (email: string, phoneObj: { phone: string; phoneCode: string }) => void;
}

/**
 * SendDICOMViewerForm
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-10-28
 */
export const SendDICOMViewerForm: React.FC<SendDICOMViewerFormProps> = ({
  className,
  isLoading = false,
  defaultFieldsValue,
  onSubmit,
}) => {
  const formClass = cn(className);
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: getEmailField(t, false, true),
    phoneObj: yup.object({
      phone: getPhoneField(t),
      phoneCode: getTextField(t),
      isoCode: getTextField(t),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SendDICOMViewerFormFields>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmitForm = async (data: SendDICOMViewerFormFields) => {
    await onSubmit(data.email, data.phoneObj);
  };

  return (
    <form className={formClass} onSubmit={handleSubmit(onSubmitForm)}>
      <fieldset className="text-left text-basic-white">
        <FormField
          className="mb-6"
          name="email"
          defaultValue={defaultFieldsValue?.email || ''}
          label={t('auth.formFields.email')}
          control={control}
          error={errors.email}
          render={({ field }) => (
            <BasicInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              isDisabled={isLoading}
              placeholder={t('auth.formFields.email')}
            />
          )}
        />
        <FormField
          name="phoneObj"
          defaultValue={defaultFieldsValue?.phoneObj}
          label={t('auth.formFields.phoneNumber')}
          control={control}
          error={errors.phoneObj?.phone}
          render={({ field }) => (
            <PhoneInputWrapper
              id={field.name}
              name={field.name}
              value={field.value.phone}
              field={field}
              isDisabled={isLoading}
              placeholder={t('auth.formFields.phoneNumber')}
              defaultIsoCode={field.value.isoCode}
            />
          )}
        />
      </fieldset>

      <Button className="mt-6" type={ButtonType.submit} isLoading={isLoading} isFullWidth>
        {t('study.sendDICOMViewer')}
      </Button>
    </form>
  );
};

export default SendDICOMViewerForm;
