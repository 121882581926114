import React from 'react';
import cn from 'classnames';
import { TabsProvider } from '@eva-pacs/core';
/**
 * Use Tabs to allow users to navigate easily between views within the same context.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-06-08
 */
export var Tabs = function (_a) {
    var defaultSelected = _a.defaultSelected, className = _a.className, _b = _a.ariaLabel, ariaLabel = _b === void 0 ? 'Tabs' : _b, children = _a.children;
    var tabsClass = cn(className, 'flex flex-col');
    return (React.createElement(TabsProvider, { ariaLabel: ariaLabel, className: tabsClass, defaultSelected: defaultSelected },
        React.createElement("div", { className: "relative flex flex-col h-full" }, children)));
};
