import cn from 'classnames';
/* Class assignment */
export var actionClass = function (className) {
    return cn(className, 
    /* Action (base) */
    'flex flex-col items-center', 'p-1.5 w-14', 'text-basic-white bg-primary-500 hover:bg-primary-600', 'rounded-lg', 'cursor-pointer');
};
export var actionLabelClass = cn(
/* Action label (base) */
'text-tiny font-semi-bold');
