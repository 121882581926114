const sequences = {
  C: 7,
  T1: 6,
  T2: 6,
  L: 5,
  S: 5,
};

export const formatMarker = (key: string, index: number, mainIndex: number) =>
  key.startsWith('T') ? `T${index + 1 + (mainIndex === 1 ? 0 : 6)}` : `${key}${index + 1}`;

export const markerGroups = Object.entries(sequences).map(([key, value], mainIndex) => {
  return Array.from({ length: value }, (_, index) => {
    return formatMarker(key, index, mainIndex);
  });
});

export const Markers = Object.freeze(
  markerGroups.flat().reduce((acc, color) => {
    acc[color] = color;
    return acc;
  }, {} as { [key: string]: string }),
);
