import { StudyType, StudyWorklistType } from '@eva-pacs/client';
import { Study } from '~/src/types/Study';
import { basicPatientDTO } from '../study/patientDTO';
import { studyCriticalValueDto } from './criticalValue.dto';

/**
 * Generates an object model to be used as payload for create/update study mutations for a given studyData
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created 2023-01-20
 */
export const studyDTO = (
  study: StudyType | StudyWorklistType,
  enableEntranceAnimation = false,
  isSwapStudy = false,
): Study => {
  return {
    id: study.id,
    folio: study.folio as string,
    createdAt: study.createdAt,
    dicomDescription: study.dicomDescription as string,
    facilityId: study.facility?.id,
    facilityName: study.facility?.name,
    studyStatus: study.status,
    // flag to determine if the current item should add a class
    // for when an study is mounted.
    enableEntranceAnimation,
    isSwapStudy,
    urgencyLevel: study.urgencyLevel,
    facilityTimezone: study.facility?.timezone as string,
    reviewRequired: study.reviewRequired ?? false,
    reviewerPractitionerId: study.reviewerPractitioner?.id,
    reviewerPractitionerFullName: study.reviewerPractitioner?.fullName || '',
    modalities: study.modalities,
    dicomReason: study.dicomReason as string,
    dicomDateTime: study.dicomDateTime,
    referringPractitioner: {
      fullName: study.referringPractitioner?.fullName || '',
      id: study.referringPractitioner?.id,
    },
    dicomReferringPhysician: study.dicomReferringPhysician as string,
    dicomRequestingPhysician: study.dicomRequestingPhysician as string,
    dicomDateTimeEditable: study.dicomDateTimeEditable,
    practitionerAssignedId: study.practitionerAssigned?.id,
    practitionerAssignedName: study.practitionerAssigned?.user?.name as string,
    practitionerAssignedFullName: study.practitionerAssigned?.fullName || '',
    patientAge: study.patientStudyAge || 0,
    patientCurrentMonthAge: study.patientStudyAgeMonths || 0,
    patientCurrentDayAge: study.patientStudyAgeDays || 0,
    patient: study.patient ? basicPatientDTO(study.patient) : null,
    criticalValues: study.criticalValues?.map((cv) => studyCriticalValueDto(cv)) || [],
  };
};
