import { useApolloClient } from '@apollo/client';
import { useStudyListStore } from '~/src/store';
import {
  GetWorklistPositionsDocument,
  GetWorklistStudyByIdDocument,
  StudyPosition,
  StudyWorklistType,
} from '@eva-pacs/client';
import { StudyUpdateEvent, StudyUpdateType } from '~/components/StudyList/useStudyListUpdates';
import { useNotificationsStore, NotificationType } from '~/src/store/notifications-store';

export const useWorklistQueries = () => {
  const client = useApolloClient();
  const tableSortBy = useStudyListStore((state) => state.tableSortBy);
  const { setNotifications } = useNotificationsStore();

  // We are using client with async/await cause we need to use this nested query.
  // With hooks this is hard cause we would need to get use of refs to avoid closure issues.
  const getWorklistPositions = async (studyId: string) => {
    try {
      const response = await client.query({
        query: GetWorklistPositionsDocument,
        fetchPolicy: 'network-only',
        variables: {
          ordering: tableSortBy || `urgencyLevel,desc`,
          ids: [studyId],
        },
      });

      if (response?.data?.positions?.length) {
        const positions = response.data.positions[0] as StudyPosition;

        return positions;
      }

      setNotifications<StudyUpdateEvent>({
        type: NotificationType.StudyListUpdates,
        payload: {
          [studyId]: StudyUpdateType.error,
        },
      });
    } catch (error) {
      setNotifications<StudyUpdateEvent>({
        type: NotificationType.StudyListUpdates,
        payload: {
          [studyId]: StudyUpdateType.error,
        },
      });
    }
  };

  const fetchNextWorklistStudyById = async (studyId: string) => {
    try {
      const positions = await getWorklistPositions(studyId);
      if (!positions) return;
      const nextStudyId = positions?.nextId;
      // When there are no nextId, stop asking for next study.
      if (!nextStudyId) return;
      const newStudy = await fetchWorklistStudyById(nextStudyId);
      return newStudy as StudyWorklistType;
    } catch (error) {
      setNotifications<StudyUpdateEvent>({
        type: NotificationType.StudyListUpdates,
        payload: {
          [studyId]: StudyUpdateType.error,
        },
      });
    }
  };

  const fetchWorklistStudyById = async (studyId: string) => {
    try {
      const response = await client.query({
        query: GetWorklistStudyByIdDocument,
        fetchPolicy: 'network-only',
        variables: {
          idsIn: [studyId],
        },
      });

      if (!response.data.studyWorklist?.results) return;
      const newStudy = response.data.studyWorklist.results[0];
      if (!newStudy) return;

      return newStudy as StudyWorklistType;
    } catch (error) {
      setNotifications<StudyUpdateEvent>({
        type: NotificationType.StudyListUpdates,
        payload: {
          [studyId]: StudyUpdateType.error,
        },
      });
    }
  };

  return {
    getWorklistPositions,
    fetchWorklistStudyById,
    fetchNextWorklistStudyById,
  };
};
