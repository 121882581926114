import cn from 'classnames';
import { TextareaSize } from '.';
/* Class assignment */
export var textareaClass = function (className, textareaSize) {
    return cn(className, 
    /* textarea (base) */
    'space-x-2 w-full appearance-none outline-none resize-y', 'transition duration-100 ease-out rounded-lg', 'bg-gray-600', 'placeholder-light-gray-600 text-basic-white', 
    /* textarea--md */
    textareaSize === TextareaSize.md && 'text-label-md h-24 py-3 px-4 leading-5', textareaSize === TextareaSize.sm && 'text-label-md h-10 px-3 leading-5');
};
