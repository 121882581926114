import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { disableAllMeasurements } from '~/utils/measurements/disableAllMeasurements';

/**
 * Saves the measurement
 *
 * @param id
 * @param data
 * @param updateMeasurementInfo
 */
const handleSaveMeasurement = (id, data, updateMeasurementInfo) => {
  updateMeasurementInfo(id as string, data);
};

/**
 * Removes measurement from the tool state and from the global state.
 *
 * @param measurementId
 * @param measurement
 * @param enabledElement
 * @param removeMeasurementById
 * @returns
 */
const handleRemoveMeasurement = (measurementId, measurement, enabledElement, removeMeasurementById) => {
  if (typeof enabledElement.dom !== 'undefined') {
    // Get the tool state from the cornerstone tool
    const toolState = cornerstoneTools.getElementToolStateManager(enabledElement.dom);
    // Ge the current tool Data
    const toolData = toolState.get(enabledElement.dom, measurement.tool);

    // Backend will return measurement data as a string.
    // Will handle the data parse.
    const measurementData = typeof measurement.data === 'string' ? JSON.parse(measurement.data)[0] : measurement.data;
    if (!toolData?.data?.length) return;

    // Find this tool data
    const indexOfData = toolData.data.findIndex((data) => data.uuid === measurementData.uuid);
    if (indexOfData !== -1) {
      toolData.data.splice(indexOfData, 1);

      // Remove measurement from state
      removeMeasurementById(measurementId);
    }
    cornerstone.updateImage(enabledElement.dom);
  }
};

/**
 * This will restore active measurements, used then you change the series.
 */
const handleRestoreActiveMeasurements = () => {
  disableAllMeasurements();
  updateAllImages();
};

/**
 * This will reset the tool state of the given tool.
 */
const manageResetToolState = (element: HTMLElement, tool: string) => {
  const toolState = cornerstoneTools.getToolState(element, tool);
  const activeToolState = toolState?.data?.find((toolData) => toolData.active === true);
  if (!activeToolState) return;

  cornerstoneTools.removeToolState(element, tool, activeToolState);
  updateAllImages();

  cornerstoneTools.store.state.isToolLocked = false;
  const toolInstances = cornerstoneTools.store.state.tools.filter((toolObject) => toolObject.name === tool);
  toolInstances.forEach((toolInstance) => (toolInstance.mode = 'active'));
};

const updateAllImages = () => {
  cornerstone.getEnabledElements().forEach((enabledElement) => {
    if (enabledElement.image) cornerstone.updateImage(enabledElement.element);
  });
};

export {
  handleSaveMeasurement,
  handleRemoveMeasurement,
  handleRestoreActiveMeasurements,
  manageResetToolState,
  updateAllImages,
};
