import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
export var Modals;
(function (Modals) {
    Modals["ConfirmationModal"] = "ConfirmationModal";
    Modals["GenericModal"] = "GenericModal";
    Modals["NoteModal"] = "NoteModal";
    Modals["PreviewQRModal"] = "PreviewQRModal";
    Modals["SuccessModal"] = "SuccessModal";
    Modals["LoadingModal"] = "LoadingModal";
    Modals["ErrorModal"] = "ErrorModal";
})(Modals || (Modals = {}));
var ModalContext = createContext({});
export var ModalProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), currentModal = _b[0], setCurrentModal = _b[1];
    var modalProviderValue = {
        currentModal: currentModal,
        setCurrentModal: setCurrentModal,
    };
    return _jsx(ModalContext.Provider, { value: modalProviderValue, children: children });
};
export var useModal = function () { return useContext(ModalContext); };
