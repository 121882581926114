import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Tab, TabPanel, Tabs, NativeSelectOption } from '@eva-pacs/ui';
import { StudyType, PatientType, useStudyQuery, useFacilitiesQuery } from '@eva-pacs/client';

import { StudyEditTab } from '../StudyEditTab';
import { PatientEditTab } from '../PatientEditTab';
import { useSessionStore } from '~/src/store';
import { CAN_EDIT_PATIENT, CAN_EDIT_STUDY, POLICIES } from '~/constants';
import { useGetAssignablePractitionerOptions } from '~/src/hooks/practitioners/useGetAssignablePractitionerOptions';

export interface StudyAndPatientFormModalProps {
  /**
   * Set classes to wrapper element
   */
  className?: string;
  /**
   * Set id to study to get data
   */
  studyId: string;
  /**
   * Callback to manage on close event
   */
  onCloseModal: () => void;
  /**
   * Callback to manage on default close event
   */
  onDefaultCancel: () => void;
}

/**
 * Modal content to edit study info and manage graphql connection
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-07-22
 */
export const StudyAndPatientFormModal: React.FC<StudyAndPatientFormModalProps> = ({
  className,
  studyId,
  onCloseModal,
  onDefaultCancel,
}) => {
  const { user } = useSessionStore();
  const { t } = useTranslation();

  /**
   * Verify authorization policies
   */
  const canUpdatePatient = POLICIES[CAN_EDIT_PATIENT](user);
  const canUpdateStudy = POLICIES[CAN_EDIT_STUDY](user);

  const [currentStudy, setCurrentStudy] = useState<StudyType | null>(null);
  const [currentFacilities, setCurrentFacilities] = useState<NativeSelectOption[]>([]);

  const { options: currentPractitioners } = useGetAssignablePractitionerOptions({});
  useStudyQuery({
    variables: {
      id: studyId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (!(response && response.study)) return;
      setCurrentStudy(response.study as StudyType);
    },
  });

  useFacilitiesQuery({
    onCompleted: (response) => {
      if (!(response.facilities && response.facilities.results && response.facilities.results.length > 0)) return;
      const tmpFacilities = response.facilities?.results.map((facility) => {
        return {
          value: facility?.id,
          label: facility?.name,
        };
      });
      setCurrentFacilities(tmpFacilities as NativeSelectOption[]);
    },
    fetchPolicy: 'network-only',
  });

  if (!currentStudy) return <Spinner />;

  return (
    <Tabs className={className} ariaLabel="Tabs" defaultSelected="studyEditTab">
      <div role="tablist" aria-label="TabList" className="flex mb-8 w-full border-b-2 border-gray-200 space-x-6">
        <Tab tabId="studyEditTab" className="py-3" activeClassName="border-b-2 border-primary-300">
          <span className="text-basic-white text-label-md font-semi-bold">{t('general.studyFormTitle')}</span>
        </Tab>
        <Tab tabId="patientEditTab" className="py-3" activeClassName="border-b-2 border-primary-300">
          <span className="text-basic-white text-label-md font-semi-bold">{t('general.patientFormTitle')}</span>
        </Tab>
      </div>
      <TabPanel tabId="studyEditTab">
        <StudyEditTab
          study={currentStudy as StudyType}
          practitioners={currentPractitioners as NativeSelectOption[]}
          facilities={currentFacilities as NativeSelectOption[]}
          onCloseModal={onCloseModal}
          onDefaultCancel={onDefaultCancel}
          isDisabled={!canUpdateStudy}
        />
      </TabPanel>
      <TabPanel tabId="patientEditTab">
        <PatientEditTab
          patient={currentStudy?.patient as PatientType}
          currentStudyID={currentStudy.id}
          onCloseModal={onCloseModal}
          onDefaultCancel={onDefaultCancel}
          isDisabled={!canUpdatePatient}
        />
      </TabPanel>
    </Tabs>
  );
};
