/* eslint-disable i18next/no-literal-string */
import { useState, useEffect, useRef } from 'react';
var initialState = {
    lastMessage: undefined,
    messages: [],
};
var supportsBroadcastAPI = typeof window !== 'undefined' && 'BroadcastChannel' in window;
export var useBrowserContextCommunication = function (channelName) {
    if (channelName === undefined) {
        throw Error('You need to pass a channel name e.g. useBrowserContextCommunication("GreatChannel")');
    }
    var _a = useState(initialState), communicationState = _a[0], setMessages = _a[1];
    var channel = useRef();
    if (supportsBroadcastAPI) {
        channel.current = new BroadcastChannel(channelName);
    }
    var postMessage = function (message) {
        var contextCommunicationMessage = {
            message: message,
            time: Date.now(),
        };
        if (message) {
            var msg = JSON.stringify(contextCommunicationMessage);
            if (supportsBroadcastAPI && channel && channel.current) {
                channel.current.postMessage(msg);
            }
            else {
                window.localStorage.setItem(channelName, msg);
            }
        }
    };
    var updateState = function (contextCommunicationMessage) {
        setMessages(function (prevState) {
            return {
                lastMessage: contextCommunicationMessage.message,
                messages: prevState.messages.concat(contextCommunicationMessage.message),
            };
        });
    };
    var updateFromLocalStorage = function (event) {
        try {
            var key = event.key;
            var value = event.newValue;
            if (key !== channelName || !value)
                return;
            var data = JSON.parse(value);
            if (data !== null && data !== undefined) {
                updateState(data);
            }
        }
        catch (error) {
            console.info('React Window Communication: Failed to parse json from localstorage');
        }
    };
    useEffect(function () {
        if (supportsBroadcastAPI && channel && channel.current) {
            if (channel && channel.current) {
                channel.current.onmessage = function (e) {
                    if (channel === null || channel === void 0 ? void 0 : channel.current)
                        updateState(JSON.parse(e.data));
                };
            }
        }
        else {
            window.addEventListener('storage', updateFromLocalStorage);
        }
        return function () {
            if (channel && channel.current) {
                channel.current.close();
                channel.current = null;
            }
            else {
                window.localStorage.removeItem(channelName);
                window.removeEventListener('storage', updateFromLocalStorage);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelName]);
    return { communicationState: communicationState, postMessage: postMessage };
};
