import cn from 'classnames';
import { BasicInputSize } from '.';
/* Class assignment */
export var inputClass = function (inputSize, isPlain, isDisabled) {
    return cn(
    /* input (base) */
    'space-x-3 w-full', 'transition duration-100 ease-out outline-none', 'placeholder-light-gray-600 text-basic-white', isPlain === true && 'bg-transparent', isPlain === false && 'bg-gray-600 rounded-lg', 
    /* input--md */
    inputSize === BasicInputSize.md && 'px-3 h-10', 
    /* input--isDisabled */
    isDisabled && 'opacity-40');
};
/* Wrapper class assignment */
export var wrapperClass = function (className, isFullWidth, isPlain, hasError) {
    return cn(className, 
    /* wrapper (base) */
    'relative flex overflow-hidden', isPlain && 'bg-transparent', !isPlain && 'bg-gray-600 rounded-lg', isFullWidth && 'w-full', hasError && 'border border-feedback-negative-500');
};
