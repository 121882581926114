import { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import cornerstoneTools from 'cornerstone-tools';
import { getMediaUrl, FileType, useModal, Modals, isMobileDevice } from '@eva-pacs/core';
import { MODALITY_TYPES } from '@eva-pacs/core';
import { CustomFilesDocument, SeriesType, InstanceViewType, useCustomFilesLazyQuery } from '@eva-pacs/client';

import {
  DicomImageData,
  SerieItem,
  useSeriesStore,
  useViewportStore,
  usePrinterStore,
  useMeasurementStore,
} from '~/src/store';
import { FRAME_RATE, SERIE_EXTERNAL_ID } from '~/constants';
import { getFileType } from '~/utils/appHelpers';
import { useViewerService } from '~/hooks';
import { getMeasurementDataByImageId } from '~/utils/measurements/getMeasurementData';
import { useMetaSeriesCreator } from './useMetaSeriesCreator';
import { getOriginalSerieId } from '~/utils/series';

export interface LoadViewerSeriesProps {
  onSuccess?: (serie: SerieItem) => void;
  onError: (error: string) => void;
}

export const useLoadViewerSeries = ({ onSuccess, onError }: LoadViewerSeriesProps) => {
  const [
    setSerieIdAndImageIndexViewport,
    setViewportImageIsLoaded,
    setViewportDisplayType,
    setActiveIndex,
    viewportData,
  ] = useViewportStore(
    (store) => [
      store.setSerieIdAndImageIndexViewport,
      store.setViewportImageIsLoaded,
      store.setViewportDisplayType,
      store.setActiveIndex,
      store.viewportData,
    ],
    shallow,
  );

  const createMetaSeriesData = useMetaSeriesCreator();

  const [getSeries, setSeries, getSerieById] = useSeriesStore(
    (store) => [store.getSeries, store.setSeries, store.getSerieById],
    shallow,
  );
  const showPrintView = usePrinterStore((store) => store.showPrintView);
  const { setCurrentModal } = useModal();
  const { getViewportSerieData } = useViewerService();
  const { t } = useTranslation();

  const [getLazySerieInstances] = useCustomFilesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ serie }) => formatSerieAndValidateResolution({ serie, earlyResolutionAdjustmentValidation: true }),
    onError: (error) => onError(error.message),
  });

  const { getSerieInstances: getSerieInstancesAndValidateMobileFiles } = useSeriesInstances({
    onCompleted: ({ serie }) => formatSerieAndValidateResolution({ serie, earlyResolutionAdjustmentValidation: true }),
    onError: (error) => onError(error.message),
  });

  const { getSerieInstances } = useSeriesInstances({
    onCompleted: ({ serie }) => formatSerieAndValidateResolution({ serie, earlyResolutionAdjustmentValidation: false }),
    onError: (error) => onError(error.message),
  });

  const handleCloseWarningModal = (serieId: string) => () => {
    setCurrentModal(null);
    // Update flag to not show the message in the same serie again
    const serieData = { ...getSerieById(serieId) };
    if (!serieData) return;
    serieData.showResolutionAdjustedMessage = false;
    addSerieData(serieData as SerieItem);
  };

  const formatSerieAndValidateResolution = ({ serie, earlyResolutionAdjustmentValidation }) => {
    if (!serie) {
      // eslint-disable-next-line i18next/no-literal-string
      onError('Serie is null in SeriesInstancesById');
      return;
    }
    const isMobile = isMobileDevice();
    const hasMobileFiles = serie.instanceFiles[0]?.mobileFile || serie.instanceParents[0]?.instance?.mobileFiles;
    const shouldShowResolutionAdjusteWarning = hasMobileFiles && isMobile;
    const serieItem = formatSerieItem(serie as SeriesType, isMobile);

    const showResolutionMessageNow = earlyResolutionAdjustmentValidation && shouldShowResolutionAdjusteWarning;
    if (!showResolutionMessageNow) {
      serieItem.showResolutionAdjustedMessage = shouldShowResolutionAdjusteWarning;
    }

    loadSerieMeasurements(serieItem);
    const metaSeries = createMetaSeriesData(serieItem);
    // If not exist metaSeries, then add the original serieItem from backend data
    if (metaSeries.length === 0) {
      addSerieData(serieItem);
      if (onSuccess) onSuccess(serieItem);
      if (showResolutionMessageNow)
        showMobileResolutionWarning(setCurrentModal, t, handleCloseWarningModal(serieItem.id));

      return serieItem;
    }

    metaSeries.forEach((metaSerie) => addSerieData(metaSerie));
    const [firstMetaSerie] = metaSeries;
    if (onSuccess) onSuccess(firstMetaSerie);
    if (showResolutionMessageNow)
      showMobileResolutionWarning(setCurrentModal, t, handleCloseWarningModal(firstMetaSerie.id));

    return firstMetaSerie;
  };

  const loadSerieMeasurements = useCallback((serieItem: SerieItem) => {
    const currentMeasurementState = useMeasurementStore.getState();
    if (!currentMeasurementState.measurementsLoaded) return;
    // Get measurements of loaded serie
    const measurements = serieItem.isKeySeries
      ? currentMeasurementState.getAllMeasurements()
      : currentMeasurementState.getMeasurementsBySerieId(serieItem.id);
    measurements.forEach((measurement) => {
      const { data, tool: toolName, instance } = measurement;

      // Search measurement updated image url from loaded serie
      const dicomImage = serieItem.dicomImageList.find((dicomImage) => instance.id === dicomImage.externalId);
      if (!dicomImage) return; // Measurement don't match with some serie Image

      const cornerstoneId = dicomImage.id;

      const dataObject = typeof data === 'string' ? JSON.parse(data)[0] : data;

      const measurementAlreadyExist = getMeasurementDataByImageId({
        imageId: cornerstoneId,
        toolName,
        uuid: dataObject.uuid,
      });

      if (measurementAlreadyExist) return;

      const toolState = {
        ...dataObject,
        handles: {
          ...JSON.parse(JSON.stringify(dataObject.handles)),
        },
      };

      cornerstoneTools.globalImageIdSpecificToolStateManager.addImageIdToolState(cornerstoneId, toolName, toolState);
    });
  }, []);

  const addSerieData = (serie: SerieItem) => {
    const seriesListToSave = [...getSeries()];
    const serieTargetIndex = getSeries().findIndex((serieItem) => serieItem.id === serie.id);
    if (serieTargetIndex === -1) return;
    seriesListToSave[serieTargetIndex] = serie;
    setSeries(seriesListToSave);
  };

  const loadASerieById = (serieId: string) => {
    return getLazySerieInstances({ variables: { serieId: getOriginalSerieId(serieId) } });
  };

  const loadSerieDetailById = (serieId: string) => {
    const serieIdToFetch = getOriginalSerieId(serieId);
    return getSerieInstances(serieIdToFetch);
  };

  const loadAndSetCurrentSerieById = ({
    viewportIndex,
    serieId,
    imageIndex = 0,
    fileType: initialFileType,
  }: {
    viewportIndex: number;
    serieId: string;
    imageIndex?: number;
    fileType?: FileType;
  }) => {
    if (!serieId) return;
    const serieTarget = getSerieById(serieId);
    const fileType = serieTarget?.fileType || initialFileType;
    const currentType = fileType || getFileType(serieId);
    setViewportDisplayType(viewportIndex, currentType);

    setSerieIdAndImageIndexViewport(viewportIndex, serieId, imageIndex);
    const isDicom = currentType === FileType.DICOM;

    const currentSerieIdInViewport = getViewportSerieData(viewportIndex)?.id;
    const isTheSameSerie = serieId === currentSerieIdInViewport;
    /**
     * Activate the viewport loader only if it is trying to load a different
     * series than the one currently loaded in the viewport
     */
    if (isDicom && !isTheSameSerie) setViewportImageIsLoaded(viewportIndex, false);

    if (serieTarget?.showResolutionAdjustedMessage)
      showMobileResolutionWarning(setCurrentModal, t, handleCloseWarningModal(serieId));

    if (serieTarget?.isLoaded && isDicom) {
      if (onSuccess) onSuccess(serieTarget);
      if (serieTarget.isKeySeries) getSerieInstancesAndValidateMobileFiles(serieId);
      return;
    }
    if (isDicom) {
      //If the serie is a meta serie, then fetch the original serie info
      const serieIdToFetch = getOriginalSerieId(serieId);
      getSerieInstancesAndValidateMobileFiles(serieIdToFetch);
      setViewportImageIsLoaded(viewportIndex, false);
    }
  };

  /*
   * Sets a set of images of the last serie
   * in the new different displayed Viewports
   */
  const setImagesOfASerieInViewports = (totalViewports, totalViewportsBeforeChange) => {
    if (totalViewportsBeforeChange >= totalViewports) return;
    const lastViewportIndexBeforeChange = totalViewportsBeforeChange - 1;
    const lastViewportSerie = getViewportSerieData(lastViewportIndexBeforeChange);
    const lastImageIndexInLastViewport = viewportData[lastViewportIndexBeforeChange]?.currentImageIndex || 0;
    const currentImagesIds = lastViewportSerie?.imagesIds;
    if (!currentImagesIds) return;
    let imageIdPointer = lastImageIndexInLastViewport; // Next image
    for (let index = lastViewportIndexBeforeChange; index < totalViewports; index++) {
      /**
       * If there are no more images in the list, then start
       * placing the images starting from the index 0.
       */
      if (currentImagesIds.length === imageIdPointer) imageIdPointer = 0;
      const serieId = lastViewportSerie?.id;
      if (serieId) setSerieIdAndImageIndexViewport(index, serieId, imageIdPointer);
      imageIdPointer++;
    }
    setActiveIndex(0);
  };

  /*
   * Sets the next serie in SeriesList Array in the next displayed Viewport
   * to render and return the serieId loaded in last viewport.
   */
  const setSeriesImagesInViewports = (totalViewports, totalViewportsBeforeChange) => {
    if (totalViewportsBeforeChange >= totalViewports) return;
    const lastViewportIndexBeforeChange = totalViewportsBeforeChange - 1;
    const seriesList = getSeries().filter((serie) => !serie.id.includes(SERIE_EXTERNAL_ID));
    const lastViewportSerieId = getViewportSerieData(lastViewportIndexBeforeChange)?.id;
    const lastSerieIndex = seriesList.findIndex((serie) => serie.id === lastViewportSerieId);
    /*
     * Sets serieIndexPointer at one serie after last viewport's serie
     */
    let serieIndexPointer = (lastSerieIndex >= 0 ? lastSerieIndex : 0) + 1;

    let lastSerieIdSelected = '';
    let index = totalViewportsBeforeChange;
    for (index; index < totalViewports; index++) {
      /**
       * If there are no more series in seriesList, then start
       * placing the images starting from the index 0.
       */
      if (serieIndexPointer >= seriesList.length) serieIndexPointer = 0;
      const serieId = seriesList[serieIndexPointer]?.id;
      const displayType = seriesList[serieIndexPointer]?.modalityType as string;
      const displayProp = getFormattedECGType(displayType);

      if (serieId) {
        loadAndSetCurrentSerieById({
          viewportIndex: index,
          serieId,
          ...displayProp,
        });
        lastSerieIdSelected = serieId;
      }
      serieIndexPointer++;
    }
    return lastSerieIdSelected;
  };

  const loadImagesInViewports = (totalViewports, totalViewportsBeforeChange) => {
    if (showPrintView) return setImagesOfASerieInViewports(totalViewports, totalViewportsBeforeChange);

    return setSeriesImagesInViewports(totalViewports, totalViewportsBeforeChange);
  };

  return {
    loadAndSetCurrentSerieById,
    loadImagesInViewports,
    loadASerieById,
    loadSerieDetailById,
    loadSerieMeasurements,
  };
};

const getFormattedECGType = (displayType) => {
  if (displayType === FileType.ECG) return { fileType: displayType };
  return {};
};

// TODO[CHAVA]: Remove this workaround when we upgrade @apollo/client
// version: https://eva-pacs.atlassian.net/browse/EVA-2440
const useSeriesInstances = ({ onCompleted, onError }) => {
  const client = useApolloClient();
  const getSerieInstances = (serieId: string) =>
    client
      .query({ query: CustomFilesDocument, variables: { serieId } })
      .then(({ data }) => onCompleted(data))
      .catch(onError);

  return {
    getSerieInstances,
  };
};

const formatSerieItem = (serie: SeriesType, isMobileDevice: boolean): SerieItem => {
  const isKeySerie = Boolean(serie.instanceParents.length);
  const serieInstances = isKeySerie ? serie.instanceParents : serie.instanceFiles;

  const dicomImageList: Array<DicomImageData> = serieInstances.map((instance) => {
    const fileInstances = isKeySerie ? instance.instance : instance;
    let instanceFile = { file: '', fileUrl: '' };
    if (isKeySerie) {
      instanceFile = getInstanceFilesLegacy(fileInstances, isMobileDevice);
    } else {
      instanceFile = getInstanceFiles(fileInstances, isMobileDevice);
    }
    const { fileUrl, file } = instanceFile;
    return {
      externalId: fileInstances.id,
      id: getMediaUrl(
        fileUrl,
        file,
        ![MODALITY_TYPES.DOC, MODALITY_TYPES.SR].includes(serie.modality.identifier as MODALITY_TYPES),
      ),
      imageOrientationPatient: [],
    };
  });

  return {
    id: serie.id,
    isLoaded: true,
    numImageFrames: serie.instanceFiles.length,
    hasDocument: Boolean(serie.hasDocument),
    modalityType: serie.modality.identifier as MODALITY_TYPES,
    dicomSeriesId: serie.dicomSeriesId,
    frameRate: FRAME_RATE,
    dicomImageList,
    isKeySeries: Boolean(serie.isKeySeries),
    imagesIds: dicomImageList.map((image) => image.id),
  };
};

const getInstanceFilesLegacy = (instance: SeriesType['instances'][0], isMobileDevice: boolean) => {
  let fileUrl, file;
  if (isMobileDevice && instance?.mobileFiles) {
    fileUrl = instance.mobileFiles.fileUrl;
    file = instance.mobileFiles.file;
  } else {
    fileUrl = instance.files?.fileUrl;
    file = instance.files?.file;
  }
  return { file, fileUrl };
};

const getInstanceFiles = (instance: InstanceViewType, isMobileDevice: boolean) => {
  let fileUrl, file;
  if (isMobileDevice && instance?.mobileFile) {
    fileUrl = instance.mobileFileUrl;
    file = instance.mobileFile;
  } else {
    fileUrl = instance.fileUrl;
    file = instance.file;
  }
  return { file, fileUrl };
};

const showMobileResolutionWarning = (setCurrentModal, t, onAccept) => {
  setCurrentModal({
    name: Modals.ConfirmationModal,
    props: {
      title: t('viewer.resolutionTitle'),
      hasCloseButton: false,
      hasCancelButton: false,
      description: t('viewer.resolutionWarning'),
      className: 'max-w-sm',
      isMaxWidthCustom: true,
      onAccept,
    },
  });
};
