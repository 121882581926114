import shallow from 'zustand/shallow';
import { useStudyListStore } from '~/src/store';
import { useWorklistQueries } from './useWorklistQueries';
import { useProcessStudyForInsertion } from '../study/useProcessStudyForInsertion';
import { StudyUpdateEvent, StudyUpdateType } from '~/components/StudyList/useStudyListUpdates';
import { useNotificationsStore, NotificationType } from '~/src/store/notifications-store';

export const useAddNewStudy = () => {
  const [getStudies] = useStudyListStore((state) => [state.getStudies], shallow);
  // Intended for worklist with worklist positions query.
  const { fetchWorklistStudyById, getWorklistPositions } = useWorklistQueries();
  const { setNotifications } = useNotificationsStore();
  const { insertNewStudy } = useProcessStudyForInsertion();

  const insertStudy = async (studyId: string) => {
    try {
      const positions = await getWorklistPositions(studyId);
      if (!positions) return;
      const newStudy = await fetchWorklistStudyById(studyId);

      if (!newStudy) return;
      insertNewStudy({
        newStudy,
        nextId: positions?.nextId,
        prevId: positions?.prevId,
      });
    } catch (error) {
      setNotifications<StudyUpdateEvent>({
        type: NotificationType.StudyListUpdates,
        payload: {
          [studyId]: StudyUpdateType.error,
        },
      });
    }
  };

  const processInsertNewWorklistStudy = (studyId: string) => {
    const studies = getStudies();

    // If there is already an study with the studyId avoid inserting a new one.
    if (studies[studyId]) return;

    insertStudy(studyId);
  };

  const processInsertNewStudyListStudy = (studyId: string) => {
    // TODO: add flow for study list when we are ready to support automatic updates.

    const studies = getStudies();

    // If there is already an study with the studyId avoid inserting a new one.
    if (studies[studyId]) return;
  };

  return {
    processInsertNewWorklistStudy,
    processInsertNewStudyListStudy,
  };
};
