import { useRef, useState } from 'react';
import cornerstone from 'cornerstone-core';
import type { Mat } from 'mirada';
import { validateBoundingBox } from '~/src/utils/boundingBox';
import { useOpenCVLoader } from '~/src/hooks/useOpenCVLoader';
import { loadImage, drawImageInCanvas, getBoundingBox, imageToContours, drawRectangle, scaleImage } from '~/utils/cv';
import { getCanvasOriginInViewport } from '~/utils/cornestone/canvasViewportRelation';
import { useRegionComparisonStore } from '~/src/store/regionComparisonToolStore';
import { getLaterality } from '~/components/Viewer/CornerstoneViewer/getOverlayData';
import { CanvasComposite, LATERALITY_TYPE } from '~/constants';
import { getEnabledElementControlled } from '~/utils/cornestone/getEnabledElementControlled';
import { setElementStateConfig } from '~/utils/cornestone/NippleInjuryTool';
import { BoundingBox } from '~/src/interfaces/BoundingBox';

export const IMAGE_MAP_SIZE = {
  height: 110,
};

export const SHADOW_IMAGE_MAP_SIZE_SCALED = {
  height: 880,
};

const REDRAW_IMAGE_MAP_DELAY = 200;

interface ImageBoundingBoxMapState {
  lastImageIdLoaded: string;
  imagesList: (Mat | null)[];
  contoursData: { contours: Mat; biggestContourIndex: number } | null;
}

export const useBoundingBoxDrawer = (viewportIndex: number) => {
  const callerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const loadOpenCV = useOpenCVLoader();
  const { addBoundingBox, getBoundingBoxByImageId } = useRegionComparisonStore();
  const [baseImages, setBaseImages] = useState<ImageBoundingBoxMapState>({
    lastImageIdLoaded: '',
    imagesList: [],
    contoursData: null,
  });

  const loadImageInCanvas = (canvas: HTMLCanvasElement | null, image) => {
    if (!canvas) return baseImages;
    if (image.imageId === baseImages.lastImageIdLoaded) return baseImages;

    baseImages.imagesList.forEach((m) => m?.delete());

    const tempCanvas = document.createElement('canvas');
    const canvasCtx = canvas.getContext('2d');
    const shadowCanvasCtx = tempCanvas.getContext('2d');

    const scaleToFitInMap = IMAGE_MAP_SIZE.height / image.height;
    if (canvasCtx) {
      canvasCtx.canvas.width = image.width * scaleToFitInMap;
      canvasCtx.canvas.height = image.height * scaleToFitInMap;
      canvasCtx.globalCompositeOperation = CanvasComposite.Lighter;
    }
    const scaleToFitShadowMap = SHADOW_IMAGE_MAP_SIZE_SCALED.height / image.height;

    if (shadowCanvasCtx) {
      shadowCanvasCtx.canvas.width = image.width * scaleToFitShadowMap;
      shadowCanvasCtx.canvas.height = image.height * scaleToFitShadowMap;
      shadowCanvasCtx.globalCompositeOperation = CanvasComposite.Lighter;
    }
    /*
     * Load the cornerstone image directly
     * into a cavas and then read it from there to take
     * advantage of the cornerstone method
     */
    cornerstone.renderToCanvas(canvas, image);
    cornerstone.renderToCanvas(tempCanvas, image);
    const scaledImage = loadImage(canvas);
    const tempScaledImage = loadImage(tempCanvas);
    let imageInContours: Mat | null = null;
    let contoursData: ImageBoundingBoxMapState['contoursData'] = null;
    if (tempScaledImage) {
      const { imageContours, contours, biggestContourIndex } = imageToContours(tempScaledImage);
      contoursData = { contours, biggestContourIndex };
      imageInContours = scaleImage(imageContours, scaleToFitInMap / scaleToFitShadowMap);
    }
    const baseImagesLoaded = [scaledImage, tempScaledImage, imageInContours];
    const baseImagesData = {
      lastImageIdLoaded: image.imageId,
      imagesList: baseImagesLoaded,
      contoursData,
    };
    setBaseImages(baseImagesData);
    return baseImagesData;
  };

  const registerAndDrawBoundingBox = (canvasRef, element, options, imageId = '') => {
    if (callerRef.current) clearTimeout(callerRef.current);

    callerRef.current = setTimeout(async () => {
      const boundingBoxExists = !!getBoundingBoxByImageId(imageId);
      const renderOptions = { ...options };
      if (!boundingBoxExists) {
        //If oundingBox doesn't exist, we need to add it
        renderOptions.registerBoundingBox = true;
      }
      renderImageCanvas(canvasRef, element, options);
    }, REDRAW_IMAGE_MAP_DELAY);
  };

  const renderImageCanvas = async (canvasRef, element, options) => {
    await loadOpenCV(); // Load opencv if it isn't already loaded in the viewer
    if (!element) return;
    const { image, canvas, viewport } = getEnabledElementControlled(element) ?? {};
    if (!image || !canvas || !viewport?.translation) return;
    const { registerBoundingBox, drawDisplayedArea, drawBoundingBox = false } = options;
    const imageData = loadImageInCanvas(canvasRef, image);
    const [img, shadowImg, imageInContours] = imageData?.imagesList || [];
    if (!img || !shadowImg || !imageInContours) return;

    const scaleToFitInMap = !image.height ? 1 : IMAGE_MAP_SIZE.height / image.height;
    const scaleToFitShadowMap = !image.height ? 1 : SHADOW_IMAGE_MAP_SIZE_SCALED.height / image.height;

    const boundingBoxImage = getBoundingBoxByImageId(image.imageId);
    const imageLaterality = getLaterality(image.imageId) as LATERALITY_TYPE;

    if (registerBoundingBox) {
      // If the bounding box already exists for the image, just copy its values
      // and only associate it with the viewport

      let boundingBox = {} as BoundingBox;
      if (boundingBoxImage) {
        boundingBox = {
          ...boundingBoxImage,
          viewportIndex,
        };
        addBoundingBox(boundingBox);
      } else {
        const { box } = getBoundingBox(shadowImg);
        boundingBox = {
          x: box.x / scaleToFitShadowMap,
          y: box.y / scaleToFitShadowMap,
          width: box.width / scaleToFitShadowMap,
          height: box.height / scaleToFitShadowMap,
          imageId: image.imageId,
          viewportIndex,
          imageLaterality,
          originalImageWidth: image.width,
          originalImageHeight: image.height,
        };
        addBoundingBox(boundingBox);
      }
      setElementStateConfig(element, {
        boundingBox: validateBoundingBox(boundingBox),
        contoursImageData: imageData,
      });
    }

    const baseImageContours = !drawBoundingBox
      ? imageInContours
      : drawRectangle(imageInContours, {
          x: (boundingBoxImage?.x || 0) * scaleToFitInMap,
          y: (boundingBoxImage?.y || 0) * scaleToFitInMap,
          width: (boundingBoxImage?.width || 0) * scaleToFitInMap,
          height: (boundingBoxImage?.height || 0) * scaleToFitInMap,
        });

    if (!drawDisplayedArea) {
      drawImageInCanvas(baseImageContours, canvasRef);
      return;
    }
    const canvasPosition = getCanvasOriginInViewport(element);
    if (!canvasPosition) return;
    const displayedArea = {
      x: (canvasPosition.x - viewport.translation.x) * scaleToFitInMap,
      y: (canvasPosition.y - viewport.translation.y) * scaleToFitInMap,
      width: canvas.clientWidth * scaleToFitInMap,
      height: canvas.clientHeight * scaleToFitInMap,
    };
    const imageWithDiplayedArea = drawRectangle(baseImageContours, displayedArea);
    drawImageInCanvas(imageWithDiplayedArea, canvasRef);
    imageWithDiplayedArea.delete();
  };

  return {
    renderImageCanvas: registerAndDrawBoundingBox,
  };
};
