export var AvailableLanguages = {
    es: 'SPANISH',
    en: 'ENGLISH',
    pt: 'PORTUGUESE',
};
export var DEFAULT_LANGUAGE_ABBREVIATION = 'es';
export var LanguageOptions = Object.entries(AvailableLanguages).map(function (_a) {
    var id = _a[0], translationKey = _a[1];
    return ({
        id: id,
        label: translationKey,
    });
});
