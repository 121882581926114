import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Icon, { IconCatalog } from '../../atoms/Icon';
import { Slider } from '../../atoms/Slider';
var SLIDER_STEP = 1;
/**
 * CinemaModePlayer is used to show the extra frames of a Sequence item
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-24
 */
var CinemaModePlayer = function (_a) {
    var className = _a.className, _b = _a.isCinemaPlaying, isCinemaPlaying = _b === void 0 ? false : _b, _c = _a.minFrameRate, minFrameRate = _c === void 0 ? 1 : _c, _d = _a.maxFrameRate, maxFrameRate = _d === void 0 ? 90 : _d, _e = _a.stepFrameRate, stepFrameRate = _e === void 0 ? 24 : _e, onSliderChange = _a.onSliderChange, onSkipToStartBtnClick = _a.onSkipToStartBtnClick, onBackBtnClick = _a.onBackBtnClick, onPlayPauseBtnClick = _a.onPlayPauseBtnClick, onNextBtnClick = _a.onNextBtnClick, onSkipToEndBtnClick = _a.onSkipToEndBtnClick, onCloseBtnClick = _a.onCloseBtnClick;
    var t = useTranslation().t;
    var cinemaModePlayerClass = cn(className, 'flex flex-col md:flex-row items-center p-2 md:space-x-6 rounded-lg max-w-lg bg-basic-black bg-opacity-50');
    var handleSkipToStartBtnClick = function () {
        if (onSkipToStartBtnClick)
            onSkipToStartBtnClick();
    };
    var handleBackBtnClick = function () {
        if (onBackBtnClick)
            onBackBtnClick();
    };
    var handlePlayPauseBtnClick = function () {
        if (onPlayPauseBtnClick)
            onPlayPauseBtnClick();
    };
    var handleNextBtnClick = function () {
        if (onNextBtnClick)
            onNextBtnClick();
    };
    var handleSkipToEndBtnClick = function () {
        if (onSkipToEndBtnClick)
            onSkipToEndBtnClick();
    };
    var handleCloseButtonClick = function () {
        if (onCloseBtnClick)
            onCloseBtnClick();
    };
    return (React.createElement("div", { className: cinemaModePlayerClass },
        React.createElement("div", { className: "flex items-center mb-4 text-basic-white space-x-2 md:mb-0" },
            React.createElement("button", { onClick: handleSkipToStartBtnClick },
                React.createElement(Icon, { icon: IconCatalog.backward, width: "40", height: "40" })),
            React.createElement("button", { onClick: handleBackBtnClick },
                React.createElement(Icon, { icon: IconCatalog.prev, width: "40", height: "40" })),
            React.createElement("button", { onClick: handlePlayPauseBtnClick, className: "bg-primary-600 rounded-full" },
                React.createElement(Icon, { icon: !isCinemaPlaying ? IconCatalog.play : IconCatalog.pause, width: "40", height: "40" })),
            React.createElement("button", { onClick: handleNextBtnClick },
                React.createElement(Icon, { icon: IconCatalog.next, width: "40", height: "40" })),
            React.createElement("button", { onClick: handleSkipToEndBtnClick },
                React.createElement(Icon, { icon: IconCatalog.forward, width: "40", height: "40" }))),
        React.createElement("div", { className: "flex items-center space-x-6" },
            React.createElement("div", { className: "flex flex-col items-center" },
                React.createElement("div", { className: "mb-3 text-basic-white text-caption-sm font-semi-bold md:text-label-sm" }, t('viewer.cinema.frameNumbersPerSecond')),
                React.createElement("div", { className: "flex items-center w-full space-x-2 md:space-x-3" },
                    React.createElement("div", { className: "text-basic-white text-label-sm font-semi-bold" }, minFrameRate),
                    React.createElement(Slider, { min: minFrameRate, max: maxFrameRate, step: SLIDER_STEP, value: stepFrameRate, className: "w-full", onChange: onSliderChange }),
                    React.createElement("div", { className: "text-basic-white text-label-sm font-semi-bold" }, maxFrameRate))),
            React.createElement("button", { className: "text-basic-white opacity-30", onClick: handleCloseButtonClick },
                React.createElement(Icon, { icon: IconCatalog.close, width: "30", height: "30" })))));
};
export default CinemaModePlayer;
