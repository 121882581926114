/**
 * Predefined voi levels
 */
export var LevelCatalog;
(function (LevelCatalog) {
    LevelCatalog["MEDIASTINO"] = "MEDIASTINO";
    LevelCatalog["LUNG"] = "LUNG";
    LevelCatalog["BONE"] = "BONE";
    LevelCatalog["BRAIN"] = "BRAIN";
    LevelCatalog["HEAD"] = "HEAD";
    LevelCatalog["BELLY"] = "BELLY";
    LevelCatalog["LIVER"] = "LIVER";
    LevelCatalog["FULL_DYNAMIC"] = "FULL_DYNAMIC";
    LevelCatalog["NONE"] = "NONE";
    LevelCatalog["OTHER"] = "OTHER";
})(LevelCatalog || (LevelCatalog = {}));
export var VOI_LEVEL = {
    MEDIASTINO: {
        key: LevelCatalog.MEDIASTINO,
        windowWidth: 400,
        windowCenter: 40,
    },
    LUNG: {
        key: LevelCatalog.LUNG,
        windowWidth: 1500,
        windowCenter: -500,
    },
    BONE: {
        key: LevelCatalog.BONE,
        windowWidth: 2000,
        windowCenter: 500,
    },
    BRAIN: {
        key: LevelCatalog.BRAIN,
        windowWidth: 80,
        windowCenter: 40,
    },
    HEAD: {
        key: LevelCatalog.HEAD,
        windowWidth: 350,
        windowCenter: 90,
    },
    BELLY: {
        key: LevelCatalog.BELLY,
        windowWidth: 400,
        windowCenter: 50,
    },
    LIVER: {
        key: LevelCatalog.LIVER,
        windowWidth: 150,
        windowCenter: 30,
    },
    FULL_DYNAMIC: {
        key: LevelCatalog.FULL_DYNAMIC,
        windowWidth: 0,
        windowCenter: 0,
    },
};
