import { Event, EventHint } from '@sentry/react';

const hundandledPromiseRejectionMessageRegexp = /Non-Error promise rejection captured with keys:/;

/**
 * Function that validates if an unhandled promise error message should be logged in sentry or not
 * */
export const unhandledRejectionErrorValidator = () => {
  const errors: Record<string, Event> = {};

  const registerError = (event: Event) => {
    const errorMessage = event.exception?.values?.[0].value;
    const errorURL = event.request?.url;
    errors[`${errorURL}:${errorMessage}`] = event;
  };

  const shouldRegisterError = (event: Event) => {
    const errorMessage = event.exception?.values?.[0].value;
    const errorURL = event.request?.url;
    return !errors[`${errorURL}:${errorMessage}`];
  };

  return (event: Event, _: EventHint) => {
    const errorMessage = event.exception?.values?.[0].value;
    // All non-handled promise events are sent
    if (!errorMessage?.match(hundandledPromiseRejectionMessageRegexp)) return event;
    const shouldRegister = shouldRegisterError(event);
    if (!shouldRegister) return null;
    registerError(event);
    return event;
  };
};
