import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import { InteractionTypes } from './cornestone.helpers';
import {
  calcAngle,
  calcYCordInRectWithX,
  getSegmentIntersection,
  getIntersection,
  calcXCordInRectWithY,
  findPerpendicularPoint,
  distanceBetweenPoints,
} from '../plane';
import { getCanvasLimitsInViewport } from '../cornestone/getCanvasLimitsInViewport';
const getLogger = csTools.importInternal('util/getLogger');
const throttle = csTools.importInternal('util/throttle');
const draw = csTools.importInternal('drawing/draw');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawLinkedTextBox = csTools.importInternal('drawing/drawLinkedTextBox');
const logger = getLogger('tools:annotation:ProbeTool');
const drawLine = csTools.importInternal('drawing/drawLine');
const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const { angleCursor } = csTools.importInternal('tools/cursors');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const drawTextBox = csTools.importInternal('drawing/drawTextBox');
const lineSegDistance = csTools.importInternal('util/lineSegDistance');
const triggerEvent = csTools.importInternal('util/triggerEvent');

import { PIXELS_NEAR_TOOL, PIXELS_MEASUREMENT_UNIT, MILIMETER_MEASUREMENT_UNIT } from '~/constants';
import { Point } from 'mirada';
import { getEnabledElementControlled } from './getEnabledElementControlled';
import { transformUnits } from '@eva-pacs/core';

const LABELS_WIDTH = 45;
/**
 * @public
 * @class CoxometryRoiTool
 * @memberof Tools.Annotation
 * @extends BaseAnnotationTool
 */

const NUMBER_OF_HANDLES = 6;

export default class Coxometry extends BaseAnnotationTool {
  throttledUpdateCachedStats: any;
  name;
  constructor(props = {}) {
    super(props, {
      supportedInteractionTypes: [InteractionTypes.MOUSE, InteractionTypes.TOUCH],
      svgCursor: angleCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
    });
    this.name = 'Coxometry';
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
    if (!goodEventData) {
      logger.error(`required eventData not supplied to tool ${super.name}'s createNewMeasurement`);
      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: false,
      complete: false,
      handles: {
        point1: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }
  isValidPoint(eventData) {
    const { currentPoints, image } = eventData;
    if (!image) return false;

    return !(
      currentPoints.image.x < 0 ||
      currentPoints.image.y < 0 ||
      currentPoints.image.y > image.height ||
      currentPoints.image.x > image.width
    );
  }

  addNewMeasurement(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const element = evt.detail.element;
    const eventData = evt.detail;
    const incompleteMeasurement = this.getIncompleteMeasurement(element);
    if (!this.isValidPoint(eventData)) return;
    if (!incompleteMeasurement) {
      const measurementData = this.createNewMeasurement(eventData);
      csTools.addToolState(element, this.name, measurementData);
      cornerstone.updateImage(element);
      return;
    }
    const numberOfHandles = Object.keys(incompleteMeasurement.handles).filter((handle) => handle.startsWith(`point`))
      .length;
    const newPoint = {
      x: eventData.currentPoints.image.x,
      y: eventData.currentPoints.image.y,
      highlight: true,
      active: true,
    };
    incompleteMeasurement.handles = { ...incompleteMeasurement.handles, [`point${numberOfHandles + 1}`]: newPoint };
    if (numberOfHandles >= NUMBER_OF_HANDLES - 1) incompleteMeasurement.complete = true;
    // csTools.addToolState(element, this.name, incompleteMeasurement);
    const eventType = csTools.EVENTS.MEASUREMENT_MODIFIED;
    const newEventData = {
      toolName: this.name,
      element,
      measurementData: incompleteMeasurement,
    };
    triggerEvent(element, eventType, newEventData);
    cornerstone.updateImage(element);
  }

  getIncompleteMeasurement(element) {
    const toolState = csTools.getToolState(element, this.name);
    if (toolState && Array.isArray(toolState.data)) return toolState.data.find(({ complete }) => complete === false);
  }

  pointNearTool(element, data, coords) {
    if (data.visible === false || !data.lines) return false;
    const colision = Object.keys(data.lines).find((lineName) => {
      if (!data.lines[lineName]) return false;
      return lineSegDistance(element, data.lines[lineName].start, data.lines[lineName].end, coords) < PIXELS_NEAR_TOOL;
    });
    return colision;
  }

  updateCachedStats(image, element, data) {
    const { stats } = data;
    if (!stats) return;
    const [, D, I] = stats;
    data.value = `${D}, ${I}`;
  }

  onMeasureModified(evt) {
    if (evt.detail.toolName !== this.name) return;
    const { element } = evt.detail;
    const image = getEnabledElementControlled(element)?.image;

    if (!image) return;

    if (evt.detail.toolName !== this.name) return;

    const data = evt.detail.measurementData;

    this.throttledUpdateCachedStats(image, element, data);

    const { stats } = data;
    if (!stats) return;
    const [, D, I] = stats;
    data.value = `${D}, ${I}`;
  }

  calcHorizontalLimitPointsInRect(rectPoint1: Point, rectPoint2) {
    if (!rectPoint1 || !rectPoint2) return [];
    //We extend the lines half the distance between the two points towards the ends
    const distance = (rectPoint2.x - rectPoint1.x) / 2;
    const x1 = rectPoint1.x - distance;
    const x2 = rectPoint2.x + distance;
    const point1 = {
      x: x1,
      y: calcYCordInRectWithX(x1, rectPoint1, rectPoint2),
    };
    const point2 = {
      x: x2,
      y: calcYCordInRectWithX(x2, rectPoint1, rectPoint2),
    };
    return [point1, point2];
  }

  calcStats(points: Array<Point>, lines: Record<string, { start: Point; end: Point }>, pixelSpacing) {
    const [first, second, third, fourth, fifth, sixth] = points;

    const firstAngle = calcAngle(first, second, third, first);
    const secondAngle = calcAngle(second, first, fourth, second);

    const rAngle = `${firstAngle.toFixed(2)}°`;
    const lAngle = `${secondAngle.toFixed(2)}°`;

    // Set the length text suffix depending on whether or not pixelSpacing is available
    let suffix = MILIMETER_MEASUREMENT_UNIT;

    if (!pixelSpacing.rowPixelSpacing || !pixelSpacing.colPixelSpacing) {
      suffix = PIXELS_MEASUREMENT_UNIT;
    }

    const formatValue = (value: number) => {
      const spacedValue = value * (pixelSpacing.rowPixelSpacing || 1);
      const label = transformUnits(`${Math.abs(spacedValue).toFixed(2)} ${suffix}`);
      return label;
    };
    const { L1, L4, L5 } = lines;
    const h1Intersection = getIntersection(L1.start, L1.end, L4.start, L4.end);
    const h2Intersection = getIntersection(L1.start, L1.end, L5.start, L5.end);

    let d1Lenght = h1Intersection ? distanceBetweenPoints(first, h1Intersection) : 0;
    const h1Height = h1Intersection ? distanceBetweenPoints(fifth, h1Intersection) : 0;
    const h2Height = h2Intersection ? distanceBetweenPoints(sixth, h2Intersection) : 0;
    let d2Lenght = h2Intersection ? distanceBetweenPoints(second, h2Intersection) : 0;
    if (!h1Intersection || h1Intersection.x > first.x) d1Lenght = 0;
    if (!h2Intersection || h2Intersection.x < second.x) d2Lenght = 0;
    const d1LenghtLabel = `${formatValue(d1Lenght)}`;
    const h1HeightLabel = `${formatValue(h1Height)}`;
    const h2HeightLabel = `${formatValue(h2Height)}`;
    const d2LenghtLabel = `${formatValue(d2Lenght)}`;
    const labels = [`Coxometria`, `D= ${rAngle}`, `I= ${lAngle}`];
    if (h1Intersection && h1Intersection.y < fifth.y) {
      labels.push(`d1= ${d1LenghtLabel}`);
      labels.push(`h1= ${h1HeightLabel}`);
    }
    if (h2Intersection && h2Intersection.y < sixth.y) {
      labels.push(`d2= ${d2LenghtLabel}`);
      labels.push(`h2= ${h2HeightLabel}`);
    }
    return labels;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, renderDashed } = super.configuration;
    const toolData = csTools.getToolState(evt.currentTarget, this.name);
    if (!toolData) return;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const { image, element } = eventData;
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) continue;

      draw(context, (context) => {
        const color = csTools.toolColors.getColorIfActive(data);

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius: handleRadius || 6,
          lineDash: null,
        };

        const {
          point1: first,
          point2: second,
          point3: third,
          point4: fourth,
          point5: fifth,
          point6: sixth,
        } = data.handles;

        data.lines = {};
        if (renderDashed) handleOptions.lineDash = lineDash;
        [first, second, third, fourth].forEach((handler) => {
          if (!handler) return;
          if (super.configuration.drawHandles) drawHandles(context, eventData, { end: handler }, handleOptions);
        });
        if (!first || !second) return;
        const [hLineStart, hLineEnd] = this.calcHorizontalLimitPointsInRect(first, second);
        drawLine(context, eventData.element, hLineStart, hLineEnd, handleOptions);
        data.lines.L1 = { start: hLineStart, end: hLineEnd };

        if (!data.handles.textBox.hasMoved) {
          const { bottomRigth } = getCanvasLimitsInViewport(element);
          const { x, y } = hLineEnd;
          const textBoxInitialAnchor = {
            x: x > bottomRigth.x - LABELS_WIDTH ? bottomRigth.x - LABELS_WIDTH : x,
            y: y + 40,
          };
          data.handles.textBox.hasMoved = true;
          Object.assign(data.handles.textBox, textBoxInitialAnchor);
        }

        if (!third) return;
        const [dLine1Start] = this.calcHorizontalLimitPointsInRect(third, first);
        drawLine(context, eventData.element, dLine1Start, first, handleOptions);
        data.lines.L2 = { start: dLine1Start, end: first };

        if (!fourth) return;
        const [dLine2Start] = this.calcHorizontalLimitPointsInRect(fourth, second);
        drawLine(context, eventData.element, dLine2Start, second, handleOptions);

        data.lines.L3 = { start: dLine2Start, end: second };

        if (!fifth && dLine1Start) return;
        const { L1 } = data.lines;
        let L1Perpendicular1 = findPerpendicularPoint(hLineStart, hLineEnd, fifth);
        let h1StartPoint = {
          x: calcXCordInRectWithY(dLine1Start.y, fifth, L1Perpendicular1),
          y: dLine1Start?.y,
        };
        const fullL1Intersection = getIntersection(L1.start, L1.end, fifth, L1Perpendicular1);
        if (fullL1Intersection && fullL1Intersection.x > first.x) {
          const diference = fullL1Intersection.x - first.x;
          fifth.x = fifth.x - diference;
          L1Perpendicular1 = findPerpendicularPoint(hLineStart, hLineEnd, fifth);
          h1StartPoint = {
            x: calcXCordInRectWithY(dLine1Start.y, fifth, L1Perpendicular1),
            y: dLine1Start?.y,
          };
        }
        const intersectionL1L4 = getSegmentIntersection(L1.start, L1.end, fifth, L1Perpendicular1);

        if (intersectionL1L4) {
          drawLine(context, eventData.element, fifth, intersectionL1L4, handleOptions);
          drawLine(context, eventData.element, intersectionL1L4, h1StartPoint, { lineDash });
        } else {
          drawLine(context, eventData.element, fifth, h1StartPoint, { lineDash });
        }
        if (super.configuration.drawHandles) drawHandles(context, eventData, { end: fifth }, handleOptions);
        data.lines.L4 = { start: fifth, end: h1StartPoint };

        if (!sixth) return;

        let L1Perpendicular2 = findPerpendicularPoint(hLineStart, hLineEnd, sixth);
        let h2StartPoint = {
          x: calcXCordInRectWithY(dLine2Start.y, sixth, L1Perpendicular2),
          y: dLine2Start?.y,
        };

        const fullL2Intersection = getIntersection(L1.start, L1.end, sixth, L1Perpendicular2);
        if (fullL2Intersection && fullL2Intersection.x < second.x) {
          const diference = fullL2Intersection.x - second.x;
          sixth.x = sixth.x - diference;
          L1Perpendicular2 = findPerpendicularPoint(hLineStart, hLineEnd, sixth);
          h2StartPoint = {
            x: calcXCordInRectWithY(dLine2Start.y, sixth, L1Perpendicular2),
            y: dLine2Start?.y,
          };
        }

        const intersectionL1L5 = getSegmentIntersection(L1.start, L1.end, sixth, L1Perpendicular2);
        if (intersectionL1L5) {
          drawLine(context, eventData.element, sixth, intersectionL1L5, handleOptions);
          drawLine(context, eventData.element, intersectionL1L5, h2StartPoint, { lineDash });
        } else {
          drawLine(context, eventData.element, sixth, h2StartPoint, { lineDash });
        }
        if (super.configuration.drawHandles) drawHandles(context, eventData, { end: sixth }, handleOptions);
        data.lines.L5 = { start: sixth, end: h2StartPoint };

        const pixelSpacing = getPixelSpacing(image);
        const stats = this.calcStats([first, second, third, fourth, fifth, sixth], data.lines, pixelSpacing);
        data.stats = stats;
        if (!data.value) {
          const [, D, I] = stats;
          data.value = `${D}, ${I}`;
          const eventType = csTools.EVENTS.MEASUREMENT_MODIFIED;
          const newEventData = {
            toolName: this.name,
            element,
            measurementData: data,
          };
          triggerEvent(element, eventType, newEventData);
        }
        const lineWidth = csTools.toolStyle.getToolWidth();
        const textBoxSpacingValue = 2;
        const textBoxAnchorPoints = () => this._findTextBoxAnchorPoints(hLineEnd, hLineEnd);

        const DLabel = 'D';
        const ILabel = 'I';
        const d1Label = 'd1';
        const h1Label = 'h1';
        const d2Label = 'd2';
        const h2Label = 'h2';

        const d1Cords: any = { x: first.x - 5 - Math.abs(fifth.x - first.x) / 2, y: first.y + 1 };
        const h1Cords: any = { x: fifth.x, y: fifth.y - Math.abs(fifth.y - first.y) / 2 };
        const d2Cords: any = { x: second.x - 5 + Math.abs(sixth.x - second.x) / 2, y: second.y + 1 };
        const h2Cords: any = { x: sixth.x - 10, y: sixth.y - Math.abs(sixth.y - second.y) / 2 };

        const RCords: any = { x: third.x - 3, y: third.y - 3 + Math.abs(third.y - first.y) / 2 };

        const LCords: any = { x: fourth.x - 2, y: fourth.y - 3 + Math.abs(fourth.y - second.y) / 2 };

        const RCanvasCords = cornerstone.pixelToCanvas(eventData.element, RCords);
        const LCanvasCords = cornerstone.pixelToCanvas(eventData.element, LCords);
        const d1CanvasCords = cornerstone.pixelToCanvas(eventData.element, d1Cords);
        const h1CanvasCords = cornerstone.pixelToCanvas(eventData.element, h1Cords);
        const d2CanvasCords = cornerstone.pixelToCanvas(eventData.element, d2Cords);
        const h2CanvasCords = cornerstone.pixelToCanvas(eventData.element, h2Cords);

        drawTextBox(context, DLabel, RCanvasCords.x, RCanvasCords.y, color);
        drawTextBox(context, ILabel, LCanvasCords.x, LCanvasCords.y, color);
        drawTextBox(context, d1Label, d1CanvasCords.x, d1CanvasCords.y, color);
        drawTextBox(context, h1Label, h1CanvasCords.x, h1CanvasCords.y, color);
        drawTextBox(context, d2Label, d2CanvasCords.x, d2CanvasCords.y, color);
        drawTextBox(context, h2Label, h2CanvasCords.x, h2CanvasCords.y, color);
        drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          data.stats,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          textBoxSpacingValue,
          true,
        );
      });
    }
  }

  /**
   * Override original _getDeviationImageCoordinates of cornerstone-tools
   * @param {Object} startHandle - `{ x, y }` in either pixel or canvas coordinates.
   * @param {Object} endHandle - `{ x, y }` in either pixel or canvas coordinates.
   * @returns {{ left: number, top: number, width: number, height: number}}
   */
  _getDeviationImageCoordinates(startHandle, endHandle) {
    return {
      left: Math.min(startHandle.x, endHandle.x),
      top: Math.min(startHandle.y, endHandle.y),
      width: Math.abs(startHandle.x - endHandle.x),
      height: Math.abs(startHandle.y - endHandle.y),
    };
  }

  /**
   * Override original _findTextBoxAnchorPoints of cornerstone-tools
   * @param {Object} startHandle - `{ x, y }` in either pixel or canvas coordinates.
   * @param {Object} endHandle - `{ x, y }` in either pixel or canvas coordinates.
   * @returns {Array.<{x: number, y: number}>}
   */
  _findTextBoxAnchorPoints(startHandle, endHandle) {
    const { left, top, width, height } = this._getDeviationImageCoordinates(startHandle, endHandle);

    return [
      {
        // Top middle point of rectangle
        x: left + width / 2,
        y: top,
      },
      {
        // Left middle point of rectangle
        x: left,
        y: top + height / 2,
      },
      {
        // Bottom middle point of rectangle
        x: left + width / 2,
        y: top + height,
      },
      {
        // Right middle point of rectangle
        x: left + width,
        y: top + height / 2,
      },
    ];
  }

  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }

  passiveCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }

  enabledCallback(element) {
    element.removeEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }

  disabledCallback(element) {
    element.removeEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }
}
