import admin from './Admin.json';
import auth from './Auth.json';
import errors from './Errors.json';
import general from './General.json';
import pages from './Pages.json';
import report from './Report.json';
import study from './Study.json';
import viewer from './Viewer.json';
import offline from './Offline.json';
import frigade from './Frigade.json';
export default {
    admin: admin,
    auth: auth,
    errors: errors,
    general: general,
    pages: pages,
    report: report,
    study: study,
    viewer: viewer,
    offline: offline,
    frigade: frigade,
};
