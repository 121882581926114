import cornerstone from 'cornerstone-core';
import { BoundingBox } from '~/src/interfaces/BoundingBox';
import { getEnabledElementControlled } from './getEnabledElementControlled';
export const scaleMgImage = (enabledElement, boundingBox: BoundingBox | null = null) => {
  const { canvas, viewport } = getEnabledElementControlled(enabledElement) ?? {};

  if (!canvas || !viewport?.scale || !boundingBox?.isValid) return;

  const currentScale = viewport.scale;
  const boundingBoxWidth = boundingBox?.width || 0;
  const boundingBoxHeight = boundingBox?.height || 0;
  const canvasWidth = canvas.clientWidth;
  const canvasHeight = canvas.clientHeight;

  const optimalScale = getBoundingBoxToFitViewportScale({
    boxWidth: boundingBoxWidth,
    boxHeight: boundingBoxHeight,
    viewportWidth: canvasWidth,
    viewportHeight: canvasHeight,
    originalSclae: currentScale,
  });

  viewport.scale = optimalScale;
  cornerstone.setViewport(enabledElement, viewport);
};

const getBoundingBoxToFitViewportScale = (values: {
  boxWidth: number;
  boxHeight: number;
  viewportWidth: number;
  viewportHeight: number;
  originalSclae: number;
}) => {
  const boxAspectRatio = values.boxWidth / values.boxHeight;
  const viewportAspectRatio = values.viewportWidth / values.viewportHeight;

  let newScale = values.originalSclae;
  let scaledWidth = values.originalSclae;

  if (boxAspectRatio > viewportAspectRatio) {
    scaledWidth = values.viewportWidth;
  } else {
    scaledWidth = values.viewportHeight * boxAspectRatio;
  }

  newScale = scaledWidth / values.boxWidth;

  return newScale;
};
