var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClipboard } from 'use-clipboard-copy';
import { COPY_CHANGE_TEXT_TIME } from '@eva-pacs/core';
import Button from '../Button';
/**
 * Use this component to copy a text into the clipboard
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-09-05
 */
export var ClipboardCopyButton = function (_a) {
    var _b = _a.btnLabelCopied, btnLabelCopied = _b === void 0 ? '' : _b, _c = _a.btnLabelCopy, btnLabelCopy = _c === void 0 ? '' : _c, textToCopy = _a.textToCopy, onCopyBtnClick = _a.onCopyBtnClick, onCopied = _a.onCopied, restOfProps = __rest(_a, ["btnLabelCopied", "btnLabelCopy", "textToCopy", "onCopyBtnClick", "onCopied"]);
    var t = useTranslation().t;
    var clipboard = useClipboard({ copiedTimeout: COPY_CHANGE_TEXT_TIME });
    useEffect(function () {
        if (onCopied)
            onCopied(clipboard.copied);
    }, [clipboard.copied]);
    var handleClick = function () {
        clipboard.copy(textToCopy);
        if (onCopyBtnClick)
            onCopyBtnClick(textToCopy);
    };
    var renderBtnLabel = function () {
        var copied = btnLabelCopied === '' ? t('general.copied') : btnLabelCopied;
        var copy = btnLabelCopy === '' ? t('general.copy') : btnLabelCopy;
        return clipboard.copied ? copied : copy;
    };
    return (React.createElement(Button, __assign({ onClick: handleClick }, restOfProps), renderBtnLabel()));
};
