import React, { useState } from 'react';
import ReactCarousel, { arrowsPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import cn from 'classnames';
import Button, { ButtonColor, ButtonSize } from '../../atoms/Button';
import { IconCatalog } from '../../atoms/Icon';
import Image from '../../atoms/Image';
import { Spinner } from '../../atoms/Spinner';
/**
 * Use this component in order to show a Carousel of images
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-06-24
 */
export var Carousel = function (_a) {
    var ariaLabel = _a.ariaLabel, className = _a.className, _b = _a.images, images = _b === void 0 ? [] : _b, _c = _a.loading, loading = _c === void 0 ? false : _c;
    var carouselClass = cn(className, 'relative mx-8');
    var _d = useState(0), value = _d[0], setValue = _d[1];
    var renderImageList = images.map(function (image) { return (React.createElement(Image, { key: image.id, src: image.imageUrl, style: { maxHeight: 500 }, alt: image.title })); });
    var renderThumbnailList = images.map(function (image) { return (React.createElement("div", { key: image.id, className: "carousel-thumbnail-image w-14 h-14 rounded-lg overflow-hidden" },
        React.createElement(Image, { src: image.imageUrl, alt: "".concat(image.title, " thumbnail"), hasMaxWidth: true }))); });
    var handleChange = function (value) { return setValue(value); };
    if (loading)
        return React.createElement(Spinner, null);
    if (images.length === 0)
        return (
        // eslint-disable-next-line i18next/no-literal-string
        React.createElement("div", { "aria-label": ariaLabel, className: "text-basic-white" }, "NO IMAGES"));
    return (React.createElement("div", { "aria-label": ariaLabel, className: carouselClass },
        React.createElement(ReactCarousel, { className: "mb-8", value: value, onChange: handleChange, plugins: [
                {
                    resolve: arrowsPlugin,
                    options: {
                        arrowLeft: (React.createElement(Button, { color: ButtonColor.dark, size: ButtonSize.md, leftIcon: IconCatalog.arrowLeft, isRounded: true })),
                        arrowLeftDisabled: (React.createElement(Button, { color: ButtonColor.dark, size: ButtonSize.md, leftIcon: IconCatalog.arrowLeft, isRounded: true, isDisabled: true })),
                        arrowRight: (React.createElement(Button, { color: ButtonColor.dark, size: ButtonSize.md, leftIcon: IconCatalog.arrowRight, isRounded: true })),
                        arrowRightDisabled: (React.createElement(Button, { color: ButtonColor.dark, size: ButtonSize.md, leftIcon: IconCatalog.arrowRight, isRounded: true, isDisabled: true })),
                        addArrowClickHandler: true,
                    },
                },
            ] }, renderImageList),
        React.createElement(Dots, { value: value, onChange: handleChange, thumbnails: renderThumbnailList })));
};
