import React from 'react';
/**
 * Use this component in order to show a Caption on Modals
 * @author Ricardo Alfonso Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-08-17
 */
var Caption = function (_a) {
    var caption = _a.caption;
    return (React.createElement("p", { className: "text-basic-white text-subtitle font-semi-bold tracking-tight" }, caption));
};
export default Caption;
