import React, { useState } from 'react';
import { setMaxHeightByOptions } from '@eva-pacs/core';
import Icon, { IconCatalog } from '../Icon';
import { Checkbox } from '../Checkbox';
import Popover, { PopoverPlacement } from '../Popover';
import { containerClass, multiSelectClass, iconContainerClass } from './styles';
export var MultiSelectSize;
(function (MultiSelectSize) {
    MultiSelectSize["xs"] = "xs";
    MultiSelectSize["sm"] = "sm";
    MultiSelectSize["md"] = "md";
    MultiSelectSize["lg"] = "lg";
})(MultiSelectSize || (MultiSelectSize = {}));
/**
 * This component could be thought of as a styled native select tag, but adding to it more functionalities such as filtering, multi selecting, among others.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-09-01
 */
export var MultiSelect = function (_a) {
    var dataTestId = _a.dataTestId, placeholder = _a.placeholder, name = _a.name, _b = _a.value, value = _b === void 0 ? [] : _b, size = _a.size, options = _a.options, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, className = _a.className, onChange = _a.onChange, onSelectClick = _a.onSelectClick;
    var _d = useState(value), selectedOptions = _d[0], setSelectedOptions = _d[1];
    var _e = useState(false), isOpen = _e[0], setIsOpen = _e[1];
    var handleCheckboxChange = function (option) { return function (event) {
        var checked = event.target.checked;
        var newList;
        if (checked)
            newList = addOption(option);
        else
            newList = removeOption(option);
        if (onChange)
            onChange(newList, name);
    }; };
    var handleClickOutside = function () { return setIsOpen(false); };
    var handleSelectClick = function () {
        setIsOpen(!isOpen);
        if (onSelectClick)
            onSelectClick();
    };
    var handleCloseTagClick = function (option) { return function () {
        var newList = removeOption(option);
        if (onChange)
            onChange(newList, name);
    }; };
    var addOption = function (option) {
        var newList = selectedOptions.concat(option);
        setSelectedOptions(newList);
        return newList;
    };
    var removeOption = function (option) {
        var newList = selectedOptions.filter(function (item) { return item.value !== option.value; });
        setSelectedOptions(newList);
        return newList;
    };
    var renderTags = selectedOptions.map(function (option, index) { return (React.createElement("div", { key: index, className: "z-10 flex items-center m-0.5 p-1 bg-gray-800 rounded space-x-1" },
        React.createElement("span", { className: "px-2 text-basic-white text-caption font-regular truncate" }, option.label),
        React.createElement("button", { onClick: handleCloseTagClick(option) },
            React.createElement(Icon, { className: "hover:text-basic-white text-gray-100 cursor-pointer", icon: IconCatalog.close, width: "14", height: "14" })))); });
    var renderOptions = options.map(function (option, index) { return (React.createElement("div", { key: index, role: "option", className: "flex items-center px-3 py-2 hover:bg-gray-700 space-x-3", "aria-selected": selectedOptions.some(function (item) { return item.value === option.value; }) },
        React.createElement(Checkbox, { id: option.label, name: name, onChange: handleCheckboxChange(option), checked: selectedOptions.some(function (item) { return item.value === option.value; }) }),
        React.createElement("label", { htmlFor: option.label, className: "flex w-full text-basic-white text-label-md" }, option.label))); });
    var popoverContent = (React.createElement("div", { role: "listbox", style: setMaxHeightByOptions({ listLength: options.length, maxOptions: 6, maxHeight: '210px' }), className: "scrollbar-w-2 scrollbar-thumb-gray-200 scrollbar-track-gray-700 scrollbar-thumb-rounded-lg flex-grow py-2 w-full bg-gray-600 border border-gray-300 rounded-md overflow-y-auto" }, renderOptions));
    return (React.createElement(Popover, { isOpen: isOpen, className: containerClass(className), menuClassName: "min-w-52", content: popoverContent, placement: PopoverPlacement.bottomLeft, onClickOutside: handleClickOutside, hasArrow: false },
        React.createElement("div", { role: "button", tabIndex: 0, className: multiSelectClass(size), "aria-disabled": isDisabled, "aria-haspopup": "listbox", "aria-expanded": isOpen, "data-testid": dataTestId, onClick: handleSelectClick, onKeyPress: handleSelectClick },
            React.createElement("div", { className: "flex flex-wrap items-center" }, selectedOptions.length !== 0 ? renderTags : placeholder),
            React.createElement("div", { className: iconContainerClass(size) },
                React.createElement(Icon, { className: "text-gray-50", icon: IconCatalog.caretDown, width: "24px", height: "24px" })))));
};
