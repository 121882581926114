import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { CopyTooltip } from '@evacenter/eden';
import { useLanguage } from '@eva-pacs/app-pacs/src/hooks/useLanguage';
var DEFAULT_TRUNCATE_LENGTH = 30;
/**
 * Title and Description Cell (Table)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-27
 */
export var TitleDescriptionCell = function (_a) {
    var title = _a.title, titleHref = _a.titleHref, _b = _a.titleExternalLink, titleExternalLink = _b === void 0 ? false : _b, titleOnClick = _a.titleOnClick, description = _a.description, descriptionPrefix = _a.descriptionPrefix, className = _a.className;
    var cellClass = cn(className, 'e-flex e-flex-col');
    var currentLanguage = useLanguage().currentLanguage;
    var isTruncated = function (text) {
        if (!text)
            return false;
        return text.length > DEFAULT_TRUNCATE_LENGTH;
    };
    var isIdTruncated = isTruncated(description || '');
    var renderTitle = function () {
        if (titleHref && titleExternalLink)
            return (React.createElement("a", { className: "inline-flex mb-1 text-basic-white underline text-caption", href: titleHref, target: "_blank", rel: "noreferrer" }, title));
        if (titleHref && !titleExternalLink)
            return (React.createElement(Link, { className: "inline-flex mb-1 text-basic-white underline text-caption", to: titleHref }, title));
        if (titleOnClick)
            return (React.createElement("button", { className: "inline-flex mb-1 text-left text-basic-white underline text-caption", onClick: titleOnClick }, title));
        return React.createElement("span", { className: "inline-flex mb-1 text-basic-white text-caption" }, title);
    };
    return (React.createElement("div", { className: cellClass },
        renderTitle(),
        description && (React.createElement(CopyTooltip, { className: "inline-flex", textToCopy: description, locale: currentLanguage, title: isIdTruncated ? descriptionPrefix : undefined, description: isIdTruncated ? description : undefined },
            React.createElement("div", { className: "e-flex e-flex-col e-truncate hover:text-light-gray-300 text-light-gray-600 text-caption font-regular" },
                React.createElement("span", null, descriptionPrefix),
                React.createElement("span", { className: "e-inline-block e-truncate" }, description))))));
};
