export var UPLOAD_STUDY_ATTACHMENT_FILES_PATH = '/uploadStudyAttachmentFiles';
export var ACTIVATE_PATH = '/activate/:token';
export var ADMIN_PATH = '/admin';
export var ADMIN_INVITE_PATH = '/admininvite';
export var EMAIL_VALIDATION_PATH = '/email-validation/:token';
export var FOUND_SERIES_PATH = '/foundseries';
export var HOME_PATH = '/';
export var LOGIN_PATH = '/login';
export var LEGACY_LOGIN_PATH = '/legacy-login';
export var PASSWORD_RESET_PATH = '/password-reset/:token';
export var RECOVERY_PASSWORD_PATH = '/recovery-password/:token';
export var REPORT_FULL_SCREEN_PATH = '/reportfullscreen';
export var REPORT_SUBMISSION_PATH = '/reportsubmission';
export var REQUEST_RECOVERY_PASSWORD_PATH = '/request-recovery-password';
export var REVIEW_SIGNUP_PATH = '/review-sign-up';
export var SIGNUP_PATH = '/signup';
export var STUDY_LIST_PATH = '/studylist';
export var UPLOAD_STUDY_PATH = '/uploadstudy';
export var VIEWER_PATH = '/viewer/';
