import cornerstone from 'cornerstone-core';
const WIDTH_THUMBNAIL_LIMIT = 250;

const scaleHeight = (originalWidth: number, originalHeight: number, maxWidth: number): number => {
  if (!originalWidth) return 0;
  const aspectRatio = originalHeight / originalWidth;
  return maxWidth * aspectRatio;
};

/**
 * Creates a thumbnail image source (data URL) from a given image.
 * It scales the image proportionally to fit within the provided width limit while preserving the aspect ratio.
 *
 * @param {HTMLImageElement | HTMLCanvasElement} image - The image or canvas element to create the thumbnail from.
 * @param {number} [widthLimit=WIDTH_THUMBNAIL_LIMIT] - The maximum width for the thumbnail. Defaults to a constant value `WIDTH_THUMBNAIL_LIMIT`.
 * @returns {string} - A data URL representing the thumbnail image.
 */

export const createThumbnailImageSrcFromImage = (image, widthLimit = WIDTH_THUMBNAIL_LIMIT) => {
  const canvas = document.createElement('canvas');
  const height = scaleHeight(image.width, image.height, widthLimit);
  canvas.width = widthLimit;
  canvas.height = height;
  cornerstone.renderToCanvas(canvas, image);
  return canvas.toDataURL();
};
