import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  useSendMailViewerLinkMutation,
  useSendMessageViewerLinkMutation,
  useGetPatientByStudyQuery,
  useViewerLinkQuery,
  useGetFacilityByStudyQuery,
} from '@eva-pacs/client';
import { useModal, Modals } from '@eva-pacs/core';
import { Spinner } from '@eva-pacs/ui';

import { PreviewQRModal } from './PreviewQRModal';
import { useErrorHandler } from '~/utils/appHelpers';
import { SendDICOMViewerForm, SendDICOMViewerFormFields } from '../SendDICOMViewerForm';

export interface PreviewQRStudyModalProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Study id
   */
  studyId: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
}

/**
 * Use this component in order to show the preview the Study's DICOM files
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-08-31
 */
export const PreviewQRStudyModal: React.FC<PreviewQRStudyModalProps> = ({ ariaLabel, studyId, className }) => {
  const modalClass = cn(className);
  const { t } = useTranslation();
  const { setCurrentModal } = useModal();
  const { handleError } = useErrorHandler();
  const [patientPhone, setPatientPhone] = useState('');
  const [patientPhoneCode, setPatientPhoneCode] = useState('');
  const [facilityCountryIsoCode, setFacilityCountryIsoCode] = useState('');
  const [facilityCountryPhoneCode, setFacilityCountryPhoneCode] = useState('');

  const [sendMail, { loading: sendingEmail }] = useSendMailViewerLinkMutation();
  const [sendMessage, { loading: sendingMessage }] = useSendMessageViewerLinkMutation();

  const { data: patient, loading: loadingPatient } = useGetPatientByStudyQuery({
    variables: {
      studyId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: facility, loading: loadingFacility } = useGetFacilityByStudyQuery({
    variables: {
      studyId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (patient) {
      setPatientPhone(patient.study?.patient.phoneNumber ?? '');
      setPatientPhoneCode(patient.study?.patient.phoneCode ?? '');
    }
  }, [patient]);

  useEffect(() => {
    if (facility) {
      setFacilityCountryPhoneCode(facility.study?.facility?.country?.phoneCode ?? '');
      setFacilityCountryIsoCode(facility.study?.facility?.country?.isoCode ?? '');
    }
  }, [facility]);

  const { data: viewerLink, loading: loadingViewerLink } = useViewerLinkQuery({
    variables: {
      studyId,
    },
    fetchPolicy: 'no-cache',
  });

  const sendDICOMViewerFormFields: SendDICOMViewerFormFields = {
    email: patient?.study?.patient.email || '',
    phoneObj: {
      phone: patientPhone,
      phoneCode: patientPhoneCode || facilityCountryPhoneCode,
      isoCode: patientPhone ? patient?.study?.patient.phoneCountry?.isoCode : facilityCountryIsoCode,
    },
  };

  const handleSubmit = async (email: string, phoneObj: { phone: string; phoneCode: string }) => {
    let success = false;

    try {
      if (email) {
        const { data: sendEmailData } = await sendMail({
          variables: {
            id: studyId,
            email: email,
          },
        });

        if (!sendEmailData?.sendMailViewerLink.success) throw new Error(sendEmailData?.sendMailViewerLink.errors);

        // Success
        success = true;
      }
    } catch (error) {
      handleError({ logMessage: error });
    }
    try {
      if (phoneObj.phone) {
        const { data: sendMessageData } = await sendMessage({
          variables: {
            id: studyId,
            phone: phoneObj.phone,
            phoneCode: phoneObj.phoneCode,
          },
        });

        if (!sendMessageData?.sendMessageViewerLink.success)
          throw new Error(sendMessageData?.sendMessageViewerLink.errors);

        // Success
        success = true;
      }
    } catch (error) {
      handleError({ logMessage: error });
    }

    if (!success)
      return handleError({
        modalProps: {
          onClose: () => setCurrentModal(null),
          description: t('errors.formFields.emailAndPhone'),
        },
      });

    // Show Success message
    setCurrentModal({
      name: Modals.SuccessModal,
      props: {
        description: t('pages.studyList.sentQRSuccessfully'),
        onClose: (): void => {
          setCurrentModal(null);
        },
      },
    });
  };

  if (loadingPatient || loadingFacility || loadingViewerLink) return <Spinner />;

  return (
    <div aria-label={ariaLabel} className={modalClass}>
      <PreviewQRModal studyId={studyId} url={viewerLink?.viewerLink?.viewerLink as string} />
      <hr className="my-10 border border-gray-500" />
      <SendDICOMViewerForm
        className="relative mb-8"
        defaultFieldsValue={sendDICOMViewerFormFields}
        isLoading={sendingEmail || sendingMessage}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
