import React from 'react';
import { actionClass, actionLabelClass } from './styles';
import Icon from '../../../atoms/Icon';
/**
 * Button Item (Toolbar item)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-12
 */
var ButtonItem = function (_a) {
    var icon = _a.icon, label = _a.label, className = _a.className, _b = _a.liteMode, liteMode = _b === void 0 ? false : _b, onClick = _a.onClick;
    var handleClick = function () {
        if (onClick)
            onClick();
    };
    return (React.createElement("div", { role: "button", className: actionClass(className), onClick: handleClick, onKeyPress: handleClick, tabIndex: 0 },
        React.createElement(Icon, { icon: icon, width: "24px", height: "24px" }),
        !liteMode && React.createElement("span", { className: actionLabelClass }, label)));
};
export default ButtonItem;
