import csTools, { EVENTS } from 'cornerstone-tools';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import { Markers } from './Markers';
import { ToolName } from '~/src/constants/toolName';
import drawMarker from './drawMarker';
import { CORNERSTONE_SPINE_LABEL_EVENT } from '~/constants';
import { useSpineLabelStore } from '~/src/store/spineLabelStore';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');

const imagePointToPatientPoint = csTools.importInternal('util/imagePointToPatientPoint');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const throttle = csTools.importInternal('util/throttle');
const triggerEvent = csTools.importInternal('util/triggerEvent');
const draw = csTools.importInternal('drawing/draw');
const drawHandles = csTools.importInternal('drawing/drawHandles');

const { textMarkerCursor } = csTools.importInternal('tools/cursors');

export interface SpineLabelConfiguration {
  markers: Array<string>;
  loop: boolean;
}

/**
 * @public
 * @class SpineLabelTool
 * @memberof Tools.Annotation
 * @classdesc Create a label and show in other viewports.
 * @extends BaseAnnotationTool
 * @author Alejandro Forero <alejandro.forero@edenmed.com>
 * Created at 2023-10-30
 */
export default class SpineLabelTool extends BaseAnnotationTool {
  name;
  configuration;
  throttledUpdateCachedStats;

  constructor(props) {
    const configuration: SpineLabelConfiguration = {
      markers: Object.keys(Markers).map((key) => Markers[key]),
      loop: false,
    };
    const defaultProps = {
      name: ToolName.SpineLabel,
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration,
      svgCursor: textMarkerCursor,
    };

    super(props, defaultProps);
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const config: SpineLabelConfiguration = this.configuration;
    const element = eventData.element;
    const current = useSpineLabelStore.getState().current;
    if (!current) {
      return;
    }

    // Create the measurement data for this tool with the end handle activated
    const measurementData = {
      visible: true,
      active: true,
      text: current,
      color: undefined,
      patientPoint: undefined,
      handles: {
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
          hasBoundingBox: true,
        },
      },
    };

    // Create a rectangle representing the image
    const imageRect = {
      left: 0,
      top: 0,
      width: eventData.image.width,
      height: eventData.image.height,
    };

    // Check if the current handle is outside the image,
    // If it is, prevent the handle creation
    if (!cornerstoneMath.point.insideRect(measurementData.handles.end, imageRect)) {
      return;
    }

    const sourceImageId = eventData?.image?.imageId;
    if (!sourceImageId) return;
    // Get currentPoints from mouse cursor on selected element
    const sourceImagePoint = eventData.currentPoints.image;

    // Transfer this to a patientPoint given imagePlane metadata
    measurementData.patientPoint = _calculatePatientPosition(sourceImageId, sourceImagePoint);

    // Update the current marker for the next marker
    let currentIndex = config.markers.indexOf(current);

    const interval = useSpineLabelStore.getState().interval;
    const ascending = useSpineLabelStore.getState().ascending;
    const increment = ascending ? interval : -interval;

    currentIndex += increment;

    if (currentIndex >= config.markers.length) {
      currentIndex = config.loop ? 0 : -1;
    } else if (currentIndex < 0) {
      currentIndex = config.loop ? config.markers.length : -1;
    }
    const setCurrent = useSpineLabelStore.getState().setCurrent;
    setCurrent(config.markers[currentIndex]);

    const allMeasurements = csTools.globalImageIdSpecificToolStateManager.saveToolState();

    if (allMeasurements) {
      Object.keys(allMeasurements).forEach((imageId) => {
        const toolState = csTools.globalImageIdSpecificToolStateManager.getImageIdToolState(
          imageId,
          ToolName.SpineLabel,
        );
        if (!toolState?.data) return;
        const markerIndex = toolState.data.findIndex((data) => data.text === measurementData.text);
        if (markerIndex !== -1) {
          const data = toolState.data[markerIndex];
          toolState.data.splice(markerIndex, 1);
          const eventType = EVENTS.MEASUREMENT_REMOVED;
          const eventData = {
            toolName: ToolName.SpineLabel,
            toolType: ToolName.SpineLabel,
            element,
            measurementData: data,
          };
          triggerEvent(element, eventType, eventData);
        }
      });
    }
    triggerEvent(element, CORNERSTONE_SPINE_LABEL_EVENT);
    return measurementData;
  }

  pointNearTool(element, data, coords) {
    if (!data.visible || !data?.handles?.end) {
      return false;
    }
    const labelCoords = cornerstone.pixelToCanvas(element, data.handles.end);
    const distance = cornerstoneMath.point.distance(labelCoords, coords);

    return distance < 8;
  }

  updateCachedStats(image, element, data) {
    const patientPosition = _calculatePatientPosition(image.imageId, data.handles.end);
    data.patientPoint = patientPosition;
  }

  renderToolData(evt) {
    const eventData = evt.detail;

    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = csTools.getToolState(eventData.element, this.name);
    if (!toolData) {
      return;
    }

    const context = getNewContext(eventData.canvasContext.canvas);
    // Render current markers
    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      if (data.visible === false) {
        continue;
      }
      const color = csTools.toolColors.getColorIfActive(data);
      draw(context, (context) => {
        // Draw the handles
        const handleOptions: any = {
          color,
          handleRadius: 1,
        };

        drawHandles(context, eventData, data.handles, handleOptions, color);
      });
      drawMarker({
        context,
        element: eventData.element,
        data,
        markerPoints: data.handles.end,
      });
    }
  }
}

function _calculatePatientPosition(imageId, imagePoint) {
  try {
    const sourceImageId = imageId;
    if (!sourceImageId) return;
    const sourceImagePlane = cornerstone.metaData.get('imagePlaneModule', sourceImageId);
    if (!sourceImagePlane) return;
    // Get currentPoints from mouse cursor on selected element
    const sourceImagePoint = imagePoint;

    // Transfer this to a patientPoint given imagePlane metadata
    return imagePointToPatientPoint(sourceImagePoint, sourceImagePlane);
  } catch {
    return;
  }
}
