var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, forwardRef } from 'react';
import { Radio } from '../Radio';
import { RadioCard } from '../RadioCard';
import { radioGroupClass } from './styles';
import { recursiveChildrenMap } from '../../utils';
export var DEFAULT_RADIO_GROUP_ID = 'radio-group';
/**
 * Use RadioGroup to control the selection of Radio or RadioCard components.
 * RadioGroup should be used to centralize the .
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-04-28
 */
export var RadioGroup = forwardRef(function (_a, ref) {
    var children = _a.children, className = _a.className, defaultValue = _a.defaultValue, name = _a.name, onChange = _a.onChange;
    var _b = useState(defaultValue), currentSelection = _b[0], setCurrentSelection = _b[1];
    return (React.createElement("div", { ref: ref, className: radioGroupClass(className), "data-testid": DEFAULT_RADIO_GROUP_ID }, recursiveChildrenMap(children, function (child) {
        // Prevent non Radio | RadioCard childs from receive radio props
        if (child.type !== Radio && child.type !== RadioCard)
            return child;
        var _a = child.props, value = _a.value, restChildProps = __rest(_a, ["value"]);
        var isChecked = "".concat(currentSelection) === "".concat(value);
        var nameProp = name || DEFAULT_RADIO_GROUP_ID;
        var handleChange = function (e) {
            setCurrentSelection("".concat(e.target.value));
            onChange && onChange(e);
        };
        if (child.type === Radio) {
            // Remove children if child is of type `Radio`
            // to avoid potential issues rendering a children within an <input />
            restChildProps.children = undefined;
        }
        return React.cloneElement(child, __assign(__assign({}, restChildProps), { value: value, name: nameProp, checked: isChecked, onChange: handleChange }));
    })));
});
