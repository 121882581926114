var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { sliderClass, thumbClass, fillClass } from './styles';
import { Range } from 'react-range';
export var SliderColor;
(function (SliderColor) {
    SliderColor["primary"] = "primary";
    SliderColor["danger"] = "danger";
})(SliderColor || (SliderColor = {}));
/**
 * Sliders provide a visual indication of adjustable content, where the user can increase or decrease the value by moving the handle along a horizontal track.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-04-20
 */
export var Slider = function (_a) {
    var _b = _a.min, min = _b === void 0 ? 0 : _b, _c = _a.max, max = _c === void 0 ? 100 : _c, _d = _a.step, step = _d === void 0 ? 0.1 : _d, value = _a.value, className = _a.className, _e = _a.color, color = _e === void 0 ? SliderColor.primary : _e, onChange = _a.onChange;
    var handleSliderChange = function (val) {
        if (onChange)
            onChange(val[0]);
    };
    /* Render JSX */
    return (React.createElement("div", { className: sliderClass(className) },
        React.createElement(Range, { renderTrack: function (_a) {
                var props = _a.props, children = _a.children;
                return (React.createElement("div", __assign({}, props, { className: fillClass(color) }), children));
            }, renderThumb: function (_a) {
                var props = _a.props;
                return React.createElement("div", __assign({}, props, { className: thumbClass }));
            }, min: min, max: max, step: step, onChange: handleSliderChange, values: [value] })));
};
