export var ENDPOINT_PATH;
(function (ENDPOINT_PATH) {
    ENDPOINT_PATH["UPLOAD_REPORT_IMAGE"] = "report-images";
    ENDPOINT_PATH["UPLOAD_ADDENDUM_IMAGE"] = "addendum-images";
    ENDPOINT_PATH["SIGNATURE_FILES"] = "practitioners";
    ENDPOINT_PATH["INSTANCE_FILES"] = "dicom-files";
    ENDPOINT_PATH["REPORTS_FILES"] = "reports";
    ENDPOINT_PATH["STUDY_FILES"] = "study-files";
    ENDPOINT_PATH["DICOM_PRINTS"] = "dicom-prints";
})(ENDPOINT_PATH || (ENDPOINT_PATH = {}));
