import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '@eva-pacs/app-pacs/src/hooks/useLanguage';
import { formatDateParts, compareTimezones, formattedRangeTime, formatStringDistance, formatDateToLocalizedString, } from '@eva-pacs/core/lib/utils/dateFormatter';
import { TagV2, TagV2Type, TagV2Appearance, DynamicTooltip, BaseTooltip, TagV2Size } from '@evacenter/eden';
/**
 * `DateAndTimeCell` displays a localized date with a formatted time
 * example:
 * ```string
 * 17/oct/2022
 * 5:30 pm
 * 1 day ago
 * ```
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-10-17
 */
export var DateAndTimeCell = function (_a) {
    var _b = _a.date, date = _b === void 0 ? [] : _b, _c = _a.showTime, showTime = _c === void 0 ? true : _c, timezone = _a.timezone;
    var t = useTranslation().t;
    var currentLanguage = useLanguage().currentLanguage;
    var rawFirstDate = date[0], rawSecondDate = date[1];
    if (!rawFirstDate)
        return null;
    var formattedFirstDate = formatDateParts(rawFirstDate, timezone);
    var timezoneDiff = timezone ? compareTimezones(rawFirstDate, timezone, currentLanguage) : null;
    var renderTooltipContent = function () {
        var userDate = formatDateParts(rawFirstDate);
        return (React.createElement("div", { className: "e-flex e-flex-col e-gap-1" },
            React.createElement(BaseTooltip.Title, null, t('study.studyTable.time.prefixTooltip', { gmt: timezoneDiff === null || timezoneDiff === void 0 ? void 0 : timezoneDiff.gmtoffsetUser })),
            React.createElement("div", { className: "e-flex e-flex-col" },
                React.createElement(BaseTooltip.Description, { className: "e-text-xs e-font-normal" }, formatDateToLocalizedString(userDate.date, currentLanguage)),
                React.createElement(BaseTooltip.Description, null, formattedRangeTime(rawFirstDate, rawSecondDate)),
                !rawSecondDate && (React.createElement(BaseTooltip.Description, null, formatStringDistance(rawFirstDate, currentLanguage))))));
    };
    return (React.createElement("div", { className: "py-2" },
        React.createElement("div", { className: "e-text-neutral-50 e-text-xs" }, formatDateToLocalizedString(formattedFirstDate.date, currentLanguage)),
        React.createElement("div", { className: "e-flex e-items-center e-text-neutral-200 mt-1.5" },
            showTime && React.createElement("span", { className: "e-text-xs" }, formattedRangeTime(rawFirstDate, rawSecondDate, timezone)),
            !(timezoneDiff === null || timezoneDiff === void 0 ? void 0 : timezoneDiff.isSameTimezone) && (React.createElement(DynamicTooltip, { content: renderTooltipContent() },
                React.createElement("div", { className: "e-flex e-ml-2" },
                    React.createElement(TagV2, { type: TagV2Type.warning, appearance: TagV2Appearance.moderate, size: TagV2Size['2xs'] },
                        t('GMT'), timezoneDiff === null || timezoneDiff === void 0 ? void 0 :
                        timezoneDiff.gmtoffset))))),
        (timezoneDiff === null || timezoneDiff === void 0 ? void 0 : timezoneDiff.isSameTimezone) && (React.createElement("div", { className: "e-text-neutral-50 e-mt-1.5 e-text-2xs" }, formatStringDistance(rawFirstDate, currentLanguage)))));
};
