import { IconCatalog as EdenIconCatalog } from '@evacenter/eden';
import cornerstoneMath from 'cornerstone-math';
import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import { MIN_DISTANCE_FOR_VALID_POINT } from '~/constants';
import drawMammographyReferencePoint from './drawMammographyReferencePoint';
import drawMammographyReferenceLines from './drawMammographyReferenceLines';
import { ToolName } from '~/src/constants/toolName';
import { getEnabledElementControlled } from './getEnabledElementControlled';
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const MouseCursor = csTools.importInternal('tools/cursors/MouseCursor');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const getLogger = csTools.importInternal('util/getLogger');
const logger = getLogger('tools:annotation:ProbeTool');
const waitForEnabledElementImageToLoad = csTools.importInternal('util/waitForEnabledElementImageToLoad');

/**
 * @public
 * @class MammographyReferenceLinesTool
 * @memberof Tools.MammographyReferenceLinesTool
 * @classdesc Tool for drawing cross reference lines.
 * @extends Tools.Base.BaseAnnotationTool
 */

const TOOL_NAME = ToolName.MammographyReferenceLines;
const TOOL_CURSOR = new MouseCursor(
  `<path fill="ACTIVE_COLOR" fill-rule="evenodd" d="${EdenIconCatalog.crossLines}" clip-rule="evenodd"/> 
`,
  {
    viewBox: {
      x: 24,
      y: 24,
    },
  },
);
export default class MammographyReferenceLinesTool extends BaseAnnotationTool {
  configuration: {
    drawHandles: boolean;
    drawHandlesOnHover: boolean;
    hideHandlesIfMoving: boolean;
    renderDashed: boolean;
  };
  throttledUpdateCachedStats: any;
  renderer: any;
  name;
  synchronizationContext: any;
  constructor(props = {}) {
    const defaultProps = {
      name: TOOL_NAME,
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: TOOL_CURSOR,
      configuration: {
        drawHandles: true,
        renderDashed: false,
      },
    };

    super(props, defaultProps);
    this.configuration = {
      drawHandles: true,
      drawHandlesOnHover: false,
      hideHandlesIfMoving: false,
      renderDashed: false,
    };
    this.synchronizationContext = null;
  }

  createNewMeasurement(eventData) {
    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(`required eventData not supplied to tool ${this.name}'s createNewMeasurement`);

      return;
    }
    const toolState = csTools.getToolState(eventData.element, this.name);
    if (toolState?.data && toolState.data.length >= 1) {
      csTools.clearToolState(eventData.element, this.name);
    }
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  /**
   * Define
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const hasEndHandle = data && data.handles && data.handles.end;
    const validParameters = hasEndHandle;
    if (!validParameters) {
      logger.warn(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);
    }

    if (!validParameters || data.visible === false) {
      return false;
    }

    const probeCoords = cornerstone.pixelToCanvas(element, data.handles.end);

    return cornerstoneMath.point.distance(probeCoords, coords) < MIN_DISTANCE_FOR_VALID_POINT;
  }

  updateCachedStats(image, element, data) {
    const pixelSpacing = getPixelSpacing(image);
    data.cachedStats = pixelSpacing;
    data.invalidated = false;
  }

  async enabledCallback(element, { synchronizationContext } = { synchronizationContext: null }) {
    const enabledElement = await waitForEnabledElementImageToLoad(element);
    if (!enabledElement || !synchronizationContext) {
      logger.warn(`Unable to enable ${this.name}. Exiting enable callback. Tool will be enabled, but will not render.`);
      return;
    }
    this.synchronizationContext = synchronizationContext;
    this.forceImageUpdate(element);
  }

  forceImageUpdate(element) {
    const enabledElement = getEnabledElementControlled(element);
    if (enabledElement?.image) {
      cornerstone.updateImage(element);
    }
  }

  disabledCallback(element) {
    this.forceImageUpdate(element);
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const [sourceElementRef] = this.synchronizationContext?.getSourceElements() || [];

    const enabledElements = cornerstone.getEnabledElements();
    const isSource = sourceElementRef === evt.currentTarget || enabledElements.length === 1;

    const context = getNewContext(eventData.canvasContext.canvas);
    if (isSource) {
      const { data } = csTools.getToolState(evt.currentTarget, this.name) || { data: [] };
      const [referencePoint] = data;
      if (!referencePoint?.remote) {
        if (!referencePoint || !referencePoint?.visible) return;
        const color = csTools.toolColors.getColorIfActive(referencePoint);
        drawMammographyReferencePoint(context, eventData, referencePoint.handles, color);
        return;
      }
    }
    const element = sourceElementRef || evt.currentTarget;
    const { data } = csTools.getToolState(element, this.name) || { data: [] };
    const [referencePoint] = data;
    if (!referencePoint || !referencePoint.visible) return;
    drawMammographyReferenceLines(context, eventData, element, referencePoint);
  }
}
