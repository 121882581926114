import React from 'react';
import cn from 'classnames';
import { GenericModal } from '../GenericModal';
import Caption from '../shared/ModalCaption';
/**
 * Use this component in order to show a success Dialog
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-08-05
 */
var SuccessModal = function (_a) {
    var caption = _a.description, className = _a.className, onClose = _a.onClose;
    var successModalClass = cn(className);
    return (React.createElement(GenericModal, { className: successModalClass, isFullWidth: false, onClose: onClose },
        React.createElement("div", { className: "flex flex-col gap-y-6 items-center mb-6 mx-20" },
            React.createElement("svg", { width: "64", height: "64", viewBox: "0 0 64 64", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("g", { clipPath: "url(#clip0)" },
                    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.9997 7.99992C18.7449 7.99992 7.99969 18.7451 7.99969 31.9999C7.99969 45.2548 18.7449 55.9999 31.9997 55.9999C45.2545 55.9999 55.9997 45.2548 55.9997 31.9999C55.9997 18.7451 45.2545 7.99992 31.9997 7.99992ZM-0.000305176 31.9999C-0.000305176 14.3268 14.3266 -7.62939e-05 31.9997 -7.62939e-05C49.6728 -7.62939e-05 63.9997 14.3268 63.9997 31.9999C63.9997 49.673 49.6728 63.9999 31.9997 63.9999C14.3266 63.9999 -0.000305176 49.673 -0.000305176 31.9999ZM46.8281 21.1715C48.3902 22.7336 48.3902 25.2663 46.8281 26.8284L30.8281 42.8284C29.266 44.3904 26.7334 44.3904 25.1713 42.8284L17.1713 34.8284C15.6092 33.2663 15.6092 30.7336 17.1713 29.1715C18.7334 27.6094 21.266 27.6094 22.8281 29.1715L27.9997 34.3431L41.1713 21.1715C42.7334 19.6094 45.266 19.6094 46.8281 21.1715Z", fill: "#4033F2" })),
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip0" },
                        React.createElement("rect", { width: "64", height: "64", fill: "white" })))),
            caption && React.createElement(Caption, { caption: caption }))));
};
export default SuccessModal;
