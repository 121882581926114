import cn from 'classnames';
import { SliderColor } from '.';
/* Class assignment */
export var sliderClass = function (className) {
    return cn(className, 
    /* Slider (base) */
    'h-1 w-full', 'relative');
};
export var thumbClass = cn(
/* Thumb (base) */
'absolute', 'rounded-full bg-basic-white cursor-pointer w-4 h-4 focus:outline-none');
export var fillClass = function (color) {
    return cn(
    /* Fill (base) */
    'h-1 w-32', 'absolute top-0 left-0', 'rounded-full ', color === SliderColor.primary && 'bg-primary-300', color === SliderColor.danger && 'bg-feedback-negative-500');
};
export var trackClass = cn(
/* Track (base) */
'h-1 w-full', 'absolute', 'rounded-full bg-gray-600');
