import { useMemo } from 'react';
import { addClassByDataId } from '@evacenter/eden';
import { PAGE_SIZE, PAGE_SIZE_INFINITE_SCROLL, REMOVE_STUDY_ANIMATION_DURATION } from '~/constants';
import { useStudyActions } from './useStudyActions';
import { useStudyListStore } from '~/src/store';

export const useRemoveStudy = () => {
  const { performRemoveStudy, performShiftStudiesBackwards } = useStudyActions();

  const [getStudies, enabledInfiniteScroll] = useStudyListStore((state) => [
    state.getStudies,
    state.enabledInfiniteScroll,
  ]);

  const pageSize = useMemo(() => (enabledInfiniteScroll ? PAGE_SIZE_INFINITE_SCROLL : PAGE_SIZE), [
    enabledInfiniteScroll,
  ]);

  const processRemoveStudy = (studyId: string) => {
    const studies = getStudies();
    const studiesArray = Object.entries(studies);
    const totalStudiesAmount = studiesArray.length;

    if (studies[studyId]) {
      // Inlcusive pointer
      // If the below conditions apply, we need to shift studies backwards.
      if (totalStudiesAmount >= pageSize - 1) {
        removeStudyWithDelayAndShift(studyId);
        return;
      }

      return removeStudyWithDelay(studyId);
    }
  };

  const removeStudyWithDelay = (studyId: string) => {
    // Animation of removal lasts REMOVE_STUDY_ANIMATION_DURATION.
    // Remove the study from store only after that happens.
    setTimeout(() => {
      performRemoveStudy(studyId);
    }, REMOVE_STUDY_ANIMATION_DURATION);

    return addRemovalClassToRow(studyId);
  };

  const addRemovalClassToRow = (studyId) => addClassByDataId('table-row-id', studyId, 'eva-fade-out-row-animation');

  const removeStudyWithDelayAndShift = (studyId: string) => {
    setTimeout(() => {
      performRemoveStudy(studyId);
      return performShiftStudiesBackwards();
    }, REMOVE_STUDY_ANIMATION_DURATION);

    return addRemovalClassToRow(studyId);
  };

  const removeStudyWithoutDelay = (studyId: string) => {
    performRemoveStudy(studyId);

    return addRemovalClassToRow(studyId);
  };

  return { processRemoveStudy, removeStudyWithDelay, removeStudyWithoutDelay };
};
