import { EVA_PRODUCT } from '../constants/products';

/**
 * Redirects to a page given a product and its path
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-04-10
 */
export const redirectToProductURL = (product: EVA_PRODUCT, path: string) => {
  const evacenterUrl = process.env['REACT_APP_EVACENTER_URL'];
  window.location.assign(`${evacenterUrl}/${product}/${path}`);
};
