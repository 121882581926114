var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { Checkbox } from '../Checkbox';
/**
 * Checkbox are used when there are multiple items to select in a list. Users can select zero, one, or any number of items.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-21
 */
export var IndeterminateCheckbox = React.forwardRef(function (_a, ref) {
    var indeterminate = _a.indeterminate, className = _a.className, restOfProps = __rest(_a, ["indeterminate", "className"]);
    var indeterminateCheckboxClass = cn(className);
    var defaultRef = useRef();
    var resolvedRef = ref || defaultRef;
    useEffect(function () {
        if (!resolvedRef.current)
            return;
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return React.createElement(Checkbox, __assign({}, restOfProps, { className: indeterminateCheckboxClass, ref: resolvedRef }));
});
