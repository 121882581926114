import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
var appendChild = function (containerEl) { return document.body.appendChild(containerEl); };
var removeChild = function (containerEl) { return document.body.removeChild(containerEl); };
/**
 * Portal component
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-03
 */
var Portal = function (_a) {
    var children = _a.children;
    var containerEl = useState(document.createElement('div'))[0];
    useEffect(function () {
        appendChild(containerEl);
        return function () {
            removeChild(containerEl);
        };
    }, [containerEl]);
    return ReactDOM.createPortal(children, containerEl);
};
export default Portal;
