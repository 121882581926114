import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';

import { REPORT_FULL_SCREEN_PATH, VIEWER_PATH } from '@eva-pacs/core';
import { UserPreferenceInputType } from '@eva-pacs/client';

export interface ScreenPreferences {
  name?: string;
  key: string;
  id?: string;
  windows: Array<WindowOpenPreference>;
}

export enum ScreenType {
  VIEWER = 'VIEWER',
  REPORT = 'REPORT',
}

export interface WindowOpenPreference {
  type: ScreenType;
  name?: string;
  args: string;
}

export const SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_KEY = 'screenPreferencesMachineId';

export const SCREEN_PREFERENCES_SELECTED_ID_LOCAL_STORAGE_KEY = 'screenPreferencesSelectedId';

export const SCREEN_PREFERENCES_CACHE_LOCAL_STORAGE_KEY = 'screenPreferencesCached';

export const SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT = uuidv4();

export const openWindowsWithScreenPreferences = async (screenPreferences: ScreenPreferences, studyId: string) => {
  screenPreferences.windows.forEach((screenPreference, index) => {
    if (screenPreference.type === ScreenType.VIEWER) {
      window.open(`${VIEWER_PATH}${studyId}`, `${VIEWER_PATH}_${index}`, screenPreference.args);
    } else if (screenPreference.type === ScreenType.REPORT) {
      window.open(
        `${REPORT_FULL_SCREEN_PATH}/${studyId}`,
        `${REPORT_FULL_SCREEN_PATH}_${index}`,
        screenPreference.args,
      );
    }
  });
};

export const getPreferenceValues = (preference): ScreenPreferences | undefined => {
  try {
    return {
      ...JSON.parse(preference?.value ?? ''),
      id: preference?.id,
      key: preference.key,
    };
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return undefined;
  }
};

export const handleScreenDuplicationKey = (preference): { key: string; value: string } => {
  const { key: keyToSplit, name = '', windows } = getPreferenceValues(preference) as ScreenPreferences;
  const splitedKey = keyToSplit.split('-');

  const newName = increaseNameTag(name);
  splitedKey[splitedKey.length - 1] = newName;

  const key = splitedKey.join('-');
  const value = JSON.stringify({ windows, name: newName });

  return { key, value };
};

const increaseNameTag = (name: string) => {
  const findTagRegex = /\((\d+)\)$/;

  if (findTagRegex.test(name)) return name.replace(findTagRegex, (_, num) => `(${parseInt(num) + 1})`);
  return `${name} (1)`;
};

export const savedCachedQueryInput = (input?: UserPreferenceInputType) => {
  if (!input) return localStorage.removeItem(SCREEN_PREFERENCES_CACHE_LOCAL_STORAGE_KEY);

  const stringfiedInput = JSON.stringify(input);
  localStorage.setItem(SCREEN_PREFERENCES_CACHE_LOCAL_STORAGE_KEY, stringfiedInput);
};

export const getCachedQueryInput = (): UserPreferenceInputType | undefined => {
  const storageValue = localStorage.getItem(SCREEN_PREFERENCES_CACHE_LOCAL_STORAGE_KEY) as string;

  try {
    return JSON.parse(storageValue);
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return undefined;
  }
};
