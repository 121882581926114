import { ExternalLibUrls } from '../constants/libs';
import type { ExternalLibIds } from '../constants/libs';
import { useLibManagerStore } from '../store/libManagerStore';
import { loadExternalScript } from './headService';

export const useLibManager = () => {
  const { promises, libsLoaded, addLibLoaded, addLibPromise, removeLibPromise } = useLibManagerStore();

  const load = (id: ExternalLibIds): Promise<ExternalLibIds> => {
    // if already loaded return it right away
    if (libsLoaded[id]) return new Promise<ExternalLibIds>((resolve) => resolve(id));

    // if we are currently loading this lib, return its promise
    const currentPrimise = promises[id];
    if (currentPrimise) return currentPrimise;

    // handle success and failure of the Lib request load
    const promise = new Promise<ExternalLibIds>((resolve, reject) => {
      try {
        loadExternalScript(id, ExternalLibUrls[id], () => {
          addLibLoaded(id);
          resolve(id);
        });
      } catch {
        return reject('Error Loading this library');
      }
    });
    promise.then(removeLibPromise);
    addLibPromise(id, promise);
    return promise;
  };

  return { load };
};
