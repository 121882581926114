import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import { ToolName } from '~/src/constants/toolName';
import renderMakers from './renderMakers';
import { getEnabledElementControlled } from '../getEnabledElementControlled';

const getLogger = csTools.importInternal('util/getLogger');
const logger = getLogger('tools:annotation:ProbeTool');
const waitForEnabledElementImageToLoad = csTools.importInternal('util/waitForEnabledElementImageToLoad');
const BaseTool = csTools.importInternal('base/BaseTool');

export default class SpineLabelReferencesTool extends BaseTool {
  name;
  renderer;
  configuration;
  synchronizationContext;
  constructor(props = {}) {
    const defaultProps = {
      name: ToolName.SpineLabelReferences,
      configuration: {
        renderer: renderMakers,
      },
      mixins: ['enabledOrDisabledBinaryTool'],
    };

    super(props, defaultProps);
    this.synchronizationContext = null;
  }

  async enabledCallback(element, { synchronizationContext } = { synchronizationContext: null }) {
    const renderer = this.configuration.renderer;
    const enabledElement = await waitForEnabledElementImageToLoad(element);

    if (!enabledElement || !synchronizationContext) {
      logger.warn(`Unable to enable ${this.name}. Exiting enable callback. Tool will be enabled, but will not render.`);

      return;
    }
    this.renderer = renderer;
    this.synchronizationContext = synchronizationContext;

    this.forceImageUpdate(element);
  }

  disabledCallback(element) {
    this.forceImageUpdate(element);
  }

  forceImageUpdate(element) {
    const enabledElement = getEnabledElementControlled(element);

    if (!enabledElement?.image) return;
    cornerstone.updateImage(element);
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    // No synch context? Adios
    if (!this.renderer || !this.synchronizationContext) {
      return;
    }
    this.renderer(eventData.element);
  }
}
