import cornerstone from 'cornerstone-core';
import dicomParser from 'dicom-parser';

import { ImagePixelData } from '@eva-pacs/client';
import { convertCentimetersToMilimeters, PHYSICAL_UNITS } from '@eva-pacs/core';

import {
  TAG_COLUMNS,
  TAG_FRAME_OF_REFERENCE_UID,
  TAG_IMAGE_ORIENTATION_PATIENT,
  TAG_IMAGE_POSITION_PATIENT,
  TAG_IMAGER_PIXEL_SPACING,
  TAG_MEASURES_SEQUENCE,
  TAG_PER_FRAME_FUNCTIONAL_GROUPS,
  TAG_PHYSICAL_DELTAX,
  TAG_PHYSICAL_DELTAY,
  TAG_PHYSICAL_UNITS_X_DIRECTION,
  TAG_PHYSICAL_UNITS_Y_DIRECTION,
  TAG_PIXEL_SPACING,
  TAG_PLANE_ORIENTATION_SEQUENCE,
  TAG_PLANE_POSITION_SEQUENCE,
  TAG_ROWS,
  TAG_SEQUENCE_OF_ULTRASOUND_REGIONS,
  TAG_SLICE_LOCATION,
  TAG_SLICE_THICKNESS,
} from '~/constants';
import { gbToBytes } from '../appHelpers';

/**
 * An Image Object in Cornerstone
 *
 * @link https://docs.cornerstonejs.org/api.html#image
 */
interface Image {
  /** The imageId associated with this image object */
  imageId: string;

  /** the minimum stored pixel value in the image */
  minPixelValue: number;

  /** the maximum stored pixel value in the image */
  maxPixelValue: number;

  /** the rescale slope to convert stored pixel values to modality pixel values or 1 if not specified */
  slope: number;

  /** the rescale intercept used to convert stored pixel values to modality values or 0 if not specified */
  intercept: number;
}

// Interaction types supported by cornerstone tools
export enum InteractionTypes {
  MOUSE = 'Mouse',
  TOUCH = 'Touch',
}

// Calculate the cross product for two vectors in three dimensional space
export const cross3D = (arrayA: Array<number>, arrayB: Array<number>) => [
  arrayA[1] * arrayB[2] - arrayA[2] * arrayB[1],
  arrayA[2] * arrayB[0] - arrayA[0] * arrayB[2],
  arrayA[0] * arrayB[1] - arrayA[1] * arrayB[0],
];

export const getNumberValues = (element: string | undefined, validateZero = false) => {
  const result = element?.split('\\').map((value) => parseFloat(value));
  if (validateZero && result && result[0] === 0 && result[1] === 0) return;
  return result;
};

export const getNumberValue = (value: string | undefined) => {
  if (value) return parseFloat(value);
};

export const dicomElementHasItems = (element: dicomParser.Element | undefined) => Boolean(element?.items?.length);

export const physicalUnitsAreValid = (dataSet: dicomParser.DataSet) => {
  const [physicalUnitsXDirectionData, physicalUnitsYDirectionData] = getPhysicalUnits(dataSet);
  return (
    physicalUnitsXDirectionData === PHYSICAL_UNITS.CENTIMETERS &&
    physicalUnitsYDirectionData === PHYSICAL_UNITS.CENTIMETERS
  );
};

export const getFrameNumber = (id: string): number | undefined => {
  const frameNumber = id.split('&frame=')[1];
  if (frameNumber) return parseInt(frameNumber);
};

export const getPhysicalUnits = (dataSet) => [
  dataSet.uint16(TAG_PHYSICAL_UNITS_X_DIRECTION),
  dataSet.uint16(TAG_PHYSICAL_UNITS_Y_DIRECTION),
];

/**
 * Get pixel properties from multiframe data
 * @param perFrameFunctionalGroups Frame functional data
 * @returns Return basic pixel metadata
 */
export const getPixelDataFromFrameFunctionalGroups = (
  perFrameFunctionalGroups: dicomParser.Element,
  frameNumber: number,
) => {
  const currentFrameParams = perFrameFunctionalGroups!.items![frameNumber];
  const pixelMeasuresSequence = currentFrameParams.dataSet?.elements[TAG_MEASURES_SEQUENCE];

  let pixelSpacing;
  let rowPixelSpacing;
  let columnPixelSpacing;
  let imagePositionPatient;
  let imageOrientationPatient;

  if (dicomElementHasItems(pixelMeasuresSequence)) {
    pixelSpacing = getNumberValues(pixelMeasuresSequence!.items![0].dataSet?.string(TAG_PIXEL_SPACING));
    [rowPixelSpacing, columnPixelSpacing] = pixelSpacing;
  }
  const planePositionSequence = currentFrameParams.dataSet?.elements[TAG_PLANE_POSITION_SEQUENCE];
  if (dicomElementHasItems(planePositionSequence)) {
    imagePositionPatient = getNumberValues(
      planePositionSequence!.items![0].dataSet?.string(TAG_IMAGE_POSITION_PATIENT),
    );
  }
  const planeOrientationSequence = currentFrameParams.dataSet?.elements[TAG_PLANE_ORIENTATION_SEQUENCE];
  if (dicomElementHasItems(planeOrientationSequence)) {
    imageOrientationPatient = getNumberValues(
      planeOrientationSequence!.items![0].dataSet?.string(TAG_IMAGE_ORIENTATION_PATIENT),
    );
  }
  return {
    pixelSpacing,
    rowPixelSpacing,
    columnPixelSpacing,
    imagePositionPatient,
    imageOrientationPatient,
  };
};

/**
 * Get pixel properties from ultrasound file
 * @param sequenceOfUltrasoundRegions Sequence ultrasound regions data
 * @returns Return basic pixel metadata
 */
export const getPixelDataFromSequenceOfUltrasoundRegions = (sequenceOfUltrasoundRegions: dicomParser.Element) => {
  let rowPixelSpacing;
  let columnPixelSpacing;

  let hasPhysicalAttr = false;

  sequenceOfUltrasoundRegions.items!.forEach((item) => {
    const itemDataSet = item.dataSet as dicomParser.DataSet;
    if (!physicalUnitsAreValid(itemDataSet) || hasPhysicalAttr) return;
    const physicalDeltaXData = convertCentimetersToMilimeters(itemDataSet.double(TAG_PHYSICAL_DELTAX));
    const physicalDeltaYData = convertCentimetersToMilimeters(itemDataSet.double(TAG_PHYSICAL_DELTAY));
    rowPixelSpacing = physicalDeltaXData;
    columnPixelSpacing = physicalDeltaYData;
    hasPhysicalAttr = true;
  });

  return {
    rowPixelSpacing,
    columnPixelSpacing,
  };
};

const getPixelSpacingValid = (value: number[]) => {
  // if array came a single value, we assume it's a square pixel
  if (value.length === 1) return [value[0], value[0]];
  return value;
};

/**
 * Get pixel properties
 * @param dataSet Dicom dataset
 * @param frameNumber Frame number (only in dicom multiframe file)
 * @returns Return basic pixel metadata
 */
export const getPixelData = (dataSet: dicomParser.DataSet, frameNumber: number | undefined) => {
  const imagerPixelSpacing = getNumberValues(dataSet.string(TAG_IMAGER_PIXEL_SPACING));
  const perFrameFunctionalGroups: dicomParser.Element = dataSet.elements[TAG_PER_FRAME_FUNCTIONAL_GROUPS];
  const sequenceOfUltrasoundRegions: dicomParser.Element = dataSet.elements[TAG_SEQUENCE_OF_ULTRASOUND_REGIONS];

  let pixelSpacing = getNumberValues(dataSet.string(TAG_PIXEL_SPACING), true);
  let imageOrientationPatient = getNumberValues(dataSet.string(TAG_IMAGE_ORIENTATION_PATIENT));
  let imagePositionPatient = getNumberValues(dataSet.string(TAG_IMAGE_POSITION_PATIENT));

  let rowPixelSpacing;
  let columnPixelSpacing;

  const isMultiframe = Boolean(dicomElementHasItems(perFrameFunctionalGroups) && frameNumber);
  const isUltrasound = dicomElementHasItems(sequenceOfUltrasoundRegions);
  const isStandardDicom = Boolean(pixelSpacing);

  if (isMultiframe) {
    const perFrameFunctionalGroupsData = getPixelDataFromFrameFunctionalGroups(perFrameFunctionalGroups, frameNumber!);
    pixelSpacing = perFrameFunctionalGroupsData.pixelSpacing;
    rowPixelSpacing = perFrameFunctionalGroupsData.rowPixelSpacing;
    columnPixelSpacing = perFrameFunctionalGroupsData.columnPixelSpacing;
    imagePositionPatient = perFrameFunctionalGroupsData.imagePositionPatient;
    imageOrientationPatient = perFrameFunctionalGroupsData.imageOrientationPatient;
  } else if (isUltrasound) {
    const sequenceOfUltrasoundRegionsData = getPixelDataFromSequenceOfUltrasoundRegions(sequenceOfUltrasoundRegions);
    rowPixelSpacing = sequenceOfUltrasoundRegionsData.rowPixelSpacing;
    columnPixelSpacing = sequenceOfUltrasoundRegionsData.columnPixelSpacing;
  } else if (isStandardDicom) {
    [rowPixelSpacing, columnPixelSpacing] = getPixelSpacingValid(pixelSpacing!);
  } else if (imagerPixelSpacing) {
    [rowPixelSpacing, columnPixelSpacing] = imagerPixelSpacing;
  }
  return {
    rowPixelSpacing,
    columnPixelSpacing,
    pixelSpacing,
    imageOrientationPatient,
    imagePositionPatient,
  };
};

/**
 * Get Standard Metadata from tags
 * @param id Dicom image id
 * @param dataSet Dicom dataset
 * @returns {ImagePixelData} Return metadata standard structure
 */
export const getMetadata = (id: string, dataSet: dicomParser.DataSet): ImagePixelData => {
  const frameNumber = getFrameNumber(id);

  const {
    rowPixelSpacing,
    columnPixelSpacing,
    pixelSpacing,
    imageOrientationPatient,
    imagePositionPatient,
  } = getPixelData(dataSet, frameNumber);

  let columnCosines;
  let rowCosines;

  if (imageOrientationPatient) {
    rowCosines = imageOrientationPatient.slice(0, 3);
    columnCosines = imageOrientationPatient.slice(3, 6);
  }

  return {
    id,
    frameOfReferenceUID: dataSet.string(TAG_FRAME_OF_REFERENCE_UID),
    rows: dataSet.uint16(TAG_ROWS),
    columns: dataSet.uint16(TAG_COLUMNS),
    imageOrientationPatient,
    rowCosines,
    columnCosines,
    imagePositionPatient,
    sliceThickness: getNumberValue(dataSet.string(TAG_SLICE_THICKNESS)),
    sliceLocation: getNumberValue(dataSet.string(TAG_SLICE_LOCATION)),
    pixelSpacing,
    rowPixelSpacing,
    columnPixelSpacing,
  };
};

/**
 * Attempts to sanitize a value by casting as a number; if unable to cast,
 * we return `undefined`
 *
 * @param {*} value
 * @returns a number or undefined
 */
export const sanitizeMeasuredValue = (value: string): number | undefined => {
  const parsedValue = Number(value);
  const isNumber = !isNaN(parsedValue);

  return isNumber ? parsedValue : undefined;
};

/**
 * Returns true if a point is within an ellipse
 * @export @public @method
 * @name pointInEllipse
 *
 * @param  {Object} ellipse  Object defining the ellipse.
 * @param  {Object} location The location of the point.
 * @returns {boolean} True if the point is within the ellipse.
 */
export const pointInEllipse = (ellipse, location) => {
  const xRadius = ellipse.width / 2;
  const yRadius = ellipse.height / 2;

  if (xRadius <= 0.0 || yRadius <= 0.0) return false;

  const center = {
    x: ellipse.left + xRadius,
    y: ellipse.top + yRadius,
  };

  /* This is a more general form of the circle equation
   *
   * X^2/a^2 + Y^2/b^2 <= 1
   */

  const normalized = {
    x: location.x - center.x,
    y: location.y - center.y,
  };

  const inEllipse =
    (normalized.x * normalized.x) / (xRadius * xRadius) + (normalized.y * normalized.y) / (yRadius * yRadius) <= 1.0;

  return inEllipse;
};

/**
 * Set cornerstone maximum image cache size in GB
 * @param gigabytes
 * @returns
 */
export const setMaxImageCache = (gigabytes: number): void =>
  cornerstone.imageCache.setMaximumSizeBytes(gbToBytes(gigabytes));

/**
 * This function will generate a dynamic WWWC
 * from image meta when provided
 * @param image
 * @return
 */
export const fullDynamicWWWC = (image?: Image) => {
  if (!image) return;
  const intercept = isNaN(image.intercept) ? 0 : image.intercept;
  const slope = isNaN(image.slope) ? 1 : image.slope;
  const maxPixelValue = image.maxPixelValue;
  const minPixelValue = image.minPixelValue;
  return fullDynamic(slope, maxPixelValue, intercept, minPixelValue);
};

/**
 * This function will generate a dynamic WWWC if the necesary values are provided
 * If no necesary values are provided it will return images default WWWC
 * which comes from viewport
 * @param seriesDicomData
 * @return
 */
export const seriesDynamicWWWC = (
  largestImagePixelValue?: number,
  smallestImagePixelValue?: number,
  rescaleSlope?: string,
  rescaleIntercept?: string,
) => {
  if (!largestImagePixelValue) return;
  const dynamicRescaleIntercept = rescaleIntercept ? parseFloat(rescaleIntercept) : 0;
  const dynamicRescaleSlope = rescaleSlope ? parseFloat(rescaleSlope) : 1;
  const dynamicSmallestImagePixelValue = smallestImagePixelValue || 0;
  return fullDynamic(
    dynamicRescaleSlope,
    largestImagePixelValue,
    dynamicRescaleIntercept,
    dynamicSmallestImagePixelValue,
  );
};

/**
 * This function will generate a dynamic WWWC
 */
const fullDynamic = (slope: number, maxPixelValue: number, intercept: number, minPixelValue: number) => {
  const largestImagePixelValue = slope * maxPixelValue + intercept;
  const smallestImagePixelValue = slope * minPixelValue + intercept;
  const windowWidth = largestImagePixelValue - smallestImagePixelValue;
  const windowCenter = windowWidth / 2 + smallestImagePixelValue;
  return { windowWidth, windowCenter };
};

/**
 * Returns the currently displayed image for an element or undefined if no image has been displayed yet
 *
 * @param element The DOM element enabled for Cornerstone
 * @returns The Cornerstone Image Object displayed in this element
 */
export const getImage = (element: HTMLElement | undefined) => {
  if (!element) return;
  return cornerstone.getImage(element);
};
