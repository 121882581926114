var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useModal } from '@eva-pacs/core';
import ConfirmationModal from './ConfirmationModal';
import { GenericModal } from './GenericModal';
import { NoteModal } from './NoteModal';
import SuccessModal from './SuccessModal';
import LoadingModal from './LoadingModal';
import ErrorModal from './ErrorModal';
var Modals = {
    ConfirmationModal: ConfirmationModal,
    GenericModal: GenericModal,
    NoteModal: NoteModal,
    SuccessModal: SuccessModal,
    LoadingModal: LoadingModal,
    ErrorModal: ErrorModal,
};
/**
 * The main container for all the Modals in the app.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-17
 */
var ModalManager = function () {
    var _a = useModal(), currentModal = _a.currentModal, setCurrentModal = _a.setCurrentModal;
    var handleCloseClick = function () {
        if (currentModal === null || currentModal === void 0 ? void 0 : currentModal.props.onClose)
            currentModal === null || currentModal === void 0 ? void 0 : currentModal.props.onClose();
        return setCurrentModal(null);
    };
    var handleSuccessClick = function (value) {
        if (currentModal === null || currentModal === void 0 ? void 0 : currentModal.props.onClose)
            currentModal === null || currentModal === void 0 ? void 0 : currentModal.props.onSuccess(value);
        return setCurrentModal(null);
    };
    if (currentModal) {
        var ModalComponent = Modals[currentModal.name];
        return (React.createElement("div", { className: "fixed z-20 inset-0 overflow-y-auto", "aria-labelledby": "modal-title", role: "dialog", "aria-modal": "true" },
            React.createElement("div", { className: "flex items-end justify-center pb-20 pt-4 px-4 min-h-screen text-center sm:block sm:p-0" },
                React.createElement("div", { className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity", "aria-hidden": "true" }),
                React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen", "aria-hidden": "true" }, "\u200B"),
                React.createElement(ModalComponent, __assign({ onClose: handleCloseClick }, currentModal.props, { onSuccess: handleSuccessClick })))));
    }
    return null;
};
export default ModalManager;
