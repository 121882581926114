import { useSessionStore, useStudyListStore } from '~/src/store';
import { useUpdateStudy } from './useUpdateStudy';
import { useAddNewStudy } from './useAddNewStudy';
import { useRemoveStudy } from '../study/useRemoveStudy';
import { StudyStatus } from '@eva-pacs/client';

export enum UpdateEvent {
  UPDATED = 'updated',
  CREATED = 'created',
  DELETED = 'deleted',
}

export const useWorklistUpdates = () => {
  const currentAccount = useSessionStore((state) => state.getCurrentAccount);
  const getStudies = useStudyListStore((state) => state.getStudies);
  const { processUpdateWorklistStudy } = useUpdateStudy();
  const { processInsertNewWorklistStudy } = useAddNewStudy();
  const { processRemoveStudy } = useRemoveStudy();

  const shouldStudyBeUpdated = (practitionerAssignedId: string, reviewerPractitionerId?: string | null): boolean => {
    const practitionerId = currentAccount()?.practitionerId;
    return practitionerAssignedId === practitionerId || reviewerPractitionerId === practitionerId;
  };

  const handleUpdateWorklistStudy = (
    studyId: string,
    practitionerAssignedId?: string,
    oldPractitionerId?: string,
    oldStatus?: string,
    status?: string,
    reviewerPractitionerId?: string | null,
    oldReviewerPractitionerId?: string | null,
  ) => {
    const studies = getStudies();
    const practitionerId = currentAccount()?.practitionerId;
    if (!practitionerAssignedId) return processRemoveStudy(studyId);

    if (!shouldStudyBeUpdated(practitionerAssignedId, reviewerPractitionerId)) {
      // If we should not update it, it means it got unassigned from that current practitioner.
      // so we proceed to check if we have that specific study in our store currently, if yes, then delete it.
      if (studies[studyId]) {
        return processRemoveStudy(studyId);
      }
      return;
    }

    // If the study is signed, remove it from the store.
    if (status === StudyStatus.SIGNED) {
      if (studies[studyId]) {
        return processRemoveStudy(studyId);
      }
      return;
    }

    const isNewPractitionerAssignedWorklistStudy =
      oldPractitionerId !== practitionerAssignedId && practitionerAssignedId === practitionerId;

    const isNewReviewerAssignedWorklistStudy =
      oldReviewerPractitionerId !== reviewerPractitionerId && reviewerPractitionerId === practitionerId;

    if (
      oldStatus === StudyStatus.SIGNED ||
      ((isNewPractitionerAssignedWorklistStudy || isNewReviewerAssignedWorklistStudy) &&
        (status === StudyStatus.IMAGES_SENT ||
          status === StudyStatus.READING ||
          status === StudyStatus.READING_PENDING ||
          status === StudyStatus.REVIEW_PENDING))
    ) {
      // avoid inserting the study in the store twice.
      if (studies[studyId]) return;
      return processInsertNewWorklistStudy(studyId);
    }

    // Is not a new study, it is an existing study (in bd, we don't know if we have it the store yet
    // so we need to check that)
    return processUpdateWorklistStudy(studyId);
  };

  return {
    shouldStudyBeUpdated,
    handleUpdateWorklistStudy,
  };
};
