var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApolloLink, Observable } from '@apollo/client';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';
import { makeAsyncIterableIteratorFromSink, applyAsyncIterableIteratorToSink, } from '@n1ru4l/push-pull-async-iterable-iterator';
import { createApplyLiveQueryPatch } from '@n1ru4l/graphql-live-query-patch';
import { getCookie } from '@eva-pacs/core';
export var wsStatus = {
    status: 'connecting',
};
var REFRESH_TOKEN_KEY = 'refresh';
var PROD = process.env.ENV === 'production';
var REACT_APP_API = process.env.REACT_APP_API;
var wsUrl = PROD ? "wss://".concat(REACT_APP_API, "/graphql/") : "ws://".concat(REACT_APP_API, "/graphql/");
var WebSocketLink = /** @class */ (function (_super) {
    __extends(WebSocketLink, _super);
    function WebSocketLink() {
        var _this = _super.call(this) || this;
        _this.client = createClient({
            url: wsUrl,
            lazy: false,
            connectionParams: function () {
                var token = getCookie(REFRESH_TOKEN_KEY);
                if (!token) {
                    return {};
                }
                return {
                    token: token,
                };
            },
            on: {
                connected: function () { return (wsStatus.status = 'connected'); },
                error: function () { return (wsStatus.status = 'error'); },
                closed: function () { return (wsStatus.status = 'closed'); },
                connecting: function () { return (wsStatus.status = 'connecting'); },
            },
        });
        return _this;
    }
    WebSocketLink.prototype.wsFetcher = function (graphQLParams) {
        var _this = this;
        return makeAsyncIterableIteratorFromSink(function (sink) { return _this.client.subscribe(graphQLParams, sink); });
    };
    WebSocketLink.prototype.request = function (operation) {
        var _this = this;
        var applyLiveQueryPatch = createApplyLiveQueryPatch();
        return new Observable(function (sink) {
            return applyAsyncIterableIteratorToSink(applyLiveQueryPatch(_this.wsFetcher({
                operationName: operation.operationName,
                query: print(operation.query),
                variables: operation.variables,
            })), sink);
        });
    };
    return WebSocketLink;
}(ApolloLink));
export { WebSocketLink };
