import React from 'react';
import cn from 'classnames';
import { CompanyAndProductLogo, ProductLogoType, CompanyAndProductLogoSize, Icon, IconCatalog } from '@evacenter/eden';

import { EVA_PRODUCT } from '~/constants';

export interface AccountPopupMenuProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set a description text
   */
  description?: string;

  /**
   * Provide a handler that is called when the item was clicked
   */
  onClick?: () => void;

  /**
   * Set the product slug
   */
  slug: EVA_PRODUCT;

  /**
   * Set the default selected option
   */
  selectedProduct: boolean;
}

/**
 * Item (ProductPopupMenu)
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-07-08
 */
export const Item = ({ className, slug, description, onClick, selectedProduct }: AccountPopupMenuProps) => {
  const classes = {
    wrapper: cn(className, 'e-flex e-justify-between e-items-center e-p-4 e-cursor-pointer hover:e-bg-neutral-900'),
    left: cn('e-flex e-flex-col e-items-start e-space-y-1'),
    right: cn('e-text-primary-400'),
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  /* Render JSX */
  return (
    <div
      role="option"
      tabIndex={-1}
      aria-selected="false"
      onClick={handleClick}
      onKeyPress={handleClick}
      className={classes.wrapper}>
      <div className={classes.left}>
        <div className="e-flex e-items-center e-h-7">
          <CompanyAndProductLogo product={ProductLogoType[slug]} size={CompanyAndProductLogoSize.sm} />
        </div>
        <div className="e-text-xs e-text-neutral-200 e-font-regular e-text-left">{description}</div>
      </div>
      {selectedProduct && (
        <div className={classes.right}>
          <Icon icon={IconCatalog.checkRounded} />
        </div>
      )}
    </div>
  );
};
