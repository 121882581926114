import { ToolbarOption } from './toolbarOption';

export const SYNCHRONIZABLE_TOOLS = [
  ToolbarOption.MEASURE,
  ToolbarOption.LENGTH,
  ToolbarOption.ZOOM,
  ToolbarOption.MOVE,
  ToolbarOption.CROP,
  ToolbarOption.ROTATE,
  ToolbarOption.ANGLE,
  ToolbarOption.ELLIPSIS,
  ToolbarOption.ANNOTATION,
  ToolbarOption.BREAST_TOOLS,
];

export const SYNCHRONIZABLE_DICOM_TOOLS = [
  ToolbarOption.BIDIRECTIONAL,
  ToolbarOption.CTR,
  ToolbarOption.LENGTH,
  ToolbarOption.BIDIRECTIONAL,
  ToolbarOption.COBBANGLE,
  ToolbarOption.ANGLE,
  ToolbarOption.COBBANGLE,
  ToolbarOption.ELLIPSIS,
  ToolbarOption.RECTANGLE,
  ToolbarOption.PROBE,
  ToolbarOption.FREEHAND,
  ToolbarOption.MAGNIFY,
  ToolbarOption.ANNOTATION,
  ToolbarOption.ERASE,
  ToolbarOption.DEVIATION,
  ToolbarOption.REFERENCE_LINES,
];

export const SYNCHRONIZABLE_COMMON_TOOLS = [ToolbarOption.WWWC];

export enum SYNC_EVENT_KEY {
  MAIN_TOOL_SELECTION = 'main-tool-selection',
  COMMON_TOOL_SELECTION = 'common-submenu-tool-selection',
  DICOM_TOOL_SELECTION = 'dicom-submenu-tool-selection',
  CHANGE_DISPOSITION = 'change-viewer-disposition',
  SERIE_SELECTION = 'serie-selection',
  COMPARE_REGION_SELECTION = 'compare-region-selection',
  SHARE_BOUNDING_BOXES = 'share-bounding-boxes',
  ASK_FOR_SELECTED_TOOL = 'ask-for-selected-tool',
  ASK_FOR_SUBMENU_SELECTED_TOOL = 'ask-for-selected-submenu-tool',
  SYNC_REFERENCE_LINES = 'sync-reference-lines',
}

export const BROWSER_WINDOW_AVAILABLE_PING_TEXT = 'BROWSER_WINDOW_AVAILABLE_PING';
export const BROWSER_WINDOW_AVAILABLE_PONG_TEXT = 'BROWSER_WINDOW_AVAILABLE_PONG';

export const WAIT_ANOTHER_WINDOWS_TIME = 100;
export const DELAY_FOR_SYNC_SUBMENU_TOOLS = 500;

export const APP_PACS_SYNC_CHANNEL = 'APP_PACS_SYNC_CHANNEL';
export const TIME_LIMIT_WITHOUT_REPORTING = 3000;
export const TIMEOUT_TO_VERIFY_ACTIVE_WINDOWS = 2000;
export const TIME_LIMIT_FOR_AN_EVENT_EXPIRATION = 2000;

export const SHARED_STORE_LOCALSTORAGE_KEY = 'studySharedStore';
export const WINDOW_ADMIN_LOCALSTORAGE_KEY = 'windowAdmin';
