import React from 'react';
import cn from 'classnames';
/**
 * AccordionPanel (Accordion)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-20
 */
export var AccordionPanel = function (_a) {
    var ariaLabel = _a.ariaLabel, trigger = _a.trigger, panelId = _a.panelId, isOpen = _a.isOpen, onClick = _a.onClick, className = _a.className, children = _a.children;
    var itemClass = cn(className);
    var handleClick = function (event) {
        if (event)
            event.persist();
        if (onClick)
            onClick(panelId);
    };
    return (React.createElement("div", { "aria-label": ariaLabel, className: itemClass },
        React.createElement("div", { onClick: handleClick, role: "button", tabIndex: 0, onKeyPress: handleClick }, trigger),
        React.createElement("div", { "aria-expanded": isOpen, className: isOpen ? '' : 'hidden' }, children)));
};
