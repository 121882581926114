import React from 'react';
import { counterClass, badgeClass } from './styles';
export var BadgeColor;
(function (BadgeColor) {
    BadgeColor["primary"] = "primary";
    BadgeColor["positive"] = "positive";
    BadgeColor["warning"] = "warning";
    BadgeColor["negative"] = "negative";
    BadgeColor["neutral"] = "neutral";
})(BadgeColor || (BadgeColor = {}));
export var BadgeSize;
(function (BadgeSize) {
    BadgeSize["xs"] = "xs";
    BadgeSize["sm"] = "sm";
    BadgeSize["md"] = "md";
    BadgeSize["lg"] = "lg";
    BadgeSize["xl"] = "xl";
    BadgeSize["xxl"] = "xxl";
})(BadgeSize || (BadgeSize = {}));
/**
 * The Badge component is a visual indicator for numeric values such as tallies and scores.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-22
 */
export var Badge = function (_a) {
    var _b = _a.color, color = _b === void 0 ? BadgeColor.primary : _b, _c = _a.size, size = _c === void 0 ? BadgeSize.md : _c, counter = _a.counter, className = _a.className;
    return (React.createElement("div", { "data-testid": "badge", className: badgeClass(className, color, size) }, counter && React.createElement("span", { className: counterClass(color, size) }, counter)));
};
