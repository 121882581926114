import cornerstone from 'cornerstone-core';

/**
 * Synchronize the target and source if are not the same
 * @export
 * @public
 * @method
 * @name panZoomSynchronizer
 *
 * @param {Object} synchronizer - The Synchronizer instance that attaches this
 * handler to an event
 * @param {HTMLElement} sourceElement - The source element for the zoom and pan values
 * @param {HTMLElement} targetElement - The target element
 * @returns {void}
 */
export default (synchronizer, sourceElement: HTMLElement, targetElement: HTMLElement) => {
  // Ignore the case where the source and target are the same enabled element
  if (targetElement === sourceElement) {
    return;
  }
  cornerstone.updateImage(targetElement);
};
