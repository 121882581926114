// Illustrations path
export const ZIP_IMAGE_PATH = '/assets/illustrations/zip.svg';
export const SEARCH_EMPTY_STATE_IMAGE_PATH = '/assets/illustrations/search-empty-state.svg';
export const PASSWORD_PROTECT_IMAGE_PATH = '/assets/illustrations/password-protect.svg';
export const CONNECTION_ERROR_IMAGE_PATH = '/assets/illustrations/connection-error.svg';
export const KEY_FOLDER_IMAGE_PATH = '/assets/illustrations/key-image.svg';
export const PDF_FILE_IMAGE_PATH = '/assets/illustrations/pdf-file.svg';
export const ECG_FILE_IMAGE_PATH = '/assets/illustrations/ecg-file.svg';
export const MPR_BOOKMARK_IMAGE_PATH = '/assets/illustrations/MPR-bookmark.svg';
export const STL_THUMBNAIL_PATH = '/assets/illustrations/STL_serie_thumbnail.svg';
export const SUCCESS_IMAGE_PATH = '/assets/illustrations/success.svg';
