import React from 'react';
import cn from 'classnames';
import { useTabs } from '@eva-pacs/core';
/**
 * TabPanel (Tabs)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-06-08
 */
export var TabPanelV2 = function (_a) {
    var tabId = _a.tabId, className = _a.className, children = _a.children, _b = _a.hasDestructiveBehavior, hasDestructiveBehavior = _b === void 0 ? false : _b;
    var activeTab = useTabs().activeTab;
    var isInactiveTab = activeTab !== tabId;
    var tabPanelClass = cn(className, { hidden: isInactiveTab });
    if (hasDestructiveBehavior && isInactiveTab)
        return null;
    return React.createElement("div", { className: tabPanelClass }, children);
};
