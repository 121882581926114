import { SeriesThumbnailsType } from '@eva-pacs/client';
import { createMetaSeries, isASplittableSerie } from './splitSeries';

export const applySeriesSeparation = (originalSeriesList: Array<SeriesThumbnailsType>) => {
  const seriesList = [...originalSeriesList];
  const splittableSeries = seriesList.filter((serie) => isASplittableSerie(serie));
  const metaSeries = splittableSeries.map(createMetaSeries);
  splittableSeries.forEach((serie, serieIndex) => {
    const seriePosition = seriesList.findIndex((commonSerie) => commonSerie.id === serie.id);
    const seriesInfo = metaSeries[serieIndex];
    // Remove the original serie and add the new metas series in original position
    const newMetaSeries = seriesInfo.map((metaSerieInfo, index) => {
      const thumbnail = index === 0 ? seriesList[seriePosition]?.thumbnail : '';
      return {
        ...seriesList[seriePosition],
        ...metaSerieInfo,
        thumbnail,
        dicomDescription: metaSerieInfo.meta.metaSerieVariant,
      };
    });
    seriesList.splice(seriePosition, 1, ...newMetaSeries);
  });
  return seriesList;
};
