/**
 * This method removes properties that are emtpy/null/undefined from an object.
 * @author Salvador Gonzalez <salvador.gonzalez@evacenter.com>
 * Created at 2023-04-10
 * returns: An object without falsy values.
 */
export var removeEmptyProperties = function (obj) {
    var entries = Object.entries(obj);
    var filteredEntries = entries.filter(function (_a) {
        var _ = _a[0], value = _a[1];
        if (value === undefined)
            return false;
        if (typeof value === 'object' && Object.keys(value).length === 0)
            return false;
        return true;
    });
    return Object.fromEntries(filteredEntries);
};
