import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { ToolName } from '~/src/constants/toolName';
import { NIPPLE_INJURY_UPDATE_CONF_EVENT } from '~/constants';
import { getEnabledElementControlled } from '../getEnabledElementControlled';
const triggerEvent = cornerstoneTools.importInternal('util/triggerEvent');

export const setElementStateConfig = (element?: HTMLElement, config = {}) => {
  if (!element) return;
  const enabledElement = getEnabledElementControlled(element);
  if (!enabledElement) return;
  const toolState = cornerstoneTools.getToolForElement(enabledElement.element, ToolName.NippleInjury);
  if (!toolState?.configuration) return;
  toolState.configuration = Object.assign(toolState.configuration, config);
  triggerEvent(element, NIPPLE_INJURY_UPDATE_CONF_EVENT, {});
};

export const notifyElementsNippleInjuryToolMode = (isToolActive: boolean) => {
  cornerstone.getEnabledElements().forEach((element) => {
    setElementStateConfig(element.element, { nippleInjuryToolEnabled: isToolActive });
  });
};

export const getElementStateConfig = (element?: HTMLElement) => {
  if (!element) return null;
  const enabledElement = getEnabledElementControlled(element);
  if (!enabledElement) return null;
  const toolConfig = cornerstoneTools.getToolForElement(enabledElement.element, ToolName.NippleInjury);
  return toolConfig;
};
