import React from 'react';
import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { ErrorState } from '@eva-pacs/ui';
import { ROUTES } from '~/constants';
import { SessionStore, useSessionStore } from '~/src/store';
import { unhandledRejectionErrorValidator } from '~/utils/unhandledRejectionErrorValidator';
interface ErrorBoundaryProps {
  /**
   * Context of the error, where it broke
   */
  context: string;
  /**
   * Component children, this component will listen children's errors
   */
  children: JSX.Element;
  /**
   * Component fallback, this component will showed in case of error
   */
  fallback?: JSX.Element;
}

const browserHistory = createBrowserHistory();
const reactRouterTracing = new TracingIntegrations.BrowserTracing({
  routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory, ROUTES, matchPath),
});

const initializeSentry = (dsn: string): void =>
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn,
    integrations: [reactRouterTracing],
    release: `pacs@${process.env.REACT_APP_VERSION}`,
    tracesSampleRate: 0.2,
    autoSessionTracking: false,
    beforeSend: unhandledRejectionErrorValidator(),
  });

const setSentryUser = (id: string, email: string): void => Sentry.setUser({ id, email });

const identifyUser = (id: string, email: string): void => {
  setSentryUser(id, email);
};

// eslint-disable-next-line i18next/no-literal-string
const setSentryEnvironmentTag = (): void => Sentry.setTag('error.side', 'frontend');

const initErrorLogging = (errorContext: string, sessionStore: SessionStore) => {
  const authenticatedUser = sessionStore.user;

  const SENTRY_DSN_FRONTEND = process.env.REACT_APP_SENTRY_DSN_FRONTEND;
  if (SENTRY_DSN_FRONTEND) {
    initializeSentry(SENTRY_DSN_FRONTEND);
    setSentryEnvironmentTag();

    if (authenticatedUser) identifyUser(authenticatedUser.id, authenticatedUser.email ?? '');
  }
};
/**
 * Error boundary component
 *
 * @author Ricardo Aguirre<ricardo.a.nava@gmail.com>
 * Updated at 2022-01-05
 */
const AppErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  context: errorContext = 'EVAPACS',
  children,
  fallback = <ErrorState />,
}) => {
  const sessionStore = useSessionStore();

  initErrorLogging(errorContext, sessionStore);

  return <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>;
};

export default AppErrorBoundary;
