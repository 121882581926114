import cn from 'classnames';
import { BasicInputSize } from '../BasicInput';
/* Class assignment */
export var phoneInputClass = function (className, inputSize, isDisabled, isFullWidth, hasError) {
    return cn(className, 
    /* wrapper (base) */
    'relative flex overflow-hidden items-center', 'bg-gray-600 rounded-lg', 
    /* input--md */
    inputSize === BasicInputSize.md && 'px-3 h-10 text-paragraph', 
    /* input--isDisabled */
    isDisabled && 'opacity-40', isFullWidth === true && 'w-full', hasError && 'border border-feedback-negative-500');
};
export var countryClass = function (inputSize) {
    return cn(
    /* country container (base) */
    'flex items-center', 
    /* input--md */
    inputSize === BasicInputSize.md && 'text-paragraph');
};
