import cornerstone from 'cornerstone-core';

export const getCanvasLimitsInViewport = (element: HTMLElement) => {
  const canvasTopLeftLimitCoordinate: any = {
    y: element.clientTop,
    x: element.clientWidth,
  };
  const canvasBottomRightLimitCoordinate: any = {
    y: element.clientTop,
    x: element.clientWidth,
  };
  const topLeft = cornerstone.canvasToPixel(element, canvasTopLeftLimitCoordinate);
  const bottomRigth = cornerstone.canvasToPixel(element, canvasBottomRightLimitCoordinate);
  return { topLeft, bottomRigth };
};
