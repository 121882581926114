import { useBodyClass } from '@eva-pacs/core';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconCatalog } from '../../../atoms';
import { genericModalClass } from './styles';
/**
 * Use this component in order to show a generic Dialog
 * that can be used with children
 * Should be consumed with Modal Context
 * @author Ricardo Aguirre<ricardo.a.nava@gmail.com>
 * Created at 2021-07-13
 */
export var GenericModal = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, children = _a.children, onClose = _a.onClose, title = _a.title, _c = _a.isMaxWidthCustom, isMaxWidthCustom = _c === void 0 ? false : _c, _d = _a.isFullWidth, isFullWidth = _d === void 0 ? true : _d, _e = _a.hasCloseButton, hasCloseButton = _e === void 0 ? true : _e;
    // eslint-disable-next-line i18next/no-literal-string
    useBodyClass('opened-modal');
    var t = useTranslation().t;
    var handleCancelButtonClick = function () {
        if (onClose)
            onClose();
    };
    var handleKeydown = useCallback(function (event) {
        if (event.key === 'Escape' && onClose) {
            onClose();
        }
    }, [onClose]);
    useEffect(function () {
        document.addEventListener('keydown', handleKeydown, false);
        return function () {
            document.removeEventListener('keydown', handleKeydown, false);
        };
    }, [handleKeydown]);
    var CloseButton = function () { return (React.createElement("div", { role: "button", tabIndex: -1, className: "flex items-center ml-auto hover:text-basic-white text-light-gray-600 cursor-pointer", onClick: handleCancelButtonClick, onKeyPress: handleCancelButtonClick },
        React.createElement("span", { className: "mr-2 text-caption font-semi-bold cursor-pointer" }, t('general.close')),
        React.createElement(Icon, { icon: IconCatalog.close, width: "24", height: "24" }))); };
    var Title = function () { return (React.createElement("h2", { className: "mr-8 text-basic-white text-subtitle font-semi-bold" }, title)); };
    return (React.createElement("div", { className: genericModalClass(className, isMaxWidthCustom, isFullWidth) },
        (title || hasCloseButton) && (React.createElement("div", { className: "flex mb-8" },
            title && React.createElement(Title, null),
            hasCloseButton && React.createElement(CloseButton, null))),
        children));
};
