import React, { Children, cloneElement, useRef, useState } from 'react';
import cn from 'classnames';
import { hasProp, isNullOrUndefined, isArray, useOnClickOutside } from '@eva-pacs/core';
/**
 * This component presents a list of options that take immediate action or navigate the user outside of the current context.
 * It can be thought of as a collection of links or buttons.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-01
 */
export var DropdownMenu = function (_a) {
    var ariaLabel = _a.ariaLabel, className = _a.className, onTriggerClick = _a.onTriggerClick, children = _a.children;
    var dropdownMenuClass = cn(className, 'inline-flex relative');
    var node = useRef(null);
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    useOnClickOutside(node.current, function () { return setIsOpen(false); });
    var handleTriggerClick = function (event) {
        setIsOpen(!isOpen);
        if (onTriggerClick)
            onTriggerClick(event);
    };
    var renderChildren = Children.map(children, function (el, index) {
        if (isNullOrUndefined(children))
            return el;
        if (!isArray(children))
            return el;
        if (index) {
            // eslint-disable-next-line i18next/no-literal-string
            if (hasProp(el === null || el === void 0 ? void 0 : el.props, 'isOpen'))
                return el;
            return cloneElement(el, { isOpen: isOpen });
        }
        // eslint-disable-next-line i18next/no-literal-string
        if (hasProp(children[1].props, 'isOpen'))
            return el;
        return cloneElement(el, { onClick: handleTriggerClick });
    });
    return (React.createElement("div", { "aria-label": ariaLabel, ref: node, className: dropdownMenuClass }, renderChildren));
};
