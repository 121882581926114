import csTools from 'cornerstone-tools';
import { PIXELS_NEAR_TOOL } from '~/constants';

const lineSegDistance = csTools.importInternal('util/lineSegDistance');

/**
 * @public
 * @class Angle
 * @memberof Tools.Annotation
 * @classdesc Create and position an angle by placing three consecutive points.
 * @extends Tools.Annotation.AngleTool
 * @author Ricardo Aguirre <ricardo.a.nava@gmail.com>
 * Created at 2022-03-16
 */
export default class CustomAngle extends csTools.AngleTool {
  name;
  constructor(props) {
    super(props);
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    if (data.visible === false) return false;

    return (
      lineSegDistance(element, data.handles.start, data.handles.middle, coords) < PIXELS_NEAR_TOOL ||
      lineSegDistance(element, data.handles.middle, data.handles.end, coords) < PIXELS_NEAR_TOOL
    );
  }
}
