import { StudyStatus } from '@eva-pacs/client';
import { useStudyActions } from '../study/useStudyActions';
import { UpdateEvent } from './useWorklistUpdates';
import { isOlderThan30Days } from '@eva-pacs/core';

export interface ProcessUpdateWatcherProps {
  studyPractitionerId: string;
  eventType: UpdateEvent;
  studyOldPractitionerId: string;
  reviewerPractitionerId?: string | null;
  oldReviewerPractitionerId?: string | null;
  userId: string;
  status?: string;
  oldStatus: string;
  createdAt?: string;
}

export const getIsIncomingWorklist = (
  sessionPractitionerId: string,
  studyOldPractitionerId?: string | null,
  studyPractitionerId?: string | null,
) => studyOldPractitionerId !== sessionPractitionerId && studyPractitionerId === sessionPractitionerId;

export const getIsOutComingWorklist = (
  sessionPractitionerId?: string,
  studyOldPractitionerId?: string | null,
  studyPractitionerId?: string | null,
) => {
  if (!studyPractitionerId) return studyOldPractitionerId === sessionPractitionerId;

  return sessionPractitionerId !== studyPractitionerId && studyOldPractitionerId === sessionPractitionerId;
};

export const getIsWorklistAssignmentUnchanged = (
  sessionPractitionerId: string,
  studyOldPractitionerId?: string | null,
  studyPractitionerId?: string | null,
) => studyOldPractitionerId === studyPractitionerId && sessionPractitionerId === studyPractitionerId;

export const isWorklistRelatedStatus = (status?: StudyStatus) => {
  if (!status) return false;
  return (
    status === StudyStatus.IMAGES_SENT ||
    status === StudyStatus.READING ||
    status === StudyStatus.READING_PENDING ||
    status === StudyStatus.REVIEW_PENDING
  );
};

export const isNonWorklistStatus = (status?: StudyStatus) => {
  if (!status) return false;
  return (
    status === StudyStatus.ADDENDUM_SIGNED ||
    status === StudyStatus.IN_ADDENDUM ||
    status === StudyStatus.DELIVERED ||
    status === StudyStatus.UPLOADING
  );
};

export const useWorklistCounterWatcher = () => {
  const { incrementWorklistTotalCount, decrementWorklistTotalCount } = useStudyActions();

  const handleIsOutcomingWorklist = (oldStatus: StudyStatus) => {
    if (oldStatus !== StudyStatus.SIGNED) return decrementWorklistTotalCount();
  };

  const handleIsIncomingWorklist = (status: StudyStatus) => {
    if (isWorklistRelatedStatus(status as StudyStatus)) return incrementWorklistTotalCount();
  };

  const processUpdateCounter = ({
    studyPractitionerId,
    eventType,
    studyOldPractitionerId,
    reviewerPractitionerId,
    oldReviewerPractitionerId,
    userId,
    status,
    oldStatus,
    createdAt,
  }: ProcessUpdateWatcherProps) => {
    // If there is no createdAt date, omit this study.
    const isOldWorklistStudy = createdAt ? isOlderThan30Days(new Date(createdAt)) : true;

    // Don't process updates for studies older than 30 days.
    if (isOldWorklistStudy) return;

    // First we need to know which type of assignment the user is currently assigned as.
    const isReviewerAssignment = reviewerPractitionerId === userId || oldReviewerPractitionerId === userId;
    const isPractitionerAssignment = studyPractitionerId === userId || studyOldPractitionerId === userId;

    // This means is not an study that is assigned to the user, neither as a reviewer or practitioner assigned, and it was not previously
    // assigned to the user as well. So we don't need to process this update.
    if (!isReviewerAssignment && !isPractitionerAssignment) return;

    const currentPractitionerId = isReviewerAssignment ? reviewerPractitionerId : studyPractitionerId;
    const currentOldPractitionerId = isReviewerAssignment ? oldReviewerPractitionerId : studyOldPractitionerId;

    switch (eventType) {
      case UpdateEvent.DELETED: {
        // Only decrement counter if the study is assigned to the current practitioner in session.
        if (currentOldPractitionerId === userId) {
          return decrementWorklistTotalCount();
        }
        return;
      }

      case UpdateEvent.UPDATED: {
        // this cases are never in worklist, so don't do anything
        if (isNonWorklistStatus(oldStatus as StudyStatus) || isNonWorklistStatus(status as StudyStatus)) return;

        const isStudyAssignedToPractitioner = currentPractitionerId === userId;

        const isOutComingWorklist = getIsOutComingWorklist(userId, currentOldPractitionerId, currentPractitionerId);
        const isIncomingWorklist = getIsIncomingWorklist(userId, currentOldPractitionerId, currentPractitionerId);
        const isWorklistAssignmentUnchanged = getIsWorklistAssignmentUnchanged(
          userId,
          currentOldPractitionerId,
          currentPractitionerId,
        );

        if (isOutComingWorklist) return handleIsOutcomingWorklist(oldStatus as StudyStatus);

        // increment only when there are new items added to worklist
        if (isIncomingWorklist) return handleIsIncomingWorklist(status as StudyStatus);

        if (isWorklistAssignmentUnchanged) {
          // Signed case of an assigned study that was previously in worklist.
          if (isStudyAssignedToPractitioner) {
            // When signing a study which is a worklist study, decrement it.
            if (status === StudyStatus.SIGNED && isWorklistRelatedStatus(oldStatus as StudyStatus))
              return decrementWorklistTotalCount();
          }

          if (oldStatus === StudyStatus.SIGNED && isWorklistRelatedStatus(status as StudyStatus))
            return incrementWorklistTotalCount();
        }

        return;
      }
      default:
        return '';
    }
  };

  return {
    processUpdateCounter,
  };
};
