import { useEffect, useState } from 'react';
/**
 * This hook allows you to debounce any fast changing value.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-04-26
 */
export var useDebounce = function (value, delay) {
    // State and setters for debounced value
    var _a = useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    useEffect(function () {
        // Update debounced value after delay
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return function () { return clearTimeout(handler); };
    }, [value, delay]);
    return debouncedValue;
};
/**
 * reference: https://usehooks.com/useDebounce/
 */
