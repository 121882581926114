import { LATERALITY_TYPE } from '~/constants';
import { BoundingBox } from '~/src/interfaces/BoundingBox';
import {
  VALID_IMAGE_SIZE_PERCENTAGE_FOR_A_BOUNDINGBOX,
  VALID_LATERALITY_OFFSET_PERCENTAGE_FOR_A_BOUNDINGBOX,
  VALID_MAX_WIDTH_IMAGE_PERCENTAGE_FOR_A_BOUNDINGBOX,
} from '~/src/constants';

export const getBiggestCombinedBox = (boundingBoxesList: BoundingBox[]) => {
  const firstViewportBoundingBox = boundingBoxesList.find((boundingBox) => boundingBox.viewportIndex === 0);
  const minY = Math.min(...boundingBoxesList.map((filteredBox) => filteredBox.y));
  const maxY = Math.max(...boundingBoxesList.map((filteredBox) => filteredBox.y + filteredBox.height));
  const maxWidth = Math.max(...boundingBoxesList.map((filteredBox) => filteredBox.width));

  let minX = firstViewportBoundingBox?.x || 0;
  let width = firstViewportBoundingBox?.width || 0;
  if (firstViewportBoundingBox?.imageLaterality === LATERALITY_TYPE.L) {
    width = maxWidth;
  } else {
    minX = minX - (maxWidth - width);
    width = maxWidth;
  }
  const biggestBox: BoundingBox = {
    x: minX,
    y: minY,
    height: maxY - minY,
    width,
    originalImageHeight: 1,
    originalImageWidth: 1,
  };
  return biggestBox;
};

export const validateBoundingBox = (boundingBox: BoundingBox) => {
  // if viewportIndex the index is undefined it is because the boundingbox comes from a remote window  and it was validated there
  if (boundingBox.viewportIndex === undefined) return boundingBox;

  if (!boundingBox.width || !boundingBox.height || !boundingBox.originalImageHeight || !boundingBox.originalImageHeight)
    return {
      ...boundingBox,
      isValid: false,
    };
  const invalidBoxSize =
    boundingBox.width / boundingBox.originalImageWidth < VALID_IMAGE_SIZE_PERCENTAGE_FOR_A_BOUNDINGBOX ||
    boundingBox.height / boundingBox.originalImageHeight < VALID_IMAGE_SIZE_PERCENTAGE_FOR_A_BOUNDINGBOX ||
    boundingBox.width / boundingBox.originalImageWidth > VALID_MAX_WIDTH_IMAGE_PERCENTAGE_FOR_A_BOUNDINGBOX;

  const lateralOffset =
    boundingBox.imageLaterality === LATERALITY_TYPE.R
      ? boundingBox.originalImageWidth - boundingBox.x - boundingBox.width
      : boundingBox.x;

  const invalidOffset =
    boundingBox.imageLaterality &&
    lateralOffset / boundingBox.originalImageWidth > VALID_LATERALITY_OFFSET_PERCENTAGE_FOR_A_BOUNDINGBOX;

  const isAnInvalidBox = !boundingBox.imageLaterality || invalidOffset || invalidBoxSize;
  const validatedBox: BoundingBox = {
    ...boundingBox,
    x: isAnInvalidBox ? 0 : boundingBox.x,
    y: isAnInvalidBox ? 0 : boundingBox.y,
    height: isAnInvalidBox ? boundingBox.originalImageHeight : boundingBox.height,
    width: isAnInvalidBox ? boundingBox.originalImageWidth : boundingBox.width,
    isValid: !isAnInvalidBox,
  };
  return validatedBox;
};
