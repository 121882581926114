var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cn from 'classnames';
import Icon from '../../Icon';
import { inputClass, wrapperClass } from './styles';
export var BasicInputSize;
(function (BasicInputSize) {
    BasicInputSize["xs"] = "xs";
    BasicInputSize["sm"] = "sm";
    BasicInputSize["md"] = "md";
    BasicInputSize["lg"] = "lg";
})(BasicInputSize || (BasicInputSize = {}));
export var IconSize;
(function (IconSize) {
    IconSize["xs"] = "18";
    IconSize["sm"] = "22";
    IconSize["md"] = "26";
    IconSize["lg"] = "30";
})(IconSize || (IconSize = {}));
/**
 * The inputs are used so that the user can enter text or other characters that he requires.
 * @author Alejandro Forero<alejoforero.af@gmail.com>
 * Created at 2021-05-26
 */
export var BasicInput = React.forwardRef(function (_a, ref) {
    var _b = _a.inputSize, inputSize = _b === void 0 ? BasicInputSize.md : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.isFullWidth, isFullWidth = _d === void 0 ? false : _d, _e = _a.isReadOnly, isReadOnly = _e === void 0 ? false : _e, _f = _a.isPlain, isPlain = _f === void 0 ? false : _f, className = _a.className, dataTestId = _a.dataTestId, _g = _a.iconSize, iconSize = _g === void 0 ? IconSize.md : _g, icon = _a.icon, hasError = _a.hasError, _h = _a.iconPosition, iconPosition = _h === void 0 ? 'right' : _h, iconClassName = _a.iconClassName, _j = _a.value, value = _j === void 0 ? '' : _j, name = _a.name, onChange = _a.onChange, onBlur = _a.onBlur, onFocus = _a.onFocus, onKeyPress = _a.onKeyPress, onIconClick = _a.onIconClick, restOfProps = __rest(_a, ["inputSize", "isDisabled", "isFullWidth", "isReadOnly", "isPlain", "className", "dataTestId", "iconSize", "icon", "hasError", "iconPosition", "iconClassName", "value", "name", "onChange", "onBlur", "onFocus", "onKeyPress", "onIconClick"]);
    var iconRightClass = cn(iconClassName, 'flex items-center justify-center pr-3', {
        'cursor-pointer': typeof onIconClick === 'function',
        'cursor-default': typeof onIconClick !== 'function',
    });
    var iconLeftClass = cn(iconClassName, 'flex items-center justify-center pl-3', {
        'cursor-pointer': typeof onIconClick === 'function',
        'cursor-default': typeof onIconClick !== 'function',
    });
    var handleInputChange = function (event) {
        if (onChange)
            onChange(event);
    };
    var handleInputBlur = function (event) {
        if (onBlur)
            onBlur(event);
    };
    var handleInputFocus = function (event) {
        if (onFocus)
            onFocus(event);
    };
    var handleInputKeyPress = function (event) {
        if (onKeyPress)
            onKeyPress(event);
    };
    var handleIconClick = function () {
        if (onIconClick)
            onIconClick();
    };
    /* Render JSX */
    return (React.createElement("div", { className: wrapperClass(className, isFullWidth, isPlain, hasError) },
        icon && iconPosition === 'left' && (React.createElement("i", { role: "button", tabIndex: 0, className: iconLeftClass, onKeyDown: handleIconClick, onClick: handleIconClick },
            React.createElement(Icon, { className: iconClassName, icon: icon, width: IconSize[iconSize], height: IconSize[iconSize] }))),
        React.createElement("input", __assign({ "data-testid": dataTestId, ref: ref, style: { caretColor: '#4E55FF' }, name: name, className: inputClass(inputSize, isPlain, isDisabled), disabled: isDisabled, value: value, readOnly: isReadOnly, onChange: handleInputChange, onBlur: handleInputBlur, onFocus: handleInputFocus, onKeyPress: handleInputKeyPress }, restOfProps)),
        icon && iconPosition === 'right' && (React.createElement("i", { role: "button", tabIndex: 0, className: iconRightClass, onKeyDown: handleIconClick, onClick: handleIconClick },
            React.createElement(Icon, { className: iconClassName, icon: icon, width: IconSize[iconSize], height: IconSize[iconSize] })))));
});
