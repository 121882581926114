import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPhoneField, getTextField } from '@eva-pacs/core';
import { PatientGender } from '@eva-pacs/client';
import {
  Alert,
  AlertVariant,
  BasicInput,
  Button,
  ButtonColor,
  ButtonType,
  DatePickerInput,
  FormField,
  PhoneObjType,
  Radio,
} from '@eva-pacs/ui';
import { PhoneInputWrapper } from '~/components/shared/PhoneInputWrapper';
import { patienteditformClass } from './styles';

export interface PatientFormFields {
  identifierEditable: string;
  fullName: string;
  gender: string;
  birthDate?: string;
  email: string;
  phoneObj: { phone: string; phoneCode: string; isoCode?: string };
  phoneCountry: {
    id: string;
    isoCode: string;
  };
  identifier: string; // TODO: reemplazar por la histora médica
}

export interface PatientEditFormProps {
  /**
   * Set visual classes to element
   */
  className?: string;

  /**
   * Pass default fields value
   */
  defaultFieldsValue: PatientFormFields;

  /**
   * Set if form is disabled
   */
  isDisabled?: boolean;

  /**
   * Emit Event when form is submited
   */
  onSubmitEmit: (data: PatientFormFields) => void;

  /**
   * Emit Event when user clicked cancel button
   */
  onCancel: () => void;
}

/**
 * Form to edit patient info with validations.
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-07-26
 */
export const PatientEditForm: React.FC<PatientEditFormProps> = ({
  className,
  defaultFieldsValue,
  isDisabled = false,
  onSubmitEmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    identifierEditable: getTextField(t),
    fullName: getTextField(t, true),
    gender: getTextField(t),
    birthDate: getTextField(t),
    email: getTextField(t),
    phoneObj: yup.object({
      phone: getPhoneField(t),
      phoneCode: getTextField(t),
    }),
    phoneCountry: yup.object({
      id: getTextField(t),
      isoCode: getTextField(t),
    }),
    identifier: getTextField(t),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<PatientFormFields>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: PatientFormFields) => onSubmitEmit(data);

  const handleInputChange = (phoneObj: PhoneObjType) => {
    if (!phoneObj.id) return;
    // eslint-disable-next-line i18next/no-literal-string
    setValue('phoneCountry.id', phoneObj?.id || '');
  };

  return (
    <form data-testid="patientEditForm" className={patienteditformClass(className)} onSubmit={handleSubmit(onSubmit)}>
      {isDirty && (
        <Alert
          className="mb-10 max-w-xs"
          description={t('general.genericPendingChangesText')}
          variant={AlertVariant.warning}
          hasCloseButton={false}
        />
      )}
      <fieldset
        className="grid gap-x-8 gap-y-10 grid-cols-3 mb-8 px-2 text-left text-basic-white"
        disabled={isDisabled}>
        <FormField
          name="identifierEditable"
          defaultValue={defaultFieldsValue.identifierEditable}
          label={t('study.studyFields.identifierEditable')}
          control={control}
          error={errors.identifierEditable}
          render={({ field }) => (
            <BasicInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder={t('study.studyFields.identifierEditable')}
            />
          )}
        />
        <FormField
          name="fullName"
          defaultValue={defaultFieldsValue.fullName}
          label={t('auth.formFields.fullName')}
          control={control}
          error={errors.fullName}
          render={({ field }) => (
            <BasicInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder={t('auth.formFields.fullName')}
            />
          )}
        />
        <FormField
          name="birthDate"
          defaultValue={defaultFieldsValue.birthDate}
          label={t('auth.formFields.birthdate')}
          control={control}
          error={errors.birthDate}
          render={({ field }) => (
            <DatePickerInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder={t('auth.formFields.birthdate')}
            />
          )}
        />
        <FormField
          name="email"
          defaultValue={defaultFieldsValue.email}
          label={`${t('auth.formFields.email')} (${t('general.optional')})`}
          control={control}
          error={errors.email}
          render={({ field }) => (
            <BasicInput
              id={field.name}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder={`${t('auth.formFields.email')} (${t('general.optional')})`}
            />
          )}
        />
        <FormField
          name="phoneObj"
          defaultValue={defaultFieldsValue.phoneObj}
          label={`${t('admin.formFields.phone')} (${t('general.optional')})`}
          control={control}
          error={errors.phoneObj?.phone}
          render={({ field }) => (
            <PhoneInputWrapper
              id={field.name}
              name={field.name}
              value={field.value.phone}
              onInputChange={handleInputChange}
              field={field}
              placeholder={`${t('admin.formFields.phone')} (${t('general.optional')})`}
              defaultIsoCode={defaultFieldsValue.phoneObj.isoCode}
            />
          )}
        />
        <FormField
          name="gender"
          defaultValue={defaultFieldsValue.gender}
          label={t('admin.formFields.gender.label')}
          control={control}
          labelIsSimpleText={true}
          error={errors.gender}
          render={({ field }) => (
            <div className="flex">
              <label className="flex items-center mr-4 text-label-sm">
                <Radio
                  className="mr-2"
                  name={field.name}
                  value={PatientGender.F}
                  checked={field.value === PatientGender.F}
                  onChange={field.onChange}
                />
                {t('admin.formFields.gender.options.female')}
              </label>
              <label className="flex items-center text-label-sm">
                <Radio
                  className="mr-2"
                  name={field.name}
                  value={PatientGender.M}
                  checked={field.value === PatientGender.M}
                  onChange={field.onChange}
                />
                {t('admin.formFields.gender.options.male')}
              </label>
            </div>
          )}
        />
      </fieldset>
      <div className="flex justify-end mt-10">
        <Button className="mr-6" color={ButtonColor.primaryAlternative} onClick={onCancel} isRounded>
          {t('viewer.report.cancelBtn')}
        </Button>
        <Button type={ButtonType.submit} isDisabled={isDisabled} isRounded>
          {t('general.actions.saveChanges')}
        </Button>
      </div>
    </form>
  );
};
