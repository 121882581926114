import Scroll from 'react-scroll';
const scroller = Scroll.scroller;

export interface ScrollToProps {
  /**
   * Name of the JSX element to scroll to.
   */
  elementName: string;

  /**
   * Container id within the JSX Element to scroll is contained.
   */
  containerId: string;
}

export interface UseScrollProps {
  duration?: number;
  delay?: number;
  offset?: number;
}

/**
 * Hook that lets you scroll to a specific JSX Element from react-scroll library with a smooth effect.
 * The JSX Element needs to be imported from the react-scroll library and place it in the DOM above or before the
 * component/section you want to scroll.
 * See this page for more information: https://github.com/fisshy/react-scroll#usage
 * @returns scrollTo callback
 * @author Salvador Gonzalez <salvador.gonzalez@evacenter.com>
 * Created at 2023-08-16
 */
export const useScroll = ({ duration = 1200, delay = 100, offset = 50 }: UseScrollProps) => {
  const scrollTo = ({ elementName, containerId }: ScrollToProps) => {
    scroller.scrollTo(elementName, {
      duration,
      delay,
      smooth: true,
      containerId: containerId,
      offset, // Scrolls to element + 50 pixels down the page
    });
  };

  return { scrollTo };
};
