import React, { useState } from 'react';
import cn from 'classnames';
import Popover, { PopoverPlacement } from '../../../Popover';
/**
 * Long Text Cell (Table)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-27
 */
export var LongTextCell = function (_a) {
    var text = _a.text, _b = _a.maxLines, maxLines = _b === void 0 ? 2 : _b, className = _a.className;
    var popoverClass = cn(className, 'max-w-lg');
    var cellClass = cn('cursor-pointer', {
        'line-clamp-1': maxLines === 1,
        'line-clamp-2': maxLines === 2,
        'line-clamp-3': maxLines === 3,
        'line-clamp-4': maxLines === 4,
        'line-clamp-5': maxLines === 5,
        'line-clamp-6': maxLines === 6,
    });
    var _c = useState(false), menuIsOpen = _c[0], setMenuIsOpen = _c[1];
    var handleTriggerClick = function () { return setMenuIsOpen(!menuIsOpen); };
    var handleClickOutside = function () { return setMenuIsOpen(false); };
    var popoverContent = React.createElement("p", { className: "p-4 text-left text-basic-white text-paragraph-sm font-regular" }, text);
    return (React.createElement(Popover, { isOpen: menuIsOpen, className: popoverClass, content: popoverContent, placement: PopoverPlacement.bottomLeft, onClickOutside: handleClickOutside },
        React.createElement("span", { role: "button", tabIndex: -1, className: cellClass, onClick: handleTriggerClick, onKeyPress: handleTriggerClick }, text)));
};
