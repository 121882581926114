import { LOGIN_PATH, LOGIN_URL_SSO_TOKEN_KEY, getCookie, removeCookie } from '@eva-pacs/core';

export const RedirectLogin = () => {
  // Regexp deletes extra "" retrieved from the cookie.
  const ssoLoginURL = getCookie(LOGIN_URL_SSO_TOKEN_KEY).replace(/^"(.*)"$/, '$1');

  // if externalLoginURL is present, it means it was a login from SSO and
  // we should remove that cookie and redirect to the URL
  // provided on it.
  if (ssoLoginURL) {
    window.location.replace(ssoLoginURL);
    removeCookie(ssoLoginURL);
    return null;
  }

  // Normal redirect for normarl users.
  window.location.assign(`${process.env.REACT_APP_EVACENTER_URL}${LOGIN_PATH}`);
  return null;
};
