import { InvariantError } from 'ts-invariant';
export var serializeFetchParameter = function (parameter, label) {
    var serialized;
    try {
        serialized = JSON.stringify(parameter);
    }
    catch (err) {
        var parseError = new InvariantError("Network request failed. ".concat(label, " is not serializable: ").concat(err.message));
        parseError.parseError = err;
        throw parseError;
    }
    return serialized;
};
