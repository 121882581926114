export var ANGLE = 'ANGLE';
export var ANNOTATION = 'ANNOTATION';
export var BIDIRECTIONAL = 'BIDIRECTIONAL';
export var CINEMA = 'CINEMA';
export var CLEAN = 'CLEAN';
export var COBBANGLE = 'COBBANGLE';
export var CROSSHAIR = 'CROSSHAIR';
export var CTR = 'CTR';
export var COXOMETRY = 'COXOMETRY';
export var GONIOMETRY = 'GONIOMETRY';
export var NIPPLE_INJURY = 'NIPPLE_INJURY';
export var ELLIPSIS = 'ELLIPSIS';
export var ERASE = 'ERASER';
export var FLIP_HORIZONTAL = 'FLIP_HORIZONTAL';
export var FLIP_VERTICAL = 'FLIP_VERTICAL';
export var FREEHAND = 'FREEHAND';
export var INVERT = 'INVERT';
export var LENGTH = 'LENGTH';
export var MAGNIFY = 'MAGNIFY';
export var MODE = 'MODE';
export var MOVE = 'MOVE';
export var VIEW3D = 'VIEW3D';
export var VIEW3D_EXIT = 'VIEW3D_EXIT';
export var MPR = 'MPR';
export var MPR_EXIT = 'MPR_EXIT';
export var PREDEFINED_LEVELS = 'PREDEFINED_LEVELS';
export var PROBE = 'PROBE';
export var RECTANGLE = 'RECTANGLE';
export var ROTATE = 'ROTATE';
export var ROTATE_LEFT = 'ROTATE_LEFT';
export var ROTATE_RIGHT = 'ROTATE_RIGHT';
export var SLAB = 'SLAB';
export var UNDO = 'UNDO';
export var WWWC = 'WWWC';
export var ZOOM = 'ZOOM';
