export enum EVA_PRODUCT {
  PANEL = 'panel',
  PACS = 'pacs',
  MANAGEMENT = 'management',
  INTELLIGENCE = 'intelligence',
  UNKNWON = '??',
}

export const EDEN_PACS_NAME = 'Eden PACS';
export const EVACENTER_URL = process.env['REACT_APP_EVACENTER_URL'];
