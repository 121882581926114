var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import crypto from 'crypto';
import MicrophoneStream from 'microphone-stream';
import { createPresignedURL } from './awsSignatureV4';
import { EventStreamMarshaller } from '@aws-sdk/eventstream-marshaller';
import { toUtf8, fromUtf8 } from '@aws-sdk/util-utf8-node';
// Converter between binary event streams messages and JSON
var eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8);
var transcriberConfig = {
    accessId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    languageCode: 'es-US',
    sampleRate: 16000,
    vocabularyName: 'pacsEsVocabulary',
};
var Transcriber = /** @class */ (function () {
    function Transcriber(sink, record) {
        if (record === void 0) { record = false; }
        this.socket = null;
        this.micStream = null;
        this.micSampleRate = null;
        this.records = [];
        this.record = false;
        this.listening = false;
        this.stoppingSocket = false;
        this.sink = sink;
        this.record = record;
    }
    Transcriber.prototype.start = function () {
        return __awaiter(this, void 0, void 0, function () {
            var audio, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.records = [];
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia({
                                audio: true,
                                video: false,
                            })];
                    case 1:
                        audio = _a.sent();
                        this.micStream = new MicrophoneStream();
                        this.micStream.setStream(audio);
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        this.micStream.on('format', function (data) {
                            _this.micSampleRate = data.sampleRate;
                            _this.buildSocket();
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.stop();
                        this.sink.onError("Unable to create microphone stream: ".concat(error_1.toString()));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Transcriber.prototype.stop = function () {
        var _a;
        this.listening = false;
        (_a = this.micStream) === null || _a === void 0 ? void 0 : _a.stop();
        this.micStream = null;
        this.closeSocket();
    };
    Transcriber.prototype.getRecords = function (bytes) {
        if (!this.record) {
            throw Error('Recording is disabled');
        }
        if (!this.listening) {
            return [];
        }
        var size = 0;
        var index;
        for (index = 0; index < this.records.length; index++) {
            size += this.records[index].byteLength;
            if (size >= bytes) {
                break;
            }
        }
        return this.records.splice(0, index + 1);
    };
    Transcriber.prototype.buildSocket = function () {
        var _this = this;
        if (!this.micStream || !this.micSampleRate) {
            throw Error('Please setup microphone stream before build socket');
        }
        var url = this.buildUrl();
        // open up our WebSocket connection
        this.socket = new WebSocket(url);
        this.socket.binaryType = 'arraybuffer';
        // when we get audio data from the mic, send it to the WebSocket if possible
        this.socket.onopen = function () {
            _this.listening = true;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            _this.micStream.on('data', function (rawAudioChunk) {
                if (_this.socket && _this.socket.readyState === _this.socket.OPEN) {
                    var encodedAudio = _this.encodeAudio(rawAudioChunk);
                    if (encodedAudio) {
                        if (_this.record) {
                            _this.records.push(encodedAudio);
                        }
                        var marshalledAudio = marshallArrayBuffer(encodedAudio);
                        _this.socket.send(marshalledAudio);
                    }
                }
            });
        };
        // handle messages, errors, and close events
        this.socket.onmessage = function (message) {
            var _a, _b, _c;
            // convert the binary event stream message to JSON
            var messageWrapper = eventStreamMarshaller.unmarshall(Buffer.from(message.data));
            // eslint-disable-next-line prefer-spread
            var messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
            if (messageWrapper.headers[':message-type'].value === 'event') {
                var results = messageBody.Transcript.Results || {};
                if (results && results.length > 0) {
                    if (((_a = results[0]) === null || _a === void 0 ? void 0 : _a.Alternatives) && ((_c = (_b = results[0]) === null || _b === void 0 ? void 0 : _b.Alternatives) === null || _c === void 0 ? void 0 : _c.length) > 0) {
                        var transcription = decodeURIComponent(escape(results[0].Alternatives[0].Transcript || ''));
                        _this.sink.onTranscript(transcription, results[0].IsPartial);
                    }
                }
            }
            else {
                _this.stop();
                _this.sink.onError("Error on socket message: ".concat(messageBody.Message));
            }
        };
        this.socket.onerror = function () {
            _this.stop();
            _this.sink.onError('WebSocket connection error.');
        };
        this.socket.onclose = function (closeEvent) {
            _this.stoppingSocket = false;
            _this.socket = null;
            _this.records = [];
            _this.stop();
            _this.sink.onTerminate('Termination reason: ' + closeEvent.reason);
        };
    };
    Transcriber.prototype.closeSocket = function () {
        if (this.socket && this.socket.readyState === this.socket.OPEN && !this.stoppingSocket) {
            // Send an empty frame so that Transcribe initiates a closure of the WebSocket after
            // submitting all transcripts
            this.stoppingSocket = true;
            var buffer = Buffer.from([]);
            this.socket.send(marshallArrayBuffer(buffer));
        }
        else if (this.socket && this.socket.readyState !== this.socket.OPEN) {
            this.socket = null;
        }
    };
    Transcriber.prototype.buildUrl = function () {
        var endpoint = 'transcribestreaming.' + transcriberConfig.region + '.amazonaws.com:8443';
        return createPresignedURL('GET', endpoint, '/stream-transcription-websocket', 'transcribe', crypto.createHash('sha256').update('', 'utf8').digest('hex'), {
            key: transcriberConfig.accessId,
            secret: transcriberConfig.secretKey,
            sessionToken: undefined,
            protocol: 'wss',
            expires: 15,
            region: transcriberConfig.region,
            query: {
                'language-code': transcriberConfig.languageCode,
                'media-encoding': 'pcm',
                'sample-rate': transcriberConfig.sampleRate,
                'vocabulary-name': transcriberConfig.vocabularyName,
            },
        });
    };
    Transcriber.prototype.encodeAudio = function (audioChunk) {
        if (!this.micSampleRate) {
            throw Error('Please setup microphone stream before convert audio');
        }
        var raw = MicrophoneStream.toRaw(audioChunk);
        if (raw == null) {
            return null;
        }
        // downsample and convert the raw audio bytes to PCM
        var downsampledBuffer = downsampleBuffer(raw, this.micSampleRate, transcriberConfig.sampleRate);
        return pcmEncode(downsampledBuffer);
    };
    return Transcriber;
}());
export { Transcriber };
var pcmEncode = function (input) {
    var buffer = new ArrayBuffer(input.length * 2);
    var view = new DataView(buffer);
    for (var i = 0; i < input.length; i++) {
        var s = Math.max(-1, Math.min(1, input[i]));
        view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return buffer;
};
var downsampleBuffer = function (buffer, inputSampleRate, outputSampleRate) {
    if (outputSampleRate === inputSampleRate) {
        return buffer;
    }
    var sampleRateRatio = inputSampleRate / outputSampleRate;
    var newLength = Math.round(buffer.length / sampleRateRatio);
    var result = new Float32Array(newLength);
    var offsetResult = 0;
    var offsetBuffer = 0;
    while (offsetResult < result.length) {
        var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
        var accum = 0;
        var count = 0;
        for (var index = offsetBuffer; index < nextOffsetBuffer && index < buffer.length; index++) {
            accum += buffer[index];
            count++;
        }
        result[offsetResult] = accum / count;
        offsetResult++;
        offsetBuffer = nextOffsetBuffer;
    }
    return result;
};
var marshallArrayBuffer = function (data) {
    // add the right JSON headers and structure to the message
    var buffer = Buffer.from(data);
    // convert the JSON object + headers into a binary event stream message
    return eventStreamMarshaller.marshall({
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event',
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent',
            },
        },
        body: buffer,
    });
};
