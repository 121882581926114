import { useEffect, useRef, useCallback, useState } from 'react';
import cornerstoneTools from 'cornerstone-tools';
import { usePrinterStore, useSeriesStore, useViewportStore } from '~/src/store';
import { getViewportEnabledElements } from '~/utils/getViewportEnabledElements';
import { allImagesHasLoaded } from '~/utils/allImagesHasLoaded';

interface UseCornerstoneParams {
  eventToListen: string;
  synchronizerFunction: (synchronizer, sourceElement: HTMLElement, targetElement: HTMLElement) => void;
  initialEnabled?: boolean;
}

type UseCornerstoneSynchHook = (activeSync: boolean) => void;

export const useCornerstoneSynchTool = ({
  eventToListen,
  synchronizerFunction,
  initialEnabled = false,
}: UseCornerstoneParams): UseCornerstoneSynchHook => {
  const [active, setActive] = useState(initialEnabled);
  const { showPrintView } = usePrinterStore();
  const { getSerieById } = useSeriesStore();
  const { viewportData } = useViewportStore();
  const synchronizerRef = useRef(new cornerstoneTools.Synchronizer(eventToListen, synchronizerFunction));

  const enableSync = useCallback((isEnabled) => {
    synchronizerRef.current.enabled = isEnabled;
  }, []);

  const removeSynchronizer = useCallback(() => {
    synchronizerRef.current.destroy();
  }, []);

  const clearElementSynchronizer = useCallback((element) => {
    synchronizerRef.current.remove(element);
  }, []);

  const setElementSynchronizer = useCallback((element) => {
    synchronizerRef.current.addSource(element);
  }, []);

  const setElementTarget = useCallback((element) => {
    synchronizerRef.current.addTarget(element);
  }, []);

  const setSincronizersElements = useCallback(
    (currentIndex: number) => {
      if (showPrintView) return;
      try {
        const viewportDataArray = Object.values(viewportData);
        const enabledElements = getViewportEnabledElements(viewportDataArray);

        const vieportsAreReady = enabledElements?.length > 0 && allImagesHasLoaded(viewportDataArray);
        if (!vieportsAreReady) return;

        const currentViewport = viewportDataArray[currentIndex];
        const currentSerieIsLoaded = getSerieById(currentViewport.currentSerieId)?.isLoaded;

        if (!currentSerieIsLoaded) return;

        enabledElements.forEach((element, indexElement) => {
          clearElementSynchronizer(element);

          if (indexElement === currentIndex) {
            setElementSynchronizer(element);
            return;
          }
          setElementTarget(element);
        });
      } catch (error) {
        console.log(error);
      }
    },
    [clearElementSynchronizer, setElementTarget, showPrintView, getSerieById, viewportData, setElementSynchronizer],
  );

  useEffect(() => {
    enableSync(active);
  }, [enableSync, active]);

  useEffect(() => {
    setSincronizersElements(0);
    return removeSynchronizer;
  }, [removeSynchronizer, setSincronizersElements]);

  return setActive;
};
