import type { IAllProps } from '@tinymce/tinymce-react';
import { removeStylesFromHTML } from '~/utils/removeStylesFromHTML';

export const TEXT_EDITOR_DEFAULT_INIT_SETTINGS: IAllProps['init'] = {
  menubar: false,
  branding: false,
  statusbar: false,
  auto_focus: true,
  toolbar_items_size: 'small',
  width: '100%',
  plugins: 'advlist lists table pagebreak searchreplace fullscreen autoresize',
  toolbar:
    'undo redo | bold italic underline | align | bullist numlist | table | pagebreak | fontsize | outdent indent',
  content_css: '/styles.css',
  body_class: 'Editor__body',
  font_formats: '',
  font_size_formats: '6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
  preview_styles: 'font-size color',
  pagebreak_separator: '<p style="page-break-before: always" ></p>',
  paste_data_images: true,
  keep_styles: true,
  height: 139, //In tinymce version 5 the default height is 139px and in version 6 it is 400, so we set that value so that it is displayed the same in both versions
  paste_preprocess: (editor, args) => {
    const stylesToIgnore = ['font-size', 'color', 'background', 'font', 'font-family'];
    args.content = removeStylesFromHTML(args.content, stylesToIgnore);
  },
  init_instance_callback: (tinymceEditor) => {
    // @ts-ignore
    WEBSPELLCHECKER.init({
      container: tinymceEditor.iframeElement,
    });
  },
};
