import React from 'react';
import cn from 'classnames';
import { useTabs } from '@eva-pacs/core';
/**
 * Tab (Tabs)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-06-08
 */
export var TabV2 = function (_a) {
    var _b;
    var tabId = _a.tabId, activeClassName = _a.activeClassName, className = _a.className, onClick = _a.onClick, children = _a.children, inactiveClassName = _a.inactiveClassName;
    var _c = useTabs(), activeTab = _c.activeTab, changeTab = _c.changeTab;
    var tabClass = cn(className, (_b = {},
        _b["".concat(activeClassName)] = activeTab === tabId,
        _b["".concat(inactiveClassName)] = activeTab !== tabId,
        _b));
    var handleClick = function () {
        changeTab(tabId);
        if (onClick)
            onClick();
    };
    return (React.createElement("button", { role: "tab", className: tabClass, "aria-selected": activeTab === tabId, "aria-controls": tabId, tabIndex: activeTab === tabId ? 0 : -1, onClick: handleClick, onKeyDown: handleClick }, children));
};
