import { useState } from 'react';
import { WatchQueryFetchPolicy } from '@apollo/client';

import { SelectOption } from '@evacenter/eden';

import { PractitionerStatus, PractitionerType, useGetPractitionersOptionsQuery } from '@eva-pacs/client';

export interface UseGetPractitionerOptionsProps {
  /**
   * Array of string of UserRole type.
   */
  roleIn: Array<string>;

  /**
   * Set value as user ID instead practitioner id.
   */
  useUserId?: boolean;

  fetchPolicy?: WatchQueryFetchPolicy;

  /**
   * Array of strings containing the practitioner's status.
   */
  statusIn: Array<PractitionerStatus>;

  getReviewersList?: boolean;
}

export const useGetPractitionerOptions = ({
  roleIn,
  statusIn,
  useUserId = false,
  fetchPolicy = 'network-only',
  getReviewersList,
}: UseGetPractitionerOptionsProps) => {
  const [practitionersOptions, setPractitionerOptions] = useState<Array<SelectOption>>([]);
  const { loading } = useGetPractitionersOptionsQuery({
    variables: {
      roleIn,
      statusIn,
      ...(getReviewersList ? { reviewer: getReviewersList } : {}),
    },
    fetchPolicy,
    onCompleted: (data) => {
      const practitionersResults = (data?.practitioners?.results ?? []) as Array<PractitionerType>;
      const practitionerOptions = practitionersResults.map((practitioner) =>
        formatSelectOption(practitioner, useUserId),
      );
      setPractitionerOptions(practitionerOptions);
    },
  });

  return {
    practitionersOptions,
    loading,
  };
};

const formatSelectOption = (practitioner: PractitionerType, useUserId = false): SelectOption => ({
  label: practitioner?.fullName ?? '',
  value: useUserId ? practitioner?.user?.id : practitioner?.id,
});
