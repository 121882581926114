import { ExternalLibIds } from '~/src/constants/libs';
import { useLibManager } from './useLibManager';
export const useOpenCVLoader = () => {
  const { load } = useLibManager();

  const loadOpenCV = async (): Promise<ExternalLibIds> => {
    return load(ExternalLibIds.Opencv).then((openCVScriptId) => {
      return new Promise<ExternalLibIds>((resolve, reject) => {
        try {
          /* When you get the opencv script it is a promise.
           * This validation and reassignment is added to avoid doing
           * it in all parts where you need to use opencv
           */
          /* @ts-ignore when open cv is a promise*/
          if (typeof cv.then === 'function') {
            /* @ts-ignore*/
            cv.then((loadedCv) => {
              cv = loadedCv;
              resolve(openCVScriptId);
            });
          }
          if (!cv.getBuildInformation) {
            cv['onRuntimeInitialized'] = () => {
              resolve(openCVScriptId);
            };
            return;
          }
          return resolve(openCVScriptId);
        } catch {
          return reject('Error Loading this library');
        }
      });
    });
  };
  return loadOpenCV;
};
