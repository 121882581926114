var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { countryCodeEmoji } from 'country-code-emoji';
import { setMaxHeightByOptions } from '@eva-pacs/core';
import Icon, { IconCatalog } from '../../Icon';
import { BasicInput, BasicInputSize } from '../BasicInput';
import Popover, { PopoverPlacement } from '../../Popover';
import { countryClass, phoneInputClass } from './styles';
/**
 * The Phone Input is used to collect phone numbers with international dial codes. It contains a dropdown which allows the user to choose from a list of countries and dial codes.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-03-09
 */
export var PhoneInput = function (_a) {
    var className = _a.className, _b = _a.inputSize, inputSize = _b === void 0 ? BasicInputSize.md : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.isFullWidth, isFullWidth = _d === void 0 ? false : _d, hasError = _a.hasError, _e = _a.value, value = _e === void 0 ? '' : _e, defaultCountry = _a.defaultCountry, _f = _a.countries, countries = _f === void 0 ? [] : _f, name = _a.name, dataTestId = _a.dataTestId, onInputChange = _a.onInputChange, restOfProps = __rest(_a, ["className", "inputSize", "isDisabled", "isFullWidth", "hasError", "value", "defaultCountry", "countries", "name", "dataTestId", "onInputChange"]);
    var _g = useState(defaultCountry), selectedCountry = _g[0], setSelectedCountry = _g[1];
    var _h = useState(value), currentValue = _h[0], setCurrentValue = _h[1];
    var _j = useState(false), isOpen = _j[0], setIsOpen = _j[1];
    useEffect(function () {
        if ((selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.isoCode) === (defaultCountry === null || defaultCountry === void 0 ? void 0 : defaultCountry.isoCode))
            return;
        setSelectedCountry(defaultCountry);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCountry]);
    var handleClickOutside = function () { return setIsOpen(false); };
    var handleSelectClick = function () { return setIsOpen(!isOpen); };
    var handleOptionClick = function (option) { return function () {
        setSelectedCountry(option);
        setIsOpen(false);
        if (onInputChange)
            onInputChange({ phone: currentValue, phoneCode: option.phoneCode, isoCode: option.isoCode });
    }; };
    var handleChange = function (event) {
        var value = event.target.value;
        setCurrentValue(value);
        if (onInputChange)
            onInputChange({ phone: value, phoneCode: selectedCountry.phoneCode, isoCode: selectedCountry.isoCode });
    };
    var renderOptions = function () {
        return countries.map(function (option, index) { return (React.createElement("div", { key: index, role: "option", tabIndex: -1, className: "flex items-center px-3 py-2 hover:bg-gray-700 cursor-pointer space-x-3", "aria-selected": (selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.isoCode) === option.isoCode, onClick: handleOptionClick(option), onKeyPress: handleOptionClick(option) },
            React.createElement("div", null, countryCodeEmoji(option.isoCode)),
            React.createElement("div", { className: "flex w-full text-basic-white text-label-md" }, option.name),
            React.createElement("div", { className: "text-basic-white" },
                "+",
                option.phoneCode))); });
    };
    var popoverContent = (React.createElement("div", { role: "listbox", style: setMaxHeightByOptions({ listLength: countries.length, maxOptions: 6, maxHeight: '210px' }), className: "scrollbar-w-2 scrollbar-thumb-gray-200 scrollbar-track-gray-700 scrollbar-thumb-rounded-lg flex-grow py-2 w-full bg-gray-600 border border-gray-300 rounded-md overflow-y-auto" }, renderOptions()));
    /* Render JSX */
    return (React.createElement("div", { className: phoneInputClass(className, inputSize, isDisabled, isFullWidth, hasError) },
        React.createElement("div", { className: countryClass(inputSize) },
            React.createElement(Popover, { isOpen: isOpen, menuClassName: "min-w-52", content: popoverContent, placement: PopoverPlacement.bottomLeft, onClickOutside: handleClickOutside, hasArrow: false },
                React.createElement("div", { role: "button", tabIndex: 0, className: "flex items-center", "aria-disabled": isDisabled || countries.length === 0, "aria-haspopup": "listbox", "aria-expanded": isOpen, onClick: handleSelectClick, onKeyPress: handleSelectClick }, selectedCountry && (React.createElement(React.Fragment, null,
                    React.createElement("div", null, countryCodeEmoji(selectedCountry.isoCode)),
                    React.createElement(Icon, { className: "text-gray-50", icon: IconCatalog.caretDown, width: "24px", height: "24px" }),
                    React.createElement("div", { className: "text-basic-white" },
                        "+",
                        selectedCountry.phoneCode)))))),
        React.createElement(BasicInput, __assign({ name: name, dataTestId: dataTestId, value: currentValue, inputSize: inputSize, isFullWidth: true, isDisabled: isDisabled || countries.length === 0, isPlain: true }, restOfProps, { onChange: handleChange }))));
};
