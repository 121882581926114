import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { ToolName } from '~/src/constants/toolName';
/**
 * Reset mammography reference lines tool state and disable.
 *
 * @export @public @method
 * @name resetMammographyReferenceLinesTool
 * @returns {void}
 */
export const resetMammographyReferenceLinesTool = () => {
  cornerstoneTools.setToolDisabled(ToolName.MammographyReferenceLines);
  cornerstone.getEnabledElements().forEach((enabledElement) => {
    if (!enabledElement.image) return;
    cornerstoneTools.clearToolState(enabledElement.element, ToolName.MammographyReferenceLines);
  });
};
