import { LevelCatalog } from '@eva-pacs/core';

import { updateAllImages } from '~/components/Viewer/MeasurementsBrowser/handleMeasurements';
import { useViewportStore } from '~/src/store';

import { restorePassiveToolState } from './tools/useSetterTools';
import { useConvolveFilter } from './tools/useConvolveFilter';

/**
 * This hooks is used to reset viewport data
 */
export const useResetViewportData = () => {
  const { clearFilter } = useConvolveFilter();
  const {
    setIsLevelAdjustedViewport,
    setIsWwwcAdjustedViewport,
    setViewportCurrentWindow,
    setViewportInvertSelected,
    setViewportFlipHSelected,
    setViewportFlipVSelected,
    setViewportPreviewed,
  } = useViewportStore();

  const resetViewportData = (viewportIndex: number) => {
    clearFilter(viewportIndex);
    setIsLevelAdjustedViewport(viewportIndex, false);
    setIsWwwcAdjustedViewport(viewportIndex, false);
    setViewportCurrentWindow(viewportIndex, LevelCatalog.NONE);
    setViewportInvertSelected(viewportIndex, false);
    setViewportFlipHSelected(viewportIndex, false);
    setViewportFlipVSelected(viewportIndex, false);
    setViewportFlipVSelected(viewportIndex, false);
    setViewportPreviewed(null);
    restorePassiveToolState();
    updateAllImages();
  };

  return { resetViewportData };
};
