interface Point {
  x: number;
  y: number;
}

interface Rect {
  start: Point;
  end: Point;
}
export const rotatePoint = (center: Point, point: Point, angle: number) => {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (point.x - center.x) + sin * (point.y - center.y) + center.x;
  const ny = cos * (point.y - center.y) - sin * (point.x - center.x) + center.y;
  return { x: nx, y: ny };
};

export const rotateLine = (rect: Rect, centerPoint: Point, angle: number) => {
  const rotatedStart = rotatePoint(centerPoint, rect.start, angle);
  const rotatedEnd = rotatePoint(centerPoint, rect.end, angle);

  return { start: rotatedStart, end: rotatedEnd };
};

export const getVerticalParalelLines = (center: Point, pixelSpacing, rotationAngle, limits, separationInCM = 2) => {
  const { colPixelSpacing = 1 } = pixelSpacing;

  const centerSeparationInPX = (separationInCM * 10) / colPixelSpacing;
  const { height } = limits;
  const line1: Rect = {
    start: { x: center.x - centerSeparationInPX, y: 0 },
    end: { x: center.x - centerSeparationInPX, y: height },
  };
  const line2: Rect = {
    start: { x: center.x + centerSeparationInPX, y: 0 },
    end: { x: center.x + centerSeparationInPX, y: height },
  };
  const rotatedLine1 = rotateLine(line1, center, rotationAngle);
  const rotatedLine2 = rotateLine(line2, center, rotationAngle);
  return [rotatedLine1, rotatedLine2];
};

export const calcAngle = (r1Start: Point, r1End: Point, r2Start: Point, r2End: Point) => {
  const vector1 = { x: r1End.x - r1Start.x, y: r1End.y - r1Start.y };
  const vector2 = { x: r2End.x - r2Start.x, y: r2End.y - r2Start.y };

  const productPoint = vector1.x * vector2.x + vector1.y * vector2.y;

  const magnitud1 = Math.sqrt(vector1.x * vector1.x + vector1.y * vector1.y);
  const magnitud2 = Math.sqrt(vector2.x * vector2.x + vector2.y * vector2.y);

  const cosAngle = productPoint / (magnitud1 * magnitud2);

  const angleInRadiants = Math.acos(cosAngle);

  const angle = (angleInRadiants * 180) / Math.PI;

  return angle;
};

export const getLineMidpoint = (point1: Point, point2: Point) => {
  const midX = (point1.x + point2.x) / 2;
  const minY = (point1.y + point2.y) / 2;
  return { x: midX, y: minY };
};

export const calcYCordInRectWithX = (xCord: number, start: Point, end: Point) => {
  const m = (end.y - start.y) / (end.x - start.x);
  const b = start.y - m * start.x;
  const yCord = m * xCord + b;
  return yCord;
};

export const isPointInsideSegment = (p: Point, start: Point, end: Point, tolerance = 1) => {
  const crossProduct = (p.y - start.y) * (end.x - start.x) - (p.x - start.x) * (end.y - start.y);

  if (Math.abs(crossProduct) > 1) return false;

  const dotProduct = (p.x - start.x) * (end.x - start.x) + (p.y - start.y) * (end.y - start.y);
  const squaredLength = (end.x - start.x) * (end.x - start.x) + (end.y - start.y) * (end.y - start.y);
  const toleranceSquared = tolerance * tolerance;

  if (dotProduct < -toleranceSquared || dotProduct > squaredLength + toleranceSquared) return false;
  return true;
};

export const getIntersection = (r1Start: Point, r1End: Point, r2Start: Point, r2End: Point): Point | null => {
  const determinant = (r1Start.x - r1End.x) * (r2Start.y - r2End.y) - (r1Start.y - r1End.y) * (r2Start.x - r2End.x);

  if (determinant === 0) return null;

  const intersectionX =
    ((r1Start.x * r1End.y - r1Start.y * r1End.x) * (r2Start.x - r2End.x) -
      (r1Start.x - r1End.x) * (r2Start.x * r2End.y - r2Start.y * r2End.x)) /
    determinant;
  const intersectionY =
    ((r1Start.x * r1End.y - r1Start.y * r1End.x) * (r2Start.y - r2End.y) -
      (r1Start.y - r1End.y) * (r2Start.x * r2End.y - r2Start.y * r2End.x)) /
    determinant;
  return { x: intersectionX, y: intersectionY };
};

export const getSegmentIntersection = (r1Start: Point, r1End: Point, r2Start: Point, r2End: Point): Point | null => {
  const intersection = getIntersection(r1Start, r1End, r2Start, r2End);
  if (!intersection) return null;
  if (isPointInsideSegment(intersection, r1Start, r1End) && isPointInsideSegment(intersection, r2Start, r2End)) {
    return intersection;
  }
  return null;
};

export const findPerpendicularPoint = (pointOnLine1: Point, pointOnLine2: Point, givenPoint: Point) => {
  const m = (pointOnLine2.y - pointOnLine1.y) / (pointOnLine2.x - pointOnLine1.x);
  const perpendicular_m = -1 / m;
  if (m === 0) return { x: givenPoint.x, y: pointOnLine1.y };
  const c_perpendicular = givenPoint.y - perpendicular_m * givenPoint.x;
  const findIntersection = (m1, c1, m2, c2) => {
    const x = (c2 - c1) / (m1 - m2);
    const y = m1 * x + c1;
    return { x, y };
  };

  const intersectionPoint = findIntersection(m, 0, perpendicular_m, c_perpendicular);

  return intersectionPoint;
};

export const calcXCordInRectWithY = (ycord: number, start: Point, end: Point) => {
  if (end.x - start.x === 0) return start.x;
  const m = (end.y - start.y) / (end.x - start.x);
  const b = start.y - m * start.x;
  const x = (ycord - b) / m;
  return x;
};

export const distanceBetweenPoints = (
  point1: Point,
  point2: Point,
  rowPixelSpacing?: number,
  colPixelSpacing?: number,
) => {
  const dx = (point2.x - point1.x) * (colPixelSpacing || 1);
  const dy = (point2.y - point1.y) * (rowPixelSpacing || 1);

  const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
  return distance;
};

export const findXCoordinateFromPoints = (point1: Point, point2: Point, y: number) => {
  const slope = (point2.y - point1.y) / (point2.x - point1.x);
  const x = (y - point1.y) / slope + point1.x;
  return x;
};
