import { useTrackVisibility } from 'react-intersection-observer-hook';

interface UseInfiniteScrollProps {
  /**
   * Margin to apply to the root element. Default is: 0px 0px 500px 0px
   */
  rootMargin?: string;
}

export const useInfiniteScroll = ({ rootMargin = '480px 0px 480px 0px' }: UseInfiniteScrollProps) => {
  const [sentryRef, { isVisible, rootRef }] = useTrackVisibility({
    rootMargin,
  });

  return {
    rootRef,
    isVisible,
    sentryRef,
  };
};
