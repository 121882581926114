import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastPropsV2, ToastVariant, useToast } from '@evacenter/eden';

import shallow from 'zustand/shallow';

import { StudyStatus, StudyUrgencyLevel, useUpdateStudyMutation } from '@eva-pacs/client';

import { Study } from '~/src/types/Study';
import { useStudyListStore } from '~/src/store';
import { useErrorHandler } from '~/utils/appHelpers';
import { useStudyListTabs } from './useStudyListTabs';
import { PAGE_SIZE_INFINITE_SCROLL, PAGE_SIZE } from '~/constants';
import { useWorklistQueries } from '../worklist/useWorklistQueries';
import { studyDTO } from '~/src/dtos/study/studyDTO';
import { WorklistTabNames } from '~/src/types/StudyList';

interface UpdatePriorityValue {
  value: StudyUrgencyLevel;
}

export enum AddStudyPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

enum UpdateStudyErrorCodes {
  SIGNED_STUDY_EXCEPTION = 'signed_study_exception',
}

export const useStudyActions = () => {
  const [
    getStudy,
    addStudyToBottom,
    removeStudy,
    addStudies,
    updateStudy,
    resetStudies,
    setWorklistTotalCount,
    getStudies,
    addStudyToTop,
    softResetStudies,
    getLastStudyId,
    getWorklistTotalCount,
    enabledInfiniteScroll,
  ] = useStudyListStore(
    (state) => [
      state.getStudy,
      state.addStudyToBottom,
      state.removeStudy,
      state.addStudies,
      state.updateStudy,
      state.resetStudies,
      state.setWorklistTotalCount,
      state.getStudies,
      state.addStudyToTop,
      state.softResetAndAddNewStudies,
      state.getLastStudyId,
      state.getWorklistTotalCount,
      state.enabledInfiniteScroll,
    ],
    shallow,
  );

  const { fetchNextWorklistStudyById } = useWorklistQueries();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const { activeTab } = useStudyListTabs();

  const pageSize = useMemo(() => {
    if (enabledInfiniteScroll) return PAGE_SIZE_INFINITE_SCROLL;

    return PAGE_SIZE;
  }, [enabledInfiniteScroll]);

  const { handleError } = useErrorHandler();
  const [updateStudyMutation] = useUpdateStudyMutation();

  const performAddStudy = (study: Study, position = AddStudyPosition.BOTTOM) => {
    checkForSpaceToInsert();
    if (position === AddStudyPosition.TOP) {
      return addStudyToTop(study);
    }

    addStudyToBottom(study);
  };

  const checkForSpaceToInsert = () => {
    const currentStudies = getStudies();
    const arrayOfStudies = Object.values(currentStudies);

    const tempArray: Array<Study> = arrayOfStudies.map((study) => study);

    // If we have full the current page, remove the last element so a new study can
    // be inserted.
    if (tempArray.length >= pageSize) {
      tempArray.pop();
      softResetStudies(tempArray);
    }
  };

  const incrementWorklistTotalCount = () => {
    const totalCount = getWorklistTotalCount();
    setWorklistTotalCount(totalCount + 1);
  };

  const decrementWorklistTotalCount = () => {
    const totalCount = getWorklistTotalCount();
    if (totalCount === 0) return;

    setWorklistTotalCount(totalCount - 1);
  };

  const performAddStudies = (studies: Array<Study>) => addStudies(studies);

  const performRemoveStudy = (id: string) => removeStudy(id);

  const performUpdateStudy = (studyId: string, newStudy: Study) => updateStudy(studyId, newStudy);

  const processShiftWorklistStudies = async (studyId: string) => {
    const newStudy = await fetchNextWorklistStudyById(studyId);
    if (!newStudy) return;
    const parsedStudy = studyDTO(newStudy, false, true);

    performAddStudy(parsedStudy);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const processShiftStudyListStudies = async (studyId: string) => {
    // TODO: Remove eslint disable and add logic here for shifting with study by id queries for study list.
  };

  const performShiftStudiesBackwards = () => {
    try {
      const lastStudyId = getLastStudyId();
      if (!lastStudyId) return;

      if (activeTab === WorklistTabNames.WORKLIST) return processShiftWorklistStudies(lastStudyId);

      return processShiftStudyListStudies(lastStudyId);
    } catch (error) {
      console.error(error);
    }
  };

  const performUpdatePractitioner = async (id: string, value: string, practitionerName?: string) => {
    const study = getStudy(id);
    try {
      const newStudy: Study = {
        ...study,
        practitionerAssignedId: value,
        practitionerAssignedFullName: practitionerName,
      };
      performUpdateStudy(id, newStudy);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            practitionerAssigned: value || null,
          },
        },
      });

      if (data?.updateStudy.success)
        return addToast({
          variant: ToastVariant.success,
          title: t('study.studyTable.tableActions.practitionerAssignedTitle'),
          subtitle: t('study.studyTable.tableActions.practitionerAssignedDescription', { medicName: practitionerName }),
          dismissInterval: 5000,
        } as ToastPropsV2);

      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) {
        const errorCode = data?.updateStudy.errors.system[0].code;
        if (errorCode === UpdateStudyErrorCodes.SIGNED_STUDY_EXCEPTION) {
          const studyToUpdate = {
            ...study,
            studyStatus: StudyStatus.SIGNED,
          };
          // At this point we know that this study is signed, so we update the study status to signed
          // leveraging optimistic UI.
          performUpdateStudy(id, studyToUpdate);

          return addToast({
            variant: ToastVariant.error,
            title: t('study.studyTable.tableActions.signedStudyExceptionTitle'),
            subtitle: t('study.studyTable.tableActions.signedStudyExceptionDescription'),
            dismissInterval: 5000,
          } as ToastPropsV2);
        }

        // throwing error to be catched by the catch block if the error code is not catched in front yet.
        throw new Error(data?.updateStudy.errors);
      }
    } catch (error) {
      performUpdateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performUpdatePriority = async (id: string, { value }: UpdatePriorityValue) => {
    const study = getStudy(id);
    try {
      const newStudy: Study = {
        ...study,
        urgencyLevel: value?.trim() as StudyUrgencyLevel,
      };
      updateStudy(id, newStudy);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            urgencyLevel: StudyUrgencyLevel[value.toUpperCase()],
          },
        },
      });
      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) throw new Error(data?.updateStudy.errors);
    } catch (error) {
      updateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performUpdateReviewerPractitioner = async (id: string, value: string, label?: string) => {
    const study = getStudy(id);
    try {
      const newStudy: Study = {
        ...study,
        reviewerPractitionerId: value,
        reviewerPractitionerFullName: label,
      };
      performUpdateStudy(id, newStudy);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            reviewerPractitioner: value || null,
          },
        },
      });

      if (data?.updateStudy.success)
        return addToast({
          variant: ToastVariant.success,
          title: t('study.studyTable.tableActions.practitionerAssignedTitle'),
          subtitle: t('study.studyTable.tableActions.practitionerAssignedDescription', { medicName: label }),
          dismissInterval: 5000,
        } as ToastPropsV2);

      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) throw new Error(data?.updateStudy.errors);
    } catch (error) {
      performUpdateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performReset = () => {
    resetStudies();
  };

  return {
    performUpdateReviewerPractitioner,
    performAddStudy,
    performRemoveStudy,
    performAddStudies,
    incrementWorklistTotalCount,
    decrementWorklistTotalCount,
    performShiftStudiesBackwards,
    performUpdateStudy,
    performUpdatePractitioner,
    performUpdatePriority,
    performReset,
  };
};
