import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

import { transformUnits } from '@eva-pacs/core';

import { PIXELS_NEAR_TOOL } from '~/constants';

import { InteractionTypes, sanitizeMeasuredValue } from './cornestone.helpers';
import drawDoubleLinkedTextBox from './drawDoubleLinkedTextBox';
import { getEnabledElementControlled } from './getEnabledElementControlled';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const drawLine = csTools.importInternal('drawing/drawLine');
const setShadow = csTools.importInternal('drawing/setShadow');
const draw = csTools.importInternal('drawing/draw');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const drawTextBox = csTools.importInternal('drawing/drawTextBox');
const moveNewHandle = csTools.importInternal('manipulators/moveNewHandle');
const { lengthCursor } = csTools.importInternal('tools/cursors');
const lineSegDistance = csTools.importInternal('util/lineSegDistance');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const roundToDecimal = csTools.importInternal('util/roundToDecimal');
const throttle = csTools.importInternal('util/throttle');
const triggerEvent = csTools.importInternal('util/triggerEvent');

// - SideEffect: Updates ctr 'suffix' if no pixel spacing meta data
const parseSuffix = (ctr: string, rowPixelSpacing: number, colPixelSpacing: number, suffix: string) => {
  const sanitizedMeasuredValue = sanitizeMeasuredValue(ctr);
  const measuredValue = roundToDecimal(sanitizedMeasuredValue, 2);
  const noSpacingData = !rowPixelSpacing || !colPixelSpacing;
  const realSuffix = noSpacingData ? 'px' : suffix;

  // Measured value is not defined, return empty string
  if (!sanitizedMeasuredValue) return '';

  // Set the length text suffix depending on whether or not pixelSpacing is available

  return transformUnits(`${measuredValue} ${realSuffix}`);
};

/**
 * @public
 * @class CTRTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring the Cardiothoracic Ratio between two straight lines.
 * @extends Tools.Base.BaseAnnotationTool
 * @author Ricardo Aguirre <ricardo.a.nava@gmail.com>
 * Created at 2022-02-16
 */
export default class CTRTool extends BaseAnnotationTool {
  hasIncomplete: boolean;
  throttledUpdateCachedStats: any;
  name: string;

  constructor(props = {}) {
    super(props, {
      supportedInteractionTypes: [InteractionTypes.MOUSE, InteractionTypes.TOUCH],
      svgCursor: lengthCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
    });

    this.name = 'CTR';
    this.hasIncomplete = false;
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    // Create the measurement data for this tool with the end handle activated
    this.hasIncomplete = false;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: '',
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        end2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    if (data.visible === false || this.hasIncomplete) return false;

    return (
      lineSegDistance(element, data.handles.start, data.handles.end, coords) < PIXELS_NEAR_TOOL ||
      lineSegDistance(element, data.handles.start2, data.handles.end2, coords) < PIXELS_NEAR_TOOL
    );
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
    const dx1 = (data.handles.start.x - data.handles.end.x) * (colPixelSpacing || 1);
    const dy1 = (data.handles.start.y - data.handles.end.y) * (rowPixelSpacing || 1);
    const dx2 = (data.handles.start2.x - data.handles.end2.x) * (colPixelSpacing || 1);
    const dy2 = (data.handles.start2.y - data.handles.end2.y) * (rowPixelSpacing || 1);

    // Calculate the length, and create the text variable with the millimeters or pixels suffix
    const length1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
    const length2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);

    // Calculate cardiothoracic ratio
    const ctr = (): number | undefined => {
      if (length1 < length2) {
        const result = (length1 / length2) * 100;
        return result;
      } else {
        const result = (length2 / length1) * 100;
        return result;
      }
    };

    data.ctr = roundToDecimal(ctr(), 2);
    data.length1 = length1;
    data.length2 = length2;
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving, renderDashed } = super.configuration;
    const toolData = csTools.getToolState(evt.currentTarget, this.name);
    const image = getEnabledElementControlled(eventData.element)?.image;
    if (!image) return;
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // If we have no toolData for this element, return immediately as there is nothing to do
    if (!toolData) return;

    const context = getNewContext(eventData.canvasContext.canvas);
    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    const font = csTools.textStyle.getFont();
    const textBoxAnchorPoints = (handles) => [handles.start, handles.end];
    const textBoxAnchorPoints2 = (handles) => [handles.start2, handles.end2];

    // We have tool data for this element - iterate over each one and draw it
    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) continue;

      draw(context, (context) => {
        setShadow(context, super.configuration);
        // Differentiate the color of activation tool
        const color = csTools.toolColors.getColorIfActive(data);

        const lineOptions = { color } as any;

        if (renderDashed) lineOptions.lineDash = lineDash;

        drawLine(context, eventData.element, data.handles.start, data.handles.end, lineOptions);

        if (data.complete) drawLine(context, eventData.element, data.handles.start2, data.handles.end2, lineOptions);

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (super.configuration.drawHandles) drawHandles(context, eventData, data.handles, handleOptions);

        // Draw the text
        context.fillStyle = color;

        const text = data.value;
        const validateXPosition1 = data.handles.start.x > data.handles.end.x;
        const validateXPosition2 = data.handles.start2.x > data.handles.end2.x;
        const xAnchorHandle1 = validateXPosition1 ? data.handles.start.x : data.handles.end.x;
        const yAnchorHandle1 = validateXPosition1 ? data.handles.start.y : data.handles.end.y;
        const xAnchorHandle2 = validateXPosition2 ? data.handles.start2.x : data.handles.end2.x;
        const yAnchorHandle2 = validateXPosition2 ? data.handles.start2.y : data.handles.end2.y;
        const textboxCoordsX = (xAnchorHandle1 > xAnchorHandle2 ? xAnchorHandle1 : xAnchorHandle2) + 20;
        const textboxCoordsY = (yAnchorHandle1 + yAnchorHandle2) / 2;

        if (!data.handles.textBox.hasMoved) {
          const textCoords = {
            x: textboxCoordsX,
            y: textboxCoordsY,
          };
          context.font = font;
          data.handles.textBox.x = textCoords.x;
          data.handles.textBox.y = textCoords.y;
        }

        // Convert the textbox Image coordinates into Canvas coordinates
        const canvasTextCoords1 = cornerstone.pixelToCanvas(eventData.element, {
          x: xAnchorHandle1 + 5,
          y: yAnchorHandle1 - 10,
          _pixelCoordinateBrand: '',
        });

        const canvasTextCoords2 = cornerstone.pixelToCanvas(eventData.element, {
          x: xAnchorHandle2 + 5,
          y: yAnchorHandle2 - 10,
          _pixelCoordinateBrand: '',
        });

        const options = {
          centering: {
            x: false,
            y: true,
          },
        };

        const lengthText1 = parseSuffix(data.length1, rowPixelSpacing, colPixelSpacing, 'mm');
        drawTextBox(context, lengthText1, canvasTextCoords1.x, canvasTextCoords1.y, color, options);
        const lengthText2 = parseSuffix(data.length2, rowPixelSpacing, colPixelSpacing, 'mm');
        drawTextBox(context, lengthText2, canvasTextCoords2.x, canvasTextCoords2.y, color, options);

        if (data.complete) {
          drawDoubleLinkedTextBox(
            context,
            eventData.element,
            data.handles.textBox,
            text,
            data.handles,
            textBoxAnchorPoints,
            textBoxAnchorPoints2,
            color,
            lineWidth,
            0,
            true,
          );
        }
      });
    }
  }

  getIncomplete(element) {
    const toolState = csTools.getToolState(element, this.name);

    if (toolState && Array.isArray(toolState.data)) return toolState.data.find(({ complete }) => complete === false);
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;
    let doneMovingCallback = (success) => {
      // DoneMovingCallback for first measurement.
      if (!success) {
        csTools.removeToolState(element, this.name, measurementData);
        return;
      }
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      measurementData.handles.start2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = (success) => {
        // DoneMovingCallback for second measurement
        if (!success) {
          csTools.removeToolState(element, this.name, measurementData);
          return;
        }

        const eventType = 'cornerstonetoolsmeasurementcompleted';
        const eventData = {
          toolName: this.name,
          element,
          measurementData,
        };
        triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      csTools.addToolState(element, this.name, measurementData);
      toMoveHandle = measurementData.handles.end;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    moveNewHandle(
      eventData,
      this.name,
      measurementData,
      toMoveHandle,
      super.options,
      interactionType,
      doneMovingCallback,
    );
  }

  onMeasureModified(evt) {
    const { element } = evt.detail;
    const image = getEnabledElementControlled(element)?.image;

    if (!image) return;

    if (evt.detail.toolName !== this.name) return;

    const data = evt.detail.measurementData;

    // Update textbox stats
    if (data.invalidated === true) {
      if (data.ctr) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    const { ctr } = data;

    data.value = `${ctr}%`;
  }

  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener('cornerstonetoolsmeasurementmodified', this.onMeasureModified);
  }

  passiveCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener('cornerstonetoolsmeasurementmodified', this.onMeasureModified);
  }

  enabledCallback(element) {
    element.removeEventListener('cornerstonetoolsmeasurementmodified', this.onMeasureModified);
  }

  disabledCallback(element) {
    element.removeEventListener('cornerstonetoolsmeasurementmodified', this.onMeasureModified);
  }
}
