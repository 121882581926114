import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Icon, IconCatalog, NativeSelectOption } from '@eva-pacs/ui';
import { StudyType, useUpdateStudyMutation, useUpdateSerieMutation } from '@eva-pacs/client';
import { formatStringDateTime, removeEmptyProperties } from '@eva-pacs/core';
import { StudyAndSeriesFields, StudyAndSeriesForm } from '~/components/StudyList/StudyAndSeriesForm';
import { BuildPdfReport, useReportService } from '~/hooks';

export interface StudyEditTabProps {
  /**
   * Set classes to wrapper element
   */
  className?: string;
  /**
   * Set study object data
   */
  study: StudyType;
  /**
   * Set if form is disabled
   */
  isDisabled?: boolean;
  /**
   * Current practitioners array
   */
  practitioners: NativeSelectOption[];
  /**
   * Current facilities array
   */
  facilities: NativeSelectOption[];
  /**
   * Callback to manage on close event
   */
  onCloseModal: () => void;
  /**
   * Callback to manage on default close event
   */
  onDefaultCancel: () => void;
}

/**
 * Tab to contain study form and manage data.
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-07-22
 */
export const StudyEditTab: React.FC<StudyEditTabProps> = ({
  className,
  study,
  practitioners,
  facilities,
  isDisabled = false,
  onCloseModal,
  onDefaultCancel,
}) => {
  const studyTabClasses = cn(className, 'px-2');
  const { t } = useTranslation();
  const [lastSerieId, setLastSerieId] = useState<string>();
  const [, setPractitionerId] = useState<string>();

  const { rebuildActiveReportPDF } = useReportService();

  const [updateStudyMutation, { data: updateStudyData }] = useUpdateStudyMutation({
    onCompleted: ({ updateStudy: { study } }) => {
      const reports = study?.reports as Array<BuildPdfReport>;
      rebuildActiveReportPDF(reports);
    },
  });
  const [updateSerieMutation, { data: updateSerieData }] = useUpdateSerieMutation();
  useEffect(() => {
    const isStudyNoDicomUpdated = updateStudyData?.updateStudy.success;
    const isStudyDicomUpdated =
      updateStudyData?.updateStudy.success &&
      updateSerieData?.updateSeries.success &&
      lastSerieId === updateSerieData?.updateSeries.series?.id;
    const isOnlySeriesUpdated = updateSerieData?.updateSeries.success;

    if (isStudyNoDicomUpdated || isStudyDicomUpdated || isOnlySeriesUpdated) onCloseModal();
    // eslint-disable-next-line
  }, [updateStudyData, updateSerieData]);

  const handleOnSubmit = (data: StudyAndSeriesFields) => {
    const updateStudyInputData = {
      ...data,
      practitionerAssigned: data.practitionerAssigned ? data.practitionerAssigned : undefined,
      series: undefined,
    };
    const validatedPayload = removeEmptyProperties(updateStudyInputData);
    const isEmptyPayload = Object.keys(validatedPayload).length === 0;

    // If there is no input data related to the study, skip mutation execution.
    if (!isEmptyPayload) {
      updateStudyMutation({
        variables: {
          id: study.id,
          input: {
            ...updateStudyInputData,
          },
        },
      });
    }

    if (!data.series?.length) return;
    setLastSerieId(data.series[data.series.length - 1].id);
    data.series.forEach((serie) => {
      const serieToUpdate = {
        ...serie,
        bodyPart: serie.bodyPart,
        dicomPerformingPhysicianInstitution: serie.dicomPerformingPhysicianInstitution,
      };

      updateSerieMutation({
        variables: {
          id: serie.id,
          input: {
            bodyPartEditable: serieToUpdate.bodyPart,
            dicomDescription: serieToUpdate.dicomDescription,
            dicomOperatorsName: serieToUpdate.dicomOperatorsName,
            dicomPerformingPhysicianInstitution: serieToUpdate.dicomPerformingPhysicianInstitution,
          },
        },
      });
    });
  };

  const onPractitionerChange = (id) => {
    setPractitionerId(id);
  };
  return (
    <div className={studyTabClasses}>
      <div className="flex items-center mb-10">
        <p className="text-left text-light-gray-600 text-paragraph font-semi-bold">
          {`${t('study.studyDateTime')}: `}
          <span className="text-basic-white">{formatStringDateTime(study.dicomDateTime)}</span>
        </p>
        <a
          className="flex items-center ml-auto text-primary-300 underline font-semi-bold"
          href={`/viewer/${study.id}`}
          target="_blank"
          rel="noopener noreferrer">
          {t('study.openStudy')}
          <Icon icon={IconCatalog.arrowExternal} />
        </a>
      </div>
      <StudyAndSeriesForm
        practitionerList={practitioners}
        facilityList={facilities}
        study={study}
        isDisabled={isDisabled}
        onPractitionerChange={onPractitionerChange}
        onSubmitEmit={handleOnSubmit}
        onCancel={onDefaultCancel}
      />
    </div>
  );
};
