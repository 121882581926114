var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cn from 'classnames';
/**
 * Use Radio to select a single option from a list. Radio should be used instead of checkboxes if only one item can be selected from a list.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-04-28
 */
export var Radio = function (_a) {
    var name = _a.name, value = _a.value, className = _a.className, _b = _a.checked, checked = _b === void 0 ? false : _b, onChange = _a.onChange, restOfProps = __rest(_a, ["name", "value", "className", "checked", "onChange"]);
    var radioClass = cn(className, 'checked:border-primary-300 checked:border-4 rounded-full min-w-4 h-4 checked:bg-basic-white border-2 border-light-gray-600 focus:outline-none appearance-none');
    var handleChange = function (event) {
        if (onChange)
            onChange(event);
    };
    return (React.createElement("input", __assign({ type: "radio", name: name, className: radioClass, checked: checked, value: value, onChange: handleChange }, restOfProps)));
};
