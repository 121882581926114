import cornerstone from 'cornerstone-core';

export const getStringMetadataTag = (imageId: string, tag: string) => {
  const viewportImage = cornerstone.imageCache.imageCache[imageId];
  if (!viewportImage) return;
  return viewportImage.image?.data?.string(tag) as string | undefined;
};

export const getSequenceStringMetadataTag = (imageId: string, tag: string) => {
  const viewportImage = cornerstone.imageCache?.imageCache[imageId];
  if (!viewportImage) return [];
  const sequenceItems = viewportImage.image?.data?.elements[tag]?.items;
  if (!sequenceItems) return [];
  return sequenceItems.map((item) =>
    Object.keys(item.dataSet?.elements || {})
      .map((key) => item?.dataSet?.string(key))
      .filter((item) => item),
  );
};
