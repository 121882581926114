import cornerstone from 'cornerstone-core';
import shallow from 'zustand/shallow';

import { EDEN_REPORT_ID, EDEN_SIDEBAR_ID, EDEN_TOOLBAR_SUBMENU_ID } from '~/constants';
import { getDeepCopy } from '~/utils/tsUtils';

import { usePanelStore, useViewportStore } from '../store';
import { useEffect } from 'react';

type AreaDataType = {
  scaleDiferencePercentage: number;
  translationValues: { x: number; y: number };
};

const PREVIEW_ATTRIBUTE_NAME = 'PREVIEW';

export const useTogglePreview = () => {
  const [sequencePanelIsOpen, reportPanelIsOpen] = usePanelStore(
    (state) => [state.sequencePanelIsOpen, state.reportPanelIsOpen],
    shallow,
  );
  const [viewportPreviewed, setViewportPreviewed, viewportData] = useViewportStore(
    (state) => [
      state.viewportPreviewed,
      state.setViewportPreviewed,
      state.viewportData,
      state.setViewportCurrentWindow,
      state.setActiveIndex,
    ],
    shallow,
  );

  const toggleView = (view: HTMLDivElement) =>
    new Promise((resolve) =>
      getViewportAreaInfo(view).then((areaData) => {
        if (viewportPreviewed !== null) {
          resetView(view);
          destroyPreviewedData(view);
        } else {
          amplifyView(view);
          setViewerPreviewedData(view);
        }

        adaptViewportArea(view, areaData);
        resolve(true);
      }),
    );

  const setViewerPreviewedData = (view?: HTMLDivElement) => {
    const currentViewIndex = getCurrentIndexByView(view);

    view?.setAttribute(PREVIEW_ATTRIBUTE_NAME, currentViewIndex.toString());
    setViewportPreviewed(currentViewIndex);
  };

  const destroyPreviewedData = (view?: HTMLDivElement) => {
    view?.removeAttribute(PREVIEW_ATTRIBUTE_NAME);
    return setViewportPreviewed(null);
  };

  const getCurrentIndexByView = (view?: HTMLDivElement) => {
    const currentViewports = Object.values(viewportData).map((viewport) => viewport?.dom);
    const currentViewIndex = currentViewports.findIndex((savedViews) => savedViews === view);
    return currentViewIndex;
  };

  const removeCurrentPreviewedViewport = async () => {
    const currentPreviewedViewport = getCurrentPreviewedViewport();
    if (!currentPreviewedViewport) return;

    const areaData = await getViewportAreaInfo(currentPreviewedViewport);

    resetView(currentPreviewedViewport);
    adaptViewportArea(currentPreviewedViewport, areaData);
    destroyPreviewedData(currentPreviewedViewport);
  };

  useEffect(() => {
    if (viewportPreviewed === null) return;

    const view = getCurrentPreviewedViewport();
    if (!view) return;

    amplifyView(view);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequencePanelIsOpen, reportPanelIsOpen]);

  return { toggleView, removeCurrentPreviewedViewport };
};

const resetView = (viewDiv: HTMLDivElement) => {
  const view = viewDiv as any;
  view.style.position = 'inherit';
  view.style.height = 'inherit';
  view.style.width = 'inherit';
  view.style.inset = '0%';
  view.style.zIndex = '0';
};

const amplifyView = (view: HTMLDivElement) => {
  const toolbarHeight = document.querySelector(`[data-testid=${EDEN_TOOLBAR_SUBMENU_ID}]`)?.clientHeight || 0;
  const sidebarWidth = document.querySelector(`[data-testid=${EDEN_SIDEBAR_ID}]`)?.clientWidth || 0;
  const reportWidth = document.querySelector(`[data-testid=${EDEN_REPORT_ID}]`)?.clientWidth || 0;
  const safeReportWidth = reportWidth === 0 ? 0 : reportWidth - 2;

  view.style.position = 'fixed';
  view.style.top = `${toolbarHeight}px`;
  view.style.left = `${sidebarWidth}px`;
  view.style.height = `calc(100% - ${toolbarHeight}px)`;
  view.style.width = `calc(100% - ${safeReportWidth + sidebarWidth}px)`;
  view.style.right = `${safeReportWidth}px`;
  view.style.bottom = `0%`;
  view.style.zIndex = '10';
};

const getCurrentPreviewedViewport = (): HTMLDivElement | null => document.querySelector(`[${PREVIEW_ATTRIBUTE_NAME}]`);

const adaptViewportArea = (view: HTMLDivElement, areaData?: AreaDataType) => {
  cornerstone.resize(view, true);

  const currentViewport = cornerstone.getViewport(view);

  if (currentViewport && areaData) {
    cornerstone.resize(view, true);
    const currentScale = currentViewport.scale;
    currentViewport.scale = currentScale * areaData.scaleDiferencePercentage;
    currentViewport.translation = areaData.translationValues;
    cornerstone.setViewport(view, currentViewport);
  }
};

const getViewportAreaInfo = (view: HTMLDivElement): Promise<AreaDataType | undefined> =>
  new Promise((resolve) => {
    const currentViewport = getDeepCopy(cornerstone.getViewport(view));
    if (!currentViewport) return;

    const previousScale = currentViewport.scale;
    const previousTranslation = currentViewport.translation;
    cornerstone.resize(view, true);

    const afterViewport = getDeepCopy(cornerstone.getViewport(view));
    if (!afterViewport) return;

    const afterScale = afterViewport.scale;
    const scaleDiferencePercentage = porcentualChange(afterScale, previousScale);
    const translationValues = { x: previousTranslation.x, y: previousTranslation.y };

    return resolve({ scaleDiferencePercentage, translationValues });
  });

const porcentualChange = (alpha: number, beta: number) => {
  const delta = (beta - alpha) / alpha;
  const result = 1 + delta;
  return result;
};
