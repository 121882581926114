import cn from 'classnames';
import { MenuPlacement } from '.';
/* Class assignment */
export var menuClass = function (className, placement) {
    return cn(className, 
    /* Item (base) */
    'rounded shadow-lg', 'flex-col', 'py-1 px-0', 'absolute', 
    /* DropdownMenu--isDisabled */
    placement === MenuPlacement.bottomLeft && 'mt-3 top-full right-0', placement === MenuPlacement.bottomRight && 'mt-3 top-full left-0', placement === MenuPlacement.topLeft && 'mb-3 bottom-full right-0', placement === MenuPlacement.topRight && 'mb-3 bottom-full left-0');
};
