import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

import { formatConerstoneArea, getCornerstoneUnit, getFormatedFinalArea, numbersWithCommas } from '@eva-pacs/core';

import { PIXELS_NEAR_TOOL, PIXELS_NEAR_TOOL_TOUCH } from '~/constants';

import { pointInEllipse } from './cornestone.helpers';

const getHandleNearImagePoint = csTools.importInternal('manipulators/getHandleNearImagePoint');
const getROITextBoxCoords = csTools.importInternal('util/getROITextBoxCoords');
const drawLinkedTextBox = csTools.importInternal('drawing/drawLinkedTextBox');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawEllipse = csTools.importInternal('drawing/drawEllipse');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const setShadow = csTools.importInternal('drawing/setShadow');
const draw = csTools.importInternal('drawing/draw');

/**
 * @public
 * @class EllipticalRoi
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing elliptical regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Annotation.EllipticalRoiTool
 * @author Ricardo Aguirre <ricardo.a.nava@gmail.com>
 * Created at 2022-03-16
 */
export default class CustomEllipticalRoi extends csTools.EllipticalRoiTool {
  name;
  constructor(props) {
    super(props);
    super.configuration = { ...super.configuration, drawHandles: true };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles = data && data.handles && data.handles.start && data.handles.end;

    const validParameters = hasStartAndEndHandles;

    if (!validParameters) csTools.logger.warn(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);
    if (!validParameters || data.visible === false) return false;

    const handleNearImagePoint = getHandleNearImagePoint(element, data.handles, coords, 6);

    if (handleNearImagePoint) return true;

    const distance = interactionType === 'mouse' ? PIXELS_NEAR_TOOL : PIXELS_NEAR_TOOL_TOUCH;
    const startCanvas = cornerstone.pixelToCanvas(element, data.handles.start);
    const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

    const minorEllipse = {
      left: Math.min(startCanvas.x, endCanvas.x) + distance / 2,
      top: Math.min(startCanvas.y, endCanvas.y) + distance / 2,
      width: Math.abs(startCanvas.x - endCanvas.x) - distance,
      height: Math.abs(startCanvas.y - endCanvas.y) - distance,
    };

    const majorEllipse = {
      left: Math.min(startCanvas.x, endCanvas.x) - distance / 2,
      top: Math.min(startCanvas.y, endCanvas.y) - distance / 2,
      width: Math.abs(startCanvas.x - endCanvas.x) + distance,
      height: Math.abs(startCanvas.y - endCanvas.y) + distance,
    };

    const pointInMinorEllipse = pointInEllipse(minorEllipse, coords);
    const pointInMajorEllipse = pointInEllipse(majorEllipse, coords);

    if (pointInMajorEllipse && !pointInMinorEllipse) return true;

    return false;
  }

  renderToolData(evt) {
    const toolData = csTools.getToolState(evt.currentTarget, this.name);

    if (!toolData) return;

    const eventData = evt.detail;
    const { image, element } = eventData;
    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    // @ts-ignore
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving, renderDashed } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    const seriesModule = cornerstone.metaData.get('generalSeriesModule', image.imageId) || {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

    draw(context, (context) => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) continue;

        // Configure
        const color = csTools.toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        // @ts-ignore
        setShadow(context, this.configuration);

        const ellipseOptions = { color, lineDash: undefined };

        if (renderDashed) {
          ellipseOptions.lineDash = lineDash;
        }

        // Draw
        drawEllipse(
          context,
          element,
          data.handles.start,
          data.handles.end,
          ellipseOptions,
          'pixel',
          data.handles.initialRotation,
        );
        drawHandles(context, eventData, data.handles, handleOptions);

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            // @ts-ignore
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            // @ts-ignore
            this.updateCachedStats(image, element, data);
          }
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = getROITextBoxCoords(eventData.viewport, data.handles);

          Object.assign(data.handles.textBox, defaultCoords);
        }

        const textBoxAnchorPoints = (handles) => _findTextBoxAnchorPoints(handles.start, handles.end);
        const textBoxContent = _createTextBoxContent(
          context,
          image.color,
          data.cachedStats,
          modality,
          hasPixelSpacing,
          // @ts-ignore
          this.configuration,
        );

        // @ts-ignore
        data.unit = getCornerstoneUnit(modality, this.configuration.showHounsfieldUnits);
        textBoxContent[0] = getFormatedFinalArea(textBoxContent[0]);

        drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          textBoxContent,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          10,
          true,
        );
      }
    });
  }
}

const _findTextBoxAnchorPoints = (startHandle, endHandle) => {
  const { left, top, width, height } = _getEllipseImageCoordinates(startHandle, endHandle);

  return [
    {
      // Top middle point of ellipse
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of ellipse
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of ellipse
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of ellipse
      x: left + width,
      y: top + height / 2,
    },
  ];
};

const _getEllipseImageCoordinates = (startHandle, endHandle) => {
  return {
    left: Math.round(Math.min(startHandle.x, endHandle.x)),
    top: Math.round(Math.min(startHandle.y, endHandle.y)),
    width: Math.round(Math.abs(startHandle.x - endHandle.x)),
    height: Math.round(Math.abs(startHandle.y - endHandle.y)),
  };
};

const _createTextBoxContent = (
  context,
  isColorImage,
  { area, mean, stdDev, min, max, meanStdDevSUV } = {
    area: 0,
    mean: 0,
    stdDev: 0,
    min: 0,
    max: 0,
    meanStdDevSUV: {
      mean: 0,
      stdDev: 0,
    },
  },
  modality,
  hasPixelSpacing,
  options = { showMinMax: undefined, showHounsfieldUnits: undefined },
) => {
  const showMinMax = options.showMinMax || false;
  const textLines: any = [];

  // Don't display mean/standardDev for color images
  const otherLines: any = [];

  if (!isColorImage) {
    const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0;
    const unit = getCornerstoneUnit(modality, options.showHounsfieldUnits);

    let meanString = `Mean: ${numbersWithCommas(mean.toFixed(2))} ${unit}`;
    const stdDevString = `Std Dev: ${numbersWithCommas(stdDev.toFixed(2))} ${unit}`;

    // If this image has SUV values to display, concatenate them to the text line
    if (hasStandardUptakeValues) {
      const SUVtext = ' SUV: ';

      const meanSuvString = `${SUVtext}${numbersWithCommas(meanStdDevSUV.mean.toFixed(2))}`;
      const stdDevSuvString = `${SUVtext}${numbersWithCommas(meanStdDevSUV.stdDev.toFixed(2))}`;

      const targetStringLength = Math.floor(context.measureText(`${stdDevString}     `).width);

      while (context.measureText(meanString).width < targetStringLength) {
        meanString += ' ';
      }

      otherLines.push(`${meanString}${meanSuvString}`);
      otherLines.push(`${stdDevString}     ${stdDevSuvString}`);
    } else {
      otherLines.push(`${meanString}`);
      otherLines.push(`${stdDevString}`);
    }

    if (showMinMax) {
      let minString = `Min: ${min} ${unit}`;
      const maxString = `Max: ${max} ${unit}`;
      const targetStringLength = hasStandardUptakeValues
        ? Math.floor(context.measureText(`${stdDevString}     `).width)
        : Math.floor(context.measureText(`${meanString}     `).width);

      while (context.measureText(minString).width < targetStringLength) {
        minString += ' ';
      }

      otherLines.push(`${minString}${maxString}`);
    }
  }

  textLines.push(formatConerstoneArea(area, hasPixelSpacing));
  otherLines.forEach((x) => textLines.push(x));

  return textLines;
};
