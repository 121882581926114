var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Breakpoint, FileExtension } from '../constants';
import cn from 'classnames';
export var doUndo = function () {
    document.execCommand('undo', false);
};
export var doRedo = function () {
    document.execCommand('redo', false);
};
/**
 * hasProp
 * @description - validate if an object has the prop passed arg
 * @function
 * @param {any} obj - Object to validate
 * @param {string} prop - prop's key to check if it belongs to the obj
 * @return {boolean} The obj does has the prop.
 */
export var hasProp = function (obj, prop) {
    if (obj === void 0) { obj = {}; }
    if (obj === null || typeof obj !== 'object')
        return false;
    return prop in obj;
};
/**
 * isArray
 * @description - validate if an element is an Array
 * @function
 * @param {any} element - Element to validate
 * @return {boolean} Element is an Array or not.
 */
export var isArray = function (element) { return Array.isArray(element); };
/**
 * isEmpty
 * @description - validate if an variable (string, string, array) is Empty
 * @function
 * @param {Object} value - Value to validate
 * @return {boolean} Value is empty or not.
 */
export var isEmpty = function (value) { return Object.keys(value).length === 0; };
/**
 * isNullOrUndefined
 * @description - validate if an element is null or undefined
 * @function
 * @param {any} value - Element to validate
 * @return {boolean} Element is null or undefined.
 */
export var isNullOrUndefined = function (value) { return value === undefined || value === null; };
/**
 * stringToArray
 * @description - parse a String into an Array based on a separator
 * @function
 * @param {string} elements - string to parse
 * @param {string} separator - string to use as a separator
 * @return {Array<string> | string} parsed string
 * (e.g. input -> 'Button, Button Group', output -> ['Button', 'Button Group']).
 */
export var stringToArray = function (elements, separator) {
    if (separator === void 0) { separator = ', '; }
    if (elements.includes(separator))
        return elements.split(separator) || [];
    return elements;
};
/**
 * formatBytes
 * @description - parse bytes to KB, MB, GB, etc
 * @function
 * @param {number} bytes - bytes to parse
 * @param {number} decimals - decimals (0) or binary (2) form
 * @return {string} Returns the bytes parsed.
 */
export var formatBytes = function (bytes, decimals) {
    if (bytes === void 0) { bytes = 0; }
    if (decimals === void 0) { decimals = 0; }
    if (bytes === 0)
        return '0 Bytes';
    var kb = 1024;
    var parsedDecimal = decimals <= 0 ? 0 : decimals || 2;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var index = Math.floor(Math.log(bytes) / Math.log(kb));
    return parseFloat((bytes / Math.pow(kb, index)).toFixed(parsedDecimal)) + ' ' + sizes[index];
};
/**
 * toMb
 * @description - parse a number into the megabyte format
 * @function
 * @param {number} bytes - bytes to parse
 * @return {number} Returns the megabyte representation.
 */
export var toMb = function (bytes) {
    return bytes / 1024 / 1024;
};
/**
 * sortAscElementsByKey
 * @description - sort an objects in array by specific key in asc order
 * @function
 * @param {Array<any>} elementList - listo to order
 * @param {string} key - key by order elements
 * @return {Array<any>} Returns the ordered list.
 */
export var sortAscElementsByKey = function (elementList, key) {
    return elementList.slice().sort(function (elementA, elementB) {
        var elementValueA = "".concat(elementA[key]).toLocaleLowerCase();
        var elementValueB = "".concat(elementB[key]).toLocaleLowerCase();
        if (elementValueA < elementValueB)
            return -1;
        if (elementValueA > elementValueB)
            return 1;
        return 0;
    });
};
/**
 * Validate if a url image is a standar image
 * @param url
 * @returns {boolean} if url match with image format list
 */
export var isAConventionalImage = function (url) {
    var regexpNoDicomImages = new RegExp("^.*\\.(".concat(FileExtension.JPG, "|").concat(FileExtension.JPEG, "|").concat(FileExtension.PNG, ")$"));
    return regexpNoDicomImages.test(url.toUpperCase());
};
/**
 * Handles the url when it's localhost or API
 * @param url
 * @param file
 * @param isDicom
 * @returns {string} url of the dcm or png file
 */
export var getMediaUrl = function (url, file, isDicom) {
    if (isDicom === void 0) { isDicom = false; }
    if (!url && !file)
        return '';
    if (isDicom && isAConventionalImage(url))
        return url;
    var REACT_APP_API = process.env.REACT_APP_API;
    if (!url.includes('http'))
        return isDicom
            ? "".concat(REACT_APP_API === null || REACT_APP_API === void 0 ? void 0 : REACT_APP_API.replace(/^(https?|http):\/\//, 'dicomweb://'), "/media/").concat(file)
            : "".concat(REACT_APP_API, "/media/").concat(file);
    return isDicom ? url === null || url === void 0 ? void 0 : url.replace(/^(https?|http):\/\//, 'dicomweb://') : url;
};
/**
 * exportFile
 * @description - Create a temporal anchor to download the file on the browser
 * @function
 * @param {string} content - content to convert (JSON, SVG, image, etc)
 * @param {string} fileName - file name
 * @param {string} contentType - content file type
 * @return {void}
 */
export var exportFile = function (content, fileName, contentType) {
    if (contentType === void 0) { contentType = 'application/json'; }
    /* Temp anchor to download file */
    var a = document.createElement('a');
    var tempBlob = content instanceof Blob ? new Blob([content], { type: contentType }) : content;
    a.href = URL.createObjectURL(tempBlob);
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
};
/**
 * This function is a wrapper to promisify the native callback on the Read Entries Request
 * @param {FileSystemDirectoryReader} reader
 * @returns File
 */
var readEntriesAsync = function (reader) {
    return new Promise(function (resolve, reject) {
        reader.readEntries(function (entries) {
            resolve(entries);
        }, function (err) {
            reject(err);
        });
    });
};
/**
 * This function is a wrapper to promisify the native callback on the GET File Request
 * @param {FileSystemFileEntry} item
 * @returns File
 */
var getFileAsync = function (item) {
    return new Promise(function (resolve, reject) {
        item.file(function (file) {
            resolve(file);
        }, function (err) {
            reject(err);
        });
    });
};
/**
 * recursiveTreeFollow
 * @description Recursive Function
 * @param {FileSystemEntry} item Directory or file
 * @param {string} path parent path
 */
var recursiveTreeFollow = function (item, path) {
    if (path === void 0) { path = ''; }
    return __awaiter(void 0, void 0, void 0, function () {
        var file, files, dirReader, entries, i, recursiveFiles;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!item.isFile) return [3 /*break*/, 2];
                    return [4 /*yield*/, getFileAsync(item)];
                case 1:
                    file = _a.sent();
                    return [2 /*return*/, [file]];
                case 2:
                    if (!item.isDirectory) return [3 /*break*/, 11];
                    files = [];
                    dirReader = item.createReader();
                    return [4 /*yield*/, readEntriesAsync(dirReader)];
                case 3:
                    entries = _a.sent();
                    _a.label = 4;
                case 4:
                    if (!(entries.length !== 0)) return [3 /*break*/, 10];
                    i = 0;
                    _a.label = 5;
                case 5:
                    if (!(i < entries.length)) return [3 /*break*/, 8];
                    return [4 /*yield*/, recursiveTreeFollow(entries[i], path + item.name + '/')];
                case 6:
                    recursiveFiles = _a.sent();
                    files.push.apply(files, recursiveFiles);
                    _a.label = 7;
                case 7:
                    i++;
                    return [3 /*break*/, 5];
                case 8: return [4 /*yield*/, readEntriesAsync(dirReader)];
                case 9:
                    entries = _a.sent();
                    return [3 /*break*/, 4];
                case 10: return [2 /*return*/, files];
                case 11: return [2 /*return*/, []];
            }
        });
    });
};
/**
 * dropFiles
 * @description handle the files drag and drop functionality
 * @param {React.DragEvent<HTMLDivElement>} event drag event
 * @param {string} path parent path
 * @return {Promise<Array<File>>} a file list
 */
export var dropFiles = function (event) { return __awaiter(void 0, void 0, void 0, function () {
    var items, files, i, item, recursiveFiles;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                items = event.dataTransfer.items;
                event.preventDefault();
                files = [];
                i = 0;
                _a.label = 1;
            case 1:
                if (!(i < items.length)) return [3 /*break*/, 4];
                item = items[i].webkitGetAsEntry();
                if (!(item && recursiveTreeFollow)) return [3 /*break*/, 3];
                return [4 /*yield*/, recursiveTreeFollow(item)];
            case 2:
                recursiveFiles = _a.sent();
                files.push.apply(files, recursiveFiles);
                _a.label = 3;
            case 3:
                i++;
                return [3 /*break*/, 1];
            case 4: return [2 /*return*/, files];
        }
    });
}); };
/**
 * convertCentimetersToMilimeters
 * @description - Convert a quantity in the unit from centimeters to millimeters
 * @function
 * @param {number} cms - Centimeters value
 * @return {number} Conversion to millimeters.
 */
export var convertCentimetersToMilimeters = function (cms) {
    if (!cms)
        return NaN;
    return cms * 10;
};
/**
 * svgToImage
 * @description - Simple function that converts a plain SVG string or SVG DOM Node into an image with custom dimensions.
 * @param {Object} settings The configuration object to override the default settings.
 * @see https://ourcodeworld.com/articles/read/1456/how-to-convert-a-plain-svg-string-or-svg-node-to-an-image-png-or-jpeg-in-the-browser-with-javascript
 * @returns {Promise}
 */
export var svgToImage = function (settings) {
    var _settings = {
        svg: '',
        // Usually all SVG have transparency, so PNG is the way to go by default
        mimetype: 'image/png',
        quality: 0.92,
        width: 'auto',
        height: 'auto',
        outputFormat: 'base64',
    };
    // Override default settings
    for (var key in settings) {
        _settings[key] = settings[key];
    }
    return new Promise(function (resolve) {
        var svgNode;
        // Create SVG Node if a plain string has been provided
        if (typeof _settings.svg === 'string') {
            // Create a non-visible node to render the SVG string
            var SVGContainer = document.createElement('div');
            SVGContainer.style.display = 'none';
            SVGContainer.innerHTML = _settings.svg;
            svgNode = SVGContainer.firstElementChild;
        }
        else {
            svgNode = _settings.svg;
        }
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var svgXml = new XMLSerializer().serializeToString(svgNode);
        var svgBase64 = 'data:image/svg+xml;base64,' + btoa(svgXml);
        var image = new Image();
        image.onload = function () {
            var self = this;
            var finalWidth, finalHeight;
            // Calculate width if set to auto and the height is specified (to preserve aspect ratio)
            if (_settings.width === 'auto' && _settings.height !== 'auto') {
                finalWidth = (self.width / self.height) * parseInt(_settings.height);
                // Use image original width
            }
            else if (_settings.width === 'auto') {
                finalWidth = self.naturalWidth;
                // Use custom width
            }
            else {
                finalWidth = _settings.width;
            }
            // Calculate height if set to auto and the width is specified (to preserve aspect ratio)
            if (_settings.height === 'auto' && _settings.width !== 'auto') {
                finalHeight = (self.height / self.width) * parseInt(_settings.width);
                // Use image original height
            }
            else if (_settings.height === 'auto') {
                finalHeight = self.naturalHeight;
                // Use custom height
            }
            else {
                finalHeight = _settings.height;
            }
            // Define the canvas intrinsic size
            canvas.width = finalWidth;
            canvas.height = finalHeight;
            // Render image in the canvas
            context === null || context === void 0 ? void 0 : context.drawImage(self, 0, 0, finalWidth, finalHeight);
            if (_settings.outputFormat == 'blob') {
                // Fullfil and Return the Blob image
                canvas.toBlob(function (blob) {
                    resolve(blob);
                }, _settings.mimetype, _settings.quality);
            }
            else {
                // Fullfil and Return the Base64 image
                resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
            }
        };
        // Load the SVG in Base64 to the image
        image.src = svgBase64;
    });
};
/**
 * getExtensionFile
 * @description - Get the file's extension
 * @param {string} fileName the file name
 * @returns {string} the extension
 */
export var getExtensionFile = function (fileName) {
    if (fileName === void 0) { fileName = ''; }
    return fileName.split('.').pop();
};
/**
 * getDeviceSize
 * @description - Check the current device size
 * @param {number} width Current device width
 * @returns {Breakpoint}
 */
export var getDeviceSize = function (width) {
    if (width < 640)
        return Breakpoint.xs;
    if (width >= 640 && width < 768)
        return Breakpoint.sm;
    if (width >= 768 && width < 1024)
        return Breakpoint.md;
    if (width >= 1024 && width < 1280)
        return Breakpoint.lg;
    if (width >= 1280 && width < 1536)
        return Breakpoint.xl;
    return Breakpoint['2xl'];
};
/**
 * isSmallWidth
 * @description - Check if the current device has small width
 * @returns {boolean}
 */
export var isSmallWidth = function () {
    var currentSize = getDeviceSize(window.innerWidth);
    return [Breakpoint.xs, Breakpoint.sm].includes(currentSize);
};
export var setMaxHeightByOptions = function (options) {
    var listLength = options.listLength, maxOptions = options.maxOptions, maxHeight = options.maxHeight;
    var calcHeight = listLength <= maxOptions ? 'auto' : maxHeight;
    return { height: calcHeight };
};
/**
 * getLastItemPath
 * @description - Get the last item from a URL or path
 * @returns {string}
 */
export var getLastItemPath = function (url) { return url.substring(url.lastIndexOf('/') + 1); };
/**
 * @description Clip text with ellipsis suffix
 * @param text Text to clip.
 * @param maxLength Max character length.
 * @return Clipped text with ellipsis.
 */
export var clipWithEllipsis = function (text, maxLength) {
    var ellipsis = '...';
    if (text.length < maxLength)
        return text;
    return "".concat(text.substring(0, maxLength - ellipsis.length)).concat(ellipsis);
};
/**
 * @description Validate if object has at least one defined property
 * @param object Object to validate
 * @return Boolean of validation
 */
export var hasDefinedValue = function (object) {
    return Object.values(object).some(function (element) { return element !== undefined; });
};
/**
 * @description Validate if device is mobile
 * @return Boolean of validation
 */
export var isMobileDevice = function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
/**
 * @description Validate if device is tablet
 * @return Boolean of validation
 */
export var isTabletDevice = function () { return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent); };
export var getSideBarClasses = function (hiddenSidebar) {
    if (hiddenSidebar === void 0) { hiddenSidebar = false; }
    return cn(
    /* General styles */
    'transition-all duration-300 ease-in', 
    /* Mobile styles */
    'absolute bottom-28 left-0 right-0 mr-0 overflow-x-auto z-4 pr-4', 
    /* Desktop styles */
    'md:w-52 md:h-full md:overflow-y-scroll hide-scrollbar md:overflow-x-hidden md:static md:p-4 md:pb-5 md:flex-col md:flex-shrink-0', {
        flex: hiddenSidebar,
        hidden: !hiddenSidebar,
    });
};
export var getViewerClasses = function (showPrintView) {
    if (showPrintView === void 0) { showPrintView = false; }
    return cn('relative flex-no-wrap flex flex-1 flex-row w-full', {
        'overflow-hidden': !showPrintView,
    });
};
/**
 * @description Given a url returns a usable blob in a callback
 * @param {string} url Network url to convert
 * @param {(blob: Blob) => void} convertBlob Callback to execute when the blob is loaded
 * @param {XMLHttpRequestResponseType} responseType Request response type
 * TODO [https://eva-pacs.atlassian.net/browse/EVA-2949] Researh if we get the same result with axios
 */
export var getFileBlobUsingURL = function (_a) {
    var url = _a.url, convertBlob = _a.convertBlob, responseType = _a.responseType;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = responseType;
    xhr.addEventListener('load', function () {
        convertBlob(xhr.response);
    });
    xhr.send();
};
export var conrnerstoneUnits;
(function (conrnerstoneUnits) {
    conrnerstoneUnits["MM"] = "mm";
    conrnerstoneUnits["CM"] = "cm";
    conrnerstoneUnits["MM2"] = "mm\u00B2";
    conrnerstoneUnits["CM2"] = "cm\u00B2";
    conrnerstoneUnits["pixelsComplete"] = "pixels";
    conrnerstoneUnits["pixels"] = "px";
    conrnerstoneUnits["percentage"] = "%";
    conrnerstoneUnits["dB"] = "dB";
    conrnerstoneUnits["SEC"] = "sec";
    conrnerstoneUnits["HERTZ"] = "hertz";
    conrnerstoneUnits["dB_SEC"] = "dB/seconds";
    conrnerstoneUnits["CM_SEC"] = "cm/sec";
    conrnerstoneUnits["CM2_SEC"] = "cm\u00B2/s";
    conrnerstoneUnits["DEG"] = "deg";
})(conrnerstoneUnits || (conrnerstoneUnits = {}));
export var transformUnits = function (line) {
    if (!(line === null || line === void 0 ? void 0 : line.length))
        return '';
    var valueLimit = 100.0;
    var _a = line.split(' '), value = _a[0], unit = _a[1];
    var floatValue = parseFloat(value);
    if (unit === conrnerstoneUnits.MM && floatValue > valueLimit)
        return "".concat((floatValue / 10).toFixed(2), " ").concat(conrnerstoneUnits.CM);
    if (unit === conrnerstoneUnits.MM2 && floatValue > valueLimit)
        return "".concat((floatValue / 100).toFixed(2), " ").concat(conrnerstoneUnits.CM2);
    return line;
};
export var numbersWithCommas = function (x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};
export var getCornerstoneUnit = function (modality, showHounsfieldUnits) {
    return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : '';
};
export var formatConerstoneArea = function (area, hasPixelSpacing) {
    // This uses Char code 178 for a superscript 2
    var suffix = hasPixelSpacing ? " mm".concat(String.fromCharCode(178)) : " px".concat(String.fromCharCode(178));
    return "Area: ".concat(numbersWithCommas(area.toFixed(2))).concat(suffix);
};
export var getFormatedFinalArea = function (value) {
    if (value === void 0) { value = ''; }
    var _a = value.split(' '), label = _a[0], area = _a[1], unit = _a[2];
    var areaFormatted = parseFloat(area.replace(/,/g, ''));
    var transformed = transformUnits("".concat(areaFormatted, " ").concat(unit));
    return "".concat(label, " ").concat(transformed);
};
