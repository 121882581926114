import { useEffect } from 'react';
import { useGetSpellCheckLanguagesLazyQuery } from '@eva-pacs/client';

import { useSessionStore } from '../store';
import { initializeWebspellchecker } from '../utils/initializeWebspellchecker';

/**
 * Use `useWebspellchecker` hook to get access to the current language or update it and initialize webspellchecker
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-08-08
 */
export const useWebspellchecker = () => {
  const token = useSessionStore((state) => state.token);
  const [fetchSpellcheckerDictionary] = useGetSpellCheckLanguagesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const language = data.spellCheckLanguages?.results?.[0]?.spellerLanguage;
      const dictionaryIds = data.spellCheckLanguages?.results?.flatMap((lang) => lang?.dictionaryIds);
      initializeWebspellchecker(language, dictionaryIds);
    },
  });

  useEffect(() => {
    if (token) {
      fetchSpellcheckerDictionary();
    }
  }, [fetchSpellcheckerDictionary, token]);
};
