import { useRef } from 'react';
import shallow from 'zustand/shallow';
import { useRegionComparisonStore } from '~/src/store/regionComparisonToolStore';
import { alignImageByLaterality } from '~/utils/cornestone/alignImageByLaterality';
import { getEnabledElementControlled } from '~/utils/cornestone/getEnabledElementControlled';
import { scaleMgImage } from '~/utils/cornestone/scaleMgImage';
import { isAMammographyImage } from '~/utils/imageMap';
import { DELAY_TO_AUTOALIGN } from '~/constants';
import { useViewportStore } from '~/src/store';

export const useMGAutoZoomAndAlignament = () => {
  const autoAlignTimeouts = useRef<Record<number, ReturnType<typeof setTimeout> | null>>({});
  const [getBoundingBoxByViewportIndex, resetRegion, getCurrentRegion] = useRegionComparisonStore(
    (state) => [state.getBoundingBoxByViewportIndex, state.resetRegion, state.getCurrentRegion],
    shallow,
  );
  const layout = useViewportStore((state) => state.layout);

  const fitToBoundingBoxIfMGImage = ({ element, viewportIndex, wasPanZoomEdited }) => {
    const imageBoundingBox = getBoundingBoxByViewportIndex(viewportIndex);
    const alignTimeout = autoAlignTimeouts.current[viewportIndex];
    if (alignTimeout) clearTimeout(alignTimeout);
    autoAlignTimeouts.current[viewportIndex] = setTimeout(() => {
      const enabledElement = getEnabledElementControlled(element);
      if (!enabledElement || !imageBoundingBox) return;
      const { image } = enabledElement;
      const imageId = image?.imageId || '';
      if (!isAMammographyImage(imageId)) return;
      const { numColumns, numRows } = layout;
      //If there is only one viewport, the image should not be horizontally aligned.
      const onlyVerticalAlignament = numColumns === 1 && numRows === 1;
      const region = getCurrentRegion();

      // If there is only one layout and there is an active region, it is because only the image was changed,
      // so we remove the current region.
      if (onlyVerticalAlignament && region) resetRegion();
      //It should not be auto-aligned if the user already manually modifies the pan or zoom
      if (wasPanZoomEdited) return;
      scaleMgImage(element, imageBoundingBox);
      alignImageByLaterality(element, imageBoundingBox, onlyVerticalAlignament);
    }, DELAY_TO_AUTOALIGN);
  };
  return { fitToBoundingBoxIfMGImage };
};
