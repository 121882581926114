import csTools from 'cornerstone-tools';
import { getVerticalParalelLines } from '~/utils/plane';
import { getSequenceStringMetadataTag } from '~/utils/cornestone/getMetadataTag';
import { getLaterality, getViewPosition } from '~/components/Viewer/CornerstoneViewer/getOverlayData';
import {
  LATERALITY_TYPE,
  VIEW_POSITION,
  DEGREES_OF_INCLINATION_FOR_REFERENCE_LINES,
  VIEW_CODE_SEQUENCE,
  TAG_VIEW_CODE_SEQUENCE,
} from '~/src/constants';
import { getEnabledElementControlled } from './getEnabledElementControlled';
const drawLine = csTools.importInternal('drawing/drawLine');
const draw = csTools.importInternal('drawing/draw');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');

/**
 * Draw two paralel lines to a point

 * @name drawMammographyRefereceLines
 *
 * @param {Object} context - The Viewport context
 * @param {Object} eventData - The source element for a click event
 * @param {Object} sourceElementRef - Element where the click occurred 
 * @param {String} referencePoint - Point coordinates
 * @returns {void}
 */
export default (context, eventData, sourceElementRef, referencePoint) => {
  const { image } = eventData;
  const viewPosition = getViewPosition(image.imageId);
  const [viewCodeSequence] = getSequenceStringMetadataTag(image.imageId, TAG_VIEW_CODE_SEQUENCE);
  const pixelSpacing = getPixelSpacing(image);
  draw(context, (context) => {
    const color = csTools.toolColors.getColorIfActive(referencePoint);

    // Validates if the reference point has information from a remote window
    const { remote } = referencePoint;
    const isMLO = viewCodeSequence?.find((codeSequence: string) =>
      codeSequence.toUpperCase().includes(VIEW_CODE_SEQUENCE.MLO),
    );
    const rotateLines = viewPosition?.includes(VIEW_POSITION.MLO) || isMLO;
    const rotationAngle = rotateLines ? DEGREES_OF_INCLINATION_FOR_REFERENCE_LINES : 0;

    const targetLaterality = getLaterality(image.imageId);

    const sourceElement = getEnabledElementControlled(sourceElementRef);
    if (!sourceElement?.image) return;
    const sourceLaterality = remote ? remote.laterality : getLaterality(sourceElement.image.imageId);

    //Validate that the images are of the same breast
    if (targetLaterality !== sourceLaterality) return;
    const rotationDirection = targetLaterality === LATERALITY_TYPE.R ? 1 : -1;
    const limits = { width: sourceElement.image.width, height: sourceElement.image.height };
    const [line1, line2] = getVerticalParalelLines(
      referencePoint.handles.end,
      pixelSpacing,
      rotationAngle * rotationDirection,
      limits,
    );

    const handleOptions = { color };
    drawLine(context, eventData.element, line1.start, line1.end, handleOptions);
    drawLine(context, eventData.element, line2.start, line2.end, handleOptions);
  });
};
