/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { spinnerClass, strokeClass } from './styles';
export var SpinnerSize;
(function (SpinnerSize) {
    SpinnerSize["xs"] = "xs";
    SpinnerSize["sm"] = "sm";
    SpinnerSize["md"] = "md";
})(SpinnerSize || (SpinnerSize = {}));
export var SpinnerColor;
(function (SpinnerColor) {
    SpinnerColor["white"] = "white";
    SpinnerColor["primary"] = "primary";
})(SpinnerColor || (SpinnerColor = {}));
/**
 * A Spinner is an outline of a circle which animates around itself indicating to the user that things are processing.
 * Use a Spinner when the content to be loaded is unknown or unpredictable.
 * @author Mario Lugo<mariolugo23@gmail.com>
 * Created at 2021-07-14
 */
export var Spinner = function (_a) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? SpinnerColor.primary : _b, _c = _a.size, size = _c === void 0 ? SpinnerSize.md : _c, _d = _a.style, style = _d === void 0 ? {} : _d, _e = _a.fullScreen, fullScreen = _e === void 0 ? true : _e;
    var progressStroke = function () {
        var borderTopColor;
        switch (color) {
            case SpinnerColor.primary:
                borderTopColor = '#4754FF'; // Primitives/Blue/300;
                break;
            case SpinnerColor.white:
                borderTopColor = '#FFFFFF'; // White;
                break;
            default:
                borderTopColor = '#4754FF'; // Primitives/Blue/300;
                break;
        }
        return {
            borderTopColor: borderTopColor,
        };
    };
    return (React.createElement("div", { "data-testid": "spinner", style: style, className: spinnerClass(className, fullScreen) },
        React.createElement("div", { style: progressStroke(), className: strokeClass(color, size) })));
};
