import { IconCatalog as EdenIconCatalog } from '@evacenter/eden';
import cornerstoneMath from 'cornerstone-math';
import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

import { transformUnits } from '@eva-pacs/core';

import { PIXELS_NEAR_TOOL, PIXELS_NEAR_TOOL_TOUCH } from '~/constants';

import drawHipLine from './drawHipLine';

const getROITextBoxCoords = csTools.importInternal('util/getROITextBoxCoords');
const drawLinkedTextBox = csTools.importInternal('drawing/drawLinkedTextBox');
const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const MouseCursor = csTools.importInternal('tools/cursors/MouseCursor');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const setShadow = csTools.importInternal('drawing/setShadow');
const draw = csTools.importInternal('drawing/draw');
const throttle = csTools.importInternal('util/throttle');

/**
 * @public
 * @class CustomDeviation
 * @memberof Tools.CustomDeviation
 * @classdesc Tool for drawing hip deviations.
 * @extends Tools.Base.BaseAnnotationTool
 */

const TOOL_NAME = 'Deviation';
const TEXT_BOX_SPACING_VALUE = 10;
const PIXELS_MEASUREMENT_UNIT = 'pixels';
const PIXEL_MEASUREMENT_UNIT = 'pixel';
const MILIMETER_MEASUREMENT_UNIT = 'mm';
const TOOL_CURSOR = new MouseCursor(
  `<path fill="ACTIVE_COLOR" fill-rule="evenodd" d="${EdenIconCatalog.deviation}" clip-rule="evenodd"/> 
`,
  {
    viewBox: {
      x: 24,
      y: 24,
    },
  },
);
export default class CustomDeviation extends BaseAnnotationTool {
  throttledUpdateCachedStats: any;
  configuration: {
    drawHandles: boolean;
    drawHandlesOnHover: boolean;
    hideHandlesIfMoving: boolean;
    renderDashed: boolean;
  };
  name: string;
  unit: string;
  constructor(props = {}) {
    const defaultProps = {
      name: TOOL_NAME,
      svgCursor: TOOL_CURSOR,
      supportedInteractionTypes: ['Mouse', 'Touch'],
    };

    super(props, defaultProps);
    this.name = TOOL_NAME;
    this.unit = PIXELS_MEASUREMENT_UNIT;
    this.configuration = {
      drawHandles: true,
      drawHandlesOnHover: false,
      hideHandlesIfMoving: false,
      renderDashed: false,
    };
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  /**
   * Override original pointNearTool of cornerstone-tools
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @param {*} interactionType
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles = data && data.handles && data.handles.start && data.handles.end;

    if (!hasStartAndEndHandles) csTools.logger.warn(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);
    if (!hasStartAndEndHandles || data.visible === false) return false;

    const distance = interactionType === 'mouse' ? PIXELS_NEAR_TOOL : PIXELS_NEAR_TOOL_TOUCH;
    const startCanvas = cornerstone.pixelToCanvas(element, data.handles.start);
    const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x),
      top: Math.min(startCanvas.y, endCanvas.y),
      width: Math.abs(startCanvas.x - endCanvas.x),
      height: Math.abs(startCanvas.y - endCanvas.y),
    };

    const distanceToPoint = cornerstoneMath.rect.distanceToPoint(rect, coords);

    return distanceToPoint < distance;
  }

  /**
   * Override original updateCachedStats of cornerstone-tools
   * @param {*} image
   * @param {*} element
   * @param {*} data
   * @returns {Void}
   */
  updateCachedStats(image, _, data) {
    const pixelSpacing = getPixelSpacing(image);
    const stats = this._calculateStats(data.handles, pixelSpacing);
    data.stats = stats;
    data.unit = this.unit;
    data.value = stats[0];

    data.cachedStats = stats;
    data.invalidated = false;
  }

  /**
   * Override original createNewMeasurement of cornerstone-tools
   * @param {*} eventData
   */
  createNewMeasurement(eventData) {
    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      csTools.logger.error(`required eventData not supplied to tool ${this.name}'s createNewMeasurement`);
      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: '',
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  /**
   * Override original renderToolData of cornerstone-tools
   * @param {*} eventData
   */
  renderToolData(eventData) {
    const toolData = csTools.getToolState(eventData.detail.element, this.name);
    const lineWidth = csTools.toolStyle.getToolWidth();

    if (!toolData) {
      return;
    }
    const context = getNewContext(eventData.detail.element.childNodes[0]);

    const { element, image } = eventData.detail;
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    const { drawHandlesOnHover, hideHandlesIfMoving, renderDashed } = this.configuration;

    draw(context, (context) => {
      // If we have tool data for this element - iterate over each set and draw it

      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) continue;

        // Configure
        const color = csTools.toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const rectOptions = { color, lineDash: undefined };

        if (renderDashed) {
          rectOptions.lineDash = lineDash;
        }
        // Update textbox stats
        if (data.invalidated === true || !data.value) {
          if (data.value) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Draw
        drawHipLine(
          context,
          element,
          data.handles.start,
          data.handles.end,
          rectOptions,
          PIXEL_MEASUREMENT_UNIT,
          data.handles.initialRotation,
        );

        if (this.configuration.drawHandles) {
          drawHandles(context, eventData.detail, data.handles, handleOptions);
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = getROITextBoxCoords(eventData.detail.viewport, data.handles);
          Object.assign(data.handles.textBox, defaultCoords);
        }

        const textBoxAnchorPoints = (handles) => this._findTextBoxAnchorPoints(handles.start, handles.end);
        const textBoxContent = data.stats;
        const textBoxSpacingValue = TEXT_BOX_SPACING_VALUE;

        drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          textBoxContent,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          textBoxSpacingValue,
          true,
        );
      }
    });
  }

  /**
   * Override original _findTextBoxAnchorPoints of cornerstone-tools
   * @param {Object} startHandle - `{ x, y }` in either pixel or canvas coordinates.
   * @param {Object} endHandle - `{ x, y }` in either pixel or canvas coordinates.
   * @returns {Array.<{x: number, y: number}>}
   */
  _findTextBoxAnchorPoints(startHandle, endHandle) {
    const { left, top, width, height } = this._getDeviationImageCoordinates(startHandle, endHandle);

    return [
      {
        // Top middle point of rectangle
        x: left + width / 2,
        y: top,
      },
      {
        // Left middle point of rectangle
        x: left,
        y: top + height / 2,
      },
      {
        // Bottom middle point of rectangle
        x: left + width / 2,
        y: top + height,
      },
      {
        // Right middle point of rectangle
        x: left + width,
        y: top + height / 2,
      },
    ];
  }

  /**
   * Override original _getDeviationImageCoordinates of cornerstone-tools
   * @param {Object} startHandle - `{ x, y }` in either pixel or canvas coordinates.
   * @param {Object} endHandle - `{ x, y }` in either pixel or canvas coordinates.
   * @returns {{ left: number, top: number, width: number, height: number}}
   */
  _getDeviationImageCoordinates(startHandle, endHandle) {
    return {
      left: Math.min(startHandle.x, endHandle.x),
      top: Math.min(startHandle.y, endHandle.y),
      width: Math.abs(startHandle.x - endHandle.x),
      height: Math.abs(startHandle.y - endHandle.y),
    };
  }

  /**
   * Override original _calculateStats of cornerstone-tools
   * @param {Array<Object>} handles `Array<{ x, y }>` in either pixel or canvas coordinates.
   * @param {Object} pixelSpacing Pixel spacing object
   * @returns {Object} The Stats object
   */
  _calculateStats(handles, pixelSpacing) {
    // Retrieve the bounds of the rectangle in image coordinates
    const roiCoordinates = this._getDeviationImageCoordinates(handles.start, handles.end);
    const horizontalLength = roiCoordinates.height * (pixelSpacing.rowPixelSpacing || 1);

    // Set the length text suffix depending on whether or not pixelSpacing is available
    let suffix = MILIMETER_MEASUREMENT_UNIT;

    if (!pixelSpacing.rowPixelSpacing || !pixelSpacing.colPixelSpacing) {
      suffix = PIXELS_MEASUREMENT_UNIT;
    }
    this.unit = suffix;
    const label = `${horizontalLength.toFixed(2)} ${suffix}`;
    const usableLabel = transformUnits(label);

    return [usableLabel];
  }
}
