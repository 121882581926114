import React from 'react';
import cn from 'classnames';
import {
  ProfileButton,
  AccountPopupMenu,
  AccountOption,
  DropdownMenuV2 as DropdownMenu,
  Locale,
  ProfileButtonVariant,
} from '@evacenter/eden';

import { useSessionStore } from '~/src/store/sessionStore';
import { usePostMessage } from '~/src/hooks/usePostMessage';

import { useLogout } from '~/src/hooks/auth/logout';
import { useLanguage } from '~/src/hooks/useLanguage';
import { CrossPacsActionType } from '~/constants';

export type AccountMenuOption = {
  isActive: boolean;
  id: string;
  caption: string;
  title: string;
};

export interface AccountPopupMenuProps {
  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The account list
   */
  accounts: Array<AccountOption>;

  /**
   * The callback to get notified when the button was clicked.
   */
  onOptionClick?: (selectedOption: AccountOption) => void;

  /**
   * Flag to indicate if the ProfileButton should show the red badge.
   */
  hasNotifications?: boolean;

  /**
   * Indicates which variant to use for the Profile Button component.
   * Default is ProfileButtonVariant.default
   */
  profileButtonVariant?: ProfileButtonVariant;
}

/**
 * This components display a popup menu to list user accounts with notifications.
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2023-08-30
 */
export const AccountMenu = ({
  className,
  dataTestId,
  accounts = [],
  onOptionClick,
  hasNotifications = false,
  profileButtonVariant = ProfileButtonVariant.default,
}: AccountPopupMenuProps) => {
  const getCurrentAccount = useSessionStore((store) => store.getCurrentAccount);

  const classes = {
    container: cn(className, 'e-flex e-flex-col z-4'),
  };

  const { postMessage } = usePostMessage();

  const { changeLanguage, currentLanguage } = useLanguage();

  const { logout } = useLogout();

  const currentOrganization = getCurrentAccount()?.organizationName;

  const userFullName = getCurrentAccount()?.userInfo.fullName;

  const handleOptionClick = (account: any) => {
    if (onOptionClick) onOptionClick(account);
  };

  const handleLanguageChange = (locale: Locale) => {
    changeLanguage(locale);

    // Emit a message to the origin about the language change
    postMessage({
      actionType: CrossPacsActionType.UPDATE_LANGUAGE,
      payload: { locale: locale },
    });
    // Refresh the page to properly load the content in the selected language
    window.location.reload();
  };

  const handleLogoutClick = () => logout();

  return (
    <div data-testid={dataTestId} className={classes.container}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <ProfileButton
            className="e-text-left"
            variant={profileButtonVariant}
            hasNotifications={hasNotifications}
            laboratoryName={currentOrganization}
            userFullname={userFullName}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <AccountPopupMenu
            locale={currentLanguage as Locale}
            className="e-w-96"
            onLanguageChange={handleLanguageChange}
            accounts={accounts}
            onOptionClick={handleOptionClick}
            onLogoutClick={handleLogoutClick}
          />
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};
