import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ErrorState as NewErrorState } from '@evacenter/eden';

import {
  usePrintersQuery,
  DicomPrinterType,
  restClient,
  ENDPOINT_PATH,
  useDicomPrintQuery,
  DicomPrintType,
  DicomPrintStatusPrint,
} from '@eva-pacs/client';
import { Button, ErrorState, LoadingState, Spinner, SuccessState } from '@eva-pacs/ui';

import { usePrinterStore } from '~/src/store/printerStore';
import { getAuthHeaders } from '~/utils/getHeadersAuth';
import { useErrorHandler } from '~/utils/appHelpers';
import { getDicomPrintPageSize } from '~/utils/print';

import { PrinterList } from './PrinterList';
import { Modals, useModal } from '@eva-pacs/core';

const uploadService = (file: File, pagesSize: string, studyId: string, dicomPrinter: string): any => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('page_size', pagesSize);
  formData.append('study', studyId);
  formData.append('dicom_printer', dicomPrinter);

  return restClient.post(`${ENDPOINT_PATH.DICOM_PRINTS}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...getAuthHeaders(),
    },
  });
};

export interface PrintDICOMProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Pass Blob
   */
  file: File;

  /**
   * Pass study id
   */
  studyId: string;

  /**
   * Optional printer id
   */
  printerId?: string;

  /**
   * Set the subtitle modal info
   */
  subtitle: string;

  /**
   * Handle close current modal
   */
  onClose: () => void;

  /**
   * Print series dicom id.
   */
  printSeriesDicomId?: string;
}

export type DicomPrintJobType = Pick<DicomPrintType, 'file' | 'id' | 'status' | 'statusPrint' | 'image'> | undefined;

/**
 * This component shows and handles the model for printing to DICOM printers
 * The component is based of Sergio's work on the cd burner list
 * The component will list from the Dicom Printer model, will upload files
 * and seek the status of Dicom Prints
 * @author Antonio Torres<toniotgz@gmail.com>
 * Created at 2022-05-04
 */
export const PrintDICOM: React.FC<PrintDICOMProps> = ({
  className,
  file,
  studyId,
  subtitle,
  onClose,
  printerId,
  printSeriesDicomId,
}) => {
  const { t } = useTranslation();
  const { setCurrentModal } = useModal();
  const printDICOMClass = cn(className);
  const { handleError } = useErrorHandler();
  const { printParams } = usePrinterStore();
  const [selectedPrinter, setSelectedPrinter] = useState(printerId || '');
  const [currentlyPrintingDicomPrintId, setCurrentlyPrintingDicomPrintId] = useState('');

  const pageSize = getDicomPrintPageSize(printParams);

  // The query to know the organization enabled dicom printers
  const { data: printersData, loading } = usePrintersQuery({
    onCompleted: (response) => {
      const printersList = response?.dicomPrinters?.results || [];
      if (printersList.length === 0) return;
      setSelectedPrinter(printersList[0]?.id);
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  // Once we have a dicom print job, this query will permit reading the status
  const { data: dicomPrint } = useDicomPrintQuery({
    onError: (error) => handleError({ logMessage: error }),
    variables: { id: currentlyPrintingDicomPrintId },
    skip: currentlyPrintingDicomPrintId === '', // Skip no print job is sent
    pollInterval: 1000, // Interval to update print status
  });

  const handlePrinterOptionClick = (printerId: string) => setSelectedPrinter(printerId);

  const handlePrintClick = async (printer = selectedPrinter) => {
    try {
      // Upload file for processing

      // for series print
      if (printSeriesDicomId) return setCurrentlyPrintingDicomPrintId(printSeriesDicomId);

      // For individual print.
      const response = await uploadService(file, pageSize, studyId, printer);
      const dicomPrintId = response.data.id;
      // Use the returned ID to find the status of the print
      setCurrentlyPrintingDicomPrintId(dicomPrintId);
    } catch (error) {
      setCurrentModal({
        name: Modals.GenericModal,
        props: {
          title: t('viewer.openPrintersModal.title'),
          className: 'max-w-sm',
          children: (
            <div className="flex justify-center">
              <NewErrorState
                className="border-none"
                body={t('viewer.printError.description')}
                title={t('viewer.printError.title')}
              />
            </div>
          ),
        },
      });
      handleError({ logMessage: error });
    }
  };

  const handleFinishClick = () => onClose();

  const printersList = printersData?.dicomPrinters?.results || [];
  const printStatus = dicomPrint?.dicomPrint?.statusPrint;

  useEffect(() => {
    if (printerId) handlePrintClick(printerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printerId]);

  if (loading) return <Spinner className="my-6" />;

  if (printStatus === DicomPrintStatusPrint.IN_PROGRESS)
    return <LoadingState title={t('study.printing.pending.title')} />;
  if (printersList.length === 0 || printStatus === DicomPrintStatusPrint.FAILED)
    return (
      <ErrorState
        title={t('study.printing.failed.title')}
        description={t('study.printing.failed.description')}
        footerSection={
          <Button className="ml-auto" onClick={handleFinishClick}>
            {t('general.actions.finish')}
          </Button>
        }
      />
    );
  if (printStatus === DicomPrintStatusPrint.COMPLETED)
    return (
      <SuccessState
        title={t('study.printing.completed.title')}
        footerSection={
          <Button className="ml-auto" onClick={handleFinishClick}>
            {t('general.actions.finish')}
          </Button>
        }
      />
    );

  if (printerId) return <Spinner className="my-6" />;

  return (
    <div className={printDICOMClass}>
      <PrinterList
        subtitle={subtitle}
        printersList={printersList as Array<DicomPrinterType>}
        defaultOption={selectedPrinter}
        onPrintBtnClick={handlePrintClick}
        onPrinterOptionClick={handlePrinterOptionClick}
      />
    </div>
  );
};
