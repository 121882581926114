import cornerstone from 'cornerstone-core';

import { ConvolveFilter, convolveFilters } from '~/constants';
import { useViewportStore } from '~/src/store';
import { applyFilterImage } from '~/utils/applyFilterImage';
import { forceUpdateImage } from '~/utils/cornestone/forceUpdateImage';
import { useOpenCVLoader } from '~/src/hooks/useOpenCVLoader';
import { getEnabledElementControlled } from '~/src/utils/cornestone/getEnabledElementControlled';
export const useConvolveFilter = () => {
  const loadOpenCV = useOpenCVLoader();
  const {
    setViewportCurrentConvolveFilter,
    activeIndex,
    getActiveViewportContent,
    getConvolveFilterByViewportIndex,
    viewportData,
  } = useViewportStore();

  const currentElement = getActiveViewportContent().dom;

  const getCurrentOriginalImage = (image) => {
    const originalImage = cornerstone.imageCache?.imageCache[image.imageId]?.image;

    return originalImage;
  };

  const convolve = (convolveFilter: ConvolveFilter) => {
    const targetFilter = convolveFilters[convolveFilter];
    if (!targetFilter || !currentElement) return;

    const { image } = getEnabledElementControlled(currentElement) ?? {};

    if (!image) return;

    setViewportCurrentConvolveFilter(activeIndex, convolveFilter);

    const kernel = targetFilter.matrix;
    const multiplier = targetFilter.multiplier;

    const originalImage = getCurrentOriginalImage(image);

    const payload = {
      pixelData: originalImage.getPixelData(),
      kernel,
      multiplier,
      imageFrame: {
        typedArrayName: originalImage.getPixelData().constructor.name,
        width: originalImage.width,
        height: originalImage.height,
      },
    };
    loadOpenCV().then(() => {
      applyFilterImage({ targetElement: currentElement, sourceImage: originalImage, payload });
    });
  };

  const keepFilter = (viewportIndex: number) => {
    const filter = getConvolveFilterByViewportIndex(viewportIndex);
    if (!filter) return;
    convolve(filter);
  };

  const clearFilter = (viewportIndex: number) => {
    setViewportCurrentConvolveFilter(viewportIndex, undefined);
    const targetElement = viewportData[viewportIndex]?.dom;
    if (!targetElement) return;
    const { image } = getEnabledElementControlled(targetElement) ?? {};
    if (!image) return;
    const originalImage = getCurrentOriginalImage(image);
    forceUpdateImage(targetElement, originalImage);
  };

  return { convolve, clearFilter, keepFilter };
};
