/**
 * @param {string} - file url to handle click programmatically
 * @return {void}
 */
export var handleProgrammaticDownload = function (fileUrl, options) {
    var link = document.createElement('a');
    link.style.display = 'none';
    link.href = fileUrl;
    if (options === null || options === void 0 ? void 0 : options.fileName)
        link.download = options.fileName;
    if (options === null || options === void 0 ? void 0 : options.target)
        link.target = options.target;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(fileUrl);
    link.href = '';
};
