import React from 'react';
import cn from 'classnames';
import { GenericModal } from '../GenericModal';
import { ErrorState } from '../../States/ErrorState';
/**
 * Use this component in order to show a error Dialog
 * @author Ricardo Alfonso Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-08-11
 */
var ErrorModal = function (_a) {
    var description = _a.description, caption = _a.caption, className = _a.className, onClose = _a.onClose, _b = _a.imageSrc, imageSrc = _b === void 0 ? '/assets/illustrations/connection-error.svg' : _b, _c = _a.hasIllustration, hasIllustration = _c === void 0 ? true : _c, footerSection = _a.footerSection;
    var errorModalClass = cn(className);
    return (React.createElement(GenericModal, { className: errorModalClass, onClose: onClose, isFullWidth: false },
        React.createElement(ErrorState, { title: caption, description: description, imageSrc: imageSrc, hasIllustration: hasIllustration, footerSection: footerSection })));
};
export default ErrorModal;
