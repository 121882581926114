import { FilterMatrix } from '../constants/convolveFilters';
import { forceUpdateImage } from './cornestone/forceUpdateImage';

interface ConvolveFilterPayload {
  pixelData: any;
  kernel: FilterMatrix;
  multiplier: number;
  imageFrame: {
    typedArrayName: string;
    width: number;
    height: number;
  };
}

interface ApplyFilterImage {
  targetElement: HTMLElement;
  sourceImage: any;
  payload: ConvolveFilterPayload;
  calculateWWWC?: boolean;
}

const getBoundingFixedImage = (storedPixelData, maxLimit, minLimit) =>
  storedPixelData.map((pixelValue) => {
    const boundedValue = Math.max(Math.min(pixelValue, maxLimit), minLimit);
    return boundedValue;
  });

/**
 * Applies a convolution operation on a given image and displays the result on a specified element.
 *
 * @param {HTMLElement} args.targetElement - The target element where the modified image will be displayed
 * @param {Image} args.sourceImage - The original image on which the convolution operation will be applied
 * @param {Object} args.payload - The object with required parameters for the convolution operation
 * @param {boolean} args.calculateWWWC - A flag that indicates if window width and center should be calculated based on the convolution result
 */

export const applyFilterImage = async (args: ApplyFilterImage) => {
  const { targetElement, sourceImage, payload } = args;

  // Get Array Image and parse to Mat object
  const image = cv.matFromArray(payload.imageFrame.height, payload.imageFrame.width, cv.CV_16SC1, payload.pixelData);

  const imageFiltered = new cv.Mat();

  // Get FilterMatrix kernel dimentions
  const kernelRows = payload.kernel.length;
  const kernelCols = payload.kernel[0].length;

  // Flat kernel to pass a filter function
  const kernelScaled = getFlatKernel(payload.kernel, payload.multiplier);
  const kernel = cv.matFromArray(kernelRows, kernelCols, cv.CV_32F, kernelScaled);

  // Apply filter and generate new image
  cv.filter2D(image, imageFiltered, -1, kernel);

  // Get new buffer to pass a current image
  const newPixelData = getBoundingFixedImage(
    imageFiltered.data16S,
    sourceImage.maxPixelValue,
    sourceImage.minPixelValue,
  );
  imageFiltered.delete();

  const modifiedImage = {
    ...sourceImage,
    getPixelData: () => newPixelData,
  };

  forceUpdateImage(targetElement, modifiedImage);
};

const getFlatKernel = (kernel: FilterMatrix, multiplier: number) => kernel.flat().map((value) => value * multiplier);
