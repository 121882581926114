import { AvailableLanguages, DEFAULT_LANGUAGE_ABBREVIATION } from '@eva-pacs/core';

/**
 * Parses the default language taken from the browser environment to our supported languages.
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2023-05-09
 */
export const parseI18nLanguage = (i18nLanguage: string): string => {
  return (
    Object.keys(AvailableLanguages).find((language) => i18nLanguage?.includes(language)) ||
    DEFAULT_LANGUAGE_ABBREVIATION
  );
};
