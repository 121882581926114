import { useBrowserContextCommunication } from '@eva-pacs/core';
import { useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, useViewportStore } from '~/src/store';

import { wait } from '~/utils/wait';
import { buildBroadcastMessage } from '~/utils/syncBrowserWindows';
import {
  BROADCAST_AVAILABILITY_WINDOW_PING_TEXT,
  BROWSER_WINDOW_AVAILABLE_PING_TEXT,
  BROWSER_WINDOW_AVAILABLE_PONG_TEXT,
} from '~/constants';
import { SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT } from '~/utils/screenPreferences';

export interface WindowListener {
  windowID: string;
  layout: Layout;
}

/**
 * This hook provides a function that lets you know how many
 * additional windows are viewing the same study.
 *
 * ```ts
 * const { getActiveWindows } = useBrowserWindowListeners();
 *
 * someFunction = async () => {
 *  const activeWindows = await getActiveWindows();
 * };
 * ```
 */

export const useBrowserWindowListeners = () => {
  const { id: studyId } = useParams() as { id: string };
  const activeWindowListeners = useRef<WindowListener[]>([]);
  const { layout } = useViewportStore();
  const { communicationState, postMessage } = useBrowserContextCommunication(studyId);

  useEffect(() => {
    const lastMessage = communicationState.lastMessage || '';
    if (!lastMessage) return;
    // eslint-disable-next-line i18next/no-literal-string
    const [browserWindowId, message, eventAsText, payload] = lastMessage.split('::');
    if (
      browserWindowId === SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT ||
      message !== BROADCAST_AVAILABILITY_WINDOW_PING_TEXT
    )
      return;
    if (eventAsText === BROWSER_WINDOW_AVAILABLE_PING_TEXT) {
      postMessage(
        buildBroadcastMessage(
          SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT,
          BROADCAST_AVAILABILITY_WINDOW_PING_TEXT,
          BROWSER_WINDOW_AVAILABLE_PONG_TEXT,
          JSON.stringify({ layout }),
        ),
      );
      return;
    }
    if (eventAsText === BROWSER_WINDOW_AVAILABLE_PONG_TEXT) {
      const payloadRecibed = JSON.parse(payload);
      activeWindowListeners.current.push({ windowID: browserWindowId, ...payloadRecibed });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationState.lastMessage]);

  const getActiveWindows = useCallback(async (timeout = 500) => {
    activeWindowListeners.current = [];
    postMessage(
      buildBroadcastMessage(
        SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT,
        BROADCAST_AVAILABILITY_WINDOW_PING_TEXT,
        BROWSER_WINDOW_AVAILABLE_PING_TEXT,
      ),
    );
    await wait(timeout);
    return activeWindowListeners.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getActiveWindows,
  };
};
