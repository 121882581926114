import React from 'react';
import Image from '../Image';
import { avatarClass, imageClass } from './styles';
export var AvatarSize;
(function (AvatarSize) {
    AvatarSize["2xs"] = "2xs";
    AvatarSize["xs"] = "xs";
    AvatarSize["sm"] = "sm";
    AvatarSize["md"] = "md";
    AvatarSize["lg"] = "lg";
    AvatarSize["xl"] = "xl";
})(AvatarSize || (AvatarSize = {}));
/**
 * Avatars are used to show a thumbnail representation of an individual or business in the interface.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-04-22
 */
var Avatar = function (_a) {
    var _b = _a.size, size = _b === void 0 ? AvatarSize.xs : _b, alt = _a.alt, _c = _a.src, src = _c === void 0 ? '/assets/images/default-avatar.jpg' : _c, className = _a.className;
    return (React.createElement("div", { className: avatarClass(className, size) },
        React.createElement(Image, { className: imageClass(size), src: src, alt: alt, noImg: React.createElement(React.Fragment, null), style: { textIndent: '-9999px' } })));
};
export default Avatar;
