import create, { GetState, SetState } from 'zustand';
import produce from 'immer';
import { FeatureFlags } from '../constants/featureFlags';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

export interface FeatureFlag {
  identifier: FeatureFlags;
}

/**
 * Flags Store
 */
export interface FeatureFlagsStore {
  flags: Array<FeatureFlag>;
  setFlags: (flags: Array<FeatureFlag>) => void;
  hasFlag: (identifier: FeatureFlags) => boolean;
}

const featureFlagsStore = (set: SetState<FeatureFlagsStore>, get: GetState<FeatureFlagsStore>): FeatureFlagsStore => ({
  flags: [],
  setFlags: (flags: Array<FeatureFlag>) => {
    return set((state) => {
      state.flags = [...flags];
    });
  },
  hasFlag: (identifier: FeatureFlags) => Boolean(get().flags.find((flag) => flag.identifier === identifier)),
});

export const useFeatureFlagsStore = create<FeatureFlagsStore>(immer(featureFlagsStore));
