import csTools from 'cornerstone-tools';
const draw = csTools.importInternal('drawing/draw');
const drawLine = csTools.importInternal('drawing/drawLine');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');

/**
 * Draw an X in a point over the image

 * @name drawMammographyReferecePoint
 *
 * @param {Object} context - The Viewport context
 * @param {Object} eventData - The source element for  a click event
 * @param {Object} referencePoint - A reference point to draw
 * @param {String} color - Color in RGB
 * @param {Number} sizePointInCM - size of the x to draw in centimeters
  * @returns {void}
 */
export default (context, eventData, referencePoint, color, sizePointInCM = 0.2) => {
  const { image } = eventData;
  const pixelSpacing = getPixelSpacing(image);
  const { colPixelSpacing = 1 } = pixelSpacing;

  // Convert centimeters to pixels
  const sizePointInPX = (sizePointInCM * 10) / colPixelSpacing;

  const point = referencePoint.end;
  draw(context, (context) => {
    const options = { color };
    const line1 = {
      start: { x: point.x - sizePointInPX, y: point.y - sizePointInPX },
      end: { x: point.x + sizePointInPX, y: point.y + sizePointInPX },
    };
    const line2 = {
      start: { x: point.x - sizePointInPX, y: point.y + sizePointInPX },
      end: { x: point.x + sizePointInPX, y: point.y - sizePointInPX },
    };

    drawLine(context, eventData.element, line1.start, line1.end, options);
    drawLine(context, eventData.element, line2.start, line2.end, options);
  });
};
