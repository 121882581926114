import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { ADMIN_INVITE_PATH, ADMIN_PATH, useInterval } from '@eva-pacs/core';
import { IDLE_TIME_MS, INITIAL_ROUTE, POLICIES } from './constants';
import { useSessionStore } from './store';
import { useUserProductPermission } from './hooks/useUserProductPermission';

/**
 * Protected Route, here wrappe all private routes
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-06-07
 */

export const WrapperRoute = ({ component: Component, isPrivate, layout: Layout, policy, ...restOfProps }) => {
  const { hasAdminPanelAccess, hasPACSAccess } = useUserProductPermission();
  const { isAuthenticated, user } = useSessionStore((state) => ({
    isAuthenticated: state.isAuthenticated(),
    user: state.user,
  }));
  const history = useHistory();

  const { path } = restOfProps;
  const isAdminRoute = [ADMIN_PATH, ADMIN_INVITE_PATH].includes(path);

  useInterval(() => {
    if (isAuthenticated) history.go(0);
  }, IDLE_TIME_MS);

  // User doesn't have policy
  if (user && policy && !POLICIES[policy](user)) return <Redirect to="/" />;
  // Non authenticated user is trying to access a private route
  if (isPrivate && !isAuthenticated) return <Redirect to="/login" />;
  // Authenticated user is accesing a non private route
  if (!isPrivate && isAuthenticated) return <Redirect to={INITIAL_ROUTE} />;
  // Trying to access admin panel without permissions
  if (isAdminRoute && !hasAdminPanelAccess) return <Redirect to="/" />;
  // User has no access to PACS
  if (!isAdminRoute && hasAdminPanelAccess && !hasPACSAccess) return <Redirect to="/admin" />;

  return (
    <Route
      {...restOfProps}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};
