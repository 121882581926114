import { toBlob, toPng, toJpeg } from 'html-to-image';
import { jsPDF } from 'jspdf';
import printJS from 'print-js';

import { ELEMENT_GRID_ID, PATIENT_LABEL_ELEMENT, SIMPLE_OVERLAY_ELEMENT, FileExtension } from '@eva-pacs/core';

import { PRINTING_DPI_HIGH, PRINTING_DPI_NORMAL, PRINTING_DPI_ULTRA, PRINTING_QUALITY } from '../constants/printing';

const htmlToImageOptions = {
  pixelRatio: 1,
  fontEmbedCSS: `@font-face { font-family: "sans-serif"; src: local("sans-serif") }`,
};

/**
 * The function takes a HTMLElement and converts it to a image.
 * @param {HTMLDivElement} div - The div to capture in the image
 * @returns {Promise<string>} returns a image from the div taken
 */
export const generateImageFromDiv = async (div: HTMLDivElement, type = 'image/png'): Promise<string> => {
  if (type === 'image/jpeg') return await toJpeg(div, htmlToImageOptions);
  return await toPng(div, htmlToImageOptions);
};

/**
 * The function takes a HTMLElement and converts it to a blob.
 * @param {HTMLDivElement} div - The div to capture in the image
 * @returns {Promise<string>} returns a blob from the div taken
 */
export const generateBlobFromDiv = async (div: HTMLDivElement): Promise<Blob | null> => {
  const imageBlob = await toBlob(div, htmlToImageOptions);

  return imageBlob;
};

/**
 * The function takes a Data URL and converts it a PDF
 * @param {string} imageData - Data URL to convert
 * @param {string} printParams - Print settings
 * @returns {string} returns a image from the div taken
 */
export const createPdfFromImage = (imageData: string, printParams: PrintParams, filename: string): void => {
  const doc = new jsPDF({
    orientation: printParams.orientation,
    unit: printParams.unit,
    format: [printParams.height, printParams.width],
  });

  doc.addImage(imageData, FileExtension.PNG, 0, 0, printParams.width, printParams.height);
  doc.save(`${filename}.pdf`);
};

/**
 * The function takes a div and shows the browser print modal
 * @param {HTMLDivElement} div - The div to capture in the image
 */
export const downloadDivAsPdf = async (div: HTMLDivElement, printParams: PrintParams, fileName: string) => {
  const imageData = await generateImageFromDiv(div);
  createPdfFromImage(imageData, printParams, fileName);
};

/**
 * The function takes a div and downloads the image
 * @param {HTMLDivElement} div - The div to capture in the image
 */
export const downloadDivAsImage = async (div: HTMLDivElement, fileName: string) => {
  const imageData = await generateImageFromDiv(div, 'image/jpeg');

  const a = document.createElement('a');
  a.href = imageData;
  a.download = `${fileName}.jpeg`;
  document.body.appendChild(a);
  a.click();
};

export const printDivAsImage = async (div: HTMLDivElement) => {
  const imageData = await generateImageFromDiv(div, 'image/jpeg');
  printJS({ printable: imageData, type: 'image', base64: true });
};

export const printUrlPdf = (url: string) => {
  printJS({ printable: url, type: 'pdf', base64: false });
};

export const getDicomPrintPageSize = (printParams: PrintParams) => {
  return `${printParams.width}${printParams.unit?.toUpperCase()}X${
    printParams.height
  }${printParams.unit?.toUpperCase()},${printParams.orientation}`;
};

export type PrintParams = {
  nameValue?: string;
  height: number;
  width: number;
  unit: 'in' | 'em' | 'pt' | 'px' | 'mm' | 'cm' | 'ex' | 'pc' | undefined;
  orientation: 'portrait' | 'p' | 'l' | 'landscape' | undefined;
};

/**
 * The function gets the quality and transforms it to an int that represents
 * the DPI of the image
 * @param {PRINTING_QUALITY} quality - The quality of the image
 */
export const getPrintingQualityAsDpi = (quality: PRINTING_QUALITY) => {
  if (quality === PRINTING_QUALITY.ultra) return PRINTING_DPI_ULTRA;
  else if (quality === PRINTING_QUALITY.high) return PRINTING_DPI_HIGH;
  else return PRINTING_DPI_NORMAL;
};

/**
 * The function gets the quality and transforms it to an float that represents
 * the zoom of the image on the viewer page
 * @param {PRINTING_QUALITY} quality - The quality of the image
 */
export const getPrintingQualityAsZoom = (quality: PRINTING_QUALITY) => {
  if (quality === PRINTING_QUALITY.ultra) return 0.1;
  else if (quality === PRINTING_QUALITY.high) return 0.2;
  else return 0.8;
};

/**
 * The function returns the styles needed to adapt the viewer to a page
 * proportional size.
 * @param {boolean} show - Show it show the printing size
 * @param {PRINTING_QUALITY} quality - The quality of the image
 * @param {PrintParams} printParams - The printing params selected
 */
export const getPrintingStyle = (quality: PRINTING_QUALITY, printParams: PrintParams) => {
  const scale = getPrintingQualityAsDpi(quality);

  const printWidth = printParams.width * scale;
  const printHeight = printParams.height * scale;

  return {
    width: `${printWidth}px`,
    height: `${printHeight}px`,
    zoom: getPrintingQualityAsZoom(quality),
  };
};

const getGrid = () => document.querySelector(`#${ELEMENT_GRID_ID}`) as HTMLDivElement | undefined;

const areaDetailAttributte = 'aria-details';

const increasePrintTries = () => {
  const grid = getGrid();
  if (!grid) return;

  const currentTries = parseInt(grid.getAttribute(areaDetailAttributte) || '0');
  const nextTry = (currentTries + 1).toString();

  grid.setAttribute(areaDetailAttributte, nextTry);
};

const restartPrintTries = () => {
  const grid = getGrid();
  if (!grid) return;

  grid.removeAttribute(areaDetailAttributte);
};

const hasRestartTooMuch = () => {
  const grid = getGrid();
  if (!grid) return false;

  const currentTries = parseInt(grid.getAttribute(areaDetailAttributte) || '0');
  return currentTries > 5;
};

const retryGettingPrintRef = async (isShowingText: boolean, patientLabel): Promise<HTMLDivElement | undefined> =>
  new Promise((resolve) => setTimeout(() => resolve(getPrintRef(isShowingText, patientLabel)), 500));

export const getPrintRef = async (isShowingText: boolean, patientLabel): Promise<HTMLDivElement | undefined> => {
  increasePrintTries();
  if (hasRestartTooMuch()) throw new Error('We have a problem getting the text, refresh and try again');

  const element = getGrid();
  if (!element) return await retryGettingPrintRef(isShowingText, patientLabel);

  const overlayPatientTexts = element.querySelectorAll(
    `[data-testid=${SIMPLE_OVERLAY_ELEMENT}] [data-testid=${PATIENT_LABEL_ELEMENT}]`,
  );

  const texts: Array<string> = [];
  overlayPatientTexts.forEach((ele) => texts.push(ele?.textContent || ''));
  const hasText = texts.every((text) => text?.length) && Boolean(texts?.length);

  if (!hasText && isShowingText) return await retryGettingPrintRef(isShowingText, patientLabel);

  restartPrintTries();
  return element;
};
