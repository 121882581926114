import cornerstone from 'cornerstone-core';
import type { PixelCoordinate } from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import { useSpineLabelStore } from '~/src/store/spineLabelStore';
import drawMarker from './drawMarker';
import { ToolName } from '~/src/constants/toolName';
import { getEnabledElementControlled } from '../getEnabledElementControlled';

const getNewContext = csTools.importInternal('drawing/getNewContext');
const convertToVector3 = csTools.importInternal('util/convertToVector3');
const projectPatientPointToImagePlane = csTools.importInternal('util/projectPatientPointToImagePlane');

/**
 * Renders the active reference line.
 *
 * @export @public @method
 * @name renderActiveReferenceLine
 * @param  {Object} context        The canvas context.
 * @param  {Object} eventData      The data associated with the event.
 * @param  {HTMLElement} targetElement    The element on which to render the reference line.
 * @param  {HTMLElement} referenceElement The element referenced by the line.
 * @returns {void}
 */
export default (targetElement) => {
  const targetEnabledElement = getEnabledElementControlled(targetElement);
  const imageId = targetEnabledElement?.image?.imageId;

  // Make sure the images are actually loaded for the target and reference
  if (!targetEnabledElement || !imageId) {
    return;
  }

  const allMeasurements = csTools.globalImageIdSpecificToolStateManager.saveToolState();
  if (!allMeasurements) return;

  const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId || '');
  if (!imagePlane || !imagePlane.imagePositionPatient || !imagePlane.rowCosines || !imagePlane.columnCosines) {
    return;
  }
  const imagePosition = convertToVector3(imagePlane.imagePositionPatient);
  const row = convertToVector3(imagePlane.rowCosines);
  const column = convertToVector3(imagePlane.columnCosines);
  const normal = column.clone().cross(row.clone());
  const propagation = useSpineLabelStore.getState().propagation;
  Object.keys(allMeasurements)
    .filter((_imageId) => _imageId !== imageId)
    .forEach((_imageId) => {
      const toolState = csTools.globalImageIdSpecificToolStateManager.getImageIdToolState(
        _imageId,
        ToolName.SpineLabel,
      );
      if (!toolState?.data) return;

      for (let i = 0; i < toolState.data.length; i++) {
        const data = toolState.data[i];
        if (!data.patientPoint) continue;
        const patientPoint = convertToVector3(data.patientPoint);
        const distance = Math.abs(normal.clone().dot(imagePosition) - normal.clone().dot(data.patientPoint));
        if (distance > propagation) continue;
        const imagePoint = projectPatientPointToImagePlane(patientPoint, imagePlane);
        const context = getNewContext(targetEnabledElement.canvas);

        const markerPoints = {
          x: imagePoint.x,
          y: imagePoint.y,
        } as PixelCoordinate;
        drawMarker({ context, element: targetElement, data, markerPoints });
      }
    });
};
