export enum ToolbarOption {
  ANGLE = 'ANGLE',
  ANNOTATION = 'ANNOTATION',
  BIDIRECTIONAL = 'BIDIRECTIONAL',
  CINEMA = 'CINEMA',
  CLEAN = 'CLEAN',
  CROP = 'CROP',
  COBBANGLE = 'COBBANGLE',
  CROSSHAIR = 'CROSSHAIR',
  CTR = 'CTR',
  ELLIPSIS = 'ELLIPSIS',
  ERASE = 'ERASER',
  FLIP_HORIZONTAL = 'FLIP_HORIZONTAL',
  FLIP_VERTICAL = 'FLIP_VERTICAL',
  FREEHAND = 'FREEHAND',
  INVERT = 'INVERT',
  LENGTH = 'LENGTH',
  MPR_LENGTH = 'MPR_LENGTH',
  MAGNIFY = 'MAGNIFY',
  MEASURE = 'MEASURE',
  DEVIATION = 'DEVIATION',
  MODE = 'MODE',
  MOVE = 'MOVE',
  VIEW3D = 'VIEW3D',
  VIEW3D_EXIT = 'VIEW3D_EXIT',
  MPR = 'MPR',
  MPR_EXIT = 'MPR_EXIT',
  PREDEFINED_LEVELS = 'PREDEFINED_LEVELS',
  PROBE = 'PROBE',
  RECTANGLE = 'RECTANGLE',
  ROTATE = 'ROTATE',
  ROTATE_LEFT = 'ROTATE_LEFT',
  ROTATE_RIGHT = 'ROTATE_RIGHT',
  SLAB = 'SLAB',
  UNDO = 'UNDO',
  WWWC = 'Wwwc',
  ZOOM = 'ZOOM',
  COMPARE_REGIONS = 'COMPARE_REGIONS',
  REFERENCE_LINES = 'REFERENCE_LINES',
  BREAST_TOOLS = 'BREAST_TOOLS',
  COXOMETRY = 'COXOMETRY',
  GONIOMETRY = 'GONIOMETRY',
  NIPPLE_INJURY = 'NIPPLE_INJURY',
  SPINE_LABEL = 'SPINE_LABEL',
  SCROLL = 'SCROLL',
}
