var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { switchClass, inputClass, switchInnerClass, switchMarkerClass } from './styles';
export var SwitchSize;
(function (SwitchSize) {
    SwitchSize["sm"] = "sm";
})(SwitchSize || (SwitchSize = {}));
/**
 * Switch represents a physical switch that allows users to turn things on or off, where choosing an option results in an immediate action.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-04-29
 */
var Switch = function (_a) {
    var name = _a.name, _b = _a.defaultValue, defaultValue = _b === void 0 ? false : _b, _c = _a.size, size = _c === void 0 ? SwitchSize.sm : _c, _d = _a.isDisabled, isDisabled = _d === void 0 ? false : _d, className = _a.className, onChange = _a.onChange, restOfProps = __rest(_a, ["name", "defaultValue", "size", "isDisabled", "className", "onChange"]);
    var _e = useState(defaultValue), isOn = _e[0], setIsOn = _e[1];
    // Update value if default value changes
    useEffect(function () {
        setIsOn(defaultValue);
    }, [defaultValue]);
    var handleChange = function (event) {
        var _a;
        var checked = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.checked;
        setIsOn(checked);
        if (onChange && !isDisabled)
            onChange(checked);
    };
    return (React.createElement("div", { className: switchClass(className, size, isDisabled) },
        React.createElement("input", __assign({ className: inputClass(size), name: name, role: "switch", type: "checkbox", checked: isOn, "aria-checked": isOn, onChange: handleChange }, restOfProps)),
        React.createElement("div", { className: switchInnerClass(size, isOn) },
            React.createElement("div", { className: switchMarkerClass(size, isOn) }))));
};
export default Switch;
