import React from 'react';
import { Switch } from 'react-router-dom';
import { Spinner } from '@eva-pacs/ui';

import { ROUTES } from './constants';
import { WrapperRoute } from './wrapperRoute';

const routeList = ROUTES.map((route) => {
  const handleComponent = () => <route.component />;

  return (
    <WrapperRoute
      exact
      key={route.key}
      path={route.path}
      component={handleComponent}
      layout={route.layout}
      isPrivate={route.isPrivate}
      policy={route.policy}
    />
  );
});

/**
 *
 * @param routes
 * @returns
 */
export const renderRoutes = () => (
  <React.Suspense fallback={<Spinner />}>
    <Switch>{routeList}</Switch>
  </React.Suspense>
);
