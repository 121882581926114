import cn from 'classnames';
/* Class assignment */
export var actionClass = function (className, isActive, isMPREnabled) {
    return cn(className, 
    /* Action (base) */
    'flex flex-col items-center', 'pb-2 w-16', 'text-gray-50 hover:text-basic-white active:bg-gray-400', 'cursor-pointer select-none', 
    /* active */
    !isActive && 'pt-3', isActive && !isMPREnabled && 'bg-gray-700 border-b-2 border-primary-400 pt-2 active', isActive && isMPREnabled && 'bg-gray-700 border-b-2 border-feedback-negative-500 pt-2 active');
};
export var actionIconClass = function (className, isActive, isMPREnabled) {
    return cn(className, 
    /* Action icon (base) */
    'mb-1', 
    /* active */
    isActive && !isMPREnabled && 'text-basic-white', isActive && isMPREnabled && 'text-feedback-negative-500');
};
export var actionLabelClass = function (isActive) {
    return cn(
    /* Action label (base) */
    'text-tiny font-semi-bold', 
    /* active */
    isActive && 'text-basic-white');
};
