import React from 'react';
import { CompanyLogo } from '@evacenter/eden';

/**
 * Auth Layout
 * @author Alejandro Forero<alejoforero.af@gmail.com>
 * Created at 2021-06-07
 */

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <div className="gap-x-8 h-full text-basic-white bg-gray-800 lg:grid lg:grid-cols-2 lg:px-28 lg:py-14">
      <div id="left-section" className="flex flex-col items-baseline h-full">
        <i>
          <CompanyLogo />
        </i>
        {children}
      </div>
      <div
        id="right-section"
        className="hidden lg:flex lg:rounded-xl"
        style={{
          backgroundImage: `url("/assets/images/bg-login.png")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </div>
  );
};
