import {
  getAssignablePractitionersVariables,
  getAssignablePractitionersWithReferingPhysicianVariables,
} from '~/utils/practitionersApolloVariables';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { useGetPractitionerOptions } from './useGetPractitionerOptions';
import type { UseGetPractitionerOptionsProps } from './useGetPractitionerOptions';

interface UseGetAssignablePractitionerProps {
  /**
   * Set value as user ID instead practitioner id.
   */
  useUserId?: boolean;
  fetchPolicy?: UseGetPractitionerOptionsProps['fetchPolicy'];
  getReviewersList?: boolean;
}

export const useGetAssignablePractitionerOptions = ({
  useUserId = false,
  fetchPolicy,
  getReviewersList = false,
}: UseGetAssignablePractitionerProps) => {
  const hasFlag = useFeatureFlagsStore((store) => store.hasFlag);
  const includeAssignableReferingPhysician = hasFlag(FeatureFlags.IncludeReferringAssigned);
  let assignablePractitionersVariables = includeAssignableReferingPhysician
    ? getAssignablePractitionersWithReferingPhysicianVariables()
    : getAssignablePractitionersVariables();

  if (getReviewersList) {
    assignablePractitionersVariables = {
      ...assignablePractitionersVariables,
    };
  }

  const { loading, practitionersOptions: options } = useGetPractitionerOptions({
    useUserId,
    fetchPolicy,
    ...assignablePractitionersVariables,
    getReviewersList,
  });

  return {
    options,
    loading,
  };
};
