import cornerstoneMath from 'cornerstone-math';
import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

const getROITextBoxCoords = csTools.importInternal('util/getROITextBoxCoords');
const drawLinkedTextBox = csTools.importInternal('drawing/drawLinkedTextBox');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const setShadow = csTools.importInternal('drawing/setShadow');
const drawRect = csTools.importInternal('drawing/drawRect');
const draw = csTools.importInternal('drawing/draw');

import { PIXELS_NEAR_TOOL, PIXELS_NEAR_TOOL_TOUCH } from '~/constants';

/**
 * @public
 * @class RectangleCrop
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing rectangular regions of interest to crop the visible version of the dicom serie
 * @extends Tools.Annotation.RectangleRoiTool
 * @author Jesus Bossa <x@jesusbossa.dev>
 * Created at 2022-03-16
 */
export default class CustomRectangleCrop extends csTools.RectangleRoiTool {
  name = 'RectangleCrop';
  configuration!: {
    showHounsfieldUnits: any;
    drawHandles: true;
    drawHandlesOnHover: false;
    hideHandlesIfMoving: false;
    renderDashed: false;
  };
  constructor(props) {
    super(props);
  }

  /**
   * Override original _calculateRectangleStats of cornerstone-tools
   * @param {*} sp
   * @param {*} rectangle
   * @returns {{ count, number, mean: number,  variance: number,  stdDev: number,  min: number,  max: number }}
   */
  _calculateRectangleStats(sp, rectangle) {
    let count = 0;
    let index = 0;
    let min = sp ? sp[0] : null;
    let max = sp ? sp[0] : null;

    for (let y = rectangle.top; y < rectangle.top + rectangle.height; y++) {
      for (let x = rectangle.left; x < rectangle.left + rectangle.width; x++) {
        min = Math.min(min, sp[index]);
        max = Math.max(max, sp[index]);
        count++;
        index++;
      }
    }

    if (count === 0) {
      return {
        count,
        mean: 0.0,
        variance: 0.0,
        stdDev: 0.0,
        min: 0.0,
        max: 0.0,
      };
    }
  }

  /**
   * Override original _calculateStats of cornerstone-tools
   * @param {*} image
   * @param {*} element
   * @param {*} handles
   * @param {*} modality
   * @param {*} pixelSpacing
   * @returns {Object} The Stats object
   */
  _calculateStats(image, element, handles, modality, pixelSpacing) {
    // Retrieve the bounds of the rectangle in image coordinates
    const roiCoordinates = this._getRectangleImageCoordinates(handles.start, handles.end);

    // Retrieve the array of pixels that the rectangle bounds cover
    const pixels = cornerstone.getPixels(
      element,
      roiCoordinates.left,
      roiCoordinates.top,
      roiCoordinates.width,
      roiCoordinates.height,
    );

    // Calculate the mean & standard deviation from the pixels and the rectangle details
    const roiMeanStdDev = this._calculateRectangleStats(pixels, roiCoordinates);

    let meanStdDevSUV;

    if (modality === 'PT') {
      meanStdDevSUV = {
        mean: csTools.calculateSUV(image, roiMeanStdDev?.mean, true) || 0,
        stdDev: csTools.calculateSUV(image, roiMeanStdDev?.stdDev, true) || 0,
      };
    }

    // Calculate the image area from the rectangle dimensions and pixel spacing
    const area =
      roiCoordinates.width *
      (pixelSpacing.colPixelSpacing || 1) *
      (roiCoordinates.height * (pixelSpacing.rowPixelSpacing || 1));

    return {
      area: area || 0,
      count: roiMeanStdDev?.count || 0,
      mean: roiMeanStdDev?.mean || 0,
      variance: roiMeanStdDev?.variance || 0,
      stdDev: roiMeanStdDev?.stdDev || 0,
      min: roiMeanStdDev?.min || 0,
      max: roiMeanStdDev?.max || 0,
      meanStdDevSUV,
    };
  }

  /**
   * Override original updateCachedStats of cornerstone-tools
   * @param {*} image
   * @param {*} element
   * @param {*} data
   * @returns {Void}
   */
  updateCachedStats(image, element, data) {
    const seriesModule = cornerstone.metaData.get('generalSeriesModule', image.imageId) || {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    const stats = this._calculateStats(image, element, data.handles, modality, pixelSpacing);

    data.cachedStats = stats;
    data.invalidated = false;
  }

  /**
   * Override original pointNearTool of cornerstone-tools
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @param {*} interactionType
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles = data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) csTools.logger.warn(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);
    if (!validParameters || data.visible === false) return false;

    const distance = interactionType === 'mouse' ? PIXELS_NEAR_TOOL : PIXELS_NEAR_TOOL_TOUCH;
    const startCanvas = cornerstone.pixelToCanvas(element, data.handles.start);
    const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x),
      top: Math.min(startCanvas.y, endCanvas.y),
      width: Math.abs(startCanvas.x - endCanvas.x),
      height: Math.abs(startCanvas.y - endCanvas.y),
    };

    const distanceToPoint = cornerstoneMath.rect.distanceToPoint(rect, coords);

    return distanceToPoint < distance;
  }

  /**
   * Override original _getRectangleImageCoordinates of cornerstone-tools
   * @param {*} startHandle
   * @param {*} endHandle
   * @returns {{ left: number, top: number, width: number, height: number}}
   */
  _getRectangleImageCoordinates(startHandle, endHandle) {
    return {
      left: Math.min(startHandle.x, endHandle.x),
      top: Math.min(startHandle.y, endHandle.y),
      width: Math.abs(startHandle.x - endHandle.x),
      height: Math.abs(startHandle.y - endHandle.y),
    };
  }

  /**
   * Override original _findTextBoxAnchorPoints of cornerstone-tools
   * @param {*} startHandle
   * @param {*} endHandle
   * @returns {Array.<{x: number, y: number}>}
   */
  _findTextBoxAnchorPoints(startHandle, endHandle) {
    const { left, top, width, height } = this._getRectangleImageCoordinates(startHandle, endHandle);

    return [
      {
        // Top middle point of rectangle
        x: left + width / 2,
        y: top,
      },
      {
        // Left middle point of rectangle
        x: left,
        y: top + height / 2,
      },
      {
        // Bottom middle point of rectangle
        x: left + width / 2,
        y: top + height,
      },
      {
        // Right middle point of rectangle
        x: left + width,
        y: top + height / 2,
      },
    ];
  }

  /**
   * Override original _getUnit of cornerstone-tools
   * @param {*} modality
   * @param {*} showHounsfieldUnits
   * @returns {"HU" : ""}
   */
  _getUnit(modality, showHounsfieldUnits) {
    return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : '';
  }

  /**
   * Override original renderToolData of cornerstone-tools
   * @param {*} evt
   */
  renderToolData(evt) {
    const toolData = csTools.getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { image, element } = eventData;
    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    const { drawHandlesOnHover, hideHandlesIfMoving, renderDashed } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);

    // Meta
    const seriesModule = cornerstone.metaData.get('generalSeriesModule', image.imageId) || {};

    // Pixel Spacing
    const modality = seriesModule.modality;

    draw(context, (context) => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = csTools.toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const rectOptions = { color, lineDash: undefined };

        if (renderDashed) {
          rectOptions.lineDash = lineDash;
        }

        // Draw
        drawRect(
          context,
          element,
          data.handles.start,
          data.handles.end,
          rectOptions,
          'pixel',
          data.handles.initialRotation,
        );

        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, handleOptions);
        }

        const hasTextMoved = data.handles.textBox.hasMoved;
        if (!hasTextMoved) {
          const defaultCoords = getROITextBoxCoords(eventData.viewport, data.handles);

          Object.assign(data.handles.textBox, defaultCoords);
        }

        const textBoxAnchorPoints = (handles) => this._findTextBoxAnchorPoints(handles.start, handles.end);
        const textBoxContent = [];
        const textBoxSpacingValue = 10;

        data.unit = this._getUnit(modality, this.configuration.showHounsfieldUnits);

        drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          textBoxContent,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          textBoxSpacingValue,
          true,
        );
      }
    });
  }
}
