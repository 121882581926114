import { PatientGender, PatientType } from '@eva-pacs/client';
import { Patient } from '~/src/types/Patient';

/**
 * Generates an object model to match `PatientType` which represents the patient.
 * @author Sergio Ruiz<sergio.ruiz@evacenter.com>
 * Created 2022-08-19
 */
export const basicPatientDTO = (patient: PatientType): Patient => {
  const { phoneNumber } = patient;

  return {
    id: patient?.id,
    identifier: patient?.identifier,
    identifierEditable: patient?.identifierEditable as string,
    fullName: patient?.fullName as string,
    gender: patient?.gender as PatientGender,
    birthDate: patient?.birthDate,
    phone: phoneNumber || '',
    phoneCountryId: patient?.phoneCountry?.id,
    phoneCountryIsoCode: patient?.phoneCountry?.isoCode,
    email: patient?.email as string,
  };
};
