import { StudyWorklistType, useGetWorklistStudyByIdLazyQuery } from '@eva-pacs/client';

import { useStudyListStore } from '~/src/store';

import { useWorklistQueries } from './useWorklistQueries';
import { useProcessStudyForInsertion } from '../study/useProcessStudyForInsertion';
import { useStudyActions } from '../study/useStudyActions';
import { useRemoveStudy } from '../study/useRemoveStudy';
import { NotificationType, useNotificationsStore } from '~/src/store/notifications-store';
import { StudyUpdateEvent, StudyUpdateType } from '~/components/StudyList/useStudyListUpdates';
import { studyDTO } from '~/src/dtos/study/studyDTO';
import { Study } from '~/src/types/Study';
import shallow from 'zustand/shallow';

export enum SortByValues {
  STATUS_ASC = 'status,asc',
  STATUS_DESC = 'status,desc',
  PATIENT_NAME_ASC = 'patient__fullName,asc',
  PATIENT_NAME_DESC = 'patient__fullName,desc',
  MODALITIES_ASC = 'modalities,asc',
  MODALITIES_DESC = 'modalities,desc',
  DESCRIPTION_ASC = 'dicomDescription,asc',
  DESCRIPTION_DESC = 'dicomDescription,desc',
  FACILITY_ASC = 'facility,asc',
  FACILITY_DESC = 'facility,desc',
  PRIORITY_ASC = 'urgencyLevel,asc',
  PRIORITY_DESC = 'urgencyLevel,desc',
  CAPTURED_DATE_ASC = 'dicomDateTimeEditable,asc',
  CAPTURED_DATE_DESC = 'dicomDateTimeEditable,desc',
  CREATED_AT_ASC = 'createdAt,asc',
  CREATED_AT_DESC = 'createdAt,desc',
}

export const useUpdateStudy = () => {
  const [getStudies, getTableSortBy, getStudiesBeforeUpdate] = useStudyListStore(
    (state) => [state.getStudies, state.getTableSortBy, state.getStudiesBeforeUpdate],
    shallow,
  );
  const { performRemoveStudy, performUpdateStudy } = useStudyActions();
  const { processRemoveStudy } = useRemoveStudy();
  const { getWorklistPositions } = useWorklistQueries();
  const { setNotifications } = useNotificationsStore();
  const { insertNewStudy } = useProcessStudyForInsertion();
  const [getWorklistStudy] = useGetWorklistStudyByIdLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (!response.studyWorklist?.results) return;
      const newStudy = response.studyWorklist.results[0];
      if (!newStudy) return;
      const currentStudies = getStudiesBeforeUpdate();
      const filteredCurrentStudy = currentStudies[newStudy.id];
      const parsedNewStudy = studyDTO(newStudy as StudyWorklistType);
      // If the updated field is not one of sortBy or urgencyLevel, don't ask for accommodation positions.
      if (!shouldStudyBeAccommodated(filteredCurrentStudy, parsedNewStudy)) {
        // Update only the study without accommodating it.
        performUpdateStudy(newStudy.id, parsedNewStudy);
        return;
      }

      acommodateStudy(newStudy.id, newStudy as StudyWorklistType);
    },
    onError: () => {
      setNotifications<StudyUpdateEvent>({
        type: NotificationType.StudyListUpdates,
        payload: {
          ['study-id']: StudyUpdateType.error,
        },
      });
    },
  });

  const shouldStudyBeAccommodated = (currentStudy: Study, newStudy: Study) => {
    const currentSortBy = getTableSortBy();
    const diff = diffProperties(currentStudy, newStudy);
    const keys = Object.keys(diff);
    if (!currentSortBy) {
      const keys = Object.keys(diff);
      return keys.includes('urgencyLevel');
    }

    const sortByToKeyMap = {
      [SortByValues.PRIORITY_ASC]: 'urgencyLevel',
      [SortByValues.PRIORITY_DESC]: 'urgencyLevel',
      [SortByValues.STATUS_ASC]: 'studyStatus',
      [SortByValues.STATUS_DESC]: 'studyStatus',
      [SortByValues.PATIENT_NAME_ASC]: 'patient',
      [SortByValues.PATIENT_NAME_DESC]: 'patient',
      [SortByValues.MODALITIES_ASC]: 'modalities',
      [SortByValues.MODALITIES_DESC]: 'modalities',
      [SortByValues.DESCRIPTION_ASC]: 'dicomDescription',
      [SortByValues.DESCRIPTION_DESC]: 'dicomDescription',
      [SortByValues.FACILITY_ASC]: 'facilityName',
      [SortByValues.FACILITY_DESC]: 'facilityName',
      [SortByValues.CAPTURED_DATE_ASC]: 'dicomDateTime',
      [SortByValues.CAPTURED_DATE_DESC]: 'dicomDateTime',
      [SortByValues.CREATED_AT_ASC]: 'createdAt',
      [SortByValues.CREATED_AT_DESC]: 'createdAt',
    };

    return keys.includes(sortByToKeyMap[currentSortBy]);
  };

  const diffProperties = (study1: Study, study2: Study) => {
    const diff: Partial<Study> = {};

    for (const key in study1) {
      if (key === 'patient') {
        if (study1.patient?.fullName !== study2.patient?.fullName) {
          diff[key] = study2[key];
        }
      } else if (study1[key] !== study2[key]) {
        diff[key] = study2[key];
      }
    }

    return diff;
  };

  const acommodateStudy = async (studyId: string, newStudy: StudyWorklistType) => {
    const positions = await getWorklistPositions(studyId);
    const studies = getStudies();

    if (!positions) return;
    const nextId = positions?.nextId;
    const prevId = positions?.prevId;

    const totalStudies = Object.keys(studies).length;

    // We need to avoid edge case where we have only one study in the list.
    if (totalStudies <= 1) return;

    if (!studies[nextId] && !studies[prevId]) {
      processRemoveStudy(studyId);
      return;
    }

    // We need to remove the current item in the store in order to insert it in where it belongs.
    performRemoveStudy(studyId);

    // Inserting updated study.
    insertNewStudy({ nextId, prevId, newStudy, enableNewStudyAnimation: false });
  };

  const processUpdateWorklistStudy = async (studyId: string) => {
    const studies = getStudies();

    // Only request study information if we are going to update it in the store, if not
    // we are not going to request information because we don't have anything to update.
    if (!studies[studyId]) return;

    getWorklistStudy({
      variables: {
        idsIn: [studyId],
      },
    });
  };

  const processUpdateStudyListStudy = (studyId: string) => {
    const studies = getStudies();

    // Only request study information if we are going to update it in the store, if not
    // we are not going to request information because we don't have anything to update.
    if (!studies[studyId]) return;

    // TODO: add queries for study list.
  };

  return {
    processUpdateWorklistStudy,
    processUpdateStudyListStudy,
  };
};
