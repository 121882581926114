export const createMammographyReferencePoint = (incomingReference) => ({
  visible: true,
  active: true,
  invalidated: true,
  remote: incomingReference,
  handles: {
    end: {
      x: incomingReference.point.x,
      y: incomingReference.point.y,
      highlight: true,
      active: true,
    },
  },
});
