import cn from 'classnames';
import { TooltipColor, TooltipPlacement, TooltipSize } from './';
/* Class assignment */
export var tooltipContainerClass = function (className) {
    return cn(className, 
    /* Tooltip container (base) */
    'inline-flex relative w-fit-content select-none', 'shadow-md z-20');
};
export var tooltipClass = function (size, color) {
    return cn(
    /* Tooltip (base) */
    'rounded-md text-center max-w-xs', 
    /* Tooltip Size */
    size === TooltipSize.sm && 'text-caption py-1.5 px-2.5', size === TooltipSize.md && 'text-label-md py-2 px-4', 
    /* Tooltip Color */
    color === TooltipColor.black && 'bg-basic-black text-basic-white');
};
export var arrowContainerClass = function (placement) {
    return cn(
    /* Popover arrow container (base) */
    'absolute w-2 h-2 bg-basic-black invisible', 
    /* arrow placement */
    placement.includes(TooltipPlacement.top) && '-bottom-1', placement.includes(TooltipPlacement.bottom) && '-top-1', placement.includes(TooltipPlacement.left) && '-right-1', placement.includes(TooltipPlacement.right) && '-left-1');
};
export var arrowClass = cn(
/* Popover arrow (base) */
'bg-basic-black absolute w-2 h-2 visible transform rotate-45');
