import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
const calculateSUV = csTools.importInternal('util/calculateSUV');
const getLogger = csTools.importInternal('util/getLogger');
const getRGBPixels = csTools.importInternal('util/getRGBPixels');
const throttle = csTools.importInternal('util/throttle');
const draw = csTools.importInternal('drawing/draw');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawTextBox = csTools.importInternal('drawing/drawTextBox');
const logger = getLogger('tools:annotation:ProbeTool');

/**
 * @public
 * @class ProbeTool
 * @memberof Tools.Annotation
 * @classdesc Tool which provides a probe of the image data at the
 * desired position.
 * @extends Tools.Annotation.ProbeTool
 */
export default class CustomProbeTool extends csTools.ProbeTool {
  throttledUpdateCachedStats: any;
  name;
  constructor(props = {}) {
    super(props);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
    if (!goodEventData) {
      logger.error(`required eventData not supplied to tool ${super.name}'s createNewMeasurement`);
      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const hasEndHandle = data && data.handles && data.handles.end;
    const validParameters = hasEndHandle;

    if (!validParameters) logger.warn(`invalid parameters supplied to tool ${super.name}'s pointNearTool`);
    if (!validParameters || data.visible === false) return false;

    const probeCoords = cornerstone.pixelToCanvas(element, data.handles.end);

    return cornerstoneMath.point.distance(probeCoords, coords) < 5;
  }

  updateCachedStats(image, element, data) {
    const x = Math.round(data.handles.end.x);
    const y = Math.round(data.handles.end.y);

    const stats: any = {};

    if (x >= 0 && y >= 0 && x < image.columns && y < image.rows) {
      stats.x = x;
      stats.y = y;

      if (image.color) {
        stats.storedPixels = getRGBPixels(element, x, y, 1, 1);
      } else {
        stats.storedPixels = cornerstone.getStoredPixels(element, x, y, 1, 1);
        stats.sp = stats.storedPixels[0];
        stats.mo = stats.sp * image.slope + image.intercept;
        stats.suv = calculateSUV(image, stats.sp);
      }
    }

    data.cachedStats = stats;
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, renderDashed } = super.configuration;
    const toolData = csTools.getToolState(evt.currentTarget, this.name);
    if (!toolData) return;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const { image, element } = eventData;
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) continue;

      draw(context, (context) => {
        const color = csTools.toolColors.getColorIfActive(data);

        if (super.configuration.drawHandles) {
          // Draw the handles
          const handleOptions: any = {
            color,
            handleRadius: handleRadius || 2,
          };

          if (renderDashed) handleOptions.lineDash = lineDash;

          drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        let str;

        const { x, y, storedPixels, mo, suv } = data.cachedStats;

        if (x >= 0 && y >= 0 && x < image.columns && y < image.rows) {
          if (image.color) {
            str = `R: ${storedPixels[0]} G: ${storedPixels[1]} B: ${storedPixels[2]}`;
          } else {
            // Draw text
            str = `${parseFloat(mo.toFixed(3))}`;
            if (suv) str += ` SUV: ${parseFloat(suv.toFixed(3))}`;
          }

          // Coords for text
          const coords: any = {
            // Translate the x/y away from the cursor
            x: data.handles.end.x + 3,
            y: data.handles.end.y - 3,
          };
          const textCoords = cornerstone.pixelToCanvas(eventData.element, coords);

          drawTextBox(context, str, textCoords.x, textCoords.y, color);
        }
      });
    }
  }
}
