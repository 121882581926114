import cornerstone from 'cornerstone-core';
import { MODALITY_TYPES } from '@eva-pacs/core';
import {
  TAG_STUDY_LATERALITY,
  TAG_VIEW_POSITION,
  TAG_MODALITY,
  TAG_STUDY_IMAGE_LATERALITY,
  COMMON_MASTOGRAPHY_STUDIES_SUFFIXES,
  TAG_VIEW_CODE_SEQUENCE,
  VIEW_CODE_SEQUENCE,
} from '~/constants';
import { getSequenceStringMetadataTag } from '~/utils/cornestone/getMetadataTag';

export const isAMammographyImage = (imageId: string) => {
  const viewportImage = cornerstone.imageCache.imageCache[imageId];
  if (!viewportImage?.image) return false;
  const modality = viewportImage.image.data?.string(TAG_MODALITY);

  if (modality === MODALITY_TYPES.MG) return true;
  if (modality !== MODALITY_TYPES.CR) return false;

  const imageLaterality = viewportImage.image.data?.string(TAG_STUDY_IMAGE_LATERALITY);
  const viewPosition = (viewportImage.image.data?.string(TAG_VIEW_POSITION) || '') as string;
  const [viewCodeSequence] = getSequenceStringMetadataTag(imageId, TAG_VIEW_CODE_SEQUENCE);

  const hasMammographyViewPosition = COMMON_MASTOGRAPHY_STUDIES_SUFFIXES.find((suffix) =>
    viewPosition?.includes(suffix),
  );
  const hasCodeSequenceMammography = viewCodeSequence?.find(
    (codeSequence: string) =>
      codeSequence.toUpperCase().includes(VIEW_CODE_SEQUENCE.MLO) ||
      codeSequence.toUpperCase().includes(VIEW_CODE_SEQUENCE.CC),
  );
  const laterality = viewportImage.image.data?.string(TAG_STUDY_LATERALITY);
  const mastographyLaterality = laterality || imageLaterality;

  if (mastographyLaterality && (hasMammographyViewPosition || hasCodeSequenceMammography)) return true;
  return false;
};
