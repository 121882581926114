import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
const drawLine = csTools.importInternal('drawing/drawLine');
const draw = csTools.importInternal('drawing/draw');
const drawTextBox = csTools.importInternal('drawing/drawTextBox');

const LINE_SIZE = 3;

/**
 * Draw two paralel lines to a point

 * @name drawMammographyRefereceLines
 *
 * @param {Object} context - The Viewport context
 * @param {Object} data - The source element for a click event
 * @param {String} markerPoints - Point coordinates
 * @returns {void}
 */
export default ({ context, element, data, markerPoints }) => {
  const color = csTools.toolColors.getColorIfActive(data);
  draw(context, (context) => {
    drawLine(
      context,
      element,
      { x: markerPoints.x + LINE_SIZE, y: markerPoints.y - LINE_SIZE },
      { x: markerPoints.x - LINE_SIZE, y: markerPoints.y + LINE_SIZE },
      { color },
    );

    drawLine(
      context,
      element,
      { x: markerPoints.x + LINE_SIZE, y: markerPoints.y + LINE_SIZE },
      { x: markerPoints.x - LINE_SIZE, y: markerPoints.y - LINE_SIZE },
      { color },
    );

    // Coords for text
    const coords: any = {
      // Translate the x/y away from the cursor
      x: markerPoints.x + 14,
      y: markerPoints.y - LINE_SIZE * 4,
    };
    const textCoords = cornerstone.pixelToCanvas(element, coords);

    drawTextBox(context, data.text, textCoords.x, textCoords.y, color);
  });
};
