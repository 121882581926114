import create, { SetState } from 'zustand';
import produce from 'immer';
import type { ExternalLibIds } from '../constants/libs';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

export type LibPromise = Promise<ExternalLibIds>;
/**
 * Libs Store
 */
export interface LibManagerStore {
  libsLoaded: Record<ExternalLibIds, boolean>;
  promises: Record<ExternalLibIds, LibPromise | null>;
  addLibLoaded: (id: ExternalLibIds) => void;
  addLibPromise: (id: ExternalLibIds, promise: LibPromise) => void;
  removeLibPromise: (id: ExternalLibIds) => void;
}

const libManagerStore = (set: SetState<LibManagerStore>): LibManagerStore => ({
  promises: {} as Record<ExternalLibIds, LibPromise>,
  libsLoaded: {} as Record<ExternalLibIds, boolean>,
  addLibLoaded: (id) => {
    return set((state) => {
      if (state.libsLoaded[id]) return;
      state.libsLoaded[id] = true;
    });
  },
  addLibPromise: (id, promise) => {
    return set((state) => {
      if (state.promises[id]) return;
      state.promises[id] = promise;
    });
  },
  removeLibPromise: (id) => {
    return set((state) => {
      if (!state.promises[id]) return;
      state.promises[id] = null;
    });
  },
});

export const useLibManagerStore = create<LibManagerStore>(immer(libManagerStore));
