import React from 'react';
import cn from 'classnames';
import Icon, { IconCatalog } from '../../Icon';
/**
 * Sorting icon (Table)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-29
 */
export var SortingIcon = function (_a) {
    var columnId = _a.columnId, _b = _a.isSorted, isSorted = _b === void 0 ? false : _b, _c = _a.isSortedDesc, isSortedDesc = _c === void 0 ? false : _c, className = _a.className;
    var sortingClass = cn(className, 'absolute inline-flex flex-col text-gray-100 -space-y-4');
    if (columnId === 'actionColumn')
        return null;
    if (isSorted) {
        if (isSortedDesc) {
            return (React.createElement("span", { className: sortingClass, style: { bottom: '7px' } },
                React.createElement(Icon, { icon: IconCatalog.caretDown, width: "24", height: "24" })));
        }
        else {
            return (React.createElement("span", { className: sortingClass, style: { bottom: '7px' } },
                React.createElement(Icon, { icon: IconCatalog.caretUp, width: "24", height: "24" })));
        }
    }
    return (React.createElement("span", { className: sortingClass, style: { bottom: '3px' } },
        React.createElement(Icon, { icon: IconCatalog.caretUp, width: "24", height: "24" }),
        React.createElement(Icon, { icon: IconCatalog.caretDown, width: "24", height: "24" })));
};
