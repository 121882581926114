var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { textareaClass } from './styles';
export var TextareaSize;
(function (TextareaSize) {
    TextareaSize["xs"] = "xs";
    TextareaSize["sm"] = "sm";
    TextareaSize["md"] = "md";
    TextareaSize["lg"] = "lg";
})(TextareaSize || (TextareaSize = {}));
/**
 * Generic text area component to use in forms.
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-07-26
 */
export var Textarea = function (_a) {
    var className = _a.className, _b = _a.textareaSize, textareaSize = _b === void 0 ? TextareaSize.md : _b, name = _a.name, restOfprops = __rest(_a, ["className", "textareaSize", "name"]);
    return React.createElement("textarea", __assign({ className: textareaClass(className, textareaSize), name: name }, restOfprops));
};
