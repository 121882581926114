import React from 'react';
import { Navbar } from '~/components/Navbar';
import { useUserProductPermission } from '~/src/hooks/useUserProductPermission';
import { MembershipExpiredAlert } from '~/components/MembershipExpiredAlert/MembershipExpiredAlert';

/**
 * Global Layout
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-07-19
 */

export const GlobalLayout: React.FC = ({ children }) => {
  const { hasAdminPanelAccess, hasPACSAccess } = useUserProductPermission();

  return (
    <div className="relative h-screen">
      <MembershipExpiredAlert />
      <Navbar className="z-10" showAdminTab={hasAdminPanelAccess} showStudyListTab={hasPACSAccess} />
      {children}
    </div>
  );
};
