import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useLanguage } from '@eva-pacs/app-pacs/src/hooks/useLanguage';
import { BaseTooltip, CopyTooltip, DynamicTooltip, Icon, IconCatalog, SimpleTooltip } from '@evacenter/eden';
var DEFAULT_TRUNCATE_LENGTH = 30;
/**
 * Patient Cell (Table)
 * @author Carlos Cuatin<carlos.cuatin@edenmed.com>
 * Created at 2024-01-23
 */
export var PatientCell = function (_a) {
    var title = _a.title, titleHref = _a.titleHref, className = _a.className, description = _a.description, titleOnClick = _a.titleOnClick, patientLabel = _a.patientLabel, descriptionPrefix = _a.descriptionPrefix, _b = _a.criticalValues, criticalValues = _b === void 0 ? [] : _b, _c = _a.titleExternalLink, titleExternalLink = _c === void 0 ? false : _c;
    var currentLanguage = useLanguage().currentLanguage;
    var isTruncated = function (text) {
        if (!text)
            return false;
        return (text === null || text === void 0 ? void 0 : text.length) > DEFAULT_TRUNCATE_LENGTH;
    };
    var isTitleTruncated = isTruncated(title);
    var isIdTruncated = isTruncated(description || '');
    var renderTitle = function () {
        if (titleHref) {
            return titleExternalLink ? (React.createElement("a", { className: "e-underline e-truncate", href: titleHref, target: "_blank", rel: "noreferrer" }, title)) : (React.createElement(Link, { className: "e-underline e-truncate", to: titleHref }, title));
        }
        if (titleOnClick)
            return (React.createElement("button", { className: "e-text-left e-underline e-truncate", onClick: titleOnClick }, title));
        return React.createElement("span", { className: "e-truncate" }, title);
    };
    var classes = {
        container: cn(className, 'e-flex e-flex-col'),
        id: cn('e-inline-block e-truncate', {
            'e-w-full': isIdTruncated,
        }),
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: "e-inline-flex e-items-center e-mb-1 e-text-basic-white hover:e-text-light-gray-600 e-text-caption" },
            criticalValues.length > 0 && (React.createElement(DynamicTooltip, { delayDuration: 0, content: criticalValues.map(function (item, index) { return (React.createElement("div", { key: index, className: "e-flex e-flex-col" },
                    React.createElement(BaseTooltip.Title, null, item.title),
                    React.createElement(BaseTooltip.Description, null, item.description),
                    index !== criticalValues.length - 1 && React.createElement("hr", { className: "e-border-gray-200 e-mt-1 e-mb-1" }))); }) },
                React.createElement("div", null,
                    React.createElement(Icon, { width: 12, height: 12, icon: IconCatalog.warningTriangle, className: "e-mr-1.5 e-text-error-500 hover:e-cursor-pointer" })))),
            isTitleTruncated ? (React.createElement(SimpleTooltip, { description: title, delayDuration: 0 }, renderTitle())) : (renderTitle())),
        description && (React.createElement("div", { className: "e-inline-flex e-text-light-gray-600 hover:e-text-light-gray-600 e-text-caption e-font-regular" },
            React.createElement(CopyTooltip, { className: classes.id, textToCopy: description, locale: currentLanguage, title: isIdTruncated ? descriptionPrefix : undefined, description: isIdTruncated ? description : undefined },
                descriptionPrefix,
                description))),
        React.createElement("span", { className: "e-text-light-gray-600 e-text-caption e-font-regular" }, patientLabel)));
};
