var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { AvailableLanguages, DEFAULT_LANGUAGE_ABBREVIATION } from '@eva-pacs/core';
import locales from './locales';
var fallbackLng = [DEFAULT_LANGUAGE_ABBREVIATION];
var availableLanguages = Object.keys(AvailableLanguages);
i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
    debug: false,
    fallbackLng: fallbackLng,
    preload: availableLanguages,
    supportedLngs: availableLanguages,
    load: 'languageOnly',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: __assign({}, locales),
});
export default i18n;
export { i18n };
