import React, { useEffect } from 'react';

import { useReportAndPatientFromStudyQuery } from '@eva-pacs/client';
import { usePreviewReportMutation } from '@eva-pacs/client';
import { PreviewPDF, Spinner } from '@eva-pacs/ui';

import { useErrorHandler } from '~/utils/appHelpers';

export interface PreviewPDFWithoutSignatureWrapperProps {
  /**
   * Study id
   */
  studyId: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Show the send button
   */
  showSendButton?: boolean;

  /**
   * Show the print button
   */
  showPrintButton?: boolean;

  /**
   * Show the download button
   */
  showDownloadButton?: boolean;
}

/**
 * Use this component for user to preview unsigned PDF
 * @author Antonio Torres<toniotgz@gmail.com>
 * Created at 2022-09-02
 */
export const PreviewPDFWithoutSignatureWrapper: React.FC<PreviewPDFWithoutSignatureWrapperProps> = ({
  studyId,
  showSendButton = false,
  showPrintButton = false,
  showDownloadButton = false,
}) => {
  const { handleError } = useErrorHandler();

  const { ...reportFromStudy } = useReportAndPatientFromStudyQuery({
    variables: {
      id: studyId,
    },
    onError: (error) => handleError({ logMessage: error }),
    fetchPolicy: 'no-cache',
  });

  // Create ISO file
  const [previewReportMutation, { data: previewResponse, loading: isPreviewLoading }] = usePreviewReportMutation({
    onError: (error) => handleError({ logMessage: error }),
    fetchPolicy: 'no-cache',
  });

  const [firstReport] = reportFromStudy.data?.study?.reports ?? [];

  const reportId = firstReport?.id;

  useEffect(() => {
    if (reportId) previewReportMutation({ variables: { id: reportId } });
  }, [previewReportMutation, reportId]);

  const pdfPreviewUrl = previewResponse?.previewReport.pdfPreviewUrl ?? '';

  if (!pdfPreviewUrl || isPreviewLoading) return <Spinner />;

  return (
    <div>
      <PreviewPDF
        pdfFile={pdfPreviewUrl}
        pdfLetterheadFile={pdfPreviewUrl}
        showDownloadButton={showDownloadButton}
        showPrintButton={showPrintButton}
        showSendButton={showSendButton}
      />
    </div>
  );
};
