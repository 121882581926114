import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import { ToolName } from '~/src/constants/toolName';
import { getEnabledElementControlled } from './getEnabledElementControlled';
import { LINE_COLOR } from '~/constants';

const BaseTool = csTools.importInternal('base/BaseTool');
const draw = csTools.importInternal('drawing/draw');
const drawLine = csTools.importInternal('drawing/drawLine');
const getNewContext = csTools.importInternal('drawing/getNewContext');
/**
 *
 *
 * @public
 * @class Test
 * @memberof Tools
 *
 * @extends Tools.Base.BaseTool
 */
export default class ExternalReferenceLinesTool extends BaseTool {
  initialConfiguration;
  name;
  constructor(configuration = {}) {
    const defaultConfig = {
      name: ToolName.ExternalReferenceLines,
      configuration: {},
      mixins: ['enabledOrDisabledBinaryTool'],
    };
    const initialConfiguration = Object.assign(defaultConfig, configuration);

    super(initialConfiguration);

    this.initialConfiguration = initialConfiguration;
  }

  enabledCallback(element) {
    this.forceImageUpdate(element);
  }

  disabledCallback(element) {
    this.forceImageUpdate(element);
  }

  forceImageUpdate(element) {
    const enabledElement = getEnabledElementControlled(element);

    if (!enabledElement?.image) return;

    cornerstone.updateImage(element);
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { data } = csTools.getToolState(evt.currentTarget, this.name) || { data: [] };
    if (data.length < 1) return;

    const referenceLine = data[0].referenceLine;

    const context = getNewContext(eventData.canvasContext.canvas);
    // Draw the referenceLines
    context.setTransform(1, 0, 0, 1, 0, 0);

    draw(context, (context) => {
      drawLine(context, eventData.element, referenceLine.start, referenceLine.end, { color: LINE_COLOR });
    });
  }
}
