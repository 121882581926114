import cornerstoneTools from 'cornerstone-tools';
import { LINE_COLOR } from '~/constants';
/**
 * Deactivate all the measurements
 *
 * @param toolState
 */
export const disableAllMeasurements = () => {
  const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();

  Object.keys(toolState).forEach((imageId) => {
    Object.keys(toolState[imageId]).forEach((toolName) => {
      if (!toolState[imageId][toolName]) return;
      toolState[imageId][toolName].data.forEach((data) => {
        data.active = false;
        data.color = LINE_COLOR;
      });
    });
  });
};
