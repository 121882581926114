import React from 'react';
import { DicomPrinterType } from '@eva-pacs/client';
import { setMaxHeightByOptions } from '@eva-pacs/core';
import { Button, ButtonType, Icon, IconCatalog } from '@eva-pacs/ui';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { optionClass } from './styles';

export interface PrinterListProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set the subtitle modal info
   */
  subtitle: string;

  /**
   * The available printers
   */
  printersList: Array<DicomPrinterType>;

  /**
   * Set the selected default printer option
   */
  defaultOption: string;

  /**
   * Callback to handle printer option click
   */
  onPrinterOptionClick: (printerId: string) => void;

  /**
   * Callback to handle print button click
   */
  onPrintBtnClick: () => void;
}

/**
 * Use this component in order to show the printer list (DicomPrinter)
 * Based of Sergio's cd burner component
 * @author Antonio Torres<toniotgz@gmail.com>
 * Created at 2022-05-04
 */
export const PrinterList: React.FC<PrinterListProps> = ({
  className,
  subtitle,
  printersList = [],
  defaultOption,
  onPrinterOptionClick,
  onPrintBtnClick,
}) => {
  const MAX_CONTAINER_LIST_HEIGHT = '250px';
  const burnerListClass = cn(className);
  const { t } = useTranslation();

  const handleSelectOption = (itemId: string) => () => {
    if (onPrinterOptionClick) onPrinterOptionClick(itemId);
  };

  const buildOptionList = printersList.map((item) => (
    <div
      role="button"
      tabIndex={-1}
      key={item?.id}
      className={optionClass(item?.id, defaultOption)}
      onClick={handleSelectOption(item?.id)}
      onKeyPress={handleSelectOption(item?.id)}>
      <div className="flex items-center w-full">
        <div className="flex items-center">
          <div className="flex items-center">
            <Icon className="text-basic-white" icon={IconCatalog.print} />
            <span className="ml-2 text-basic-white text-paragraph font-medium overflow-hidden">{item?.name}</span>
          </div>
        </div>
        <div className="ml-auto mr-4 text-basic-white text-paragraph font-medium">{item?.facility?.name}</div>
      </div>
    </div>
  ));

  return (
    <div className={burnerListClass}>
      {subtitle && (
        <div className="mb-6 pb-4 text-left text-basic-white text-paragraph font-medium leading-6 border-b-2 border-gray-600">
          {subtitle}
        </div>
      )}

      {/* DISK LIST */}
      <div
        style={setMaxHeightByOptions({
          listLength: printersList.length,
          maxOptions: 4,
          maxHeight: MAX_CONTAINER_LIST_HEIGHT,
        })}
        className="scrollbar-w-2 scrollbar-thumb-gray-200 scrollbar-track-gray-700 scrollbar-thumb-rounded-lg flex flex-col items-center pr-2 h-56 overflow-y-auto">
        {buildOptionList}
      </div>

      {/* ACTIONS */}
      <div className="flex gap-x-4 justify-end pt-9">
        <Button type={ButtonType.button} onClick={onPrintBtnClick} leftIcon={IconCatalog.print}>
          {t('viewer.report.printBtn')}
        </Button>
      </div>
    </div>
  );
};
