import create, { GetState, SetState } from 'zustand';

import { EVA_PRODUCT } from '../constants/products';
import { Product } from '../interfaces/Product';
import { ProductRole } from '../interfaces/ProductRole';
import { Role } from '../interfaces/Role';
import { immer } from './';

/**
 * Product Permissions Store
 */
interface ProductRolesStore {
  /**
   * List of product roles by user
   */
  productRoles: Array<ProductRole>;
  /**
   * Action to set List of product roles
   */
  setProductRoles: (productRoles: Array<ProductRole>) => void;
  /**
   * Get role by product slug
   */
  getRoleByProductSlug: (product: EVA_PRODUCT) => Role;
  /**
   * Get product list
   */
  getProductList: () => Array<Product>;
}

const productRolesStore = (set: SetState<ProductRolesStore>, get: GetState<ProductRolesStore>): ProductRolesStore => ({
  productRoles: [],
  setProductRoles: (productRoles) =>
    set((state) => {
      state.productRoles = productRoles;
      return state;
    }),
  getRoleByProductSlug: (product) => {
    const productPermission = (get().productRoles.find((productRole) => productRole.product.slug === product) ??
      {}) as ProductRole;
    return productPermission.role;
  },
  getProductList: () => get().productRoles.map((productRole) => productRole.product),
});

export const useProductRolesStore = create<ProductRolesStore>(immer(productRolesStore));
