import React from 'react';
import { actionClass, actionIconClass, actionLabelClass } from './styles';
import Icon from '../../../atoms/Icon';
import { Spinner } from '../../../atoms/Spinner';
/**
 * Action Item (Toolbar item)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-04-26
 */
var ActionItem = function (_a) {
    var icon = _a.icon, label = _a.label, isDisabled = _a.isDisabled, _b = _a.isActive, isActive = _b === void 0 ? false : _b, _c = _a.liteMode, liteMode = _c === void 0 ? false : _c, className = _a.className, iconClassName = _a.iconClassName, _d = _a.iconSize, iconSize = _d === void 0 ? '24px' : _d, buttonCustomStyles = _a.buttonCustomStyles, _e = _a.isMPREnabled, isMPREnabled = _e === void 0 ? false : _e, _f = _a.isLoading, isLoading = _f === void 0 ? false : _f, onClick = _a.onClick;
    var handleClick = function () {
        if (onClick)
            onClick();
    };
    if (isLoading)
        return (React.createElement("div", { className: "flex flex-col items-center w-16" },
            React.createElement(Spinner, { style: { zoom: '0.4' } })));
    return (React.createElement("button", { tabIndex: 0, "data-cy": "button-".concat(label.split(' ')[0]), className: actionClass(className, isActive, isMPREnabled), onClick: handleClick, disabled: isDisabled, style: buttonCustomStyles },
        React.createElement(Icon, { className: actionIconClass(iconClassName, isActive, isMPREnabled), icon: icon, width: iconSize, height: iconSize }),
        !liteMode && React.createElement("span", { className: actionLabelClass(isActive) }, label)));
};
export default ActionItem;
