/* MODALITY TYPES */
export var MODALITY_TYPES;
(function (MODALITY_TYPES) {
    MODALITY_TYPES["CR"] = "CR";
    MODALITY_TYPES["CT"] = "CT";
    MODALITY_TYPES["MG"] = "MG";
    MODALITY_TYPES["MR"] = "MR";
    MODALITY_TYPES["PET"] = "PT";
    MODALITY_TYPES["ECG"] = "ECG";
    MODALITY_TYPES["DOC"] = "DOC";
    MODALITY_TYPES["SR"] = "SR";
    MODALITY_TYPES["DX"] = "DX";
    MODALITY_TYPES["US"] = "US";
    MODALITY_TYPES["OTHER"] = "OT";
})(MODALITY_TYPES || (MODALITY_TYPES = {}));
