import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { GenericModal } from '../GenericModal';
import { BasicInput } from '../../../atoms/Inputs';
import Button, { ButtonColor, ButtonType } from '../../../atoms/Button';
/**
 * This component appears when the user adds a Note
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-17
 */
export var NoteModal = function (_a) {
    var title = _a.title, acceptLabel = _a.acceptLabel, cancelLabel = _a.cancelLabel, className = _a.className, onCancel = _a.onCancel, onAccept = _a.onAccept;
    var modalClass = cn(className, 'max-w-xl');
    var t = useTranslation().t;
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var handleOnChange = function (event) { return setValue(event.target.value); };
    var handleCancelButtonClick = function () {
        if (onCancel)
            onCancel();
    };
    var handleAcceptButtonClick = function () {
        if (onAccept)
            onAccept(value);
    };
    return (React.createElement(GenericModal, { className: modalClass, onClose: handleCancelButtonClick, isMaxWidthCustom: true, title: title || t('general.modal.confirmation.title') },
        React.createElement("div", { className: "flex flex-col gap-y-6" },
            React.createElement(BasicInput, { className: "mb-3", value: value, placeholder: t('study.notesFields.note'), name: "note", onChange: handleOnChange }),
            React.createElement("div", { className: "flex gap-x-4 justify-end" },
                React.createElement(Button, { type: ButtonType.button, color: ButtonColor.secondary, onClick: handleCancelButtonClick }, cancelLabel || t('general.actions.cancel')),
                React.createElement(Button, { type: ButtonType.button, onClick: handleAcceptButtonClick }, acceptLabel || t('general.actions.accept'))))));
};
