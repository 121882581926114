import cornerstoneTools from 'cornerstone-tools';

import { MeasurementType } from '@eva-pacs/client';

import { MeasurementData } from '~/src/store';

type GetMeasurementDataByImageId = {
  imageId: string;
  toolName: string;
  uuid: string;
};

export const getMeasurementDataByImageId = ({ imageId, toolName, uuid }: GetMeasurementDataByImageId) => {
  const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager.getImageIdToolState(imageId, toolName);
  if (!toolState?.data) return;

  const measurement = toolState.data.find((measurement) => measurement.uuid === uuid);
  if (!measurement) return;

  return measurement;
};

type GetMeasurementDataByElement = {
  element: HTMLElement;
  toolName: string;
  uuid: string;
};

export const getMeasurementDataByElement = ({ element, toolName, uuid }: GetMeasurementDataByElement) => {
  const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager.get(element, toolName);
  if (!toolState?.data) return;

  const measurement = toolState.data.find((measurement) => measurement.uuid === uuid);
  if (!measurement) return;

  return measurement;
};

export const getMeasurementIdByUUID = (measurementList: MeasurementData, uuid: string): string | undefined => {
  const measurementIdList = Object.keys(measurementList);
  const measurementId = measurementIdList.find((measurementId: string) => {
    const targetUUID = getMeasuremetUUID(measurementList[measurementId]);
    return targetUUID === uuid;
  });
  return measurementId;
};

const getMeasuremetUUID = (measurement: MeasurementType): string => {
  const measurementData = typeof measurement.data === 'string' ? JSON.parse(measurement.data)[0] : measurement.data;
  return measurementData?.uuid ?? '';
};
