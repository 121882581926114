import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useOnClickOutside } from '@eva-pacs/core';
import { Badge, BadgeColor, BadgeSize } from '../Badge';
import Button, { ButtonSize, ButtonColor, ButtonVariant } from '../Button';
import { DropdownMenu } from '../DropdownMenu';
import { Menu, MenuPlacement } from '../DropdownMenu/Menu';
import { Item } from '../DropdownMenu/Item';
import { IconCatalog } from '../Icon';
export var StatusType;
(function (StatusType) {
    StatusType["positive"] = "positive";
    StatusType["warning"] = "warning";
    StatusType["negative"] = "negative";
})(StatusType || (StatusType = {}));
/**
 * This is a dropdown menu that choose several status options
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-22
 */
export var StatusMenu = function (_a) {
    var ariaLabel = _a.ariaLabel, className = _a.className, defaultOption = _a.defaultOption, isDisabled = _a.isDisabled, options = _a.options, onItemClick = _a.onItemClick;
    var statusMenuClass = cn(className);
    var node = useRef(null);
    var _b = useState(false), menuIsOpen = _b[0], setMenuIsOpen = _b[1];
    var _c = useState(defaultOption), selectedOption = _c[0], setSelectedOption = _c[1];
    useOnClickOutside(node.current, function () { return setMenuIsOpen(false); });
    useEffect(function () {
        if (defaultOption)
            setSelectedOption(defaultOption);
    }, [defaultOption]);
    var handleTriggerClick = function () { return setMenuIsOpen(!menuIsOpen); };
    var handleItemClick = function (option) { return function () {
        setSelectedOption(option);
        if (onItemClick)
            onItemClick(option);
        setMenuIsOpen(false);
    }; };
    var renderStatusOption = options.map(function (option) { return (React.createElement(Item, { key: option.type, className: "text-basic-white hover:bg-gray-700", onClick: handleItemClick(option), isDisabled: option.isDisabled },
        React.createElement("span", { className: "flex items-center" },
            React.createElement(Badge, { className: "mr-2", color: BadgeColor[option.type], size: BadgeSize.sm }),
            React.createElement("span", { className: "text-basic-white" }, option.label)))); });
    return (React.createElement("div", { "aria-label": ariaLabel, ref: node, className: statusMenuClass },
        React.createElement(DropdownMenu, null,
            React.createElement(Button, { rightIcon: IconCatalog.chevronDown, color: ButtonColor.dark, size: ButtonSize.sm, variant: ButtonVariant.text, onClick: handleTriggerClick, disabled: isDisabled },
                React.createElement("span", { className: "flex items-center" },
                    React.createElement(Badge, { className: "mr-2", color: BadgeColor[selectedOption.type], size: BadgeSize.sm }),
                    React.createElement("span", { className: "text-basic-white" }, selectedOption.label))),
            React.createElement(Menu, { className: "z-10 bg-gray-600 border", isOpen: menuIsOpen, placement: MenuPlacement.bottomLeft }, renderStatusOption))));
};
