import React from 'react';
import cn from 'classnames';
import Image from '../../../atoms/Image';
/**
 * This component is used for presenting various empty states, such as no items, empty search, broken link, welcome screen, etc. They are most commonly seen the first time a user interacts with a product or page, but can be used when data has been deleted or is unavailable. An empty state is an opportunity to engage and delight users adding an actionable button.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-09-07
 */
export var EmptyState = function (_a) {
    var title = _a.title, description = _a.description, _b = _a.imageSrc, imageSrc = _b === void 0 ? '/assets/illustrations/success.svg' : _b, footerSection = _a.footerSection, className = _a.className;
    var emptyStateClass = cn(className, 'flex');
    return (React.createElement("div", { className: emptyStateClass },
        React.createElement("div", { className: "gap-y-6 m-auto" },
            React.createElement("div", { className: "mb-6 mx-auto w-fit-content" },
                React.createElement(Image, { src: imageSrc, hasMaxWidth: true, alt: title, noImg: React.createElement(React.Fragment, null) })),
            React.createElement("div", { className: "mx-auto" },
                React.createElement("p", { className: "mb-6 text-center text-basic-white text-subtitle font-semi-bold tracking-tight" }, title),
                React.createElement("p", { className: "mb-6 text-center text-light-gray-600 text-paragraph-sm" }, description),
                footerSection))));
};
