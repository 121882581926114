import { useViewerQrPdfLazyQuery } from '@eva-pacs/client';
import { handleProgrammaticDownload } from '@eva-pacs/core';

export interface GetViewerQrServiceProps {
  studyId: string;
}

export const useGetViewerQrService = ({ studyId }: GetViewerQrServiceProps) => {
  const [getViewerQrPdf] = useViewerQrPdfLazyQuery({
    variables: {
      studyId,
    },
    onCompleted: ({ viewerLinkPdf }) => {
      const pdfContent = viewerLinkPdf?.pdfBase64;
      if (!pdfContent) return;
      const linkSource = `data:application/pdf;base64,${pdfContent}`;
      // eslint-disable-next-line i18next/no-literal-string
      const fileName = 'qr.pdf';
      handleProgrammaticDownload(linkSource, { fileName });
    },
    fetchPolicy: 'no-cache',
  });

  return { getViewerQrPdf };
};
