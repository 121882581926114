var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatBytes, getExtensionFile, toMb } from '@eva-pacs/core';
/**
 * The File Input allows a user to transfer a file or submit content of their own.
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2021-07-15
 */
export var FileInput = React.forwardRef(function (_a, ref) {
    var ariaLabel = _a.ariaLabel, _b = _a.multiple, multiple = _b === void 0 ? false : _b, className = _a.className, style = _a.style, extensions = _a.extensions, _c = _a.limitSize, limitSize = _c === void 0 ? 10 : _c, onInputChange = _a.onInputChange, restOfProps = __rest(_a, ["ariaLabel", "multiple", "className", "style", "extensions", "limitSize", "onInputChange"]);
    var t = useTranslation().t;
    var isValidFileSize = function (size) {
        var MB = toMb(size);
        return MB <= limitSize;
    };
    var isValidExtension = function (fileName) {
        if (!extensions)
            return true;
        var ext = getExtensionFile(fileName);
        return extensions.includes(ext);
    };
    var validator = function (file) {
        if (file === null || !file)
            return '';
        if (!isValidFileSize(file.size))
            return t('errors.uploader.fileExceedSize', { size: formatBytes(file.size, 2) });
        if (!isValidExtension(file.name))
            return t('errors.uploader.unsupportedMediaType', { file: file.name });
        return '';
    };
    var handleChange = function (event) {
        var files = event.target.files;
        if (files === null)
            return;
        if (files.length === 0)
            return;
        var validationResponse = [];
        Array.from(files).forEach(function (file) {
            var tempFile = file;
            var currentErrorMsg = validator(tempFile);
            validationResponse = __spreadArray(__spreadArray([], validationResponse, true), [{ file: tempFile, error: currentErrorMsg }], false);
        });
        if (onInputChange)
            onInputChange(validationResponse);
        // Clean input file
        event.target.value = '';
    };
    /* Render JSX */
    return (React.createElement("input", __assign({ ref: ref, "data-testid": "fileInput", "aria-label": ariaLabel, className: className, style: style, type: "file", tabIndex: -1, onChange: handleChange, multiple: multiple }, restOfProps)));
});
