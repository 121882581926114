import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import { getEnabledElementControlled } from './getEnabledElementControlled';
import { MODALITY_TYPES } from '@eva-pacs/core';
import { useFeatureFlagsStore } from '~/src/store';
import { FeatureFlags } from '~/constants';

const BaseTool = csTools.importInternal('base/BaseTool');
/**
 *
 * http://dicom.nema.org/dicom/2013/output/chtml/part03/sect_C.9.html
 *
 * @public
 * @class Overlay
 * @memberof Tools
 *
 * @classdesc Tool for displaying a scale overlay on the image.
 * @extends Tools.Base.BaseTool
 */
export default class OverlayTool extends BaseTool {
  initialConfiguration;
  constructor(configuration = {}) {
    const defaultConfig = {
      name: 'Overlay',
      configuration: {},
      mixins: ['enabledOrDisabledBinaryTool'],
    };
    const initialConfiguration = Object.assign(defaultConfig, configuration);

    super(initialConfiguration);

    this.initialConfiguration = initialConfiguration;
  }

  enabledCallback(element) {
    this.forceImageUpdate(element);
  }

  disabledCallback(element) {
    this.forceImageUpdate(element);
  }

  forceImageUpdate(element) {
    const enabledElement = getEnabledElementControlled(element);

    if (!enabledElement?.image) return;

    cornerstone.updateImage(element);
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { enabledElement, image, canvasContext } = eventData;

    if (!eventData || !enabledElement || !image) return;

    const module = cornerstone.metaData.get('generalSeriesModule', image.imageId);
    const isModalityHeavy = !module || [MODALITY_TYPES.MG, MODALITY_TYPES.CR].includes(module?.modality);
    const enforceOverlayTool = useFeatureFlagsStore.getState().hasFlag(FeatureFlags.EnforceOverlayTool);

    if (!enforceOverlayTool && isModalityHeavy) return;
    const overlayPlaneMetadata = cornerstone.metaData.get('overlayPlaneModule', image.imageId);

    if (!overlayPlaneMetadata || !overlayPlaneMetadata.overlays || !overlayPlaneMetadata.overlays.length) return;

    const imageWidth = image.columns;
    const imageHeight = image.rows;
    overlayPlaneMetadata.overlays.forEach((overlay) => {
      if (overlay.visible === false) return;

      const layerCanvas = document.createElement('canvas');

      layerCanvas.width = imageWidth;
      layerCanvas.height = imageHeight;

      const layerContext = layerCanvas.getContext('2d');

      if (!layerContext) return;

      layerContext.fillStyle = overlay.fillStyle || 'white';

      if (overlay.type === 'R') {
        layerContext.fillRect(0, 0, layerCanvas.width, layerCanvas.height);
        layerContext.globalCompositeOperation = 'xor';
      }

      let index = 0;

      for (let yAxis = 0; yAxis < overlay.rows; yAxis++) {
        for (let xAxis = 0; xAxis < overlay.columns; xAxis++) {
          if (overlay.pixelData[index++] > 0) layerContext.fillRect(xAxis, yAxis, 1, 1);
        }
      }

      // Guard against non-number values
      const overlayX = !isNaN(parseFloat(overlay.x)) && isFinite(overlay.x) ? overlay.x : 0;
      const overlayY = !isNaN(parseFloat(overlay.y)) && isFinite(overlay.y) ? overlay.y : 0;

      // Draw the overlay layer onto the canvas
      canvasContext.drawImage(layerCanvas, overlayX, overlayY);
    });
  }
}
