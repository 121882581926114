import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  useGetIsoByStudyLazyQuery,
  useBurnersQuery,
  useCreateDownloadIsoMutation,
  useBurnIsoMutation,
  useUpdateBurnIsoStatusMutation,
  BurnerType,
  DownloadIsoType,
  BurnIsoMutation,
  DownloadIsoViewStatus,
  DownloadIsoViewStatusBurn,
  UpdateBurnIsoStatusMutation,
} from '@eva-pacs/client';
import { handleProgrammaticDownload } from '@eva-pacs/core';
import { useErrorHandler } from '~/utils/appHelpers';
import { ErrorState, SuccessState, LoadingState, Spinner, Button } from '@eva-pacs/ui';
import { RETRY_ISO_DOWNLOAD_TIME } from '~/constants';

import { BurnerList } from './BurnerList';

export interface BurnIsoProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Pass study id
   */
  studyId: string;

  /**
   * Set the subtitle modal info
   */
  subtitle: string;

  /**
   * Checks if the user can burn discs
   */
  canBurnDiscs: boolean;

  /**
   * Callback when the ISO was downloaded
   */
  onDownload?: () => void;
}

/**
 * Use this component in order to show the burners list modal
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * @link https://lucid.app/lucidchart/8f2a4b51-a46d-4e2d-b56a-7f32b7bff2d7/edit?invitationId=inv_0147b234-848f-4545-9e36-fb529d4d448c
 * Created at 2022-03-02
 */
export const BurnIso: React.FC<BurnIsoProps> = ({ canBurnDiscs, className, studyId, subtitle, onDownload }) => {
  const burnIsoClass = cn(className);
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const [iso, setIso] = useState<DownloadIsoType>();
  const [selectedBurner, setSelectedBurner] = useState('');
  const [isBurnMode, setIsBurnMode] = useState(false);
  const [isFirstValidation, setIsFirstValidation] = useState(true);
  const isoIsReady = iso && iso.fileUrl && iso.status === DownloadIsoViewStatus.COMPLETED;

  const handleCreateIso = (isoResponse: DownloadIsoType | undefined) => {
    if (isForceDownload(isoResponse)) handleDownload();
    setTimeout(getIsoByStudy, RETRY_ISO_DOWNLOAD_TIME);
    if (!isoResponse || isoResponse.status === DownloadIsoViewStatus.HAS_UPDATES) return createDownloadIsoMutation();
    setIso(isoResponse);
  };

  const isForceDownload = (isoResponse: DownloadIsoType | undefined) => {
    const burnersList = burnersData?.burners?.results || [];
    const shouldForceDownload = !canBurnDiscs || burnersList?.length === 0;
    return isoResponse?.status === DownloadIsoViewStatus.COMPLETED && shouldForceDownload;
  };

  const handleBurnIso = (isoResponse: DownloadIsoType) => {
    if (isoResponse.statusBurn === DownloadIsoViewStatusBurn.PENDING)
      setTimeout(getIsoByStudy, RETRY_ISO_DOWNLOAD_TIME);
    if (isoResponse.statusBurn === DownloadIsoViewStatusBurn.COMPLETED) setIsBurnMode(false);
    setIso(isoResponse);
  };

  const handleFirstValidation = (isoResponse: DownloadIsoType | undefined) => {
    setIsFirstValidation(false);
    if (isoResponse?.statusBurn === DownloadIsoViewStatusBurn.PENDING) setIsBurnMode(true);
    getIsoByStudy();
  };

  const handleValidateIso = (isoResponse: DownloadIsoType) => {
    if (isFirstValidation) handleFirstValidation(isoResponse);
    else if (isBurnMode && isoResponse.status === DownloadIsoViewStatus.COMPLETED) handleBurnIso(isoResponse);
    else handleCreateIso(isoResponse);
  };

  const [getIsoByStudy] = useGetIsoByStudyLazyQuery({
    variables: { studyId },
    onCompleted: ({ study }) => {
      if (!study?.downloadsIso) return;
      handleValidateIso(study.downloadsIso[0] as DownloadIsoType);
    },
    fetchPolicy: 'network-only',
  });

  // Create ISO file
  const [createDownloadIsoMutation] = useCreateDownloadIsoMutation({
    variables: { input: { study: studyId } },
    onError: (error) => handleError({ logMessage: error }),
  });

  // Burn ISO file
  const [burnIsoMutation] = useBurnIsoMutation({
    onCompleted: ({ createBurnIso: { success } }: BurnIsoMutation) => {
      if (success) getIsoByStudy();
      else handleError({ logMessage: t('errors.burn.genericError') });
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  // Reset ISO statusBurn
  const [updateBurnCdStatusMutation] = useUpdateBurnIsoStatusMutation({
    onCompleted: ({ updateDownloadIso: { success, downloadIso } }: UpdateBurnIsoStatusMutation) => {
      if (!success || !downloadIso) return;
      setIso(downloadIso as DownloadIsoType);
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  // Ask for Burners: Do the organization have burners?
  const { data: burnersData, loading } = useBurnersQuery({
    onCompleted: (response) => {
      const burnersList = response?.burners?.results ?? [];
      setSelectedBurner(burnersList[0]?.id);
      getIsoByStudy();
    },
    onError: (error) => handleError({ logMessage: error }),
    fetchPolicy: 'network-only',
  });

  const handleDownload = () => {
    if (!isoIsReady) return;
    handleProgrammaticDownload(iso?.fileUrl ?? '');
    onDownload && onDownload();
  };

  const burnersList = burnersData?.burners?.results || [];
  if (loading || burnersList?.length === 0 || !canBurnDiscs) return <Spinner className="my-6" />;

  const handleBurnerOptionClick = (burnerId: string) => setSelectedBurner(burnerId);

  const handleBurnClick = () => {
    if (!isoIsReady) return;
    setIsBurnMode(true);
    burnIsoMutation({
      variables: { input: { study: studyId, burner: selectedBurner } },
    });
  };

  const handleFinishClick = () =>
    updateBurnCdStatusMutation({
      variables: { downloadIsoId: iso?.id, input: { statusBurn: null } },
    });

  const renderContent = () => {
    switch (iso?.statusBurn) {
      case DownloadIsoViewStatusBurn.PENDING:
        return <LoadingState title={t('study.burnCD.pending.title')} />;

      case DownloadIsoViewStatusBurn.COMPLETED:
        return (
          <SuccessState
            title={t('study.burnCD.completed.title')}
            footerSection={
              <Button className="ml-auto" onClick={handleFinishClick}>
                {t('general.actions.finish')}
              </Button>
            }
          />
        );

      case DownloadIsoViewStatusBurn.FAILED:
        return (
          <ErrorState
            title={t('study.burnCD.failed.title')}
            description={t('study.burnCD.failed.description')}
            footerSection={
              <Button className="ml-auto" onClick={handleFinishClick}>
                {t('general.actions.finish')}
              </Button>
            }
          />
        );

      default:
        return (
          <BurnerList
            subtitle={subtitle}
            burnersList={burnersList as Array<BurnerType>}
            defaultOption={selectedBurner}
            isLoadingDownloadBtn={iso?.status !== DownloadIsoViewStatus.COMPLETED}
            onBurnBtnClick={handleBurnClick}
            onDownloadBtnClick={handleDownload}
            onBurnerOptionClick={handleBurnerOptionClick}
          />
        );
    }
  };

  return <div className={burnIsoClass}>{renderContent()}</div>;
};
