var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Children, cloneElement, useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import Portal from '../Portal';
import { arrowContainerClass, arrowClass, tooltipContainerClass, tooltipClass } from './styles';
export var TooltipPlacement;
(function (TooltipPlacement) {
    TooltipPlacement["topLeft"] = "top-start";
    TooltipPlacement["top"] = "top";
    TooltipPlacement["topRight"] = "top-end";
    TooltipPlacement["leftTop"] = "left-start";
    TooltipPlacement["left"] = "left";
    TooltipPlacement["leftBottom"] = "left-end";
    TooltipPlacement["rightTop"] = "right-start";
    TooltipPlacement["right"] = "right";
    TooltipPlacement["rightBottom"] = "right-end";
    TooltipPlacement["bottomLeft"] = "bottom-start";
    TooltipPlacement["bottom"] = "bottom";
    TooltipPlacement["bottomRight"] = "bottom-end";
})(TooltipPlacement || (TooltipPlacement = {}));
export var TooltipColor;
(function (TooltipColor) {
    TooltipColor["black"] = "black";
})(TooltipColor || (TooltipColor = {}));
export var TooltipSize;
(function (TooltipSize) {
    TooltipSize["sm"] = "sm";
    TooltipSize["md"] = "md";
})(TooltipSize || (TooltipSize = {}));
export var Tooltip = function (_a) {
    var ariaLabel = _a.ariaLabel, text = _a.text, _b = _a.color, color = _b === void 0 ? TooltipColor.black : _b, _c = _a.placement, placement = _c === void 0 ? TooltipPlacement.top : _c, _d = _a.size, size = _d === void 0 ? TooltipSize.md : _d, _e = _a.hasArrow, hasArrow = _e === void 0 ? true : _e, className = _a.className, children = _a.children;
    var _f = useState(null), tooltipElement = _f[0], setTooltipElement = _f[1];
    var _g = useState(null), arrowElement = _g[0], setArrowElement = _g[1];
    var refTriggerNode = useRef(null);
    var _h = useState(false), open = _h[0], setOpen = _h[1];
    /* Popper config */
    var _j = usePopper(refTriggerNode.current, tooltipElement, {
        placement: placement,
        modifiers: [
            // eslint-disable-next-line i18next/no-literal-string
            { name: 'offset', options: { offset: [0, 8] } },
            // eslint-disable-next-line i18next/no-literal-string
            { name: 'arrow', options: { element: arrowElement } },
        ],
    }), styles = _j.styles, attributes = _j.attributes;
    var handleMouseOver = function () { return setOpen(true); };
    var handleMouseOut = function () { return setOpen(false); };
    var elements = Children.toArray(children); //[1]
    /* Append handle to the trigger component */
    elements = cloneElement(elements[0], {
        ref: refTriggerNode,
        onMouseOver: handleMouseOver,
        onMouseOut: handleMouseOut,
    });
    return (React.createElement(React.Fragment, null,
        elements,
        React.createElement(Portal, null,
            React.createElement("div", __assign({ "aria-label": ariaLabel, className: tooltipContainerClass(className), ref: setTooltipElement, style: __assign({}, styles.popper) }, attributes.popper), open && (React.createElement("span", { className: tooltipClass(size, color) },
                text,
                hasArrow && (React.createElement("div", { id: "arrow", ref: setArrowElement, style: styles.arrow, "data-popper-arrow": true, className: arrowContainerClass(placement) },
                    React.createElement("span", { className: arrowClass })))))))));
};
/**
 * [1]
 * References:
 * https://css-tricks.com/using-react-portals-to-render-children-outside-the-dom-hierarchy/
 * https://codepen.io/davidgilbertson/pen/ooXVyw
 */
