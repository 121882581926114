export var MAX_PHONE_LENGTH = 13;
export var MIN_PHONE_LENGTH = 7;
export var FieldTypes;
(function (FieldTypes) {
    FieldTypes["TEXTAREA"] = "LONG_TEXT";
    FieldTypes["TEXT_INPUT"] = "SHORT_TEXT";
    FieldTypes["RADIO"] = "CHOICE";
    FieldTypes["CHECKBOX"] = "MULTIPLE_CHOICE";
    FieldTypes["MULTIPLE_TEXT"] = "MULTIPLE_TEXT";
    FieldTypes["NUMBER_INPUT"] = "INTEGER";
    FieldTypes["SELECT"] = "SELECT";
    FieldTypes["SWITCH"] = "BOOLEAN";
    FieldTypes["FILE_INPUT"] = "FILE";
})(FieldTypes || (FieldTypes = {}));
