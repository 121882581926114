import React, { useEffect, useState } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useCountriesQuery, CountryType } from '@eva-pacs/client';
import { BasicInputSize, CountryOption, PhoneInput, PhoneInputProps, PhoneObjType } from '@eva-pacs/ui';
import { DEFAULT_ISO_CODE } from '~/constants';
import { UseFormSetValue } from 'react-hook-form';
import { PatientFormFields } from '~/components/StudyList/PatientEditForm';

export interface PhoneInputWrapperProps extends Partial<PhoneInputProps> {
  /**
   * Set the default phone code.
   */
  defaultIsoCode?: string;

  /**
   * Set value for upper form context
   */
  setValue?: UseFormSetValue<PatientFormFields>;

  /**
   * Pass the parent useForm field
   */
  field?: ControllerRenderProps<FieldValues, string>;
}

/**
 * Use this component as logic for phone input ui component
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2022-03-09
 */
export const PhoneInputWrapper: React.FC<PhoneInputWrapperProps> = ({
  defaultIsoCode = DEFAULT_ISO_CODE,
  inputSize = BasicInputSize.md,
  isDisabled = false,
  isFullWidth = false,
  hasError,
  field,
  value = '',
  name,
  onInputChange,
  ...restOfProps
}) => {
  const [parsedCountries, setParsedCountries] = useState<Array<CountryOption>>([]);
  const [defaultCountry, setDefaultCountry] = useState<CountryOption | undefined>();

  const parsePhoneNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, '');

  const parseCountries = (countries: Array<CountryType>): Array<CountryOption> =>
    countries.map((country) => ({
      id: country.id,
      name: country.name,
      isoCode: country.isoCode,
      phoneCode: country.phoneCode,
    }));

  useEffect(() => {
    handleSetDefaultCountry(parsedCountries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultIsoCode, parsedCountries]);

  useCountriesQuery({
    onCompleted: (response) => {
      const countries = response.countries?.results;
      if (!countries) return;

      const newCountries = parseCountries(countries as Array<CountryType>);
      setParsedCountries(newCountries);
      handleSetDefaultCountry(newCountries);
    },
  });

  const handleSetDefaultCountry = (countries: Array<CountryOption>) => {
    // Use to appropriate load default country to avoid race condition.
    const defaultFallbackCountry = countries.find((country) => country.isoCode === DEFAULT_ISO_CODE);

    const currentDefaultCountry = countries.find((country) => country.isoCode === defaultIsoCode);

    if (currentDefaultCountry) {
      // Sets the default country only if the find method returns an object.
      return setDefaultCountry(currentDefaultCountry);
    }

    // If a default country was not found, then set the default country fallback (MX)
    setDefaultCountry(defaultFallbackCountry);
  };

  const handleChange = (phoneObj: PhoneObjType) => {
    const country = parsedCountries.find((country) => country.isoCode === phoneObj.isoCode);
    const cleanPhoneObj = {
      phoneCode: phoneObj.phoneCode,
      phone: parsePhoneNumber(phoneObj.phone),
      isoCode: phoneObj.isoCode,
      id: country?.id,
    };
    if (field) field.onChange(cleanPhoneObj);
    if (onInputChange) onInputChange(cleanPhoneObj);
  };

  return (
    <PhoneInput
      countries={parsedCountries}
      defaultCountry={defaultCountry || parsedCountries[0]}
      inputSize={inputSize}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      hasError={hasError}
      value={value}
      name={name}
      onInputChange={handleChange}
      {...restOfProps}
    />
  );
};
