import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import { transformUnits } from '@eva-pacs/core';
import { getLaterality } from '~/components/Viewer/CornerstoneViewer/getOverlayData';
import {
  MILIMETER_MEASUREMENT_UNIT,
  PIXELS_MEASUREMENT_UNIT,
  PIXELS_NEAR_TOOL,
  NIPPLE_INJURY_UPDATE_CONF_EVENT,
} from '~/constants';
import { getElementStateConfig } from './setConfig';
import { getNipplePositionApproach } from './nipplePositionAproximation';
import { InteractionTypes } from '../cornestone.helpers';
import { getLineMidpoint, distanceBetweenPoints } from '../../plane';
import { wait } from '../../wait';
import { getEnabledElementControlled } from '../getEnabledElementControlled';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const drawLine = csTools.importInternal('drawing/drawLine');
const getLogger = csTools.importInternal('util/getLogger');
const setShadow = csTools.importInternal('drawing/setShadow');
const draw = csTools.importInternal('drawing/draw');
const anyHandlesOutsideImage = csTools.importInternal('manipulators/anyHandlesOutsideImage');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const drawTextBox = csTools.importInternal('drawing/drawTextBox');
const { lengthCursor } = csTools.importInternal('tools/cursors');
const lineSegDistance = csTools.importInternal('util/lineSegDistance');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const roundToDecimal = csTools.importInternal('util/roundToDecimal');
const throttle = csTools.importInternal('util/throttle');
const debounce = csTools.importInternal('util/debounce');

const logger = getLogger('tools:annotation:ProbeTool');
const triggerEvent = csTools.importInternal('util/triggerEvent');

/**
 * @public
 * @class NippleInjuryTool
 * @author Mauricio Campos <mauricio.campos@evacenter.com>
 * @memberof Tools.NippleInjury
 * @extends BaseAnnotationTool
 * Created at 2023-11-01
 */

const WAIT_FOR_IMAGE_DELAY = 300;

export default class NippleInjury extends BaseAnnotationTool {
  throttledUpdateCachedStats: any;
  throttledUpdateAnotherMeasurement: any;
  name: string;
  nipplePosition: { x: number; y: number };
  measurementDebounces: any;
  constructor(props = {}) {
    super(props, {
      supportedInteractionTypes: [InteractionTypes.MOUSE, InteractionTypes.TOUCH],
      svgCursor: lengthCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        measurementCreationThreshold: -1,
      },
    });

    this.nipplePosition = { x: 0, y: 0 }; //Center of the viewport
    this.name = 'NippleInjury';
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
    this.throttledUpdateAnotherMeasurement = debounce(this.updateAnotherMeasurement, 110);
    this.measurementDebounces = null;
  }

  createNewMeasurement(eventData) {
    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
    if (!goodEventData) {
      logger.error(`required eventData not supplied to tool ${super.name}'s createNewMeasurement`);
      return;
    }
    const { image } = eventData;
    const laterality = getLaterality(image.imageId || '');
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: true,
      value: '',
      handles: {
        injuryPoint: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: false,
          active: true,
        },
        nipplePosition: {
          x: this.nipplePosition.x,
          y: this.nipplePosition.y,
          highlight: false,
          active: false,
          laterality,
        },
      },
    };
  }

  /**
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    if (data.visible === false) return false;
    const probeCoords = cornerstone.pixelToCanvas(element, data.handles.nipplePosition);
    if (cornerstoneMath.point.distance(probeCoords, coords) < PIXELS_NEAR_TOOL) return false;
    if (!data.handles.injuryPoint) return false;
    return lineSegDistance(element, data.handles.nipplePosition, data.handles.injuryPoint, coords) < PIXELS_NEAR_TOOL;
  }

  updateAnotherMeasurement(image, element, uuidToExclude) {
    clearTimeout(this.measurementDebounces);
    this.measurementDebounces = setTimeout(() => {
      const toolState = csTools.getToolState(element, this.name);

      toolState?.data.forEach((data) => {
        if (data.uuid === uuidToExclude) return;
        this.updateStats(image, data);
        const eventType = csTools.EVENTS.MEASUREMENT_MODIFIED;
        const newEventData = {
          toolName: this.name,
          element,
          measurementData: data,
        };
        triggerEvent(element, eventType, newEventData);
      });
    }, 300);
  }

  isANippleMarkerPositionUpdate = (measurementHandle, nipplePosition) => {
    return measurementHandle.x !== nipplePosition.x || measurementHandle.y !== nipplePosition.y;
  };

  updateStats = (image, data) => {
    const { nipplePosition, injuryPoint } = data.handles;
    if (!nipplePosition || !injuryPoint) return;
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
    const length = distanceBetweenPoints(nipplePosition, injuryPoint, rowPixelSpacing, colPixelSpacing);
    const sufix = !rowPixelSpacing || !colPixelSpacing ? PIXELS_MEASUREMENT_UNIT : MILIMETER_MEASUREMENT_UNIT;
    data.length = transformUnits(`${roundToDecimal(length, 2)} ${sufix}`);
    data.invalidated = false;
  };

  updateCachedStats(image, element, currentData) {
    const toolState = csTools.getToolState(element, this.name);
    this.updateStats(image, currentData);
    toolState?.data.forEach((data) => {
      if (data.uuid === currentData.uuid) return;
      this.updateStats(image, data);
    });
    this.updateAnotherMeasurement(image, element, currentData.uuid);
  }

  getIncomplete(element) {
    const toolState = csTools.getToolState(element, this.name);

    if (toolState && Array.isArray(toolState.data)) return toolState.data.find(({ complete }) => complete === false);
  }

  addNewMeasurement(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;
    const element = evt.detail.element;

    // we validate if the nipple point exists
    const toolState = csTools.getToolState(element, this.name);
    if (!toolState?.data?.[0]) return;

    const incompleteMeasurement = this.getIncomplete(element);
    if (!incompleteMeasurement) {
      const eventData = evt.detail;
      const measurementData = this.createNewMeasurement(eventData);
      csTools.addToolState(element, this.name, measurementData);
      const eventType = csTools.EVENTS.MEASUREMENT_MODIFIED;
      const newEventData = {
        toolName: this.name,
        element,
        measurementData,
      };
      triggerEvent(element, eventType, newEventData);
      cornerstone.updateImage(element);
      return;
    }
    const injuryPoint = {
      x: eventData.currentPoints.image.x,
      y: eventData.currentPoints.image.y,
      highlight: true,
      active: true,
    };
    incompleteMeasurement.complete = true;
    incompleteMeasurement.handles.injuryPoint = injuryPoint;
    const eventType = csTools.EVENTS.MEASUREMENT_MODIFIED;
    const newEventData = {
      toolName: this.name,
      element,
      measurementData: incompleteMeasurement,
    };
    triggerEvent(element, eventType, newEventData);
    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);
  }

  onMeasureModified(evt) {
    if (evt.detail.toolName !== this.name) return;
    const { element } = evt.detail;
    const image = getEnabledElementControlled(element)?.image;
    if (!image) return;
    const data = evt.detail.measurementData;
    if (data.invalidated === true) {
      if (data.length) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    const { length } = data;

    data.value = `D=${length ? `${length}` : ''}`;
  }

  handleSetNipplePosition = async (element) => {
    const toolState = csTools.getToolState(element, this.name);
    const enabledElement = getEnabledElementControlled(element);
    if (!enabledElement?.image) await wait(WAIT_FOR_IMAGE_DELAY);

    // If there is already a measurement, we do not add the mark for the nipple.
    if (toolState?.data[0]) return;

    if (!enabledElement?.image) return;
    const { image } = enabledElement;
    const laterality = getLaterality(image.imageId || '');
    const nipplePosition = getNipplePositionApproach(element);

    this.nipplePosition = nipplePosition;
    const measurementData = {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: '',
      handles: {
        nipplePosition: {
          x: this.nipplePosition.x,
          y: this.nipplePosition.y,
          highlight: true,
          active: true,
          laterality,
        },
        injuryPoint: {
          x: this.nipplePosition.x,
          y: this.nipplePosition.y,
          highlight: false,
          active: true,
          temporal: true,
        },
      },
    };
    csTools.addToolState(element, this.name, measurementData);
    cornerstone.updateImage(element);
  };

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving } = super.configuration;
    const toolData = csTools.getToolState(evt.currentTarget, this.name);
    // If we have no toolData for this element, return immediately as there is nothing to do
    if (!toolData) return;

    const context = getNewContext(eventData.canvasContext.canvas);
    const firstNipplePosition = toolData.data[0]?.handles.nipplePosition;

    for (let i = toolData.data.length - 1; i >= 0; i--) {
      const data = toolData.data[i];

      if (data.visible === false) continue;

      draw(context, (context) => {
        setShadow(context, super.configuration);
        // Differentiate the color of activation tool
        const color = csTools.toolColors.getColorIfActive(data);
        const nonActiveColor = csTools.toolColors.getColorIfActive({});

        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };
        if (i > 0) {
          data.handles.nipplePosition.x = firstNipplePosition.x;
          data.handles.nipplePosition.y = firstNipplePosition.y;
          data.invalidated = true;
        }
        this.nipplePosition = {
          x: data.handles.nipplePosition.x,
          y: data.handles.nipplePosition.y,
        };
        const { nipplePosition, injuryPoint } = data.handles;

        if (!nipplePosition) return;
        const nippleLabel = nipplePosition.laterality || '';

        const nippleLabelCanvasCords = cornerstone.pixelToCanvas(eventData.element, nipplePosition);

        if (i < 1 && super.configuration.drawHandles)
          drawHandles(context, eventData, { end: nipplePosition }, { color: nonActiveColor });
        if (i < 1)
          drawTextBox(context, nippleLabel, nippleLabelCanvasCords.x - 22, nippleLabelCanvasCords.y - 20, color);

        if (!injuryPoint || injuryPoint.temporal) return;
        if (super.configuration.drawHandles) drawHandles(context, eventData, { end: injuryPoint }, handleOptions);
        drawLine(context, eventData.element, nipplePosition, injuryPoint);
        const lineMidPoint = getLineMidpoint(injuryPoint, nipplePosition);
        const lineTextCoords = cornerstone.pixelToCanvas(eventData.element, lineMidPoint as any);
        drawTextBox(context, data.length, lineTextCoords.x, lineTextCoords.y, color);
      });
    }
  }

  validateRemovedMeasurement = (evt) => {
    if (evt.detail.toolName !== this.name) return;
    const { element, measurementData } = evt.detail;
    const measurements = csTools.getToolState(element, this.name).data || [];
    if (measurements.length === 0) return;
    // all measurements have the position of the nipple in the same coordinates so
    // it is enough to validate the first one
    const { nipplePosition } = measurementData.handles;
    if (!nipplePosition) return;
    const enabledElement = getEnabledElementControlled(element);
    if (!enabledElement?.image) return;
    const deletedForInvalidNipplePosition = anyHandlesOutsideImage({ image: enabledElement.image }, { nipplePosition });
    if (!deletedForInvalidNipplePosition) return;
    measurements.forEach((measurement) => {
      csTools.removeToolState(element, this.name, measurement);
    });
  };

  validateIfRemoveNippleMarker = (element) => {
    const toolState = csTools.getToolState(element, this.name);
    // The nipple marker is not removed if there is already more than one measurement
    // or if there is no measurement
    if (!toolState || toolState?.data?.length > 1) return;
    // If there is a measurement but it does not have the marker for the injury, we eliminate it
    const { injuryPoint } = toolState.data[0]?.handles || {};
    // The nipple marker is the only measurement and it isnt moved yet
    if (!injuryPoint || injuryPoint?.temporal) {
      toolState?.data.forEach((measurement) => {
        csTools.removeToolState(element, this.name, measurement);
      });
    }
  };

  handleActiveTool = (evt) => {
    const elementToolState = getElementStateConfig(evt.srcElement);
    if (elementToolState._configuration?.nippleInjuryToolEnabled || elementToolState.mode === 'active') {
      this.handleSetNipplePosition(evt.srcElement);
    } else {
      this.validateIfRemoveNippleMarker(evt.srcElement);
    }
  };

  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    this.handleActiveTool = this.handleActiveTool.bind(this);
    element.addEventListener(NIPPLE_INJURY_UPDATE_CONF_EVENT, this.handleActiveTool);
    element.addEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
  }

  passiveCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
    this.handleActiveTool = this.handleActiveTool.bind(this);
    element.addEventListener(NIPPLE_INJURY_UPDATE_CONF_EVENT, this.handleActiveTool);
    element.addEventListener(csTools.EVENTS.MEASUREMENT_REMOVED, this.validateRemovedMeasurement);
  }

  enabledCallback(element) {
    element.removeEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
    element.removeEventListener(csTools.EVENTS.MEASUREMENT_REMOVED, this.validateRemovedMeasurement);
    element.removeEventListener(NIPPLE_INJURY_UPDATE_CONF_EVENT, this.handleActiveTool);
  }

  disabledCallback(element) {
    element.removeEventListener(csTools.EVENTS.MEASUREMENT_REMOVED, this.validateRemovedMeasurement);
    element.removeEventListener(csTools.EVENTS.MEASUREMENT_MODIFIED, this.onMeasureModified);
    element.removeEventListener(NIPPLE_INJURY_UPDATE_CONF_EVENT, this.handleActiveTool);
  }
}
