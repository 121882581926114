var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect } from 'react';
import { IconCatalog } from '../../Icon';
import { IconSize, BasicInput, BasicInputSize } from '../BasicInput';
/**
 * The inputs are used so that the user can enter text or other characters that he requires.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-05-26
 */
export var SearchInput = function (_a) {
    var _b = _a.inputSize, inputSize = _b === void 0 ? BasicInputSize.md : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, _d = _a.iconSize, iconSize = _d === void 0 ? IconSize.md : _d, _e = _a.isFullWidth, isFullWidth = _e === void 0 ? false : _e, hasError = _a.hasError, _f = _a.value, value = _f === void 0 ? '' : _f, name = _a.name, dataTestId = _a.dataTestId, onChange = _a.onChange, restOfProps = __rest(_a, ["inputSize", "isDisabled", "iconSize", "isFullWidth", "hasError", "value", "name", "dataTestId", "onChange"]);
    var _g = useState(value), currentValue = _g[0], setCurrentValue = _g[1];
    useEffect(function () {
        setCurrentValue(value);
    }, [value]);
    var handleInput = function (event) {
        setCurrentValue(event.target.value);
        if (onChange)
            onChange(event);
    };
    /* Render JSX */
    return (React.createElement(BasicInput, __assign({ name: name, dataTestId: dataTestId, icon: IconCatalog.search, value: currentValue, inputSize: inputSize, iconSize: iconSize, hasError: hasError, 
        // eslint-disable-next-line i18next/no-literal-string
        iconPosition: "left", iconClassName: "text-basic-white", isFullWidth: isFullWidth, isDisabled: isDisabled }, restOfProps, { onChange: handleInput })));
};
