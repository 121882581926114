import type { OrganizationCriticalValueType, StudyCriticalValueType } from '@eva-pacs/client';
import type { CriticalValue } from '~/src/types/CriticalValue';

export const organizationCriticalValueDto = (criticalValue?: OrganizationCriticalValueType): CriticalValue | null => {
  if (!criticalValue) return null;
  return {
    id: criticalValue.criticalValueId,
    name: criticalValue.criticalValueName || '',
  };
};

export const studyCriticalValueDto = (criticalValue?: StudyCriticalValueType): CriticalValue | null => {
  if (!criticalValue) return null;
  return {
    id: criticalValue.id,
    observation: criticalValue.observation || '',
    criticalValueId: criticalValue.criticalValue?.id,
    name: criticalValue.criticalValueName || criticalValue?.criticalValue?.name || '',
  };
};
