import { ProductGroupType } from '@eva-pacs/client';

import { evaProductImage } from '../constants/productImage';
import { EVA_PRODUCT } from '../constants/products';
import { ProductRole } from '../interfaces/ProductRole';

export const productGroupsToProductRolesDTO = (productGroups: Array<ProductGroupType>): Array<ProductRole> => {
  const productRoles: Array<ProductRole> = (productGroups ?? []).map((productGroup) => ({
    product: {
      description: productGroup?.product.description ?? '',
      name: productGroup?.product.name ?? '',
      slug: productGroup?.product.slug ?? '',
      logo: evaProductImage[productGroup?.product.slug as EVA_PRODUCT],
    },
    role: {
      id: productGroup?.group?.id ?? '',
      name: productGroup?.group?.name ?? '',
    },
  }));
  return productRoles;
};
