import cn from 'classnames';
/* Class assignment */
export var imageClass = function (className, imageLoaded, hasMaxWidth) {
    return cn(className, 
    /* Image (base) */
    'transition-opacity duration-500 max-w-none', hasMaxWidth === true && 'max-w-full', 
    /* image--hidden */
    imageLoaded === false && 'opacity-0', 
    /* image--visible */
    imageLoaded === true && 'opacity-100');
};
