import React, { useEffect, useState } from 'react';
import { setGridClass, tableClass, cellClass } from './styles';
/**
 * This is used to "fill in" the grid that the user will change the layout to, if they click on a specific table cell.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-04
 */
var SetGrid = function (_a) {
    var _b = _a.rows, rows = _b === void 0 ? 3 : _b, _c = _a.columns, columns = _c === void 0 ? 3 : _c, _d = _a.defaultSelectedCell, defaultSelectedCell = _d === void 0 ? { row: -1, col: -1 } : _d, _e = _a.boxSize, boxSize = _e === void 0 ? 20 : _e, _f = _a.cellBorder, cellBorder = _f === void 0 ? 1 : _f, className = _a.className, onClick = _a.onClick, onChange = _a.onChange;
    var _g = useState(defaultSelectedCell), selectedCell = _g[0], setSelectedCell = _g[1];
    var _h = useState([[]]), table = _h[0], setTable = _h[1];
    var emptyCell = {
        row: -1,
        col: -1,
    };
    var isRange = function (cell, parentCell) {
        return cell.row <= parentCell.row && cell.col <= parentCell.col;
    };
    var highlightCells = function (currentCell) {
        var table = [];
        for (var row = 0; row < rows; row++) {
            var newRow = [];
            for (var col = 0; col < columns; col++) {
                var cell = { row: row, col: col };
                if (isRange(cell, currentCell))
                    cell.isHover = true;
                if (isRange(currentCell, emptyCell) && isRange(cell, selectedCell))
                    cell.selectedBefore = true;
                newRow.push(cell);
            }
            table.push(newRow);
        }
        setTable(table);
    };
    useEffect(function () {
        highlightCells(emptyCell);
    }, []);
    var handleCellClick = function (currentCell) { return function () {
        setSelectedCell(currentCell);
        highlightCells(currentCell);
        if (onClick)
            onClick(currentCell);
        if (onChange)
            onChange(currentCell);
    }; };
    var handleCellMouseEnter = function (currentCell) { return function () { return highlightCells(currentCell); }; };
    var handleCellMouseLeave = function (currentCell) { return function () { return highlightCells(currentCell); }; };
    var style = {
        minWidth: columns * boxSize + (columns + 5) * cellBorder,
    };
    return (React.createElement("div", { className: setGridClass(className), style: style },
        React.createElement("table", { role: "table", className: tableClass },
            React.createElement("tbody", null, table.map(function (row, i) {
                return (React.createElement("tr", { role: "row", key: i }, row.map(function (cell, j) {
                    /* eslint-disable */
                    return (React.createElement("td", { "aria-label": "cell-".concat(i, "-").concat(j), role: "cell", className: cellClass(cell.isHover), style: {
                            width: boxSize,
                            height: boxSize,
                        }, key: j, onKeyPress: handleCellClick(cell), onMouseEnter: handleCellMouseEnter(cell), onMouseLeave: handleCellMouseLeave(emptyCell), onClick: handleCellClick(cell) }));
                })));
            })))));
};
export default SetGrid;
