import React, { useEffect, useState } from 'react';
import { imageClass } from './styles';
/**
 * Images
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2021-04-22
 */
var Image = React.forwardRef(function (_a, ref) {
    var src = _a.src, srcSet = _a.srcSet, alt = _a.alt, _b = _a.hasMaxWidth, hasMaxWidth = _b === void 0 ? false : _b, noImg = _a.noImg, style = _a.style, className = _a.className, onLoad = _a.onLoad;
    var _c = useState(false), imageLoaded = _c[0], setImageLoaded = _c[1];
    var _d = useState(Boolean(src)), isValidSrc = _d[0], setIsValidSrc = _d[1];
    useEffect(function () {
        setIsValidSrc(Boolean(src));
    }, [src]);
    var handleLoad = function (event) {
        setImageLoaded(true);
        if (onLoad)
            onLoad(event);
    };
    return (React.createElement(React.Fragment, null, isValidSrc ? (React.createElement("img", { ref: ref, className: imageClass(className, imageLoaded, hasMaxWidth), style: style, src: src, srcSet: srcSet, alt: alt, onLoad: handleLoad, onError: function () { return setIsValidSrc(false); } })) : (React.createElement(React.Fragment, null, noImg ? (noImg) : (React.createElement("div", { className: "absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" },
        React.createElement("p", { className: "text-xl text-gray-500 font-semi-bold" }, alt)))))));
});
// eslint-disable-next-line i18next/no-literal-string
Image.displayName = 'Image';
export default Image;
