export var PHYSICAL_UNITS;
(function (PHYSICAL_UNITS) {
    PHYSICAL_UNITS[PHYSICAL_UNITS["NONE"] = 0] = "NONE";
    PHYSICAL_UNITS[PHYSICAL_UNITS["PERCENT"] = 1] = "PERCENT";
    PHYSICAL_UNITS[PHYSICAL_UNITS["DECIBELS"] = 2] = "DECIBELS";
    PHYSICAL_UNITS[PHYSICAL_UNITS["CENTIMETERS"] = 3] = "CENTIMETERS";
    PHYSICAL_UNITS[PHYSICAL_UNITS["SECONDS"] = 4] = "SECONDS";
    PHYSICAL_UNITS[PHYSICAL_UNITS["HERTZ"] = 5] = "HERTZ";
    PHYSICAL_UNITS[PHYSICAL_UNITS["DECIBELS_SECONDS"] = 6] = "DECIBELS_SECONDS";
    PHYSICAL_UNITS[PHYSICAL_UNITS["CENTIMETERS__SECONDS"] = 7] = "CENTIMETERS__SECONDS";
})(PHYSICAL_UNITS || (PHYSICAL_UNITS = {}));
export var FRAME_RATE = 24;
