import React from 'react';
import Icon, { IconCatalog } from '../Icon';
import { containerClass, nativeSelectClass, iconClass } from './styles';
export var NativeSelectSize;
(function (NativeSelectSize) {
    NativeSelectSize["xs"] = "xs";
    NativeSelectSize["sm"] = "sm";
})(NativeSelectSize || (NativeSelectSize = {}));
/**
 * NativeSelect
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-06
 */
var NativeSelect = function (_a) {
    var name = _a.name, options = _a.options, placeholder = _a.placeholder, value = _a.value, _b = _a.size, size = _b === void 0 ? NativeSelectSize.sm : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, className = _a.className, onChange = _a.onChange, dataTestId = _a.dataTestId;
    var renderOptions = options.map(function (item, index) { return (React.createElement("option", { key: index, value: item.value }, item.label)); });
    var handleChange = function (event) {
        if (onChange)
            onChange(event);
    };
    var defaultValue = options.find(function (option) { return option.value === value; });
    return (React.createElement("div", { className: containerClass(className) },
        React.createElement("select", { className: nativeSelectClass(size), name: name, value: (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.value) || '', disabled: isDisabled, "data-testid": dataTestId, onChange: handleChange },
            placeholder && (React.createElement("option", { disabled: true, value: "" }, placeholder)),
            renderOptions),
        React.createElement(Icon, { className: iconClass(size), icon: IconCatalog.caretDown, width: "24px", height: "24px" })));
};
export default NativeSelect;
