import { useEffect } from 'react';
var addBodyClass = function (className) { return document.body.classList.add(className); };
var removeBodyClass = function (className) { return document.body.classList.remove(className); };
export var useBodyClass = function (className) {
    useEffect(function () {
        // Set up
        className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);
        // Clean up
        return function () {
            className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className);
        };
    }, [className]);
};
