import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

const path = csTools.importInternal('drawing/path');

/**
 * Draw a horizontal line defined by `corner1` and `corner4`.
 * @public
 * @method drawHipLine
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context - Target context
 * @param {HTMLElement} element - The DOM Element to draw on
 * @param {Object} corner1 - `{ x, y }` in either pixel or canvas coordinates.
 * @param {Object} corner4 - `{ x, y }` in either pixel or canvas coordinates.
 * @param {Object} options - See {@link path}
 * @param {String} [coordSystem='pixel'] - Can be "pixel" (default) or "canvas". The coordinate
 *     system of the points passed in to the function. If "pixel" then cornerstone.pixelToCanvas
 *     is used to transform the points from pixel to canvas coordinates.
 * @param {Number} initialRotation - Rectangle initial rotation
 * @returns {undefined}
 */
export default (context, element, corner1, corner4, options, coordSystem = 'pixel', initialRotation = 0.0) => {
  if (coordSystem === 'pixel') {
    corner1 = cornerstone.pixelToCanvas(element, corner1);
    corner4 = cornerstone.pixelToCanvas(element, corner4);
  }

  const viewport = cornerstone.getViewport(element);

  if (!viewport) return;

  // Calculate the center of the image
  const { clientWidth: width, clientHeight: height } = element;
  const { scale, translation } = viewport;
  const rotation = viewport.rotation - initialRotation;

  const centerPoint = {
    x: width / 2 + translation.x * scale,
    y: height / 2 + translation.y * scale,
  };

  if (Math.abs(rotation) > 0.05) {
    corner1 = rotatePoint(corner1, centerPoint, -rotation);
    corner4 = rotatePoint(corner4, centerPoint, -rotation);
  }

  const halfDistance = (corner4.x - corner1.x) / 2;

  let corner2 = {
    x: corner1.x + halfDistance,
    y: corner1.y,
  };

  let corner3 = {
    x: corner4.x - halfDistance,
    y: corner4.y,
  };

  if (Math.abs(rotation) > 0.05) {
    corner1 = rotatePoint(corner1, centerPoint, rotation);
    corner2 = rotatePoint(corner2, centerPoint, rotation);
    corner3 = rotatePoint(corner3, centerPoint, rotation);
    corner4 = rotatePoint(corner4, centerPoint, rotation);
  }

  path(context, options, (context) => {
    context.moveTo(corner1.x, corner1.y);
    context.lineTo(corner2.x, corner2.y);
    context.lineTo(corner3.x, corner3.y);
    context.lineTo(corner4.x, corner4.y);
  });
};

interface CartesianCoordinates {
  x: number;
  y: number;
}
const rotatePoint = (point: CartesianCoordinates, center: CartesianCoordinates, angle: number) => {
  const angleRadians = angle * (Math.PI / 180); // Convert to radians

  const rotatedX =
    Math.cos(angleRadians) * (point.x - center.x) - Math.sin(angleRadians) * (point.y - center.y) + center.x;

  const rotatedY =
    Math.sin(angleRadians) * (point.x - center.x) + Math.cos(angleRadians) * (point.y - center.y) + center.y;

  return {
    x: rotatedX,
    y: rotatedY,
  };
};
